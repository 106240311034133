import { extendTheme } from "@chakra-ui/react";

import { breakpoints } from "./breakpoints";
import { spacing } from "./spacing";
import { colors } from "./colors";

export type Theme = typeof theme;

export const theme = {
  spacing,
  colors,
  fontSizes: {
    xxxs: "0.5rem",
    xxs: "0.6rem",
    xs: "0.7rem",
    sm: "1rem",
    base: "1rem",
    md: "1.1rem",
    lg: "1.5rem",
    xl: "2rem",
    xxl: "2.5rem",
    xxxl: "3rem"
  },
  radius: {
    sm: "2px",
    md: "4px",
    lg: "8px",
    rounded: "50%",
    rounded_full: "9999px"
  }
} as const;

// 3. extend the theme
export const chakraTheme = extendTheme({
  ...theme as Theme,
  breakpoints
});

export default theme;