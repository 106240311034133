import { VStack } from "@chakra-ui/react";

const UnstyledFullSpace: typeof VStack = props => (
  <VStack
    w="100%"
    h="100%"
    flex={1}
    spacing="0px"
    {...props}
  />
);

export const FullSpace = UnstyledFullSpace;