import React from "react";
import {
  Text,
  HStack,
  Box,
  VStack,
  Button
} from "@chakra-ui/react";
import { Recommendation } from "src/api/users/footprint";

import theme from "../theme";

interface Props {
    impossible: Recommendation;
    selected: Recommendation;
    onSelect(selected: Recommendation, notSelected: Recommendation): void;
}

const ImpossibleCombination: React.FC<Props> = ({
  impossible,
  selected,
  onSelect
}) => (
  <VStack
    display="flex"
    alignItems="flex-start"
    spacing={4}
  >
    <Box>
      <Text
        textAlign="start"
        fontSize="lg"
      >Impossible Combination
      </Text>

      <Text>{`${selected.recommendation.name} and ${impossible.recommendation.name}`}  cannot both be selected since they are mutually exclusive.
      </Text>
    </Box>

    <HStack
      justifyContent="flex-end"
      width="full"
    >

      <Button
        color={theme.colors.blue[ 500 ]}
        size="sm"
        onClick={() => onSelect(impossible, selected)}
      >Keep {impossible.recommendation.name}
      </Button>

      <Button
        color={theme.colors.blue[ 500 ]}
        size="sm"
        onClick={() => onSelect(selected, impossible)}
      >Choose {selected.recommendation.name}
      </Button>
    </HStack>
  </VStack>
);

export default ImpossibleCombination;