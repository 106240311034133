import { useBreakpointValue as useChakraBreakpointValue } from "@chakra-ui/react";
import { useMemo } from "react";

import { breakpoints } from "../components/theme/breakpoints";

export type BreakpointKey = keyof Record<keyof typeof breakpoints, string>;
export type BreakpointValue = typeof breakpoints[BreakpointKey];

export function useBreakpointKeyValue(): [BreakpointKey, BreakpointValue | undefined] {
  const value = useChakraBreakpointValue(breakpoints);
  // find key for matching value from object
  const key = useMemo(() => Object.keys(breakpoints).find(key => breakpoints[ key as BreakpointKey ] === value), [ value ]) as BreakpointKey;

  return useMemo(() => [ key ?? "base", value ?? "0em" ], [ key, value ]);
}
