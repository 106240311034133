import {
  Box,
  BoxProps
} from "@chakra-ui/react";

import theme from "../theme";
import { ReactComponent as TickSvg } from "../../assets/icons/tick.svg";

type TickProps = {
  size?: BoxProps["width"],
  color?: BoxProps["fill"]
};

export const TickCircle: React.FC<BoxProps & TickProps> = props => {
  const { color, ...restProps } = props;

  return (
    <Box
      width={props.size || "1em"}
      height={props.size || "1em"}
      bg={props.bg ?? props.background ?? "black"}
      borderRadius={theme.radius.rounded}
      display="inline-block"
      p="0.4ch"
      role="img"
      alt="Complete"
      {...restProps}
    >
      <TickSvg
        width="100%"
        height="100%"
        fill={(color ?? "white") as string}
      />
    </Box>
  );
};