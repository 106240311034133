export const gradientBackgrounds = [
  "https://genous-public-assets.s3.eu-west-2.amazonaws.com/public/gradients/gradient_01.jpg",
  "https://genous-public-assets.s3.eu-west-2.amazonaws.com/public/gradients/gradient_02.jpg",
  "https://genous-public-assets.s3.eu-west-2.amazonaws.com/public/gradients/gradient_03.jpg",
  "https://genous-public-assets.s3.eu-west-2.amazonaws.com/public/gradients/gradient_04.jpg",
  "https://genous-public-assets.s3.eu-west-2.amazonaws.com/public/gradients/gradient_05.jpg",
  "https://genous-public-assets.s3.eu-west-2.amazonaws.com/public/gradients/gradient_06.jpg",
  "https://genous-public-assets.s3.eu-west-2.amazonaws.com/public/gradients/gradient_07.jpg",
  "https://genous-public-assets.s3.eu-west-2.amazonaws.com/public/gradients/gradient_08.jpg",
  "https://genous-public-assets.s3.eu-west-2.amazonaws.com/public/gradients/gradient_09.jpg",
  "https://genous-public-assets.s3.eu-west-2.amazonaws.com/public/gradients/gradient_10.jpg",
  "https://genous-public-assets.s3.eu-west-2.amazonaws.com/public/gradients/gradient_11.jpg",
  "https://genous-public-assets.s3.eu-west-2.amazonaws.com/public/gradients/gradient_12.jpg",
  "https://genous-public-assets.s3.eu-west-2.amazonaws.com/public/gradients/gradient_13.jpg"
];

export const getGradientByIndex = (index: number): string => {
  return gradientBackgrounds[ (index + 1) % gradientBackgrounds.length ];
};