import { Image } from "@chakra-ui/react";

export const Logo: React.FC<{ white?: boolean }> = props => {
  const { white = false } = props;

  return (
    <Image
      src={`/assets/Genous-logo${white ? "-white" : ""}.png`}
      alt="Genous"
      className="logo"
      w="130px"
      p={{ md: "2" }}
    />
  );
};