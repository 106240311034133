import {
  storyblokEditable,
  SbBlokData
} from "@storyblok/react";
import {
  Text as ChakraText,
  Image as ChakraImage
} from "@chakra-ui/react";

type ImageBlokProps = { blok: SbBlokData & { image: Array<{ filename: string, alt?: string}>; hidden: boolean } };

export const ImageBlok: React.FC<ImageBlokProps> = ({ blok }) =>
  !blok.hidden && blok.image?.length ? (
    <ChakraText>
      <ChakraImage
        src={blok.image[ 0 ].filename}
        alt={blok.image[ 0 ].alt ?? ""}
        {...storyblokEditable(blok)}
        width="100%"
        maxHeight="auto"
      />
    </ChakraText>
  ) :
    <></>;
