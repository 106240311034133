import {
  storyblokEditable,
  SbBlokData
} from "@storyblok/react";
import { render } from "storyblok-rich-text-react-renderer";

import { ColumnLayout } from "../shared";
import { theme } from "../theme";

type SectionBlokProps = { blok: SbBlokData & { heading: string, content: React.ReactNode[], grey: boolean, hidden: boolean } };

export const SectionBlok: React.FC<SectionBlokProps> = ({ blok }) =>
  !blok.hidden ? (
    <ColumnLayout
      title={blok.heading}
      content={render(blok.content ?? "") ?? ""}
      bg={blok.grey ? theme.colors.gray[ 100 ] : undefined}
      py={theme.spacing[ 20 ]}
      className="section-blok"
      {...storyblokEditable(blok)}
    />
  ) : <></>;
