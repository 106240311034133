import {
  WrapperVariant,
  wrapperSizes
} from "../components/shared/Wrapper";
import { breakpoints } from "../components/theme/breakpoints";

// object keyed by breakpoints, whereby its value is the gutter width down the
// sides of the wrapper width at that given breakpoint
export const cssCalcWrappingGutterMap = (variant: WrapperVariant): Record<string, string> => Object.entries(wrapperSizes[ variant ]).reduce<Record<string, string>>((acc, [ key, value ]) => {
  // max 1rem guards against: 100% - 100% = 0 gutter
  acc[ key ] = `max(1rem, calc((100% - ${value}) / 3))`;

  return acc;
}, {});

type BreakpointKey = keyof Record<keyof typeof breakpoints, string>;
type BreakpointValue = typeof breakpoints[BreakpointKey];

/**
 * Returns max-width of wrapper at given breakpoint
 */
export const getWrapperForBreakpoint = (variant: WrapperVariant, bpv: BreakpointValue): string => {
  for (const _key in breakpoints) {
    const key = _key as BreakpointKey;

    if (breakpoints[ key ] === bpv) {
      return wrapperSizes[ variant ][ key as keyof typeof wrapperSizes[ WrapperVariant ] ];
    }
  }

  return "0";
};