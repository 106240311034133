import {
  Spinner,
  VStack
} from "@chakra-ui/react";
import React from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

import { LoginPageProps } from ".";
import { Button } from "../../components/button";
import { CenterContent } from "../../components/shared/CenterContent";
import { FullSpace } from "../../components/shared/FullSpace";
import { Wrapper } from "../../components/shared/Wrapper";
import { Text } from "../../components/text";
import theme from "../../components/theme";
import { routes } from "../../constants";
import { useAuthContext } from "../../hooks/useAuthContext";

export const OAuthPage: React.FC<LoginPageProps> = ({ location }) => {
  const { authDataState } = useAuthContext();
  const history = useHistory();

  React.useEffect(() => {
    if (authDataState.authenticated) {
      // redirect to previous page, otherwise home page
      history.push(location?.state?.from || "/");
    }
  }, [
    history,
    authDataState,
    location
  ]);

  return (
    <FullSpace
      bg={theme.colors.gray[ 100 ]}
      py={theme.spacing[ 8 ]}
    >
      <Wrapper>
        <CenterContent justifyContent="flex-start">
          <VStack spacing={theme.spacing[ 8 ]}>

            <Text
              as="span"
              fontSize="sm"
              color={theme.fontSizes.sm}
            >
              Authenticating with external provider...
            </Text>

            <Spinner
              size="lg"
              marginTop="2rem"
            />

            <Button
              as={Link}
              to={routes.login}
              marginTop="4rem"
            >
              Return to login
            </Button>
          </VStack>
        </CenterContent>
      </Wrapper>
    </FullSpace>
  );
};