import { Dispatch } from "@reduxjs/toolkit";
import { QuestionSectionGroup } from "src/api/questions";

import {
  Action,
  ActionTypes
} from "../actionTypes";

export const updateSectionGroups = (sectionGroups: QuestionSectionGroup[]) => {
  return (dispatch: Dispatch<Action>): void => {
    dispatch({
      type: ActionTypes.UPDATE_SECTION_GROUPS,
      payload: sectionGroups
    });
  };
};

export const updateCurrentSectionGroup = (currentSectionGroup: number | null) => {
  return (dispatch: Dispatch<Action>): void => {
    dispatch({
      type: ActionTypes.UPDATE_CURRENT_SECTION_GROUP,
      payload: currentSectionGroup
    });
  };
};

export const updatePropertyId = (propertyId?: number) => {
  return (dispatch: Dispatch<Action>): void => {
    dispatch({
      type: ActionTypes.UPDATE_PROPERTY_ID,
      payload: propertyId
    });
  };
};
