import { Box } from "@chakra-ui/react";

import { Button } from "../../components/button/Button";
import { ReactComponent as ArrowSvg } from "../../assets/icons/arrow-up.svg";
import { Text } from "../../components/text";

export const ScrollToTopButton: typeof Button = () => (
  <Button
    inverted
    color="black"
    aria-label="Scroll to top of page"
    textTransform="unset"
    fontWeight="medium"
    onClick={() => window.scrollTo({
      top: 0,
      behavior: "smooth"
    })}
  >
    <Box
      as="span"
      pb="1"
      mr="2"
    >
      <ArrowSvg
        width="1em"
        height="1em"
      />
    </Box>

    <Text as="span">
      Top
    </Text>
  </Button>
);