import { useAxios } from "../../hooks/useAxios";
import { JSONAPIResponse } from "../json-api-response";

// request + response types

type SurveyEndpointConfig = {
  GET_SURVEY_PRODUCT: EndpointConfig<
    {
      selectedAddons?: {
        solar: boolean;
        insulation: boolean;
        heating: boolean;
      }
    },
    JSONAPIResponse<{
        priceId: string;
        label: string;
        price: number;
        checkoutLink: string;
        availableAddons: {
          solar: boolean;
          insulation: boolean;
          heating: boolean;
        }
        selectedAddons: {
          solar: boolean;
          insulation: boolean;
          heating: boolean;
        }
      }>
    >
    GET_INVOICE: EndpointConfig<
    { invoiceId: string },
    JSONAPIResponse<{
      id: string;
      status: "PENDING" | "COMPLETE" | "CANCELLED";
      amount: number;
    }>

  >;
};

const requestInitialisers: RequestConfig<SurveyEndpointConfig> = {
  GET_SURVEY_PRODUCT: (
    formData => ({
      url: "/survey/product",
      method: "GET",
      params: formData
    })
  ),
  GET_INVOICE: (
    formData => ({
      url: "/survey/invoice",
      method: "GET",
      params: formData
    })
  )
};

export const useSurveyAPI: UseApiRequest<SurveyEndpointConfig> = name => useAxios(requestInitialisers[ name ]);
