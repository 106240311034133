import {
  Answer,
  ImportV2Result,
  Question
} from ".";
import { useAxios } from "../../hooks/useAxios";
import { JSONAPIResponse } from "../json-api-response";

type NoParams = Record<string, never>;

interface DownloadDrivewayRequest {
  radiators: any;
  answers: Answer[]
}

interface SaveToDatabaseRequest {
  propertyId: number
  answers: Answer[]
}

interface CalculateRequest {
  propertyId?: number;
  methodology?: string;
  output?: string;
  answers: Answer[]
}

interface ImportRequest {
  propertyId: number
}

interface SaveToIRRequest {
  co2e: number;
  sapNumber: number;
  potentialCO2: number;
  potentialSAP: number;
  potentialSaving: number;
  propertyId: number;
  recommendations: Array<{
    slug: string;
    title: string;
    co2Saving: number;
    savingPerYear: number;
    cost: number;
  }>
}

interface InteventionsRequest {
  slugs: string[];
  answers?: Answer[]
}

interface InterestRequest {
  recommendationId: number;
  interested: boolean;
}

type V2ConfigEndpointConfig = {
  CALCULATE: EndpointConfig<
  CalculateRequest | {warmup: true}, JSONAPIResponse<any>
  >;
  FORM: EndpointConfig<
  NoParams,
  JSONAPIResponse<Question[]>
  >;
  IMPORT: EndpointConfig<
  ImportRequest,
  JSONAPIResponse<ImportV2Result>
  >;
  IMPORT_SURVEY: EndpointConfig<
  any,
  JSONAPIResponse<any>
  >;
  "SAVE-TO-IR": EndpointConfig<SaveToIRRequest, void>;
  RUN_INTERVENTIONS: EndpointConfig<
  InteventionsRequest,
  JSONAPIResponse<{
    comparison: {
      sap: number;
      co2: number;
      costToRun: number;
      costToInstall: number;
      ror: number;
      payoff: number;
    },
    calculation: any
    dwelling: any
  }>
  >;
  COMBINE_RECOMMENDATIONS: EndpointConfig<
  InteventionsRequest,
  {
    comparison: {
      sap: number;
      co2: number;
      costToRun: number;
      costToInstall: number;
      sapGrade: number;
      sapRating: string;
      ror: number;
      payoff: number;
    },
    calculation: any;
    dwelling: any;
    }
  >;
  UPDATE_INTEREST: EndpointConfig<
  InterestRequest
  >;
  SAVE_DOC: EndpointConfig<
  CalculateRequest, JSONAPIResponse<string>
  >;
  SAVE_DRIVEWAY: EndpointConfig<
  SaveToDatabaseRequest, JSONAPIResponse<string>
  >;
  DOWNLOAD_DRIVEWAY: EndpointConfig<
  DownloadDrivewayRequest, JSONAPIResponse<string>
  >;
  FILE_UPLOAD: EndpointConfig<
  {contentType: string}, JSONAPIResponse<{url: string; fileURL: string}>
  >;
  PV_PERFORMANCE: EndpointConfig<
   {
    costOfInstallation: number;
    timePeriodToEvaluate: number;
    annualElectricityPriceRise: number;
    annualExportPriceRise: number;
    answers: Answer[];
    changes: string[];
  }, JSONAPIResponse<{
    retrofitAdded: boolean;
    batteryEESS: number | null;
    batteryAdded: boolean;
    timePeriodToEvaluate: number;
    annualExportPriceRise: number;
    annualElectricityPriceRise: number;
    costOfInstallation: number;
    batteryType?: "LARGE" | "SMALL";
    toBePVBatteryMCS: { calculation: Calculation };
    toBEPVBatteryGenous: { calculation: Calculation };
    retrofitMCS: { calculation: Calculation };
    retrofitGenous: { calculation: Calculation };
  }>
  >;
  HP_PERF: EndpointConfig<HPRequest, JSONAPIResponse<HPResponse>>;
  WARMUP: EndpointConfig<NoParams, JSONAPIResponse<any>>;
};

const requestInitialisers: RequestConfig<V2ConfigEndpointConfig> = {
  "FORM": (
    () => ({
      url: "/v2/form",
      method: "GET",
      timeout: 100000
    })
  ),
  "CALCULATE": (
    req => ({
      url: "/v2/calculate",
      method: "POST",
      timeout: 100000,
      timeoutErrorMessage: "Timed out",
      data: req
    })
  ),
  "SAVE_DOC": (
    req => ({
      url: "/v2/recommendation-doc",
      method: "POST",
      timeout: 100000,
      timeoutErrorMessage: "Timed out",
      data: req
    })
  ),
  "IMPORT": (
    req => ({
      url: "/v2/import",
      method: "POST",
      data: req
    })
  ),
  "IMPORT_SURVEY": (
    req => ({
      url: "/v2/survey-import",
      method: "POST",
      data: req
    })
  ),
  "SAVE-TO-IR": (
    req => ({
      url: "/v2/copy-v2-rec",
      method: "POST",
      data: req
    })
  ),
  "RUN_INTERVENTIONS": (
    req => ({
      url: "/v2/run-interventions",
      method: "POST",
      timeout: 100000,
      timeoutErrorMessage: "Timed out",
      data: req
    })
  ),
  "COMBINE_RECOMMENDATIONS": (
    req => ({
      url: "/users/run-recommendations",
      method: "POST",
      timeout: 100000,
      data: req
    })
  ),
  "UPDATE_INTEREST": (
    req => ({
      url: "/users/update-recommendation-interested",
      method: "PATCH",
      params: req
    })
  ),
  "WARMUP": (
    () => ({
      url: "/v2/warmup",
      method: "POST"
    })
  ),
  "SAVE_DRIVEWAY": (
    req => ({
      url: "/v2/save-answers",
      method: "POST",
      data: req
    })
  ),
  "DOWNLOAD_DRIVEWAY": (
    req => ({
      url: "/v2/driveway",
      method: "POST",
      data: req
    })
  ),
  "FILE_UPLOAD": (
    req => ({
      url: "/v2/file-upload",
      method: "POST",
      data: req
    })
  ),
  "PV_PERFORMANCE": (
    req => ({
      url: "/v2/pv-performance",
      method: "POST",
      data: req
    })
  ),
  "HP_PERF": (
    req => ({
      url: "/v2/ashp-performance",
      method: "POST",
      data: req
    })
  )

};

export const useV2ConfigAPI: UseApiRequest<V2ConfigEndpointConfig> = name => useAxios(requestInitialisers[ name ]);

interface Calculation {
  elecTabG43: number;
  elecTabC44: string;
  // 145
  elecTabD145: number;
  elecTabE145: number;
  elecTabF145: number;
  // C 263
  elecTabC263: number;
  elecTabC236: number;
  elecTabC333: number;
  elecTabC360: number;
  // D
  elecTabD263: number;
  elecTabD333: number;
  elecTabO263: number;
  elecTabD236: number;
  elecTabD360: number;
  // E
  elecTabE263: number;
  elecTabE333: number;
  elecTabE236: number;
  elecTabE360: number;
  // F
  elecTabF263: number;
  elecTabF333: number;
  elecTabF236: number;
  elecTabF360: number;
  // G
  elecTabG263: number;
  elecTabG333: number;
  elecTabG236: number;
  elecTabG360: number;
  // H
  elecTabH263: number;
  elecTabH333: number;
  elecTabH236: number;
  elecTabH360: number;
  // I
  elecTabI263: number;
  elecTabI333: number;
  elecTabI236: number;
  elecTabI360: number;
  // J
  elecTabJ263: number;
  elecTabJ333: number;
  elecTabJ236: number;
  elecTabJ360: number;
  // K
  elecTabK263: number;
  elecTabK333: number;
  elecTabK236: number;
  elecTabK360: number;
  // L
  elecTabL263: number;
  elecTabL333: number;
  elecTabL236: number;
  elecTabL360: number;
  // M
  elecTabM263: number;
  elecTabM333: number;
  elecTabM236: number;
  elecTabM360: number;
  // N
  elecTabN263: number;
  elecTabN333: number;
  elecTabN236: number;
  elecTabN360: number;
  elecTabC179: number;
  elecTabD179: number;
  elecTabE179: number;
  elecTabF179: number;
  elecTabG179: number;
  elecTabH179: number;
  elecTabI179: number;
  elecTabJ179: number;
  elecTabL179: number;
  elecTabM179: number;
  elecTabK179: number;
  elecTabN179: number;
  elecTabC393: number;
  elecTabD393: number;
  elecTabE393: number;
  elecTabF393: number;
  elecTabG393: number;
  elecTabH393: number;
  elecTabI393: number;
  elecTabJ393: number;
  elecTabK393: number;
  elecTabL393: number;
  elecTabM393: number;
  elecTabN393: number;
  method: string;
  installedCapacityPV1: number;
  installedCapacityPV2: number;
  installedCapacityPV3: number;
  orientationDegSouthPV1: number;
  orientationDegSouthPV2: number;
  orientationDegSouthPV3: number;
  orientationDegHorizontalPV1: number;
  orientationDegHorizontalPV2: number;
  orientationDegHorizontalPV3: number;
  postcodeRegion: string;
  kWhkWpYrPV1: number;
  kWhkWpYrPV2: number;
  kWhkWpYrPV3: number;
  shadeFactorPV1: number;
  shadeFactorPV2: number;
  shadeFactorPV3: number;
  estAnnualOutputPV1: number;
  estAnnualOutputPV2: number;
  estAnnualOutputPV3: number;
  assumedOccupancyArchetypeC44: string;
  assumedOccupancyArchetypeG43: string;
  elecTabO236: number;
  elecTabO360: number;
  elecTabO303: number;
  elecTabC397: number;
  elecTabC398: number;
  elecTabC399: number;
  elecTabC400: number;
  elecTabO179: number;
  elecTabG78: number;
  outputsK35: number;
  outputsL35: number;
  componentDataF2047: number;
  futurePVNumPanels1: number;
  futurePVNumPanels2: number;
  futurePVNumPanels3: number;
  co2e_cars: number;
  co2e_home: number;
  sap_number: number;
  sap_grade: string;
  electricity_demand: number;
  electricity_grid: number;
  electricity_exported: number;
  electricity_generated: number;
  heat_loss_to_maintain_temp: number;
  useful_gains: number;
  overall_space_heating: number;
  heat_1_percent_of_load: number;
  heat_1_efficiency: number;
  heat_2_percent_of_load: number;
  heat_2_efficiency: number;
  space_heating_1_energy: number;
  space_heating_2_energy: number;
  total_space_heating_energy: number;
  space_heating_kwh_m2_yr: number;
  hot_water_demand: number;
  total_heat_required: number;
  pv_diverter_input_sap_10_only_or_sap_app: number;
  hot_water_energy_required: number;
  hot_water_efficiency: number;
  hw_heating_load: number;
  electricity_used_by_pumps: number;
  electricity_generated_by_pv: number;
  net_electricity_benefit: number;
  space_heating_cost_electricity: number;
  space_heating_cost_other_fuel: number;
  hot_water_cost_electricity: number;
  hot_water_cost_other: number;
  energy_saving_cost: number;
  total_energy_cost: number;
  cost_exc_ff_cars: number;
  cost_inc_ff_cars: number;
  co2e_inc_ff_cars: number;
  peak_heat_demand: number;
  mb_heat_perimeter_losses_wall: number;
  mb_heat_perimeter_losses_floor: number;
  mb_heat_perimeter_losses_roof: number;
  e1_heat_perimeter_losses_wall: number;
  e1_heat_perimeter_losses_floor: number;
  e1_heat_perimeter_losses_roof: number;
  e2_heat_perimeter_losses_wall: number;
  e2_heat_perimeter_losses_floor: number;
  e2_heat_perimeter_losses_roof: number;
  e3_heat_perimeter_losses_wall: number;
  e3_heat_perimeter_losses_floor: number;
  e3_heat_perimeter_losses_roof: number;
  e4_heat_perimeter_losses_wall: number;
  e4_heat_perimeter_losses_floor: number;
  e4_heat_perimeter_losses_roof: number;
  tfa: number;
  kwp_output: number;
  window_area: number;
  window_area_e1: number;
  window_area_e2: number;
  window_area_e3: number;
  window_area_e4: number;
  perimeterMeasured: number;
  perimeterAssumed: number;
  rir_top_ceiling_area_mb: number;
  rir_top_ceiling_area_e1: number;
  rir_top_ceiling_area_e2: number;
  rir_top_ceiling_area_e3: number;
  rir_top_ceiling_area_e4: number;
  rir_top_stud_wall_area_computed_mb: number;
  rir_top_stud_wall_area_computed_e1: number;
  rir_top_stud_wall_area_computed_e2: number;
  rir_top_stud_wall_area_computed_e3: number;
  rir_top_stud_wall_area_computed_e4: number;
  rir_slope_area_computed_mb: number;
  rir_slope_area_computed_e1: number;
  rir_slope_area_computed_e2: number;
  rir_slope_area_computed_e3: number;
  rir_slope_area_computed_e4: number;
  main_window_area: number;
  e1_window_area: number;
  e2_window_area: number;
  e3_window_area: number;
  e4_window_area: number;
  secondaryGlazingC79: number;
  trvsC72: number;
  fuel: {
    [key: string]: {
      standingCharge: number;
      pricePerKwh: number
    }
  }
  outputsTab: {
    k38: number;
    k34: number;
  };
  elecNotEpc: {
    d70: number;
    e70: number;
    f70: number;
    c44: string;
    d81: string;
    e81: string;
    f81: string;
  };
  car: {
    "car-1-fuel"?: string
    "car-2-fuel"?: string
    "car-3-fuel"?: string
    "car-4-fuel"?: string
    "car-1-mileage"?: number
    "car-2-mileage"?: number
    "car-3-mileage"?: number
    "car-4-mileage"?: number
    "car-1-percent-home-charging"?: number
    "car-2-percent-home-charging"?: number
    "car-3-percent-home-charging"?: number
    "car-4-percent-home-charging"?: number
  }
}

interface HPRequest {
  answers: Answer[];
  roomByRoom: RoomByRoom[];
  changes: string[];
  capacityOfSystem: number;
  numberOfUnits: number;
  shieldingOfASHP: string;
  numOfSurfaces: number;
  pureHPHybrid: string;
  distanceToAssessmentPoint: number;
  numberOfBedrooms: number;
  epcHotWaterRequirement: number;
  renewableHeatingCoverage: string;
  validEPCNumber: string;
  epcSpacingHeatingRequirement: number;
  timePeriodToEvalulate: number;
  annualFossilFuelPriceRiseAssumed: number;
  annualElectricityPriceRiseAssumed: number;
  heatPump: {
    manufacturer: string;
    model: string;
    mcsNumber: string;
    capacityPerUnit: number;
    scop35C: number;
    scop40C: number;
    scop45C: number;
    scop50C: number;
    scop55C: number;
    scop60C: number;
    scop65C: number;
  }
  cylinder: {
    name: string;
    volume: number;
    coilSize: number;
    immersionHeaterPower: number;
    minutesBeforeStart: number;
    usePerWeek: number;
  }
  costs: {
    installationCost: number;
    timePeriod: number;
    elecPriceRise: number;
    fossilFuelPriceRise: number;
  }
}

interface HeatingSystem {
  manufacturer: string;
  model: string;
  sapDescription: string;
  mcsHeatPercent?: number;
  genousHeatPercent?: number;
  percentEfficiency: number;
  fuel: string;
  fuelPricePerkWh: number;
}

interface RoomByRoom {
  name: string;
  specificHeatLossWM2: number;
  existingEmitters: string;
  toBeRemoved: boolean;
  newEmitters: string;
  tempAchieved: number;
  agreedTempTarget: number;
  floorArea: number;
  heatLossTargetTemp: number;
  passOrFail: "PASS" | "FAIL";
  includeInHeatLossCalc: boolean;
}

interface HPResponse {
  pdf: string;
  epcSpaceHeatingRequirementOutputsC6: number;
  epcHotWaterRequirementOutputsC7: number;
  locationForDesignTemp: string;
  validEPCNumber: string;
  epcSpacingHeatingRequirement: number;
  epcHotWaterRequirement: number;
  numberOfBedrooms: number;
  worstPerformingRoom: string;
  numberOfPeople: number;
  numberOfUnits: number;
  locationForDesignTemperature: string;
  altitude: string;
  distanceToAssessmentPoint?: number;
  numOfSurfaces: number;
  shieldingOfASHP: string;
  heatingSystem1: HeatingSystem;
  heatingSystem2?: HeatingSystem;
  secondaryHeatingSystem?: HeatingSystem;
  hotWaterSystem: HeatingSystem;
  hotWaterCylinderSize: string;
  heatPumpSelected: {
    manufacturer: string;
    modelNumber: string;
    mcsCertNum: string;
  }
  capacityOfSystem: number;
  renewableHeatingCoverage: string;
  pureHPHybrid: string;
  cylinderName: string;
  cylinderVolume: number;
  cylinderMinMCSRecVolume: number;
  cylinderCoilSize: number;
  immersionHeaterPower: number;
  // D. Design Parameters
  targetTempInLivingArea: {mcs: number; genous: number};
  designTempChoice: {mcs: number; genous: number};
  designTempAmbient: {mcs: number; genous: number};
  hotWaterCylinderTemp: {mcs: number; genous: number};
  flowTempHigh: {mcs: number; genous: number};
  flowTempLow: {mcs?: number; genous: number};
  heatingSCOPEquivAtDesignTemp: {mcs?: number; genous: number};
  hotWaterSCOPEquivAtDesignTemp: {mcs?: number; genous: number};
  ambientTempHeatingStartsAt: {mcs?: number; genous: number};
  immersionDetailsMinutesBeforeStart: number;
  immersionUsePerWeek: number;
  // E. Heating Performance
  renewableHeatRequiredForPropertySpaceHeating: {
    mcs: number;
    genous: number;
  }
  renewableHeatRequiredForPropertySpaceHotWater: {
    mcs: number;
    genous: number;
  }
  renewableHeatRequiredTotal: {
    mcs: number;
    genous: number;
  }
  otherEnergySupplied: {
    mcs: number;
    genous: number;
  }
  totalEnergyAssessed: {
    mcs: number;
    genous: number;
  }
  heatedFloorArea: {
    mcs: number;
    genous: number;
  }
  averagePeakHeatLossPerSM: {
    mcs: number;
    genous: number;
  }
  peakHeatDemand: {
    mcs: number;
    genous: number;
  }
  hotWaterRequiredPerDay: {
    mcs: number;
    genous: number;
  }
  hotWaterRecoveryTimeNoImmersion: {
    mcs: number;
    genous: number;
  }
  hotWaterRecoveryTimeWithImmersion: {
    mcs: number;
    genous: number;
  }
  energyUsedSpaceHeating: {
    mcs: {
      existing?: number;
      proposed?: number;
      difference?: number;
    }
    genous: {
      existing: number;
      proposed: number;
      difference: number;
    }
  }
  energyUsedHotWater: {
    mcs: {
      existing?: number;
      proposed?: number;
      difference?: number;
    }
    genous: {
      existing: number;
      proposed: number;
      difference: number;
    }
  }
  energyUsedTotal: {
    mcs: {
      existing?: number;
      proposed?: number;
      difference?: number;
    }
    genous: {
      existing: number;
      proposed: number;
      difference: number;
    }
  }
  // F. Financial and carbon performance
  installationCostGross: number;
  annualRunCostSavingsEstimate: {
    mcs: number;
    genous: number;
  }
  boilerUpgradeSchemeGrant: number;
   netInstallationCost: number;
   timePeriodToEvaluate: number;
   annualElectricityPriceRiseAssumed: number;
   annualFossilFuelPriceRiseAssumed: number;
  annualReturnPercent: {
    mcs: number;
    genous: number;
  }
  annualCO2eReduction: {
    mcs: number;
    genous: number;
  }
  totalPeriodRunCostSaving: {
    mcs: number;
    genous: number;
  }
  impliedPaybackPeriod: {
    mcs: number;
    genous: number;
  }
  totalReturnsExpenditure: {
    mcs: number;
    genous: number;
  }
  totalPeriodCO2eAvoided: {
    mcs: number;
    genous: number;
  }
  // G. Room-by-room details
  rooms: RoomByRoom[];
  // H. Compliance details
  correctedSoundPressureLevels: {
    mcs: number;
    genous: string;
  }
  roomByRoomTempApproved: {
    mcs: string;
    genous: string;
  }
  amountOfHeatLoadHPCanProvide: {
    mcs: number;
    genous: string;
  }
  amountAnnualHeatingRequiredHPProvide: {
    mcs: number;
    genous: string;
  }
  amountOfAnnualHeatingHybridWillProvide: {
    mcs: number;
    genous: string;
  }
  isHotWaterCoveredByHPORElectricAlready: {
    mcs: string;
    genous: string;
  }
  isValidEPCPlaceConsistentMCSRules: {
    mcs: string;
    genous: string;
  }
  compliantInstallBus: string
  compliantInstallNoBus: string
  monthlyFuturePerformance: {
    kwh: {
      ashpSpaceHeating: {
        jan: number;
        feb: number;
        march: number;
        april: number;
        may: number;
        june: number;
        july: number;
        aug: number;
        sept: number;
        oct: number;
        nov: number;
        dec: number;
      }
      hybridTopUp: {
        jan: number;
        feb: number;
        march: number;
        april: number;
        may: number;
        june: number;
        july: number;
        aug: number;
        sept: number;
        oct: number;
        nov: number;
        dec: number;
      }
      secondaryHeating: {
        jan: number;
        feb: number;
        march: number;
        april: number;
        may: number;
        june: number;
        july: number;
        aug: number;
        sept: number;
        oct: number;
        nov: number;
        dec: number;
      }
      hotWater: {
      jan: number;
      feb: number;
      march: number;
      april: number;
      may: number;
      june: number;
      july: number;
      aug: number;
      sept: number;
      oct: number;
      nov: number;
      dec: number;
      }
    }
    cost: {
      total: {
        jan: number;
        feb: number;
        march: number;
        april: number;
        may: number;
        june: number;
        july: number;
        aug: number;
        sept: number;
        oct: number;
        nov: number;
        dec: number;
      }
      ashpSpaceHeating: {
        jan: number;
        feb: number;
        march: number;
        april: number;
        may: number;
        june: number;
        july: number;
        aug: number;
        sept: number;
        oct: number;
        nov: number;
        dec: number;
      }
      hybridTopUp: {
        jan: number;
        feb: number;
        march: number;
        april: number;
        may: number;
        june: number;
        july: number;
        aug: number;
        sept: number;
        oct: number;
        nov: number;
        dec: number;
      }
      secondaryHeating: {
        jan: number;
        feb: number;
        march: number;
        april: number;
        may: number;
        june: number;
        july: number;
        aug: number;
        sept: number;
        oct: number;
        nov: number;
        dec: number;
      }
      hotWater: {
        jan: number;
        feb: number;
        march: number;
        april: number;
        may: number;
        june: number;
        july: number;
        aug: number;
        sept: number;
        oct: number;
        nov: number;
        dec: number;
      }
    }
  }
}
