import { RadioCard } from "./RadioCard";

const EPCRadioCard: typeof RadioCard = props => (
  <RadioCard
    containerProps={{
      maxW: "200px",
      minW: "40%",
      bg: "white",
      borderRadius: "md"
    }}
    radioCheckboxProps={{
      borderWidth: "1px",
      borderRadius: "md",
      boxShadow: "md",
      _checked: {
        boxShadow: "0 0 0 2px dodgerblue",
        border: 0
      },
      _focus: { boxShadow: "outline" },
      _hover: { boxShadow: "outline" },
      px: 5,
      py: 3,
      height: "100%"
    }}
    {...props}
  />
);

export default EPCRadioCard;