import { Box } from "@chakra-ui/react";

import { ReactComponent as _BoltSvg } from "../../assets/icons/lightning-bolt.svg";
import theme from "../theme";

export const BoltSvg: typeof Box = props => (
  <Box
    w="1.2em"
    h="1.2em"
    color={theme.colors.blue[ 500 ]}
    {...props}
  >
    <_BoltSvg
      width="100%"
      height="100%"
      fill="currentColor"
    />
  </Box>
);