import React from "react";
import {
  Flex,
  Heading,
  Text,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer
} from "@chakra-ui/react";

import MonthlyFuturePerformanceAndCost from "./charts/MonthlyFuturePerformanceAndCost";

const ASHPDoc = () => {
  return (
    <Flex
      direction="column"
      margin={4}
      gap={8}
    >
      <Heading textAlign="center">ASHP Performance estimate - MCS and Genous Methodologies</Heading>

      {/* Property Details*/}
      <Heading
        className='table-header'
        as='h4'
        size='sm'
        padding={2}
      >A. Property Details
      </Heading>

      <Flex
        justifyContent={{ md: "space-between" }}
        direction={{
          base: "column",
          md: "row"
        }}
      >
        {/* table 1 */}
        <TableContainer>
          <Table variant='simple'>

            <Tbody>
              <Tr>
                <Td>EPC Number</Td>

                <Td>Value Here</Td>

              </Tr>

              <Tr>
                <Td>Location for design temperature</Td>

                <Td>Value Here</Td>

              </Tr>

              <Tr>
                <Td>Altitude</Td>

                <Td>Value Here</Td>

              </Tr>

            </Tbody>

          </Table>
        </TableContainer>

        {/* table 2 */}
        <TableContainer>
          <Table variant='simple'>

            <Tbody>
              <Tr>
                <Td>Number of bedrooms</Td>

                <Td>Value Here</Td>

              </Tr>

              <Tr>
                <Td>Number of people in property</Td>

                <Td>Value Here</Td>

              </Tr>

            </Tbody>

          </Table>
        </TableContainer>

        {/* table 3 */}

        <TableContainer>
          <Table variant='simple'>

            <Tbody>
              <Tr>
                <Td>Distance to assessment point</Td>

                <Td>Value Here</Td>

              </Tr>

              <Tr>
                <Td># surfaces &lt; 1m from unit</Td>

                <Td>Value Here</Td>

              </Tr>

              <Tr>
                <Td>Shielding of ASHP</Td>

                <Td>Value Here</Td>

              </Tr>

            </Tbody>

          </Table>
        </TableContainer>
      </Flex>

      {/* Existing System */}

      <Heading
        className='table-header'
        as='h4'
        size='sm'
        padding={2}
      >B. Existing System
      </Heading>

      <TableContainer>
        <Table variant='simple'>

          <Thead>
            <Tr>
              <Th />

              <Th />

              <Th />

              <Th />

              <Th
                colSpan={2}
                textAlign="center"
              >% heat
              </Th>

              <Th />

              <Th />
            </Tr>

            <Tr>
              <Th />

              <Th>Manufacturer</Th>

              <Th>Model</Th>

              <Th>SAP Description</Th>

              <Th>MCS/EPC</Th>

              <Th>Genous</Th>

              <Th>% efficiency</Th>

              <Th>Fuel</Th>

              <Th>Fuel Price per kWh</Th>
            </Tr>
          </Thead>

          <Tbody>
            <Tr>
              <Td>Heating System 1</Td>

              <Td>{" "}</Td>

              <Td>{" "}</Td>

              <Td>None</Td>

              <Td>{" "}</Td>

              <Td>{" "}</Td>

              <Td>{" "}</Td>

              <Td>Value Here</Td>

              <Td>{" "}</Td>

            </Tr>

            <Tr>
              <Td>Heating System 2</Td>

              <Td>{" "}</Td>

              <Td>{" "}</Td>

              <Td>None</Td>

              <Td>{" "}</Td>

              <Td>{" "}</Td>

              <Td>{" "}</Td>

              <Td>Value Here</Td>

              <Td>{" "}</Td>

            </Tr>

            <Tr>
              <Td>Secondary heating system</Td>

              <Td>N/A</Td>

              <Td>N/A</Td>

              <Td>Value Here </Td>

              <Td>{" "}</Td>

              <Td>{" "}</Td>

              <Td>{" "}</Td>

              <Td>Value Here</Td>

              <Td>{" "}</Td>

            </Tr>

            <Tr>
              <Td>Hot water system</Td>

              <Td>{" "}</Td>

              <Td>{" "}</Td>

              <Td>Value Here </Td>

              <Td>N/A</Td>

              <Td>N/A</Td>

              <Td>{" "}</Td>

              <Td>Value Here</Td>

              <Td>{" "}</Td>

            </Tr>

            <br />

            <Tr>
              <Td>Hot water cylinder size</Td>

              <Td>{" "}</Td>

            </Tr>

          </Tbody>

        </Table>
      </TableContainer>

      {/* Proposed System */}
      <Heading
        className='table-header'
        as='h4'
        size='sm'
        padding={2}
      >C. Proposed System
      </Heading>

      <TableContainer>
        <Table variant='simple'>

          <Thead>
            <Tr>
              <Th />

              <Th>Name
              </Th>

              <Th>Model Number
              </Th>

              <Th>MCS Certification Number</Th>

            </Tr>

          </Thead>

          <Tbody>
            <Tr>
              <Td>Heat Pump Selected</Td>

              <Td>Value Here</Td>

              <Td>Value Here</Td>

              <Td>Value Here</Td>

            </Tr>

          </Tbody>

        </Table>
      </TableContainer>

      <TableContainer>
        <Table variant='simple'>
          <Tbody>
            <Tr>
              <Td> Number of units</Td>

              <Td>
                {" "}
              </Td>
            </Tr>

            <Tr>
              <Td> Capacity of System</Td>

              <Td>
                {" "}
              </Td>

            </Tr>

            <Tr>
              <Td> Type of Installation</Td>

              <Td>
                {" "}
              </Td>

              <Td>
                MCS Recommended Size
              </Td>

            </Tr>

            <Tr>
              <Td> Cylinder Size</Td>

              <Td>
                {" "}
              </Td>

              <Td>
                {" "}
              </Td>

            </Tr>

            <Tr>
              <Td> Cylinder coil Size</Td>

              <Td>
                {" "}
              </Td>

            </Tr>

            <Tr>
              <Td>Immersion Heater Power</Td>

              <Td>
                {" "}
              </Td>

            </Tr>

          </Tbody>
        </Table>
      </TableContainer>

      {/* Design Parameters */}
      <Heading
        className='table-header'
        as='h4'
        size='sm'
        padding={2}
      >D. Design Parameters
      </Heading>

      <Flex
        justifyContent={{ md: "space-between" }}
        direction={{
          base: "column",
          md: "row"
        }}
      >
        <TableContainer>
          <Table variant='simple'>
            <Thead>
              <Tr>
                <Thead />

                <Th>MCS</Th>

                <Th>Genous Target</Th>

                <Th />
              </Tr>
            </Thead>

            <Tbody>
              <Tr>
                <Td>Target temperature in living area</Td>

                <Td />

                <Td />

                <Td>Degrees C</Td>
              </Tr>

              <Tr>
                <Td>Target temperature choice</Td>

                <Td />

                <Td />

                <Td />
              </Tr>

              <Tr>
                <Td>Design temperature (ambient, corrected)</Td>

                <Td />

                <Td />

                <Td>Degrees C</Td>
              </Tr>

              <Tr>
                <Td>Hot Water cylinder temperature</Td>

                <Td />

                <Td />

                <Td>Degrees C</Td>
              </Tr>

              <Tr>
                <Td>Flow temperature - high</Td>

                <Td />

                <Td />

                <Td>Degrees C</Td>
              </Tr>

              <Tr>
                <Td>Flow temperature - low</Td>

                <Td />

                <Td />

                <Td>Degrees C</Td>
              </Tr>

              <Tr>
                <Td>Heating SCOP / Equivalent at design temperature</Td>

                <Td />

                <Td />

                <Td />
              </Tr>

              <Tr>
                <Td>Hot Water SCOP / Equivalent at design temperature</Td>

                <Td />

                <Td />

                <Td />
              </Tr>

              <Tr>
                <Td>Ambient temperature heating starts at</Td>

                <Td />

                <Td />

                <Td>Degrees C</Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>

        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th />

                <Th>Minutes before start</Th>

                <Th>Use Per Week</Th>
              </Tr>
            </Thead>

            <Tbody>
              <Tr>
                <Td>Immersion details</Td>

                <Td />

                <Td />
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </Flex>

      {/* Heating Performance */}
      <Heading
        className='table-header'
        as='h4'
        size='sm'
        padding={2}
      >E. Heating Performance
      </Heading>

      <TableContainer>
        <Table variant='simple'>
          <Thead>
            <Tr>
              <Th />

              <Th>MCS/EPC</Th>

              <Th>Genous</Th>

              <Th />
            </Tr>
          </Thead>

          <Tbody>
            <Tr>
              <Td>Energy required to heat property</Td>

              <Td />

              <Td />

              <Td>kWh</Td>
            </Tr>

            <Tr>
              <Td>Energy required for hot water</Td>

              <Td />

              <Td />

              <Td>kWh</Td>
            </Tr>

            <Tr>
              <Td>Less thermostatically-controlled electric heating</Td>

              <Td />

              <Td />

              <Td>kWh</Td>
            </Tr>

            <Tr>
              <Td>Total energy required</Td>

              <Td />

              <Td />

              <Td>kWh</Td>
            </Tr>

            <Tr>
              <Td>Heated Floor Area</Td>

              <Td />

              <Td />

              <Td>m<sup>2</sup></Td>
            </Tr>

            <Tr>
              <Td>Average peak heat loss per square metre</Td>

              <Td />

              <Td />

              <Td>W/m<sup>2</sup></Td>
            </Tr>

            <Tr>
              <Td>Peak heat demand</Td>

              <Td />

              <Td />

              <Td>kW</Td>
            </Tr>

            <Tr>
              <Td>Hot water required per day</Td>

              <Td />

              <Td />

              <Td>litres</Td>
            </Tr>

            <Tr>
              <Td>Hot water recovery - no immersion</Td>

              <Td />

              <Td />

              <Td>minutes</Td>
            </Tr>

            <Tr>
              <Td>Hot water recovery - with immersion</Td>

              <Td />

              <Td />

              <Td>minutes</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>

      <TableContainer>
        <Table variant='simple'>
          <Thead>
            <Tr>
              <Th />

              <Th
                colSpan={3}
                textAlign='center'
              >MCS
              </Th>

              <Th
                colSpan={3}
                textAlign='center'
              >Genous Target
              </Th>
            </Tr>

            <Tr>
              <Th />

              <Th>Existing</Th>

              <Th>Proposed</Th>

              <Th>Difference</Th>

              <Th>Existing</Th>

              <Th>Proposed</Th>

              <Th>Difference</Th>
            </Tr>
          </Thead>

          <Tbody>
            <Tr>
              <Td>Energy used - space heating</Td>

              <Td />

              <Td />

              <Td />

              <Td />

              <Td />

              <Td />

              <Td>kWh</Td>
            </Tr>

            <Tr>
              <Td>Energy used - hot water</Td>

              <Td />

              <Td />

              <Td />

              <Td />

              <Td />

              <Td />

              <Td>kWh</Td>
            </Tr>

            <Tr>
              <Td>Energy used - total</Td>

              <Td />

              <Td />

              <Td />

              <Td />

              <Td />

              <Td />

              <Td>kWh</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>

      {/* Financial and Carbon Performance */}
      <Heading
        className='table-header'
        as='h4'
        size='sm'
        padding={2}
      >F. Financial and Carbon Performance
      </Heading>

      <Flex
        justifyContent={{ md: "space-between" }}
        direction={{
          base: "column",
          md: "row"
        }}
      >
        <TableContainer>
          <Table variant='simple'>
            <Tbody>
              <Tr>
                <Td>Installation cost (gross)</Td>

                <Td />

                <Td />
              </Tr>

              <Tr>
                <Td>Boiler Upgrade Scheme Grant</Td>

                <Td />

                <Td />
              </Tr>

              <Tr>
                <Td>Net installation cost</Td>

                <Td />

                <Td />
              </Tr>

              <Tr>
                <Td>Time period to evaluate</Td>

                <Td />

                <Td>Years</Td>
              </Tr>

              <Tr>
                <Td>Annual electricity price rise assumed</Td>

                <Td />

                <Td>% p.a.</Td>
              </Tr>

              <Tr>
                <Td>Installation cost (gross)</Td>

                <Td />

                <Td>% .p.a.</Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>

        <TableContainer>
          <Table variant='simple'>
            <Thead>
              <Tr>
                <Th />

                <Th>MCS</Th>

                <Th>Genous Target</Th>

                <Th />
              </Tr>
            </Thead>

            <Tbody>
              <Tr>
                <Td>Annual run cost estimate</Td>

                <Td />

                <Td />

                <Td />
              </Tr>

              <Tr>
                <Td>Annual return (%)</Td>

                <Td />

                <Td />

                <Td />
              </Tr>

              <Tr>
                <Td>Annual CO<sub>2</sub>e reduction</Td>

                <Td />

                <Td />

                <Td>Tonnes .p.a.</Td>
              </Tr>

              <Tr>
                <Td>Total period run cost savings</Td>

                <Td />

                <Td />

                <Td />
              </Tr>

              <Tr>
                <Td>Implied payback period</Td>

                <Td />

                <Td />

                <Td>Years</Td>
              </Tr>

              <Tr>
                <Td>Total returns / expenditure</Td>

                <Td />

                <Td />

                <Td />
              </Tr>

              <Tr>
                <Td>Total period CO<sub>2</sub>e avoided</Td>

                <Td />

                <Td />

                <Td>Years</Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </Flex>

      {/* Room by room details */}
      <Heading
        className='table-header'
        as='h4'
        size='sm'
        padding={2}
      >
        G. Room By Room Details
      </Heading>

      <TableContainer>
        <Table variant='simple'>
          <Thead>
            <Tr>
              <Th />

              <Th>Specific heat loss w/m<sup>2</sup></Th>

              <Th>New Radiators?</Th>

              <Th>Radiator 1</Th>

              <Th>Radiator 2</Th>

              <Th>Radiator 3</Th>

              <Th>Temperature achieved</Th>

              <Th>Agreed temperature target</Th>

              <Th>Pass/Fail</Th>
            </Tr>
          </Thead>

          <Tbody>
            <Tr>
              <Td>Kitchen</Td>

              <Td />

              <Td />

              <Td />

              <Td />

              <Td />

              <Td
                bgColor='red'
                color='white'
              >20
              </Td>

              <Td bgColor='green.100'>21</Td>

              <Td
                bgColor='red'
                color='white'
              >FAIL
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>

      <TableContainer>
        <Table variant='simple'>
          <Tbody>
            <Tr>
              <Td>Worst performing room</Td>

              <Td />
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>

      {/*Compliance details */}
      <Heading
        className='table-header'
        as='h4'
        size='sm'
        padding={2}
      >
        H. Compliance Details
      </Heading>

      <TableContainer>
        <Table variant='simple'>
          <Thead>
            <Tr>
              <Th />

              <Th>MCS</Th>

              <Th />
            </Tr>
          </Thead>

          <Tbody>
            <Tr>
              <Td>Corrected sound pressure level</Td>

              <Td>39</Td>

              <Td
                bgColor='green.100'
                color='white'
              >PASS
              </Td>
            </Tr>

            <Tr>
              <Td>Room-by-room temperatures approved</Td>

              <Td>YES</Td>

              <Td
                bgColor='green.100'
                color='white'
              >PASS
              </Td>
            </Tr>

            <Tr>
              <Td>Amount of heat load HP can provide</Td>

              <Td>80%</Td>

              <Td
                bgColor='red'
                color='white'
              >PASS
              </Td>
            </Tr>

            <Tr>
              <Td>Oversize required for hot water</Td>

              <Td />

              <Td
                bgColor='green.100'
                color='white'
              >PASS
              </Td>
            </Tr>

            <Tr>
              <Td>Amount of annual heating HP will provide</Td>

              <Td />

              <Td
                bgColor='green.100'
                color='white'
              >PASS
              </Td>
            </Tr>

            <Tr>
              <Td>Amount of annual heating hybrid will provide</Td>

              <Td />

              <Td
                bgColor='green.100'
                color='white'
              >PASS
              </Td>
            </Tr>

            <Tr>
              <Td>Is a valid EPC in place consistent with MCS rules</Td>

              <Td />

              <Td
                bgColor='green.100'
                color='white'
              >PASS
              </Td>
            </Tr>

            <Tr>
              <Td>Any metering to be installed</Td>

              <Td>39</Td>

              <Td
                bgColor='green.100'
                color='white'
              >PASS
              </Td>
            </Tr>

            <Tr>
              <Td>BUS applied for</Td>

              <Td>39</Td>

              <Td
                bgColor='green.100'
                color='white'
              >PASS
              </Td>
            </Tr>

            <Tr>
              <Td>DNO accepted</Td>

              <Td />

              <Td
                bgColor='green.100'
                color='white'
              >PASS
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>

      <TableContainer>
        <Table variant='simple'>
          <Tbody>
            <Tr>
              <Td>Compliant install - BUS</Td>

              <Td />
            </Tr>

            <Tr>
              <Td>Compliant install - NO BUS</Td>

              <Td />
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>

      {/*Monthly future performance and cost estimate */}
      <Heading
        className='table-header'
        as='h4'
        size='sm'
        padding={2}
      >
        I. Monthly future performance and cost estimate
      </Heading>

      <MonthlyFuturePerformanceAndCost />

    </Flex>
  );
};

export default ASHPDoc;