import theme from ".";

const easedGrayGradient = `
    hsl(0, 0%, 97%) 0%,
    hsl(0, 0%, 96.94%) 8.1%,
    hsl(0, 0%, 96.76%) 15.5%,
    hsl(0, 0%, 96.48%) 22.5%,
    hsl(0, 0%, 96.12%) 29%,
    hsl(0, 0%, 95.7%) 35.3%,
    hsl(0, 0%, 95.24%) 41.2%,
    hsl(0, 0%, 94.75%) 47.1%,
    hsl(0, 0%, 94.25%) 52.9%,
    hsl(0, 0%, 93.76%) 58.8%,
    hsl(0, 0%, 93.3%) 64.7%,
    hsl(0, 0%, 92.88%) 71%,
    hsl(0, 0%, 92.52%) 77.5%,
    hsl(0, 0%, 92.24%) 84.5%,
    hsl(0, 0%, 92.06%) 91.9%,
    hsl(0, 0%, 92%) 100%
`;

export const gradients = {
  lightGray: `linear-gradient(${theme.colors.white[ 50 ]}, ${theme.colors.white[ 150 ]})`,
  // easing-gradient for smoother gradient
  // ref: https://larsenwork.com/easing-gradients/#editor
  lightGray2: `linear-gradient(
    to bottom,
    ${easedGrayGradient}
  )`,
  lightGray2reversed: `linear-gradient(
    to top,
    ${easedGrayGradient}
  )`,
  underConstruction: "repeating-linear-gradient(-45deg, yellow, yellow 10px, black 10px, black 20px)"
} as const;