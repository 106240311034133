export const routes = {
  home: "/",
  bsaEvent: "/bsa-event-register",
  landing: "/landing",
  oauth: "/oauth",
  footprint: "/footprint",
  footprintNextSteps: "/next-steps",
  footprintAnon: "/footprint-anon/",
  purchaseSurvey: "/purchase-survey",
  surveyInvoice: "/survey/complete",
  takeAction: "/footprint/take-action",
  scheduleCallConfirmed: "/footprint/schedule-call/confirmed",
  profile: "/profile",
  changePassword: "/change-password",
  changeEmail: "/change-email",
  register: "/register",
  verify: "/register/verify",
  polypipe: "/polypipe",
  login: "/retrieve",
  retrieve: "/retrieve",
  retrieveCode: "/retrieve/code",
  forgotPassword: "/forgot-password",
  admin: "/admin",
  resetPassword: "/forgot-password/reset",
  welcome: "/sections/welcome",
  suggestSignUp: "/sections/sign-up",
  section: (sectionId: number | ":sectionId") => `/sections/${sectionId}`,
  question: (sectionId: number | ":sectionId", questionGroupId: number | ":groupId") => `/sections/${sectionId}/questions/${questionGroupId}`,
  about: "/about",
  experience: "/about/experience",
  howItWorks: "/about/how-it-works",
  whatsNext: "/about/whats-next",
  aboutGenous: "/about-genous",
  expertsGenous: "/about-genous/experts",
  credentialsGenous: "/about-genous/credentials",
  customersGenous: "/about-genous/customers",
  insights: "/insights",
  insightsNewsHub: "/insights/news-hub",
  insightsOurBlog: "/insights/blog",
  insightsCaseStudies: "/insights/case-studies",
  reduce: "/reduce-your-carbon",
  reduceSection: (subject: string) => `/reduce-your-carbon/${subject}`,
  renewableHeating: "/renewable-heating",
  caseStudies: "/case-studies",
  caseStudiesSection: (subject: string) => `/case-studies/${subject}`,
  usefulInformation: "/useful-information",
  usefulInformationSection: (subject: string) => `/useful-information/${subject}`,
  blog: "/blog",
  blogSection: (subject: string) => `/blog/${subject}`,
  faqs: "/faqs",
  docs: "/docs",
  docsPVPerformance: "/docs/pv-performance",
  docsASHP: "/docs/ashp",
  docsGenous: "/docs/genous",
  docsMCS: "/docs/mcs",
  genousDoc: (title: string) => `/docs/genous/${title}`,
  mcsDoc: (title: string) => `/docs/mcs/${title}`,
  contact: "/contact",
  privacyPolicy: "/privacy-policy",
  termsAndConditions: "/terms-and-conditions",
  // offsetPlans: "/offset-plans",
  selectedOffsetPlan: "/offsets-plans/plan",
  offsets: "/offsets",
  managePlan: "/offsets/plan/manage",
  changePlan: "/offsets/plan/change",
  setupPlan: "/offsets/plan/setup",
  confirmPlan: "/offsets/complete",
  pipedrive: "/pipedrive",
  v2: "/v2",
  driveway: "/driveway",
  testASHP: "/ashp-test"

} as const;
