import {
  Dispatch,
  useEffect
} from "react";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "src/redux/useTypeSelector";

function resetCurrentSectionGroup(dispatch: Dispatch<{
  type: "UPDATE_CURRENT_SECTION_GROUP",
  payload: 0
}>) {
  dispatch({
    type: "UPDATE_CURRENT_SECTION_GROUP",
    payload: 0
  });
}

/**
 * Resets to a state we can re-run the review process from scratch, post-authentication
 */
export function useResetCurrentSectionGroup(): void {
  const currentSectionGroup = useTypedSelector(
    state => state.sectionGroups.currentSectionGroupIndex
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (currentSectionGroup !== 0) {
      resetCurrentSectionGroup(dispatch);
    }
  }, [ currentSectionGroup, dispatch ]);
}