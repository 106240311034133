import {
  Box,
  Button,
  Text,
  Spinner,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  HStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  ModalOverlay
} from "@chakra-ui/react";
import _ from "lodash";
import {
  useEffect,
  useState
} from "react";
import { Answer } from "src/api/v2";
import { useV2ConfigAPI } from "src/api/v2/config";
import theme from "src/components/theme";
import { formatCurrency } from "src/helpers/number";
import { CalculationReport } from "src/pages/v2/components/calc/Report";
import { useHistory } from "react-router";
import { ReactComponent as QuestionSVG } from "src/assets/icons/question-mark-circle.svg";
import { useUsersApi } from "src/api/users/users.api";
import { Recommendation } from "src/api/users/footprint";
import { FaArrowAltCircleRight } from "react-icons/fa";

  interface Props {
    answers?: Answer[];
    selectedSlugs: string[];
    recommendations: Recommendation[];
  }

  interface RanRecommendations {
    cost: number ;
    co2: number ;
    sap: number ;
    costToInstall: number ;
    sapRating: string;
    payoff: number ;
    ror: number ;
  }

export const RecommendationFooter: React.FC<Props> = ({
  selectedSlugs, answers, recommendations
}) => {
  const [ combineRecommendationsResponse, runCombineRecommendations ] = useV2ConfigAPI("COMBINE_RECOMMENDATIONS");
  const [ showRecommendationsReport, setShowRecommendationsReport ] = useState(false);
  const [ dirty, setDirty ] = useState(true);
  const [ firstRun, setFirstRun ] = useState(true);
  const [ ranRecommendations, setRanRecommendations ] = useState<RanRecommendations | null>(null);
  const history = useHistory();
  const [ , callbackRequest ] = useUsersApi("REQUEST_CALL");

  useEffect(() => {
    if (firstRun) {
      setFirstRun(false);

      runCombineRecommendations({
        slugs: selectedSlugs,
        answers: answers || undefined
      });
    }
  }, [
    firstRun,
    runCombineRecommendations,
    selectedSlugs,
    answers
  ]);

  useEffect(() => {
    setDirty(true);
  }, [ selectedSlugs ]);

  useEffect(() => {
    if (combineRecommendationsResponse.complete && combineRecommendationsResponse?.data) {
      setRanRecommendations({
        co2: combineRecommendationsResponse.data.comparison.co2,
        cost: combineRecommendationsResponse.data.comparison.costToRun,
        sapRating: combineRecommendationsResponse.data.comparison.sapRating,
        sap: combineRecommendationsResponse.data.comparison.sap,
        costToInstall: combineRecommendationsResponse.data.comparison.costToInstall,
        ror: combineRecommendationsResponse.data.comparison.ror,
        payoff: combineRecommendationsResponse.data.comparison.payoff
      });
      setDirty(false);
    }
  }, [ combineRecommendationsResponse ]);

  if (selectedSlugs.length === 0) {
    return null;
  }

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        position="fixed"
        zIndex={5}
        bottom={0}
        left={0}
        style={{ borderImage: "linear-gradient(to right, #9B72F2, #88D69E) 1" }}
        borderWidth={2}
        right={0}

      >
        <Box
          display="flex"
          background={theme.colors.black}
          color={theme.colors.white}
          opacity={0.9}
          padding={5}
          height={100}
          whiteSpace="nowrap"
          flexDir="column"
          justifyContent="center"
        >

          <Box
            display="flex"
            alignItems="center"
            justifyContent='space-between'
            width="full"
            px={{
              base: 0,
              md: 6
            }}
          >
            <Box>

              {combineRecommendationsResponse.complete && !combineRecommendationsResponse.pending && !combineRecommendationsResponse.error && ranRecommendations?.co2 && !dirty && (
                <Box
                  display="flex"
                  flexDirection="row"

                  alignItems="center"
                  flexGrow={2}
                >

                  <HStack
                    flexDirection='row'
                    justifyContent="space-between"
                    spacing={theme.spacing[ 12 ]}
                  >
                    <Box>
                      <Telemetry
                        variable="CO2 Saving"
                        value={ranRecommendations.co2.toFixed(1)}
                      />
                    </Box>

                    <Box>
                      <Telemetry
                        variable="Cost Saving"
                        value={formatCurrency(Number(ranRecommendations.cost.toFixed(0))) || "N/A"}
                      />
                    </Box>

                    <Box
                      display={{
                        base: "none",
                        md: "inline-block"
                      }}
                    >
                      <Telemetry
                        variable="SAP Change"
                        value={`${ranRecommendations.sapRating} ${ranRecommendations.sap >= 0 ? "+" : ""}${ranRecommendations.sap?.toFixed(0)}` || "N/A"}
                      />
                    </Box>

                    <Box
                      display={{
                        base: "none",
                        md: "inline-block"
                      }}
                    >
                      <Telemetry
                        variable="Cost to Install"
                        value={ranRecommendations.costToInstall ? formatCurrency(Number(ranRecommendations.costToInstall.toFixed(0))) : "N/A"}
                      />
                    </Box>

                    <Box
                      display={{
                        base: "none",
                        md: "inline-block"
                      }}
                    >
                      <Telemetry
                        variable="RoR"
                        value={`${ranRecommendations.ror?.toFixed(1)}%`}
                      />
                    </Box>

                    <Box
                      display={{
                        base: "none",
                        xl: "inline-block"
                      }}
                    >
                      <Telemetry
                        variable="Payoff"
                        value={`${ranRecommendations.payoff?.toFixed(1)}yrs`}
                      />
                    </Box>

                  </HStack>
                </Box>
              )}

              {combineRecommendationsResponse.error && (
                <>
                  <Text
                    display={{
                      base: "none",
                      lg: "inline-block"
                    }}
                    fontSize="sm"
                  >Error running recommendations
                  </Text>

                </>
              )}

              {combineRecommendationsResponse.pending && (
                <Spinner size='sm' />
              )}
            </Box>

            <Box
              display="flex"
              alignItems="center"
            >

              <Box
                display={{
                  base: "none",
                  lg: "inline-block"
                }}
                marginRight={4}
                whiteSpace="pre-wrap"
                color="black"
              >
                <Popover trigger="hover">
                  <PopoverTrigger>
                    <QuestionSVG
                      width="1em"
                      height="1em"
                      fill={theme.colors.white}
                      cursor="pointer"
                    />
                  </PopoverTrigger>

                  <PopoverContent>
                    <PopoverBody>
                      At any point if you change the selections, click ‘re-run recommendations’ at the bottom of the screen to update the cumulative benefit of all interventions; our digital twin does the hard work of evaluating all the interactions, and we can refine these further as we get more information.
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Box>

              <Button
                disabled={combineRecommendationsResponse.pending || !dirty}
                size="sm"
                border="1px solid #34eba4"
                background="none"
                _hover={{ background: "#34eba4" }}
                display={{
                  base: "none",
                  lg: "inline-block"
                }}
                onClick={() => {
                  runCombineRecommendations({
                    slugs: selectedSlugs,
                    answers: answers || undefined
                  });
                }}
              >Re-Run Recommendations
              </Button>

              <Button
                disabled={combineRecommendationsResponse.pending}
                size="sm"
                border="1px solid #34eba4"
                background="none"
                justifySelf="flex-end"
                rightIcon={<FaArrowAltCircleRight />}
                marginLeft={5}
                _hover={{ background: "#34eba4" }}
                onClick={() => {
                  callbackRequest({
                    useEmail: true,
                    usePhone: false,
                    actions: recommendations.filter(r => r.interested).map(r => r.recommendation.name)
                  });

                  // Weird bug, if setTimeout does not exist, callbackRequest function above does not call backend?
                  setTimeout(() => {
                    history.push("/next-steps", {
                      combineRecommendationsResponse,
                      tickerData: {
                        co2_saving: ranRecommendations?.co2.toFixed(2),
                        cost_saving: formatCurrency(ranRecommendations.cost) || "N/A",
                        ending_epc: ranRecommendations.sapRating,
                        cost_to_install: ranRecommendations.costToInstall ? formatCurrency(ranRecommendations.costToInstall) : "N/A",
                        ror: `${ranRecommendations.ror?.toFixed(2)}%`,
                        payoff: `${ranRecommendations.payoff?.toFixed(1)}yrs`
                      }
                    });
                  }, 50);
                }}
              >Next
              </Button>
            </Box>
          </Box>

        </Box>
      </Box>

      <Modal
        isOpen={showRecommendationsReport}
        onClose={() => setShowRecommendationsReport(false)}
        size="6xl"
      >
        <ModalOverlay />

        <ModalContent>
          <ModalCloseButton />

          <ModalBody
            px={theme.spacing[ 32 ]}
            py={theme.spacing[ 12 ]}
          >

            <CalculationReport
              loading={false}
              dwelling={combineRecommendationsResponse.data?.dwelling}
              report={combineRecommendationsResponse.data?.calculation}
              mapping={combineRecommendationsResponse.data?.dwelling}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

interface PropsTelemetry {
    variable: string;
    value: string;
}

const Telemetry = ({ variable, value }: PropsTelemetry) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
    >
      <Text
        fontSize="sm"
        textColor="white"
      >{variable}
      </Text>

      <Text
        fontSize="md"
        fontWeight="bold"
        textColor={theme.colors.green[ "100" ]}
      >{value}
      </Text>

    </Box>
  );
};