import { createBreakpoints } from "@chakra-ui/theme-tools";

/**
 * @see https://chakra-ui.com/docs/features/responsive-styles
 *
 * based on TailwindCSS breakpoints (https://tailwindcss.com/docs/breakpoints)
 */
export const breakpoints = createBreakpoints({
  "sm": "640px",
  "md": "768px",
  "lg": "1024px",
  "xl": "1280px",
  "2xl": "1536px"
} as const);