import {
  Box,
  BoxProps,
  HStack,
  useDisclosure,
  Collapse,
  Center,
  Button as ChakraButton
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import {
  useHistory,
  Link
} from "react-router-dom";
import { ReactComponent as ChevronDown } from "src/assets/icons/chevron-down.svg";
import { ReactComponent as ChevronUp } from "src/assets/icons/chevron-up.svg";
import { Wrapper } from "src/components/shared";

import { useAuthContext } from "../../../hooks/useAuthContext";
import { Text } from "../../../components/text";
import theme from "../../../components/theme";
import { routes } from "../../../constants";

const AnimatedBox = motion<Omit<BoxProps, "transition">>(Box);

export const MakeChangesSection: React.FC = () => {
  const { onToggle, isOpen } = useDisclosure();
  const { authDataState } = useAuthContext();
  const isAuthenticated = authDataState.authenticated;
  const history = useHistory();

  return (
    <AnimatedBox
      width="100%"
      {...animatedProps}
      mb={theme.spacing[ 8 ]}
    >
      <Wrapper variant="text">

        <Text
          as="h4"
          fontSize={theme.fontSizes.lg}
          fontWeight="semibold"
          letterSpacing="-0.9px"
        >
          Make meaningful changes
        </Text>

        <Text>
          Ready to see what might work for your property?
          {isAuthenticated ? (
            " Tick the interventions you might be interested in – you’re not committed at this point so more is better – and click ‘Next’ when you’re ready."
          ) : (
            <Text
              color={theme.colors.gray[ 700 ]}
              fontSize="sm"
            >Make sure to
              {" "}

              <Text
                as={Link}
                to={{
                  pathname: routes.register,
                  state: { from: history.location.pathname }
                }}
                textDecoration="underline"
              >
                save your recommendations
              </Text>

              {" "}
              to further customise your install options.
            </Text>
          )}

        </Text>

        <>
          <HStack
            onClick={onToggle}
            cursor="pointer"
          >
            <Text
              color={theme.colors.gray[ 700 ]}
            >More about how this works
            </Text>

            {isOpen ? (
              <ChevronUp
                width="1rem"
                fill={theme.colors.gray[ 700 ]}
              />
            ) : (
              <ChevronDown
                width="1rem"
                fill={theme.colors.gray[ 700 ]}
              />
            )}
          </HStack>

          <Collapse
            in={isOpen}
            animateOpacity
          >
            <Text color={theme.colors.gray[ 700 ]}>
              <Text
                color={theme.colors.gray[ 700 ]}
                my={theme.spacing[ 4 ]}
              >

                We've identified a number of interventions that may work for you – our algorithm divides those that should be possible based into 'high potential', which are pre-selected, and 'medium potential', which aren’t initially selected but you can pick from to make a list that you’d like to explore further (there are a number of low potential interventions which we don’t show you at this stage).

                <br />

                {isAuthenticated && (
                  <>
                    <br />
                    For each option, we show the stand-alone impact and likely budget, but the black ticker at the bottom of the screen shows the combined impact of those you’ve selected all together. If you change what’s in selected, click The “Re-run Recommendations” budget and our software will recalculate the cumulative impact.

                    <br />
                  </>
                )}

                <br />

                We’ll refine the answers and options as you give us more information; if you’d like to short-cut that, a home survey can help – see the box below. Or you can speak to one of our advisers and we’ll help you out.

              </Text>
            </Text>
          </Collapse>

        </>
      </Wrapper>
    </AnimatedBox>
  );
};

const animatedProps: Parameters<typeof AnimatedBox>[0] = {
  initial: {
    opacity: 0,
    y: 20
  },
  whileInView: {
    opacity: 1,
    y: 0
  },
  viewport: {
    amount: .2,
    once: true
  },
  transition: {
    type: "spring",
    mass: 0.5,
    stiffness: 60
  }
};