/**
 * Returns array of values:
 * - filters out false / undefined
 * - if truthy bool, return KEY, otherwise return VALUE
 */
export const getAnswerValues = (keyValues: string | number | Record<string, string>): string[] => {
  // The answer type could be a single string or number, in which case we just want to return this value
  // We convert it to string and
  if (typeof keyValues === "string" || typeof keyValues === "number") return [ String(keyValues) ];

  return Object.keys(keyValues)
    // Remove undefined answers from submission
    .filter(key => String(keyValues[ key ]) !== undefined)
    // Remove false answers from submission
    .filter(key => String(keyValues[ key ]) !== "false")
    // Map true values to the key that they're associated with (e.g. cavity: true becomes just cavity)
    .map(key => {
      if (String(keyValues[ key ]) === "true") {
        return key;
      }

      return keyValues[ key ];
    });
};