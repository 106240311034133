import {
  AgeBand,
  WallsShared,
  PropertyType,
  GlazingType,
  WallConstruction,
  FloorDescription,
  RoofInsulationThickness
} from "./types";

export const builtForm = (epc: string): WallsShared => {
  const lower = epc.toLowerCase();

  if (lower.includes("detached")) {
    return WallsShared[ "Detached" ];
  }

  if (lower.includes("enclosed") && lower.includes("mid")) {
    return WallsShared[ "EnclosedMidTerrace" ];
  }

  if (lower.includes("mid-")) {
    return WallsShared[ "MidTerrace" ];
  }

  if (lower.includes("semi")) {
    return WallsShared[ "SemiDetached" ];
  }

  return Object.values(BuiltForm).find(key => {
    if (key.toLowerCase().match(RegExp(epc.toLowerCase()))) {
      return key;
    }
  }) as WallsShared;
};

export const propertyType = (epc: string): PropertyType => {
  if (epc === "Flat") {
    return PropertyType.FlatOrMaisonette;
  }

  return Object.values(PropertyTypeEPC).find(key => {
    if (key.toLowerCase().match(RegExp(epc.toLowerCase()))) {
      return key;
    }
  }) as PropertyType;
};

export const ageBand = (epc: string): AgeBand => {
  const res = Object.values(ConstructionAgeBand).find(key => {
    if (key.toLowerCase().match(RegExp(epc.toLowerCase()))) {
      return true;
    }
  }) as AgeBand;

  return res.split(":")[ 1 ].trim();
};

export const glazingType = (epc: string) => {
  const lower = epc.toLowerCase();

  switch (epc.toLowerCase()) {
    case "fully double glazed" :
      return GlazingType[ "Double Glazing < 2002PVC frame 6mm gap / non-PVC" ];
    case "single glazed":
      return GlazingType[ "Single Glazing" ];
    case "full secondary glazing":
      return GlazingType[ "Secondary Glazing" ];
    case "mostly secondary glazing":
      return GlazingType[ "Secondary Glazing" ];
    case "fully triple glazed":
      return GlazingType[ "Triple Glazing" ];
    case "some secondary glazing" :
      return GlazingType[ "Secondary Glazing" ];
    case "some double glazing":
      return GlazingType[ "Double Glazing < 2002PVC frame 6mm gap / non-PVC" ];
    case "partial double glazing":
      return GlazingType[ "Double Glazing < 2002PVC frame 6mm gap / non-PVC" ];
    case "partial secondary glazing":
      return GlazingType[ "Secondary Glazing" ];
  }

  if (lower.includes("single")) {
    return GlazingType[ "Single Glazing" ];
  }

  return GlazingType[ "Double Glazing < 2002PVC frame 6mm gap / non-PVC" ];
};

export const wallConstruction = (epc: string) => {
  const lower = epc.toLowerCase();

  if (lower.includes("cavity")) {
    return WallConstruction[ "Cavity" ];
  }

  if (lower.includes("cob")) {
    return WallConstruction[ "Cob wall" ];
  }

  if (lower.includes("granite")) {
    return WallConstruction[ "Granite or whinstone" ];
  }

  if (lower.includes("whinstone")) {
    return WallConstruction[ "Granite or whinstone" ];
  }

  if (lower.includes("park")) {
    return WallConstruction[ "Park home wall" ];
  }

  if (lower.includes("sandstone")) {
    return WallConstruction[ "Sandstone or limestone" ];
  }

  if (lower.includes("limestone")) {
    return WallConstruction[ "Sandstone or limestone" ];
  }

  if (lower.includes("brick")) {
    return WallConstruction[ "Solid brick" ];
  }

  if (lower.includes("system")) {
    return WallConstruction[ "System built" ];
  }

  if (lower.includes("timber")) {
    return WallConstruction[ "Timber Frame" ];
  }

  return WallConstruction[ "Cavity" ];
};

export const floorDescription = (epc: string) => {
  const lower = epc.toLowerCase();

  if (lower.includes("suspended")) {
    return FloorDescription[ "Suspended" ];
  }

  if (lower.includes("solid")) {
    return FloorDescription[ "Solid" ];
  }

  if (lower.includes("below")) {
    return FloorDescription[ "(another dwelling below)" ];
  }

  if (lower.includes("external")) {
    return FloorDescription[ "To external air" ];
  }

  if (lower.includes("unheated")) {
    return FloorDescription[ "To unheated space" ];
  }

  return FloorDescription.Solid;
};

export const roofInsThickness = (epc: string): RoofInsulationThickness => {
  const lower = epc.toLowerCase();

  if (lower.includes("above")) {
    return RoofInsulationThickness.Zero;
  }

  if (lower.includes("no insulation")) {
    return RoofInsulationThickness.Zero;
  }

  if (lower.includes("25mm")) {
    return RoofInsulationThickness.TwentyFiveMM;
  }

  if (lower.includes("50mm")) {
    return RoofInsulationThickness.FiftyMM;
  }

  if (lower.includes("75mm")) {
    return RoofInsulationThickness.SeventyFiveMM;
  }

  if (lower.includes("100mm")) {
    return RoofInsulationThickness.OneHundredMM;
  }

  if (lower.includes("150mm")) {
    return RoofInsulationThickness.OneHundredFiftyMM;
  }

  if (lower.includes("200mm")) {
    return RoofInsulationThickness.TwoHundredMM;
  }

  if (lower.includes("250mm")) {
    return RoofInsulationThickness.TwoHundredFiftyMM;
  }

  if (lower.includes("270mm")) {
    return RoofInsulationThickness.TwoHundredSeventyMM;
  }

  if (lower.includes("300mm")) {
    return RoofInsulationThickness.ThreeHundredMM;
  }

  if (lower.includes("350mm")) {
    return RoofInsulationThickness.ThreeHundredFiftyMM;
  }

  if (lower.includes("400mm")) {
    return RoofInsulationThickness.FourHundredMM;
  }

  return RoofInsulationThickness.NI;
};

enum ConstructionAgeBand {
  "England and Wales: Before 1900" = "England and Wales: Before 1900",
  "England and Wales: 1900-1929" = "England and Wales: 1900-1929",
  "England and Wales: 1930-1949" = "England and Wales: 1930-1949",
  "England and Wales: 1950-1966" = "England and Wales: 1950-1966",
  "England and Wales: 1967-1975" = "England and Wales: 1967-1975",
  "England and Wales: 1976-1982" = "England and Wales: 1976-1982",
  "England and Wales: 1983-1990" = "England and Wales: 1983-1990",
  "England and Wales: 1991-1995" = "England and Wales: 1991-1995",
  "England and Wales: 1996-2002" = "England and Wales: 1996-2002",
  "England and Wales: 2003-2006" = "England and Wales: 2003-2006",
  "England and Wales: 2007-2011" = "England and Wales: 2007-2011",
  "2023" = "2023",
  "England and Wales: 2012 onwards" = "England and Wales: 2012 onwards",
  "Scotland: Before 1919" = "Scotland: Before 1919",
  "Scotland: 1919-1929" = "Scotland: 1919-1929",
  "Scotland: 1930-1949" = "Scotland: 1930-1949",
  "Scotland: 1950-1964" = "Scotland: 1950-1964",
  "Scotland: 1965-1975" = "Scotland: 1965-1975",
  "Scotland: 1976-1983" = "Scotland: 1976-1983",
  "Scotland: 1984-1991" = "Scotland: 1984-1991",
  "Scotland: 1992-1998" = "Scotland: 1992-1998",
  "Scotland: 1999-2002" = "Scotland: 1999-2002",
  "Scotland: 2003-2007" = "Scotland: 2003-2007",
  "Scotland: 2008-2011" = "Scotland: 2008-2011",
  "Scotland: 2012 onwards" = "Scotland: 2012 onwards",
}

 enum PropertyTypeEPC {
  "House" = "House",
  "Bungalow" = "Bungalow",
  "Flat" = "Flat",
  "Maisonette" = "Maisonette",
  "Park Home" = "Park Home",
}

enum BuiltForm {
  Detached = "Detached",
  SemiDetached = "Semi-detached",
  SemiDetachedOrEndTerrace = "Semi-detached/End-terrace",
  MidTerrace = "Mid-terrace",
  EndTerrace = "End-terrace",
  EnclosedEndTerrace = "Enclosed end-terrace",
  EnclosedMidTerrace = "Enclosed mid-terrace",
}