import { Box } from "@chakra-ui/react";
import { Wrapper } from "src/components/shared/Wrapper";
import theme from "src/components/theme";

export const SectionGroupQuestionsWrapper: React.FC = ({ children }) => (
  <>
    <Box
      boxShadow="inset 0 0 0 5px black"
      width="100%"
      mt="0 !important"
      height="100%"
      minH="50vh"
      flex={1}
      position="relative"
      bg={theme.colors.gray[ 100 ]}
      sx={{ "&>*:not(picture)": { position: "relative" } }}
    >
      <Wrapper
        variant="text"
        py={theme.spacing[ 8 ]}
      >
        {children}
      </Wrapper>

    </Box>
  </>
);
