import HowItWorksSectionStep from "./HowItWorksSectionStep";

const HowItWorksSectionSteps = (): JSX.Element => (
  <>
    <HowItWorksSectionStep
      stepNumber={1}
      image="https://genous-public-assets.s3.eu-west-2.amazonaws.com/public/how-it-works/home-check.jpg"
    >
      Complete our Home Check. It only takes 5 minutes, and no measurements or preparation is needed.
    </HowItWorksSectionStep>

    <HowItWorksSectionStep
      stepNumber={2}
      image="https://genous-public-assets.s3.eu-west-2.amazonaws.com/public/how-it-works/support.jpg"
    >
      One of our decarbonisation experts will be in touch to discuss your options.
    </HowItWorksSectionStep>

    <HowItWorksSectionStep
      stepNumber={3}
      image="https://genous-public-assets.s3.eu-west-2.amazonaws.com/public/how-it-works/solar_fitting.jpg"
    >
      Our fully accredited, local installers will carry out the work to our designs and specification.
    </HowItWorksSectionStep>

    <HowItWorksSectionStep
      stepNumber={4}
      image="https://genous-public-assets.s3.eu-west-2.amazonaws.com/public/how-it-works/heat-pump.jpg"
    >
      Measures delivered and carbon reduced.
    </HowItWorksSectionStep>
  </>
);

export default HowItWorksSectionSteps;