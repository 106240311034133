import {
  FieldValues,
  UseFormGetValues
} from "react-hook-form";
import {
  QuestionSectionGroup,
  Question,
  QuestionType
} from "src/api/questions";

export const getAllQuestionNamesFromDepends = (sectionGroupData: QuestionSectionGroup): string[] | undefined => {
  // Get slugs of all questions which others depend on
  const dependentQuestionSlugs = sectionGroupData.questions.reduce<string[]>((acc, question) => {
    if (question.dependsUpon) {
      const dependentQuestion = sectionGroupData.questions.find(q => q.id === question.dependsUpon);

      if (dependentQuestion) acc.push(dependentQuestion.slug);
    }

    return acc;
  }, []);

  if (dependentQuestionSlugs.length === 0) return undefined;

  return dependentQuestionSlugs;
};

/**
 * Function to filter questions shown on the UI by the dependsUpon relation on each question
 * dependsUpon is the ID of another question (right now always on the same page as the current question)
 * dependsUponValue is an object containing an array of values that cause the question to show
 * We have to check both the EPC value (since some questions are autofilled from the EPC) and the answer in
 * react-hook-form, since both of these may contribute to whether a question is shown or hidden
 */
export const filterBasedOnDepends = (getValues: UseFormGetValues<FieldValues>, sectionGroupData: QuestionSectionGroup, question: Question): boolean => {
  const { dependsUpon, dependsUponValue } = question;

  if (dependsUpon && dependsUponValue?.values) {
    const dependentQuestion = sectionGroupData.questions.find(q => q.id === dependsUpon);
    const isEPCAnswered = dependentQuestion?.answers?.[ 0 ];
    const userAnswer = dependentQuestion ? getValues()[ dependsUpon ]?.[ dependentQuestion.slug ] : null;

    if (userAnswer === undefined && isEPCAnswered) {
      const epcAnswerValue = isEPCAnswered.value.value;

      return dependsUponValue.values.some(v => epcAnswerValue.includes(v));
    } else return dependsUponValue.values.includes(userAnswer);
  }

  return true;
};

// some types need to return array, some don't
export const formatAnswer = (questionType: QuestionType, value: string[]): string | number | string[] => {
  let formatted;

  // For checkboxes, address lookups and EPC lookups, the full value array is what we need here
  if (questionType === QuestionType.Checkbox || questionType === QuestionType.AddressLookup || questionType === QuestionType.EPCLookup || questionType === QuestionType.PINLocation) {
    formatted = value;
  // Otherwise, we want the 1st item from the value array
  } else {
    formatted = value[ 0 ];
  }

  // return number instead of stringified value
  const type = typeof formatted === "string" && !Number.isNaN(Number(formatted)) ? Number(formatted) : formatted;

  return type;
};