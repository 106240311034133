import {
  Text,
  Box,
  Button
} from "@chakra-ui/react";
import { useEffect } from "react";
import {
  useHistory,
  useLocation
} from "react-router";
import { useSurveyAPI } from "src/api/survey/survey.api";

import {
  LoadingPage,
  Wrapper
} from "../shared";

export const SurveyInvoice: React.FC = () => {
  const { search } = useLocation();
  const history = useHistory();
  const [ invoiceRes, invoiceReq ] = useSurveyAPI("GET_INVOICE");

  useEffect(() => {
    const query = new URLSearchParams(search);
    const sessionId = query.get("session_id");

    if (!sessionId) {
      throw new Error("No Session ID");
    }

    invoiceReq({ invoiceId: sessionId });
  }, [ search, invoiceReq ]);

  if (invoiceRes.pending) {
    return <LoadingPage />;
  }

  return (
    <Wrapper
      variant="narrow"
      minHeight='max'
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        shadow="md"
        padding={10}
        background="gray.100"
        height="fit-content"
      >
        <Text fontSize='xl'>Complete</Text>

        <Text>We'll be in touch shortly to confirm your Survey date.</Text>

        <Text>Thanks for choosing Genous!</Text>

        <br />

        {invoiceRes.data && (
          <Text
            color="gray.600"
            fontSize='sm'
          >Invoice Id: {invoiceRes.data.data.id}
          </Text>
        )}

        {invoiceRes.data && (
          <Text
            color="gray.600"
            fontSize="sm"
          >Total paid: £{invoiceRes.data.data.amount}
          </Text>
        )}

        <br />

        <Box
          display="flex"
          justifyContent="flex-end"
        >
          <Button
            onClick={() => history.push("/footprint")}
            variant='ghost'
            size='sm'
            color="blue.700"
          >Return To Home Check
          </Button>
        </Box>
      </Box>
    </Wrapper>
  );
};