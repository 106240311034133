import { useLocalStorage } from "@gravitywelluk/react-hooks";
import {
  FootprintScores,
  Recommendation
} from "src/api/users/footprint";

interface FootprintState {
  footprintScores?: FootprintScores;
  interestedRecommendations?: Recommendation[];
}

const initialState: FootprintState = {};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useFootprint = () => {
  return useLocalStorage<FootprintState>("footprint", initialState);
};
