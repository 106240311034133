import { AddressData } from "./postcode-lookup";

/**
 * Lines that we want to pull out of the postcode lookup API response to display on the address lookup form
 */
export const addressLinesToUse = [
  "line_1",
  "line_2",
  "line_3",
  "town_or_city",
  "county",
  "country"
];

/**
 * Formats a single line of an address
 * @param addressLine The line to format
 * @param isFirst Whether the line is the first line in the set to be formatted
 * @returns The addressLine with commas inserted in the correct places
 */
const formatAddressLine = (addressLine: string, isFirst = false): string | null => {
  if (addressLine !== "" && !isFirst) {
    return `, ${addressLine}`;
  }

  return addressLine;
};

/**
 * Gets a comma-separated address, showing only fields with values
 */
export const formatAddress = (address: AddressData): string => {
  return `${formatAddressLine(address.line_1, true)}${formatAddressLine(address.line_2)}${formatAddressLine(address.line_3)}${formatAddressLine(address.line_4)}${formatAddressLine(address.district)}${formatAddressLine(address.town_or_city)}${formatAddressLine(address.county)}${formatAddressLine(address.country)}`;
};