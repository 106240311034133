import {
  useEffect,
  useRef,
  useState
} from "react";
import Select from "react-dropdown-select";
import {
  Box,
  HStack,
  Spacer,
  VStack,
  TextProps,
  SlideFade,
  BoxProps,
  Stack,
  Checkbox,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Image,
  Input,
  Badge,
  Button,
  Switch
} from "@chakra-ui/react";
import { Wrapper } from "src/components/shared";
import {
  desktopBreakpointKey,
  useIsDesktop
} from "src/hooks/useIsDesktop";
import { ReactComponent as ArrowDownSvg } from "src/assets/icons/arrow-down.svg";
import theme from "src/components/theme";
import { Text } from "src/components/text";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import {
  FaArrowCircleRight,
  FaArrowCircleUp,
  FaArrowRight,
  FaChevronRight,
  FaHouseUser,
  FaLeaf,
  FaMoneyBill
} from "react-icons/fa";
import { useAuthContext } from "src/hooks/useAuthContext";
import PreferencesModal from "src/components/shared/PreferencesModal";

import { routes } from "src/constants";
import { getIsValidPostcode } from "src/helpers";

const masking = "linear-gradient(310deg, white, white 30%, transparent 60%, transparent)";
const compactMasking = "linear-gradient(310deg, white, white 30%, transparent 50%, transparent)";

interface Option {
  value: number;
  label: string;
  slug: string;
  icon: React.ReactElement;
}

const options: Option[] = [
  {
    value: 0,
    label: "Go Green",
    slug: "goGreen",
    icon: <FaLeaf />
  },
  {
    value: 1,
    label: "Save Money",
    slug: "savedMoney",
    icon: <FaMoneyBill />
  },
  {
    value: 2,
    label: "Upgrade & Futureproof",
    slug: "upgradeFutureProof",
    icon: <FaHouseUser />

  },
  // {
  //   value: 3,
  //   label: "Improve EPC",
  //   slug: "cut-costs"

  // }
  {
    value: 4,
    label: "Boost EPC",
    slug: "becomeCompliant",
    icon: <FaArrowCircleUp />
  }
  // {
  //   value: 5,
  //   label: "Solar Panels",
  //   slug: "cut-costs"

  // },
  // {
  //   value: 6,
  //   label: "Renewable Heating",
  //   slug: "cut-costs"

  // },
  // {
  //   value: 7,
  //   label: "Insulation",
  //   slug: "cut-costs"

  // },
  // {
  //   value: 8,
  //   label: "Better Controls",
  //   slug: "cut-costs"

  // },
  // {
  //   value: 9,
  //   label: "Building Upgrades",
  //   slug: "cut-costs"

  // },
  // {
  //   value: 10,
  //   label: "Electric Vehicle",
  //   slug: "cut-costs"

  // },
  // {
  //   value: 11,
  //   label: "Boiler Upgrade",
  //   slug: "cut-costs"
  // }
];

export const HeroSection: React.FC<{heading?: string, content?: string, imgUrl?: string, compact?: boolean}> = ({
  heading, content, imgUrl, compact
}) => {
  const history = useHistory();
  const sectionRef = useRef<HTMLDivElement | null>(null);
  const isDesktop = useIsDesktop();
  const clipTransitionStartXCoord = isDesktop ? "100vh" : "0%";
  const clipTransitionEndSize = Math.max(window.innerWidth, window.innerHeight) * 1.5;
  const [ isAnimatedIn, setIsAnimatedIn ] = useState(false);
  const [ heroImgLoaded, setHeroImgLoaded ] = useState(false);
  const [ postcode, setPostcode ] = useState("");
  // const [ selectedPriorities, setSelectedPriorities ] = useState(options.slice(0, 5));
  const { authDataState } = useAuthContext();
  const { authenticated } = authDataState ?? {};
  const [ selectedPriorities, setSelectedPriorities ] = useState(localStorage.getItem("priorities-v1") ? Object.keys(JSON.parse(localStorage.getItem("priorities-v1"))).filter((key, val, array) => JSON.parse(localStorage.getItem("priorities-v1")!)[ key ] === true) : []);
  const defaultImgUrl = `https://genous-public-assets.s3.eu-west-2.amazonaws.com/public/landing/${useIsDesktop() ? "house.png" : "house-mobile.jpg"}`; const [ selectedBudget, setSelectedBudget ] = useState(null);
  const [ selectedDisruption, setSelectedDisruption ] = useState(null);
  const [ position, setPosition ] = useState(0);

  useEffect(() => {
    const int = setInterval(() => {
      setPosition(prev => (prev + 1) % 100); // Loop the position back to 0 after 100%
    }, 200);

    return () => clearInterval(int);
  }, [ position ]);

  useEffect(() => {
    localStorage.setItem("priorities-v1", JSON.stringify({
      goGreen: selectedPriorities.find(p => p === "goGreen") ? true : false,
      savedMoney: selectedPriorities.find(p => p === "savedMoney") ? true : false,
      becomeCompliant: selectedPriorities.find(p => p === "becomeCompliant") ? true : false,
      upgradeFutureProof: selectedPriorities.find(p => p === "upgradeFutureProof") ? true : false
    }));
  }, [ selectedPriorities ]);

  const handleChangeBudget = option => {
    setSelectedBudget(option);
  };

  const handleChangeDisruption = option => {
    setSelectedDisruption(option);
  };

  const onPostcodeChange = (event: React.FormEvent<HTMLInputElement>) => {
    setPostcode(event.currentTarget.value);
  };

  const onPostcodeKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      startImpactReview();
    }
  };

  const onChangePrioritySelection = (prioritySlug: string, isChecked: boolean) => {
    options.map(option => {
      if (option.slug === prioritySlug && isChecked) {
        setSelectedPriorities([ ...selectedPriorities, option.slug ]);
      } else if (option.slug === prioritySlug && !isChecked) {
        setSelectedPriorities(selectedPriorities.filter(p => p !== prioritySlug));
      }
    });
  };

  const startImpactReview = () => {
    if (getIsValidPostcode(postcode)) {
      localStorage.setItem("postcode", postcode);
      history.push(routes.section(1));
    } else {
      alert("Please enter a valid UK postcode");
    }
  };

  return (
    <Box
      bg="white"
      fontSize='md'
    >

      <Wrapper
        minHeight={{
          base: "0",
          md: compact ? undefined : "100vh"
        }}
      >
        <VStack
          bg="none"
          alignItems="flex-start"
          py={{
            "base": `clamp(${theme.spacing[ 4 ]}, 10vh, ${theme.spacing[ 6 ]})`,
            [ desktopBreakpointKey ]: theme.spacing[ 32 ],
            "2xl": theme.spacing[ 32 ]
          }}
          color="black"
          spacing={theme.spacing[ 5 ]}
          {...liftZIndex()}
        >
          <Text
            fontSize={{
              base: theme.fontSizes[ "xl" ],
              md: theme.fontSizes[ "xxxl" ]
            }}
            lineHeight="1.35"
            fontWeight='bold'
            maxW={750}
          >
            {heading ?? (
              <><GradientText
                gradient="linear-gradient(45deg, #a000ff, #df5508)"
                text="Decarbonise"
                /> your home materially, effectively, easily.
              </>
            )}
          </Text>

          <Box
            maxW="min(750px)"
            marginTop={2}
            marginBottom={8}
          >
            <Text color={theme.colors.gray[ 800 ]}>Take our free 5-minute <b>Home Check</b> to explore ways to reduce your carbon emissions and save energy and money. What's more, our home decarbonisation experts are on hand to take care of everything for you.</Text>

          </Box>

          <Box width="100%">

            <VStack
              spacing={3}
              alignItems="flex-start"
              color={theme.colors.gray[ 800 ]}

            >
              <Box
                display={{
                  base: "none",
                  lg: "flex"
                }}
                flexDirection='column'
                alignItems="flex-start"
              >
                <Box mb={3}>
                  <Text>Let's start by understanding your priorities</Text>
                </Box>

                <Stack
                  spacing={4}
                  direction='row'
                  wrap='wrap'
                  justifyContent='center'
                  alignItems='center'
                >

                  {options.map((option, index) => (
                    <Box
                      key={index}
                      flexDirection='row'
                      placeItems='center'
                      display="flex"
                      cursor="pointer"
                      onClick={e => onChangePrioritySelection(option.slug, e.target.checked)}
                    >
                      {option.icon}

                      <label
                        style={{
                          marginLeft: 4,
                          cursor: "pointer"
                        }}
                        htmlFor={option.slug}
                      >
                        {option.label} {" "}
                      </label>

                      <Switch
                        ml={1}
                        size='md'
                        id={option.slug}
                        isChecked={selectedPriorities.find(p => p === option.slug)}

                      />
                    </Box>
                  )

                  )}

                </Stack>

                {/* preferences modal */}

                <PreferencesModal
                  selectedBudget={selectedBudget}
                  selectedDisruption={selectedDisruption}
                  handleChangeBudget={handleChangeBudget}
                  handleChangeDisruption={handleChangeDisruption}
                />

              </Box>

              <Stack
                width={{
                  base: "100%",
                  lg: "auto"
                }}
                direction={[ "column", "row" ]}
              >

                <Input
                  borderRadius='md'
                  border="1px solid #CCC !important"
                  placeholder="Enter your postcode"
                  width="100%"
                  autoFocus
                  minWidth="200px"
                  onChange={onPostcodeChange}
                  onKeyPress={onPostcodeKeyPress}
                  value={postcode}
                />

                <Button
                  size="md"
                  transition="ease-in 0.2s"
                  height="50px"
                  color="gray.100"
                  borderBottom="2px solid #606369"
                  background="linear-gradient(45deg, #131921, #555a63)"
                  backgroundSize='200% 200%'
                  backgroundPosition={`${position}% ${position}%`}
                  width="100%"
                  minWidth="200px"
                  borderRadius="xl"
                  _hover={{
                    color: "white",
                    borderBottom: "4px solid #606369"
                  }}
                  onClick={startImpactReview}
                  rightIcon={<FaChevronRight />}
                >
                  Get Started
                </Button>
              </Stack>
            </VStack>
          </Box>

          {!compact && (
            <><Spacer />

              <HStack
                {...liftZIndex()}
                marginTop={4}
              >
                <Box
                  as="span"
                  w="1em"
                  color={theme.colors.green[ 200 ]}
                >
                  <ArrowDownSvg
                    width="100%"
                    height="100%"
                    fill="currentColor"
                  />
                </Box>

                <Text
                  as="button"
                  onClick={() => {
                    // scroll to below section
                    const rect = sectionRef.current?.getBoundingClientRect();

                    window.scrollTo({
                      top: rect ? rect.y + rect.height :
                      window.scrollY + window.innerHeight,
                      behavior: "smooth"
                    });
                  }}
                  fontSize={{ "2xl": theme.fontSizes.md }}
                >
                  Learn About The Genous Difference
                </Text>
              </HStack>
            </>
          )}

        </VStack>
      </Wrapper>

      <Box
        overflow="hidden"
        style={{
          height: "100%",
          width: "100%",
          objectFit: "cover",
          position: "absolute",
          // right: 0,
          top: 0,
          WebkitMaskImage: compact ? compactMasking : masking,
          maskImage: compact ? compactMasking : masking,
          transition: "opacity 1s ease-out",
          opacity: heroImgLoaded ? 1 : 0
        }}
        display={{
          xl: "block",
          base: "none"
        }}
      >
        <Image
          src={imgUrl ?? defaultImgUrl}
          display={{
            base: "none",
            md: "block"
          }}
          style={{
            height: "100%",
            width: "100%",
            minHeight: "700px",
            objectFit: "cover",
            position: "absolute",
            right: -250,
            // right: 0,
            top: 100,
            WebkitMaskImage: compact ? compactMasking : masking,
            maskImage: compact ? compactMasking : masking,
            transition: "opacity 1s ease-out",
            opacity: heroImgLoaded ? 1 : 0
          }}
          top={0}
          maxHeight={{
            base: "120vmin",
            md: "unset"
          }}
          onLoad={() => {
            setHeroImgLoaded(true);
          }}
        />
      </Box>

      {/* for image overlay */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          pointerEvents: "none" // Ensure the overlay doesn't block interactions
        }}
      />

    </Box>
  );
};

function liftZIndex(): TextProps {
  return ({
    zIndex: "1",
    position: "relative"
  });
}

const GradientText = ({ text, gradient }: {text: string; gradient: string}) => {
  const style = {
    background: gradient,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    display: "inline-block"
  };

  return <span style={style}>{text}</span>;
};