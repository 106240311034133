import {
  storyblokEditable,
  SbBlokData
} from "@storyblok/react";

import { ImageCarousel } from "../carousel/ImageCarousel";

type ImageCarouselBlokProps = { blok: SbBlokData & { images: Array<{ filename: string, name?: string, alt?: string}>; hidden: boolean } };

export const ImageCarouselBlok: React.FC<ImageCarouselBlokProps> = ({ blok }) =>
  !blok.hidden && blok.images?.length ? (
    <ImageCarousel
      items={blok.images.map(image => {
        return {
          text: image.name,
          imgAlt: image.alt ?? "",
          imgSrc: image.filename
        };
      })}
      {...storyblokEditable(blok)}
    />
  ) :
    <></>;
