import {
  Box,
  Flex,
  Link
} from "@chakra-ui/react";

import { routes } from "src/constants";

const linkStyles = { ":hover": { textDecoration: "underline" } };

const DesktopNavigation: React.FC = () => {
  return (
    <Box
      as="nav"
      w="70%"
      mx={5}
      py={2}
      whiteSpace="nowrap"
      fontWeight="semibold"
    >
      <Flex alignItems="center" gap={5}>
        <Link href={routes.aboutGenous} sx={linkStyles}>About Us</Link>

        <Link href={routes.reduce}>Reduce Your Carbon</Link>

        <Link href={routes.howItWorks}>How It Works</Link>

        <Link href={routes.insightsNewsHub}>Insights</Link>

        <Link href={routes.faqs}>FAQs</Link>
      </Flex>
    </Box>
  );
};

export default DesktopNavigation;