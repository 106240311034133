import React, {
  useEffect,
  useState
} from "react";
import { RouteComponentProps } from "react-router";
import { Stack } from "@chakra-ui/react";
import { gradients } from "src/components/theme/gradients";
import {
  FullSpace,
  ImpactReviewButton
} from "src/components/shared";
import { HeroSection } from "src/components/landing/sections/HeroSection";
import { BestInClassSection } from "src/components/landing/sections/BestInClassSection";
import { ExpertsSection } from "src/components/landing/sections/ExpertsSection";
import { ShowCommitmentSection } from "src/components/landing/sections/ShowCommitmentSection";
import { ActionsCarousel } from "src/components/landing/sections/ActionsCarousel";
import { GrayGradientContainer } from "src/components/landing/GrayGradientContainer";
import { useInView } from "react-intersection-observer";
import HowItWorksSection from "src/pages/footprint/sections/HowItWorksSection/HowItWorksSection";
import { useAuthContext } from "src/hooks/useAuthContext";
import { LetsGetGoingSection } from "src/components/landing/sections/LetsGetGoingSection";

export const HomePage: React.FC<RouteComponentProps> = () => {
  const { authDataState: { authenticated } } = useAuthContext();

  const [ ref ] = useInView({
    /* Optional options */
    threshold: 0
  });

  const [ hasScrolled, setHasScrolled ] = useState(false);

  useEffect(() => {
    const handler = () => setHasScrolled(true);

    window.addEventListener("scroll", handler, {
      passive: true,
      once: true
    });

    return () => window.removeEventListener("scroll", handler);
  }, []);

  return (

    <FullSpace
      justifyContent="space-between"
      backgroundImage={gradients.lightGray}
    >

      <FullSpace
        overflow="hidden"
        as={Stack}
        height="100%"
        spacing="0"
        sx={{ "&>*": { w: "100%" } }}
      >

        <HeroSection />

        <BestInClassSection />

        <GrayGradientContainer>
          <ShowCommitmentSection />

          <ActionsCarousel />
        </GrayGradientContainer>

        <ExpertsSection />

        <HowItWorksSection />

        {!authenticated && hasScrolled && <ImpactReviewButton />}

        <GrayGradientContainer>
          <div ref={ref}>
            <LetsGetGoingSection />
          </div>
        </GrayGradientContainer>

      </FullSpace>

    </FullSpace>

  );
};

export default HomePage;