import {
  Box,
  BoxProps,
  HStack
} from "@chakra-ui/react";
import {
  AnimatePresence,
  motion,
  MotionProps
} from "framer-motion";
import { Link } from "react-router-dom";
import { Wrapper } from "src/components/shared";
import { Text } from "src/components/text";
import theme from "src/components/theme";
import { ReactComponent as ArrowRightSvg } from "src/assets/icons/arrow-right.svg";
import type { Recommendation } from "src/api/users/footprint";

import { routes } from "src/constants";

const AnimatedBox = motion(Box);

const ActionsListPopover = ({ list, show }: {list: Recommendation[], show: boolean}): JSX.Element => {
  return (
    <AnimatePresence>
      {show && (
        <AnimatedBox
          key="actions-list"
          initial={{
            opacity: 0,
            y: -100
          }}
          {...actionsListStyleProps}
        >
          <Wrapper
            variant="text"
            display="flex"
            as={Link}
            to={{
              pathname: routes.takeAction,
              state: { recommendations: list }
            }}
          >
            <Box
              flex={1}
            >
              <Text
                fontSize={{
                  base: theme.fontSizes.md,
                  lg: theme.fontSizes.lg
                }}
                fontWeight="semibold"
              >
                My interests
              </Text>

              <Text
                fontSize={{
                  base: theme.fontSizes.xs,
                  lg: theme.fontSizes.sm,
                  xl: theme.fontSizes.base
                }}
                fontWeight="light"
                color={theme.colors.gray[ 500 ]}
                noOfLines={1}
                title={list.map(recommendation => recommendation.altRecommendationName ?? recommendation.recommendation.name).join(", ")}
              >
                {list.length ? (
                  <>
                    {list[ 0 ].altRecommendationName ?? list[ 0 ].recommendation.name}

                    {" "}

                    {list.length > 1 && ", +" + (list.length - 1) + " more"}
                  </>
                ) : "-"}
              </Text>
            </Box>

            <HStack
              alignItems="flex-end"
              pb={1}
            >
              <HStack spacing={3}>
                <Text color={theme.colors.green[ 200 ]}>Tell me more</Text>

                <Box
                  w="20px"
                  h="20px"
                  color={theme.colors.green[ 100 ]}
                  ml="1rem"
                >
                  <ArrowRightSvg fill="currentColor" />
                </Box>
              </HStack>
            </HStack>
          </Wrapper>
        </AnimatedBox>
      )}
    </AnimatePresence>
  );
};

const actionsListStyleProps: BoxProps & MotionProps = {
  initial: {
    y: "100%",
    opacity: 0
  },
  animate: {
    y: "0%",
    opacity: 1,
    transition: {
      type: "spring",
      stiffness: 100,
      mass: 0.1,
      delay: 0.2
    }
  },
  exit: {
    y: "100%",
    opacity: 0
  },
  zIndex: 2,
  bg: "black",
  color: "white",
  pos: "fixed",
  bottom: "0",
  w: "100%",
  py: {
    base: theme.spacing[ 5 ],
    md: theme.spacing[ 6 ]
  },
  borderTop: "2px solid black"
};

export default ActionsListPopover;