import {
  Box,
  BoxProps,
  HStack,
  VStack,
  Button as ChakraButton
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import {
  Link,
  useHistory
} from "react-router-dom";
import { useAnimatedScrollState } from "src/hooks/useAnimatedScrollState";

import { TickCircle } from "../../../components/shared/TickCircle";
import { Wrapper } from "../../../components/shared/Wrapper";
import { Text } from "../../../components/text";
import theme from "../../../components/theme";
import { routes } from "../../../constants";

type IntroSectionProps = {username: string, completed?: boolean, isAuthenticated?: boolean};

const AnimatedBox = motion<BoxProps>(Box);

export const IntroSection: React.FC<IntroSectionProps> = ({
  username, completed, isAuthenticated
}) => {
  const animatedStateValue = useAnimatedScrollState();
  const history = useHistory();

  return (
    <Box
      width="100%"
      bg={theme.colors.black}
      borderColor={theme.colors.black} // (!) keep in sync with bg color
      color={theme.colors.gray[ 100 ]}
      pt={{
        base: theme.spacing[ "12" ],
        md: theme.spacing[ "16" ]
      }}
      pb={theme.spacing[ "24" ]}
      position="sticky"
      top={0}
    >
      <Wrapper
        variant="text"
        opacity={animatedStateValue}
      >
        <VStack
          alignItems="flex-start"
        >
          {username && (
            <Text>
              <Text as="span">
                Hello,
                <br />
              </Text>

              <Text
                as="span"
                fontSize={{
                  base: username.length <= 7 ? theme.fontSizes.xxl : theme.fontSizes.xl,
                  sm: theme.fontSizes.xxl
                }}
                fontWeight="semibold"
                lineHeight={1}
              >
                {username || "Human"}
              </Text>
            </Text>
          )}

          <br />

          <HStack
            flexWrap="wrap"
            width="100%"
            justifyContent={{
              base: "space-between",
              md: "flex-start"
            }}
            spacing={{ md: 6 }}
          >
            <Text
              fontWeight="semibold"
              lineHeight="1.8"
            >
              Home Check
            </Text>

            {/* completed text */}

            {completed && isAuthenticated && (
              <AnimatedBox
                as="span"
                borderRadius={theme.radius.md}
                color={theme.colors.green[ 100 ]}
                bg={`${theme.colors.green[ 100 ]}20`}
                p={`${theme.spacing[ 1 ]} ${theme.spacing[ 2 ]}`}
                opacity={0}
                animate={{ opacity: [ 0, 1 ] }}
              >
                <AnimatedBox
                  animate={{
                    opacity: [ 0, 1 ],
                    transition: { delay: 0.35 }
                  }}
                >
                  <Text
                    fontWeight="medium"
                    fontSize={theme.fontSizes.xs}
                    display="flex"
                    alignItems="center"
                  >
                    <Text
                      as="span"
                      letterSpacing="-0.25px"
                    >
                      Complete
                    </Text>

                    <TickCircle
                      as="span"
                      background={theme.colors.green[ 100 ]}
                      size="1.2em"
                      ml="1ch"
                      color="black"
                    />
                  </Text>
                </AnimatedBox>
              </AnimatedBox>
            )}

          </HStack>

          <Text color={theme.colors.gray[ 200 ]}>
            We now understand more about you and some of the major sources of your CO2 emissions, as well as the potential for savings. These are shown below.
          </Text>

          <br />

          <HStack>
            <Text
              as={Link}
              to={routes.section(1)}
              color={theme.colors.gray[ 400 ]}
              textDecoration="underline"
              fontSize={[ "xs", "sm" ]}
            >
              Run my Home Check again
            </Text>

            {!username && (
              <ChakraButton
                as={Link}
                to={{
                  pathname: routes.register,
                  state: { from: history.location.pathname }
                }}
                fontSize={[ "xs", "sm" ]}
                minW={{
                  base: "unset",
                  md: "10ch"
                }}
                borderWidth={1}
                borderColor={theme.colors.black}
                borderRadius={3}
                backgroundColor={theme.colors.white}
                textColor={theme.colors.black}
                _hover={{
                  bg: theme.colors.black,
                  color: theme.colors.white
                }}
              >
                Signup & Access Full Report
              </ChakraButton>
            )}
          </HStack>
        </VStack>
      </Wrapper>
    </Box>
  );
};
