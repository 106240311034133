import { QuestionSectionGroup } from "src/api/questions";

export enum ActionTypes {
  UPDATE_SECTION_GROUPS = "UPDATE_SECTION_GROUPS",
  UPDATE_CURRENT_SECTION_GROUP = "UPDATE_CURRENT_SECTION_GROUP",
  UPDATE_PROPERTY_ID = "UPDATE_PROPERTY_ID",
  UPDATE_FOOTPRINT = "UPDATE_FOOTPRINT"
}

interface SectionGroupsAction {
  type: ActionTypes.UPDATE_SECTION_GROUPS,
  payload: QuestionSectionGroup[]
}

interface CurrentSectionGroupAction {
  type: ActionTypes.UPDATE_CURRENT_SECTION_GROUP,
  payload: number | null
}

interface PropertyIDAction {
  type: ActionTypes.UPDATE_PROPERTY_ID,
  payload: number | undefined
}

export type Action = SectionGroupsAction | CurrentSectionGroupAction | PropertyIDAction;