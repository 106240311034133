import React from "react";
import {
  Box,
  Spinner
} from "@chakra-ui/react";
import {
  Redirect,
  Route,
  RouteProps
} from "react-router-dom";

import { useAuthContext } from "../../hooks/useAuthContext";
import { routes } from "../../constants";

export const PrivateRoute: React.FC<RouteProps & { disabled?: boolean }> = ({
  location,
  component,
  ...props
}) => {
  const { authDataState } = useAuthContext();
  const { disabled } = props;

  if (disabled) {
    return (
      <Route
        location={location}
        {...props}
      >
        <Redirect
          to={{ pathname: routes.login }}
        />
      </Route>
    );
  }

  if (!authDataState.authenticated) {
    return (
      <Route
        location={location}
        {...props}
      >
        {authDataState.authenticating ? (
          <Box
            h="100%"
            w="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Spinner size="lg" />
          </Box>
        ) : (
          <Redirect
            to={{
              pathname: routes.login,
              state: { from: location?.pathname }
            }}
          />
        )}
      </Route>
    );
  }

  return (
    <Route
      location={location}
      component={component}
      {...props}
    />
  );
};
