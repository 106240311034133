/* eslint-disable @typescript-eslint/no-non-null-assertion */
import dayjs from "dayjs";
import {
  Box,
  HStack,
  Spacer,
  VStack
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { JSONAPIResponse } from "src/api/json-api-response";
import {
  PropertyFootprint,
  RecommendationStatus
} from "src/api/users/footprint";
import { Button } from "src/components/button";
import ProgressCircle from "src/components/progress-circle/ProgressCircle";
import { Wrapper } from "src/components/shared";
import { Text } from "src/components/text";
import theme from "src/components/theme";
import { roundCO2 } from "src/helpers/number";
import { PortfolioCard } from "src/components/offsets";
import PortfolioPieChart from "src/components/offsets/PortfolioPieChart";
import { useMemo } from "react";

import { routes } from "src/constants";

interface RecommendationsFootprintProps {
  getFootprintResponse: ApiResponse<JSONAPIResponse<PropertyFootprint>>,

}

const OffsettingFootprint: React.FC<RecommendationsFootprintProps> = ({ getFootprintResponse }) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { data } = getFootprintResponse.data! || {};
  const hasOffsetPlan = !!data.offsetSubscription?.offsetPortfolio;

  const potentialSavingsFromInterests = useMemo(() => data.recommendations
    .filter(recommendation => recommendation.status === RecommendationStatus.CommunicationPending)
    .reduce((prev, cur) => prev + (cur.annualCO2Saving || 0), 0), [ data.recommendations ]);

  if (!data) return null;

  else return (
    <Wrapper
      variant="text"
      mt="37px"
      pb={20}
    >
      {!hasOffsetPlan && (
        <>
          {location.pathname !== routes.takeAction && (
            <Text>
              There are many reasons why you might not be in a position to reduce some of your carbon output, and while there are steps that you can take that Genous doesn’t yet cover, like changing diets or holidaying without flights, offsetting (click
              {" "}

              <Text
                as={Link}
                to={routes.reduce + "/offsets"}
                color="royalblue"
              >here
              </Text>

              {" "}
              to know more) can be the best thing you can do for that part of your footprint that you just can’t change. We make this easy for you, while ensuring you have access to high-quality offsetting that really makes a difference.
            </Text>
          )}

          {location.pathname === routes.takeAction && (
            <Text>
              In the meantime, there will be other parts of your carbon footprint that you might not be in a position to reduce, and while there are steps that you can take that Genous doesn’t yet cover, like changing diets or holidaying without flights, offsetting (click
              {" "}

              <Text
                as={Link}
                to={routes.reduce + "/offsets"}
                color="royalblue"
              >here
              </Text>

              {" "}
              to know more) can be the best thing you can do for that part of your footprint that you just can’t change. We make this easy for you, while ensuring you have access to high-quality offsetting that really makes a difference.
            </Text>
          )}

          <VStack
            justifyContent="center"
            my={10}
          >
            <Circle
              data={data}
              potentialSavingsFromInterests={potentialSavingsFromInterests}
            />
          </VStack>
        </>
      )}

      {hasOffsetPlan ? (
        <VStack
          alignItems="stretch"
          spacing={20}
        >
          {/* <Box
            mx="auto"
            w="100%"
            maxW={350}
          >
            <FootprintSummaryTable
              footprintScores={data.footprintScores}
              totalTonnesOffset={data.offsetSubscription?.targetTonnes || 0}
              savingsFromInterests={potentialSavingsFromInterests}
            />
          </Box> */}

          <VStack
            boxShadow={[ `0 0 0 2px ${theme.colors.gray[ 200 ]}`, `0 0 0 2px ${theme.colors.gray[ 300 ]}` ]}
            borderRadius={theme.radius.lg}
            p={[
              `${theme.spacing[ 10 ]} ${theme.spacing[ 6 ]}`,
              `${theme.spacing[ 10 ]} ${theme.spacing[ 8 ]}`,
              theme.spacing[ 10 ]
            ]}
            alignItems="stretch"
            spacing={theme.spacing[ 6 ]}
          >
            <Text
              fontSize={theme.fontSizes.lg}
              fontWeight={500}
              mt={-3}
              mb={10}
            >
              Your offset plan:
            </Text>

            <HStack
              alignItems="flex-start"
              spacing={[ theme.spacing[ 6 ], theme.spacing[ 10 ] ]}
            >
              <PortfolioPieChart portfolio={data.offsetSubscription!.offsetPortfolio!} />

              <PortfolioCard
                portfolio={data.offsetSubscription!.offsetPortfolio!}
                tonnesToOffset={0}
                planType={data.offsetSubscription!.interval!}
                cost={data.offsetSubscription!.cost}
              />
            </HStack>

            <Spacer />

            <Box>
              {/* <TwoColumnTable
                rows={[ [ isMonthly ? "Start date" : "Date", dayjs(data.offsetSubscription?.startDate).format("MMM DD YYYY") ?? "-" ], [ isMonthly ? "You pay a monthly subscription" : "You made a one-off payment", data.offsetSubscription?.cost ? formatCurrency(data.offsetSubscription?.cost ?? 0) : "-" ] ]}
              />

              <br /> */}

              <Text
                as={Link}
                to={routes.offsets}
                color={theme.colors.gray[ 600 ]}
                textDecoration="underline"
              >Change
              </Text>

              {" "}
              your plan,

              {" "}
              view your

              {" "}

              <Text
                as={Link}
                to={routes.managePlan}
                color={theme.colors.gray[ 600 ]}
                textDecoration="underline"
              >invoices
              </Text>

              {" "}
              or

              {" "}

              <Text
                as={Link}
                to={routes.managePlan}
                color={theme.colors.gray[ 600 ]}
                textDecoration="underline"
              >cancel
              </Text>

              {" "}
              your subscription
            </Box>
          </VStack>
        </VStack>
      ) : (
        <>
          <VStack
            alignItems="stretch"
            spacing={8}
          >
            <HStack
              alignItems="flex-start"
              spacing={6}
            >
              <Box
                bg="black"
                w={8}
                h={8}
                borderRadius="full"
                flexShrink={0}
              />

              <Text>
                Your household’s footprint — From what you’ve told us, plus some assumptions based on UK averages.
              </Text>
            </HStack>

            <HStack
              alignItems="flex-start"
              spacing={6}
            >
              <Box
                bg={theme.colors.green[ 200 ]}
                w={8}
                h={8}
                borderRadius="full"
                flexShrink={0}
              />

              <Text>
                Actions being considered that could reduce your carbon output.
              </Text>
            </HStack>

            <HStack
              alignItems="flex-start"
              spacing={6}
            >
              <Box
                bg={theme.colors.blue[ 500 ]}
                w={8}
                h={8}
                borderRadius="full"
                flexShrink={0}
              />

              <Text>
                “In the meantime, you can offset to help to address the carbon output that you can’t address in other ways
              </Text>
            </HStack>
          </VStack>

          <Spacer p={10} />

          <HStack>
            <Button
              as={Link}
              to={routes.offsets}
              w="100%"
              maxW={300}
              mx="auto"
            >
              Offset plans
            </Button>
          </HStack>
        </>
      )}
    </Wrapper>
  );
};

// Pulled some components below for a more readable render

const Circle = ({ data, potentialSavingsFromInterests }: {data: PropertyFootprint, potentialSavingsFromInterests: number}) => {
  return (
    <>
      <ProgressCircle
        value={potentialSavingsFromInterests}
        max={data.footprintScores.footprint}
        innerValue={data.offsetSubscription?.targetTonnes || data.footprintScores.footprint - potentialSavingsFromInterests}
        maxW={350}
      >
        <Text
          color={theme.colors.gray[ 500 ]}
          fontSize={theme.fontSizes.xs}
          textAlign="center"
          mt={-4}
        >

          <Text
            color="black"
            fontSize={potentialSavingsFromInterests > 0 ? "base" : "xxl"}
            fontWeight="bold"
            as="span"
            display='block'
            mt="3"
            lineHeight="0.9"
          >
            {roundCO2(data.footprintScores.footprint)}
          </Text>

          <span>
            Estimated output
          </span>

          {potentialSavingsFromInterests > 0 && (

            <>
              <br />

              <Text
                color={theme.colors.green[ 200 ]}
                fontSize={theme.fontSizes.xxl}
                fontWeight="bold"
                as="span"
                display='block'
                mt="3"
                lineHeight="0.9"
              >
                {roundCO2(potentialSavingsFromInterests)}
              </Text>

              <span style={{ color: theme.colors.green[ 200 ] }}>
                Potential reduction
              </span>
            </>
          )}
        </Text>

      </ProgressCircle>

      <Text
        color={theme.colors.gray[ 500 ]}
        fontSize={theme.fontSizes.xs}
        textAlign="center"
        mt={-4}
      >* measurements are in tonnes of CO<sub>2</sub>e
      </Text>
    </>
  );
};

export default OffsettingFootprint;
