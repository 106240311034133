import {
  Box,
  HStack,
  Stack
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

import { routes } from "src/constants";

import { Wrapper } from "../../shared/Wrapper";
import { Text } from "../../text";
import theme from "../../theme";
import {
  desktopBreakpointKey,
  useIsDesktop
} from "../../../hooks/useIsDesktop";
import { JoinBox } from "../JoinBox";
import { ReactComponent as ArrowRightSvg } from "../../../assets/icons/arrow-right.svg";

export type CtaSectionProps = { heading: string, content: string | React.ReactNode[], cta: string, route?: string, className?: string };

export const CtaSection: React.FC<CtaSectionProps> = ({
  heading, content, cta, route, className
}) => {
  const isDesktop = useIsDesktop();

  return (
    <Wrapper
      as={isDesktop ? HStack : Stack}
      className={className}
      justifyContent={isDesktop ? "space-between" : "flex-start"}
      alignItems="flex-start"
      height="100%"
      color="black"
      py={{
        [ desktopBreakpointKey ]: theme.spacing[ 12 ],
        "2xl": theme.spacing[ 32 ]
      }}
      mb={!isDesktop ? theme.spacing[ 12 ] : 0}
      flexWrap={{
        "base": "nowrap",
        "2xl": "wrap"
      }}
      spacing={{
        base: theme.spacing[ 10 ],
        [ desktopBreakpointKey ]: theme.spacing[ 20 ]
      }}
    >
      <Box
        flexShrink={1}
        flexBasis={{
          "base": "unset",
          "lg": "100%",
          "2xl": "50%"
        }}
      >
        {heading && (
          <>

          </>
        )}

        {!Array.isArray(content) ? (
          <>
            <Text
              fontWeight="light"
            >
              {heading}
            </Text>

            <br />

            <Text
              color={theme.colors.gray[ 800 ]}
              mb={{
                base: `${theme.spacing[ 6 ]} !important`,
                [ desktopBreakpointKey ]: `${theme.spacing[ 16 ]} !important`
              }}
            >
              {content}
            </Text>
          </>
        ) : (
          <>
            <Text
              fontWeight={1000}
              fontSize="4xl"
            >
              {heading}
            </Text>

            <br />

            <Box
              fontSize="md"
              color={theme.colors.gray[ 800 ]}
              lineHeight="1.75"
            >
              {content}
            </Box>
          </>
        )}
      </Box>

      <JoinBox
        as={Link}
        to={route ?? routes.welcome}
      >
        <HStack
          justifyContent="space-between"
          width="100%"
        >
          <Text
            fontSize={{
              base: theme.fontSizes.xl,
              [ desktopBreakpointKey ]: "40px"
            }}
            fontWeight="semibold"
            lineHeight={1.4}
          >
            {cta}
          </Text>

          <ArrowRightSvg
            fill={theme.colors.green[ 200 ]}
            width="30px"
            aria-hidden
          />
        </HStack>
      </JoinBox>
    </Wrapper>
  );
};