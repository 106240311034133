import {
  Box,
  BoxProps,
  ComponentWithAs,
  HStack,
  Image,
  ResponsiveValue
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

import { desktopBreakpointKey } from "../../hooks/useIsDesktop";
import { Text } from "../text";
import { ReactComponent as ArrowRightSvg } from "../../assets/icons/arrow-right.svg";
import theme from "../theme";
import { routes } from "../../constants";
import { kebabCase } from "../../helpers/string";

type ReduceCarbonTopicPreviewProps = {
  title: string;
  text: string;
  imgSrc: string;
  imgAlt: string;
  small?: ResponsiveValue<boolean>
};

export const ReduceCarbonTopicPreview: ComponentWithAs<"div", BoxProps & ReduceCarbonTopicPreviewProps> = ({
  title, text, imgSrc, imgAlt, small, ...restProps
}) => {
  return (
    <Box
      as={Link}
      to={routes.reduceSection(kebabCase(title))}
      {...topicPreviewStyles(small)}
      {...restProps}
    >
      {/* image section */}

      <Box
        h={small ? undefined : { [ desktopBreakpointKey ]: "70%" }}
        sx={{
          aspectRatio: {
            base: "1.35",
            [ desktopBreakpointKey ]: small ? undefined : "unset"
          }
        }}
        bg={theme.colors.green[ 200 ]}
        mb={{
          base: theme.spacing[ 4 ],
          [ desktopBreakpointKey ]: small ? undefined : theme.spacing[ 8 ]
        }}
        pos="relative"
      >
        <Image
          src={imgSrc}
          alt={imgAlt}
          loading="lazy"
          width="100%"
          height="100%"
          objectFit="cover"
          transition="opacity 1s ease-out"
        />
      </Box>

      {/* bottom section */}

      <HStack
        spacing={theme.spacing[ 6 ]}
      >
        <Box
          px={{ base: theme.spacing[ 1 ] }}
        >
          <Text
            color={theme.colors.gray[ 800 ]}
            type="heading"
            opacity="0.3"
            mb="0.33em"
          >
            {title}
          </Text>

          <Text
            color={theme.colors.gray[ 800 ]}
            type="body"
            noOfLines={3}
          >
            {text}
          </Text>
        </Box>

        <Box
          as="span"
          w={{
            base: 4,
            md: 6
          }}
          color={theme.colors.green[ 200 ]}
          flexShrink={0}
        >
          <ArrowRightSvg
            width="100%"
            height="100%"
            fill="currentColor"
          />
        </Box>
      </HStack>
    </Box>
  );
};

const topicPreviewStyles = (isSmall?: ResponsiveValue<boolean>): BoxProps => ({
  bg: "white",
  border: `2px solid ${theme.colors.gray[ 200 ]}`,
  _hover: { borderColor: theme.colors.green[ 200 ] },
  ...(isSmall ? small : responsive)
});

const responsive: BoxProps = {
  p: {
    base: theme.spacing[ 3 ],
    md: theme.spacing[ 6 ]
  },
  height: {
    [ desktopBreakpointKey ]: "500px",
    "2xl": "600px"
  },
  width: {
    "base": "min(400px, 75vmin)",
    [ desktopBreakpointKey ]: "500px",
    "2xl": "600px"
  },
  minH: {
    base: "min(400px, 75vmin)",
    [ desktopBreakpointKey ]: "unset"
  }
};

const small: BoxProps = {
  p: { base: theme.spacing[ 4 ] },
  width: { base: "min(400px, 75vmin)" },
  minH: { base: "min(400px, 75vmin)" }
};