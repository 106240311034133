/* eslint-disable react-hooks/exhaustive-deps */
import { Auth } from "@aws-amplify/auth";
import { useToast } from "@chakra-ui/react";
import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from "axios";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { devLog } from "../../helpers/node-env";
import { useAuthContext } from "../useAuthContext";
import { handleDevPostcodeLookup } from "./utils";

interface RequestDefinition<Params> {
  config: AxiosRequestConfig;
  params: Params;
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const useAxios = <ResponseData, Params>(initialiser: AxiosRequestInitialiser<any>): UseAxiosReturnValue<ResponseData, Params> => {
  const { authDataState, setAuthDataState } = useAuthContext();
  const history = useHistory();
  const toast = useToast();

  const axiosInstance = React.useMemo(() => axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 30000
  }), [ authDataState ]);

  const [ requestDefinition, setRequestDefinition ] = React.useState<RequestDefinition<Params> | null>(null);

  const triggerRequest = React.useCallback((params: Params) => {
    const {
      headers, unauthenticated, ...requestConfig
    } = initialiser(params);

    if (unauthenticated) {
      setRequestDefinition({
        config: {
          ...requestConfig,
          headers
        },
        params
      });
    } else {
      Auth.currentAuthenticatedUser().then(user => {
        const token = user.getSignInUserSession()?.getIdToken().getJwtToken() || "";
        const authHeaders = { Authorization: `Bearer ${token}` };

        setRequestDefinition({
          config: {
            ...requestConfig,
            headers: {
              ...headers,
              ...authHeaders
            }
          },
          params
        });
      }).catch(() => {
        // TODO: Check why its calling this too many times

        setRequestDefinition({
          config: {
            ...requestConfig,
            headers
          },
          params
        });
      });
    }
  }, []);

  const [ response, setResponse ] = React.useState<ApiResponse<ResponseData>>({
    data: null,
    error: null,
    pending: false,
    complete: false
  });

  const onResponseSuccess = React.useCallback((axiosResponse: AxiosResponse<ResponseData>) => {
    setResponse({
      data: axiosResponse.data,
      error: null,
      pending: false,
      complete: true
    });
  }, []);

  const onResponseError = React.useCallback((axiosError: AxiosError<LambdaError>) => {
    // return static JSON in development testing for specific postcode
    // to get around consistently hitting api-limit
    const __exitOnDev = handleDevPostcodeLookup(axiosError, testData => {
      setResponse({
        data: testData as ResponseData,
        error: null,
        pending: false,
        complete: true
      });
    });

    if (__exitOnDev) return;

    if (axiosError.response) {
      if (axiosError.response.status === 403) {
        // check for valid user session
        Auth.currentAuthenticatedUser({ bypassCache: true }).then(({ token }) => {
          if (token) {
            throw new Error("No ID token");
          }

          const jwtPayload = token.split(".")[ 1 ];
          const parsedJWT = JSON.parse(atob(jwtPayload));
          const tokenExpired = Date.now() - (parsedJWT.exp * 1000) > 0;

          // if current token is valid and different to one in auth state
          if (!tokenExpired && token && token !== authDataState.idToken) {
            // update auth state with refreshed JWT
            setAuthDataState({
              authenticated: true,
              error: null,
              authenticating: false,
              idToken: token,
              isAdmin: false
            });
            // refresh page
            history.go(0);
          } else {
            setResponse({
              data: null,
              error: {
                id: "",
                code: "UNAUTHORIZED",
                status: 403,
                title: "Unauthorised",
                message: "The user is not authorised to access this resource",
                type: ""
              },
              pending: false,
              complete: true
            });
          }
        // sign out and redirect to login if unauthenticated
        }).catch(() => {
          Auth.signOut();

          setAuthDataState({
            authenticated: false,
            authenticating: false,
            error: null,
            idToken: "",
            isAdmin: false
          });
        });
      } else {
        // Use the given error, or provide a generic error.
        const error = axiosError?.response?.data?.error || {
          id: "",
          code: "CONNECTION_REFUSED",
          status: 500,
          title: "Connection Refused",
          details: "This service is unavailable. Please try again later."
        };

        setResponse({
          data: null,
          error,
          pending: false,
          complete: true
        });
      }
    } else {
      setResponse({
        data: null,
        error: {
          id: "",
          code: "CONNECTION_REFUSED",
          status: 500,
          title: "Connection Refused",
          message: "This service is unavailable. Please try again later.",
          type: ""
        },
        pending: false,
        complete: true
      });
    }
  }, []);

  React.useEffect(() => {
    if (!requestDefinition) {
      return;
    }

    // set loading state
    setResponse({
      data: null,
      error: null,
      pending: true,
      complete: false
    });

    // make request
    axiosInstance(requestDefinition.config)
      .then(onResponseSuccess)
      .catch(onResponseError);
  }, [ requestDefinition ]);

  useEffect(() => {
    const shouldSuppressErrors = process.env.REACT_APP_SUPPRESS_AXIOS_ERRORS === "true";

    if (!shouldSuppressErrors && requestDefinition?.config.url !== "/users/me/info/footprint") {
      if (response.error) {
        toast({
          title: "An error occurred with your request",
          description: response.error.message ?? response.error.title ?? response.error.code ?? response.error.status,
          status: "error"
        });
        devLog(`Response Error (${response.error.status})`, response.error);
      }
    }
  }, [ response.error ]);

  return [ response, triggerRequest ];
};