import { combineReducers } from "redux";

import { propertyReducer } from "./property";
import { sectionGroupReducer } from "./sectionGroups";

const reducers = combineReducers({
  sectionGroups: sectionGroupReducer,
  property: propertyReducer
});

export default reducers;

//This RootState is required to use useSelector
export type RootState = ReturnType<typeof reducers>;