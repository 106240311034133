import {
  Text as ChakraText,
  Heading as ChakraHeading,
  TextProps,
  ComponentWithAs
} from "@chakra-ui/react";
import { theme } from "src/components/theme";

type TextType = "heading" | "body" | "homepageHeading" | "homepageBody";

interface CustomTextProps {
  type?: TextType;
}

export const Text: ComponentWithAs<"p", TextProps> & CustomTextProps = ({ type, ...props }) => {
  if (type === "heading" || type === "homepageHeading") {
    return (
      <ChakraHeading
        {...props}
        {...textStyle(type)}
      />
    );
  } else {
    return (
      <ChakraText
        {...props}
        {...textStyle(type)}
      />
    );
  }
};

const textStyle = (type: string) => {
  switch (type) {
    case "heading":
      return {
        fontSize: {
          // mobile
          "base": theme.fontSizes.md,
          "sm": "21px",
          "md": "28px",
          // desktop
          "lg": "20px",
          "xl": theme.fontSizes.lg,
          "2xl": theme.fontSizes.xl
        }
      };
    case "homepageHeading":
      return {
        fontSize: {
          // mobile
          base: "28px",
          // desktop
          lg: theme.fontSizes.xl
        }
      };
    case "body":
      return {
        fontSize: {
          // mobile
          "base": theme.fontSizes.base,
          "sm": theme.fontSizes.base,
          "md": theme.fontSizes.md,
          // desktop
          "lg": theme.fontSizes.md,
          "xl": theme.fontSizes.md,
          "2xl": "20px"
        }
      };
    case "homepageBody":
      return {
        fontSize: {
          // mobile
          base: theme.fontSizes.md,
          // desktop
          lg: theme.fontSizes.lg
        }
      };
    default:
      return undefined;
  }
};