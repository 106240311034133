import { Text } from "src/components/text";

interface SectionGroupDescriptionProps {
  description: string;
}

export const SectionGroupDescription: React.FC<SectionGroupDescriptionProps> = ({ description }) => {
  return description && description !== "" ? (
    <Text>
      {description}
    </Text>
  ) : (<></>);
};