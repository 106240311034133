import Joi from "joi";

// from stackoverflow
const UK_PHONE_REGEX = /^(?:(?:\(?(?:0(?:0|11)\)?[\s-]?\(?|\+)44\)?[\s-]?(?:\(?0\)?[\s-]?)?)|(?:\(?0))(?:(?:\d{5}\)?[\s-]?\d{4,5})|(?:\d{4}\)?[\s-]?(?:\d{5}|\d{3}[\s-]?\d{3}))|(?:\d{3}\)?[\s-]?\d{3}[\s-]?\d{3,4})|(?:\d{2}\)?[\s-]?\d{4}[\s-]?\d{4}))(?:[\s-]?(?:x|ext\.?|#)\d{3,4})?$/;
const onlyStartingPlusAndNumbers = /^(\+44|[0-9])[0-9]+$/;

/**
 * Chain further Joi rules onto the end for further customisation
 */
export const getUKTelephoneSchema = (): Joi.StringSchema =>
  Joi.string().pattern(onlyStartingPlusAndNumbers).pattern(UK_PHONE_REGEX).messages({ "string.pattern.base": "Please enter a valid UK telephone number" }).min(7).max(15).replace(/[ ]/g, "" /** spaces trip up the regex */);
