import {
  Button as ChakraButton,
  ComponentWithAs,
  ButtonProps
} from "@chakra-ui/react";
import debounceFn from "lodash.debounce";

import { useWait } from "../../hooks/useWait";
import type { Wait } from "../../hooks/useWait";
import theme from "../theme";

interface CustomButtonProps {
  inverted?: boolean;
  debounce?: number;
  children?: React.ReactNode | ((ms: Wait) => React.ReactNode);
  uppercase?: boolean; // otherwise lowercases AND increases font-size (for legibility)
}

type ButtonWithCustomProps = ComponentWithAs<"button", ButtonProps & CustomButtonProps>;

/**
 * Accepts function-as-child to enable custom rendering children with access to
 * debounced `waiting` state. Else it'll simply render using the `isLoading`
 * prop (`<Spinner/>`) from Chakra when waiting.
 * @example
 * ```jsx
 * <Button debounce={1000}> {(waiting) => waiting ? "..." : "Send"} </Button>
 * ```
 */

export const Button: ButtonWithCustomProps = props => {
  const {
    onClick, debounce, children, inverted, isLoading, uppercase = true, ...restProps
  } = props;

  if (debounce && debounce < 0) {
    throw new Error("`debounce` must be a positive number");
  }

  const { waiting, setWaiting } = useWait();

  const bg = theme.colors.black,
    color = theme.colors.white,
    hoverBg = theme.colors.gray[ 600 ],
    activeBg = theme.colors.gray[ 400 ];

  const handleClick = debounce ? debounceFn(e => {
    if (debounce) setWaiting(debounce);

    onClick?.(e);
  }, debounce, {
    leading: true,
    trailing: false
  }) : onClick;

  return (
    <ChakraButton
      onClick={handleClick}
      children={typeof children === "function" ? children(waiting) : children}
      isLoading={!!waiting || isLoading}
      // style props
      padding="0.5em 1em"
      textTransform={uppercase ? "uppercase" : "capitalize"}
      fontSize={uppercase ? theme.fontSizes.sm : theme.fontSizes.md}
      fontWeight={uppercase ? 700 : "medium"}
      letterSpacing={uppercase ? "2px" : "0.02em"}
      borderRadius="2px"
      minW={{
        base: "12ch",
        sm: "14ch"
      }}
      bg={inverted ? "transparent" : bg}
      color={inverted ? hoverBg : color}
      _hover={{
        bg: inverted ? activeBg : hoverBg,
        color: inverted && "rgba(0,0,0,0.66)"
      }}
      _active={{
        bg: inverted ? "rgba(0,0,0,0.33)" : hoverBg,
        color: inverted ? hoverBg : activeBg
      }}
      {...restProps}
    />
  );
};