import { stringify } from "querystring";

import { useAxios } from "../../hooks/useAxios";
import { JSONAPIResponse } from "../json-api-response";
import { PropertyFootprint } from "./footprint";

// request + response types

type UtmData = {
    source?: string;
    medium?: string;
    campaign?: string;
    term?: string;
    content?: string;
};

type CallUserData = {
  useEmail: boolean;
  usePhone: boolean;
  telephone?: string;
  actions: string[];
};

type NoParams = Record<string, never>;

export interface UserPathParams {
  id: string;
}
export interface PropertyPathParams {
  propertyId: number;
}

type UpdateUserParams = UserPathParams & Partial<UserEditableProperties>;

type UsersEndpointConfig = {
  GET_ME: EndpointConfig<
    UtmData,
    JSONAPIResponse<{currentUser: User, propertyId: number}>
  >;
  GET_FOOTPRINT: EndpointConfig<
  NoParams,
  JSONAPIResponse<PropertyFootprint>
  >;
  GET_ANON_FOOTPRINT: EndpointConfig<
  PropertyPathParams,
  JSONAPIResponse<PropertyFootprint>
  >;
  UPDATE_FOOTPRINT: EndpointConfig<
  NoParams,
  null
  >;
  REQUEST_CALL: EndpointConfig<
    CallUserData,
    JSONAPIResponse<string>
  >;
  GET_USER: EndpointConfig<
    UserPathParams,
    JSONAPIResponse<User>
  >;
  GET_USERS: EndpointConfig<
    {page: number},
    JSONAPIResponse<{count: number, rows: User[], pageSize: number}>
  >;
  UPDATE_USER: EndpointConfig<
    UpdateUserParams,
    JSONAPIResponse<User>
  >;
  DELETE_USER: EndpointConfig<
    UserPathParams,
    null
  >;
  EXPORT_USER: EndpointConfig<
    UserPathParams,
    string
  >;
  EXPORT_USERS: EndpointConfig<
    NoParams,
    string
  >;
  REGISTER_INTEREST: EndpointConfig<
    {recommendationSlug: string},
    null
  >;
};

// users api specific logic

const requestInitialisers: RequestConfig<UsersEndpointConfig> = {
  GET_ME: (
    (data: UtmData) => ({
      url: `/users/me/info${data ? `?${stringify(data)}` : ""}`,
      method: "GET"
    })
  ),
  GET_FOOTPRINT: (
    () => ({
      url: "/users/me/info/footprint",
      method: "GET"
    })
  ),
  GET_ANON_FOOTPRINT: (
    ({ propertyId }) => ({
      url: `/anon/users/footprint/${propertyId}`,
      method: "GET"
    })
  ),
  UPDATE_FOOTPRINT: (
    () => ({
      url: "/users/me/info/footprint",
      method: "PUT"
    })
  ),
  REQUEST_CALL: (
    (data: CallUserData) => ({
      url: "/users/me/info/footprint/callback",
      method: "POST",
      data
    })
  ),
  GET_USER: (
    ({ id }) => ({
      url: `/users/${id}`,
      method: "GET"
    })
  ),
  GET_USERS: (
    ({ page = 1 }) => ({
      url: "/users",
      method: "GET",
      params: { page }
    })
  ),
  UPDATE_USER: (
    ({ id, ...params }) => ({
      url: `/users/${id}`,
      method: "PATCH",
      data: params
    })
  ),
  DELETE_USER: (
    ({ id }) => ({
      url: `/users/${id}`,
      method: "DELETE"
    })
  ),
  EXPORT_USER: (
    ({ id }) => ({
      url: `/users/${id}/export`,
      method: "GET"
    })
  ),
  EXPORT_USERS: (
    () => ({
      url: "/users/export",
      method: "GET"
    })
  ),
  REGISTER_INTEREST: (
    ({ recommendationSlug }) => ({
      url: "/users/me/info/footprint/register-interest",
      method: "PUT",
      params: { recommendationSlug }
    })
  )
};

export const useUsersApi: UseApiRequest<UsersEndpointConfig> = name => useAxios(requestInitialisers[ name ]);
