import React, {
  useState,
  useEffect
} from "react";
import {
  RouteComponentProps,
  useHistory
} from "react-router";
import {
  VStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Link as ChakraLink
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import Auth from "@aws-amplify/auth";

import { routes } from "src/constants";

import { useAuthContext } from "../../hooks/useAuthContext";
import { Button } from "../../components/button";
import { CenterContent } from "../../components/shared/CenterContent";
import { Text } from "../../components/text";
import {
  FieldError,
  StyledChakraTextInput as Input
} from "../../components/form";
import { LoadingPage } from "../../components/shared/LoadingPage";
import { Wrapper } from "../../components/shared/Wrapper";
import { FullSpace } from "../../components/shared/FullSpace";
import theme from "../../components/theme";

const schema = Joi.object({
  email: Joi.string().email({ tlds: { allow: false } }).required(),
  password: Joi.string()
    .pattern(new RegExp("^[a-zA-Z0-9]{8,}$"))
    .when("tabIndex", {
      is: 1,
      then: Joi.string().required(),
      otherwise: Joi.string().optional()
    })
    .messages({ "string.pattern.base": "Password must be at least 8 characters and contain only letters and numbers." })
});

export const RetrievePage: React.FC = () => {
  const { authDataState } = useAuthContext();
  const history = useHistory();
  const [ confirmPasswordResetError, setConfirmPasswordResetError ] = useState<string | null>(null);
  const [ tabIndex, setTabIndex ] = useState(0);

  const {
    register, handleSubmit, formState: { errors, isDirty }, reset
  } = useForm({ resolver: joiResolver(schema) });

  const loginWithEmail = async (data: Record<string, string>) => {
    try {
      await Auth.forgotPassword(data.email);
      history.push(routes.retrieveCode, { email: data.email });
    } catch (e) {
      if (e.code === "UserNotFoundException") {
        setConfirmPasswordResetError("Email not registered!");
      } else {
        setConfirmPasswordResetError("Error logging in using email. Please try again");
      }

      console.log(e);
    }
  };

  const loginWithEmailAndPassword = async (data: Record<string, string>) => {
    try {
      await Auth.signIn(data.email, data.password);
      history.push(routes.footprint);
    } catch (e) {
      if (e.code === "UserNotFoundException" || e.code === "NotAuthorizedException") {
        setConfirmPasswordResetError("Incorrect email or password. Please check your credentials and try again");
      } else {
        setConfirmPasswordResetError("Error logging in using email and password. Please try again");
      }

      console.log(e);
    }
  };

  const handleVerify = async (data: Record<string, string>) => {
    try {
      if (tabIndex === 0) {
        await loginWithEmail(data);
      }

      if (tabIndex === 1) {
        await loginWithEmailAndPassword(data);
      }
    } catch (e) {
      setConfirmPasswordResetError("Email not registered");
      console.log("Error while logging in");
      console.log(e);
    }
  };

  useEffect(() => {
    reset();
  }, [ tabIndex, reset ]);

  return (
    <FullSpace
      bg={theme.colors.gray[ 100 ]}
      py={theme.spacing[ 8 ]}
    >
      <Wrapper variant="form">
        <CenterContent justifyContent="flex-start">
          {authDataState.authenticating ? (
            <LoadingPage />
          ) : (
            <VStack
              // maxW="300px"
              w="100%"
              spacing={theme.spacing[ 6 ]}
            >
              <Text
                textAlign="center"
                fontSize={theme.fontSizes.lg}
                fontWeight="semibold"
              >
                Retrieve your Home Check
              </Text>

              <Tabs
                isManual
                size='md'
                variant='unstyled'
                align='start'
                index={tabIndex}
                onChange={index => setTabIndex(index)}
                defaultIndex={0}
              >
                <TabList>
                  <Tab
                    _selected={{
                      color: "white",
                      bg: theme.colors.green[ 100 ]
                    }}

                  >Email only
                  </Tab>

                  <Tab
                    _selected={{
                      color: "white",
                      bg: theme.colors.green[ 100 ]
                    }}

                  >Email & password
                  </Tab>
                </TabList>

              </Tabs>

              <Text
                textAlign="center"
                fontSize="sm"
              >
                {tabIndex === 0 ? "Please enter your email address below to retrieve your Home Check results." : "Please enter your email address and password below to retrieve your Home Check results."}
              </Text>

              <form
                onSubmit={handleSubmit(handleVerify)}
                style={{ width: "100%" }}
              >
                <VStack
                  spacing={theme.spacing[ 8 ]}
                  w="100%"
                >
                  {confirmPasswordResetError && (
                    <Text color="red">
                      {confirmPasswordResetError}
                    </Text>
                  )}

                  <Input
                    type="text"
                    placeholder="Email address"
                    w="100%"
                    textAlign="center"
                    isInvalid={errors.email}
                    {...register("email")}
                  />

                  <FieldError
                    error={errors.email}
                    fieldName="Email address"
                  />

                  {tabIndex === 1 && (
                    <>
                      <Input
                        type="password"
                        placeholder="Password"
                        w="100%"
                        textAlign="center"
                        isInvalid={errors.password}
                        {...register("password")}
                      />

                      <FieldError
                        error={errors.password}
                        fieldName="Password"
                      />

                      <Text>
                        Forgot your password?{" "}

                        <ChakraLink
                          href={routes.forgotPassword}
                        >
                          Reset it now!
                        </ChakraLink>
                      </Text>
                    </>
                  )}

                  <Button
                    type="submit"
                    disabled={!isDirty}
                  >
                    Login
                  </Button>
                </VStack>
              </form>
            </VStack>
          )}
        </CenterContent>
      </Wrapper>
    </FullSpace>
  );
};

export default RetrievePage;