export enum UserOffsetPortfolioInterval {
  MONTHLY = "MONTHLY",
  ANNUAL = "ANNUAL"
}

export enum UserOffsetPortfolioStatus {
  ACTIVE = "ACTIVE",
  PENDING = "PENDING",
  INACTIVE = "INACTIVE",
  SUSPENDED = "SUSPENDED",
}

export interface OffsetPortfolio {
  id: string;
  name: string;
  description: string;
  whyToChoose: string;
  offsetProviders: OffsetProvider[];
  currentPrice?: number;
}

export interface OffsetProvider {
  name: string;
  currentPrice?: number;
  portfolioOffsetProvider: {
    portfolioPercentage: number;
  }
}

export interface UserOffsetPortfolioAttributes {
  startDate: Date;
  offsetPortfolioId: string;
  cost: number;
  interval: UserOffsetPortfolioInterval;
  targetTonnes: number;
  status: UserOffsetPortfolioStatus;
  offsetPortfolio?: OffsetPortfolio;
}
