import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  plugins: {
    title: {
      display: true,
      text: "Monthly future performance and cost estimate"
    }
  },
  responsive: true,
  plugins: { legend: { position: "bottom" as const } },
  scales: {
    x: { stacked: true },
    y: { stacked: true }
  }
};

const labels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export const data = {
  labels,
  datasets: [
    {
      type: "line" as const,
      label: "Total Cost",
      order: 1,
      borderColor: "rgb(255, 99, 132)",
      borderWidth: 3,
      fill: false,
      data: [
        200,
        350,
        700,
        1000,
        800,
        1500,
        2000,
        800,
        800,
        600,
        250
      ]
    },
    {
      type: "bar" as const, // Specify type for bar datasets
      label: "ASHP - Space Heating",
      order: 2,
      data: [
        0,
        100,
        200,
        300,
        400,
        500,
        600,
        700,
        800,
        900,
        1000
      ],
      backgroundColor: "#156082"
    },
    {
      type: "bar" as const, // Specify type for bar datasets
      label: "Hybrid top-up",
      order: 2,
      data: [
        0,
        100,
        200,
        300,
        400,
        500,
        600,
        700,
        800,
        900,
        1000
      ],

      backgroundColor: "#E97132"
    },
    {
      type: "bar" as const, // Specify type for bar datasets
      label: "Secondary Heating",
      order: 2,
      data: [
        0,
        100,
        200,
        300,
        400,
        500,
        600,
        700,
        800,
        900,
        1000
      ],
      backgroundColor: "#196B24"
    },
    {
      type: "bar" as const, // Specify type for bar datasets
      label: "Hot water",
      order: 2,
      data: [
        0,
        100,
        200,
        300,
        400,
        500,
        600,
        700,
        800,
        900,
        1000
      ],
      backgroundColor: "#0F9ED5"
    }

  ]
};

const MonthlyFuturePerformanceAndCost = () => {
  return (
    <Bar
      options={options}
      data={data}
    />
  );
};

export default MonthlyFuturePerformanceAndCost;