import React, { useState } from "react";
import {
  RouteComponentProps,
  useHistory
} from "react-router";
import {
  VStack,
  useToast
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import Auth from "@aws-amplify/auth";

import { routes } from "src/constants";

import { useAuthContext } from "../../hooks/useAuthContext";
import { Button } from "../../components/button";
import { CenterContent } from "../../components/shared/CenterContent";
import { Text } from "../../components/text";
import {
  FieldError,
  StyledChakraTextInput as Input
} from "../../components/form";
import { LoadingPage } from "../../components/shared/LoadingPage";
import { Wrapper } from "../../components/shared/Wrapper";
import { FullSpace } from "../../components/shared/FullSpace";
import theme from "../../components/theme";

export type ForgotPasswordPageProps = RouteComponentProps<
  undefined,
  Record<string, unknown>,
  { from: string }
>;

const schema = Joi.object({ email: Joi.string().email({ tlds: { allow: false } }).required() });

export const ResetPasswordPage: React.FC<ForgotPasswordPageProps> = ({ location }) => {
  const { authDataState } = useAuthContext();
  const history = useHistory();
  const [ confirmPasswordResetError, setConfirmPasswordResetError ] = useState<string | null>(null); const toast = useToast();

  const schema = Joi.object({

    password: Joi.string()
      .pattern(new RegExp("^[a-zA-Z0-9]{8,}$")).required().messages({ "string.pattern.base": "Password must be at least 8 characters and contain only letters and numbers." }),
    confirm_password: Joi.string()
      .valid(Joi.ref("password"))
      .required()
      .messages({ "any.only": "Passwords do not match" })
  });

  const {
    register, handleSubmit, formState: { errors, isDirty }
  } = useForm({ resolver: joiResolver(schema) });

  const handleResetPassword = async (data: Record<string, string>) => {
    try {
      await Auth.forgotPasswordSubmit(location.state.email, location.state.code, data.password);

      toast({
        status: "success",
        title: "Password reset successfully!",
        description: "You can now use your new password to retrieve your home check.",
        duration: 3000,
        isClosable: true,
        position: "top-right"
      });
    } catch (e) {
      if (e.code === "UserNotFoundException") {
        setConfirmPasswordResetError("Email not registered!");
      } else {
        setConfirmPasswordResetError("Error verifying email. Please try again");
      }

      console.log(e);
    }
  };

  return (
    <FullSpace
      bg={theme.colors.gray[ 100 ]}
      py={theme.spacing[ 8 ]}
    >
      <Wrapper variant="form">
        <CenterContent justifyContent="flex-start">
          {authDataState.authenticating ? (
            <LoadingPage />
          ) : (
            <VStack
              // maxW="300px"
              w="100%"
              spacing={theme.spacing[ 6 ]}
            >
              <Text
                textAlign="center"
                fontSize={theme.fontSizes.lg}
                fontWeight="semibold"
              >
                Reset your password
              </Text>

              <Text
                textAlign="center"
                fontSize="sm"
              >
                Enter your email address below to reset your password
              </Text>

              <form
                onSubmit={handleSubmit(handleResetPassword)}
                style={{ width: "100%" }}
              >
                <VStack
                  spacing={theme.spacing[ 8 ]}
                  w="100%"
                >
                  {confirmPasswordResetError && (
                    <Text color="red">
                      {confirmPasswordResetError}
                    </Text>
                  )}

                  <Input
                    type="password"
                    placeholder="Password"
                    isInvalid={errors.password}
                    {...register("password")}
                  />

                  <FieldError
                    error={errors.password}
                    fieldName="Password"
                  />

                  <Input
                    type="password"
                    placeholder="Confirm Password"
                    isInvalid={errors.confirm_password}
                    {...register("confirm_password")}
                  />

                  <FieldError
                    error={errors.confirm_password}
                    fieldName="Confirm Password"
                  />

                  <Button
                    type="submit"
                    disabled={!isDirty}
                  >
                    Submit
                  </Button>
                </VStack>
              </form>
            </VStack>
          )}
        </CenterContent>
      </Wrapper>
    </FullSpace>
  );
};

export default ResetPasswordPage;