import {
  Spacer,
  VStack
} from "@chakra-ui/react";
import React, { useRef } from "react";

import { Wrapper } from "../../shared/Wrapper";
import { Text } from "../../text";
import theme from "../../theme";
import { desktopBreakpointKey } from "../../../hooks/useIsDesktop";
import { BackgroundImage } from "../../shared/BackgroundImage";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";

const abstractJpg = "https://genous-public-assets.s3.eu-west-2.amazonaws.com/public/landing/abstract.jpg";
const abstractJpg2x = "https://genous-public-assets.s3.eu-west-2.amazonaws.com/public/landing/abstract%402x.jpg";
const abstractJpg3x = "https://genous-public-assets.s3.eu-west-2.amazonaws.com/public/landing/abstract%403x.jpg";
const abstractWebp = "https://genous-public-assets.s3.eu-west-2.amazonaws.com/public/landing/abstract.webp";
const abstractWebp3x = "https://genous-public-assets.s3.eu-west-2.amazonaws.com/public/landing/abstract%403x.webp";

export const ExpertsSection: React.FC = () => {
  const shade = "rgba(0, 0, 0, 0.75)";
  const observedRef = useRef<HTMLDivElement>(null);
  const observed = useIntersectionObserver(observedRef, { freezeOnceVisible: true });

  return (
    <BackgroundImage
      ref={observedRef}
      backgroundImage={{
        // background filler for before bg image loads
        base: "url(\"https://genous-public-assets.s3.eu-west-2.amazonaws.com/public/background/bg_green.svg\")",
        sm: "url(\"https://genous-public-assets.s3.eu-west-2.amazonaws.com/public/background/bg_green.svg\")"
      }}
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      py={{
        base: `clamp(${theme.spacing[ 12 ]}, 10vh, ${theme.spacing[ 40 ]})`,
        [ desktopBreakpointKey ]: theme.spacing[ 48 ]
      }}
      position="relative"
      overflow="hidden"
      imageProps={{ children: observed?.isIntersecting ?? !window.IntersectionObserver ? imageSources : null }}
    >
      <Wrapper>
        <VStack
          alignItems={{
            base: "flex-start",
            [ desktopBreakpointKey ]: "baseline"
          }}
          px={{ [ desktopBreakpointKey ]: theme.spacing[ 16 ] }}
          py={{ [ desktopBreakpointKey ]: `clamp(${theme.spacing[ 16 ]}, 6vh, ${theme.spacing[ 48 ]})` }}
          spacing={theme.spacing[ 8 ]}
          color="white"
          bg={{
            base: shade,
            [ desktopBreakpointKey ]: "black"
          }}
          mx="auto"
          mr={{ [ desktopBreakpointKey ]: "30%" }}
          w={{
            base: "100% !important",
            [ desktopBreakpointKey ]: "min(67%, 900px) !important"
          }}
          boxShadow={{
            base: "0 0 0 100vmax " + shade,
            [ desktopBreakpointKey ]: "unset"
          }}
        >
          <Text
            type="homepageHeading"
            fontWeight="medium"
            lineHeight="1.2"
            letterSpacing="-0.01em"
            pr="10%"
          >
            End-to-end expertise
          </Text>

          <Text
            type="homepageBody"
            lineHeight="1.5"
            letterSpacing="-0.01em"
          >
            Our network of fully accredited suppliers can get required work done, and we can get subsidies like the <b>Great British Insulation Scheme</b> and

            {" "}

            <b>Boiler Upgrade Scheme</b> for you.
          </Text>

          <Text
            type="homepageBody"
            lineHeight="1.5"
            letterSpacing="-0.01em"
          >
            We cut through the complexity and make increasing home efficiency simple.
          </Text>

          <Spacer
            p={{ [ desktopBreakpointKey ]: "0.5rem" }}
          />

        </VStack>
      </Wrapper>
    </BackgroundImage>
  );
};

const imageSources = (
  <>
    <source
      srcSet={abstractWebp3x}
      media="(min-width: 1921px)"
      type="image/webp"
    />

    <source
      srcSet={abstractJpg3x}
      media="(min-width: 1921px)"
      type="image/jpeg"
    />

    <source
      srcSet={abstractJpg2x}
      media="(min-width: 451px)"
      type="image/jpeg"
    />

    <source
      srcSet={abstractWebp}
      type="image/webp"
    />

    <img
      src={abstractJpg}
      loading="lazy"
    />
  </>
);