import {
  OffsetPortfolio,
  UserOffsetPortfolioInterval
} from "src/api/offsets";
import styled from "styled-components";
import { ReactComponent as QuestionCircleSvg } from "src/assets/icons/question-mark-circle.svg";
import {
  Box,
  BoxProps,
  Divider,
  HStack,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  UnorderedList,
  VStack
} from "@chakra-ui/react";
import {
  useMemo,
  useState
} from "react";
import { formatCurrency } from "src/helpers/number";
import {
  portfolioAnnualCost,
  portfolioMonthlyCost
} from "src/helpers/get-portfolio-costs";

import { Text } from "../text/Text";
import theme from "../theme";
import PortfolioPieChart from "./PortfolioPieChart";

interface PortfolioCardProps extends BoxProps {
  portfolio: OffsetPortfolio;
  tonnesToOffset: number;
  planType?: UserOffsetPortfolioInterval;
  currentPortfolio?: boolean;
  cost?: number;
}

export const PortfolioCard: React.FC<PortfolioCardProps> = ({
  portfolio,
  tonnesToOffset,
  planType = UserOffsetPortfolioInterval.MONTHLY,
  currentPortfolio,
  cost
}) => {
  const costPerTonne = useMemo(() => portfolio.currentPrice ?? 0, [ portfolio.currentPrice ]);

  const monthlyCost = useMemo(() => (planType === UserOffsetPortfolioInterval.MONTHLY && cost) || portfolioMonthlyCost(costPerTonne, tonnesToOffset), [
    cost,
    costPerTonne,
    planType,
    tonnesToOffset
  ]);

  const annualCost = (planType === UserOffsetPortfolioInterval.ANNUAL && cost) || portfolioAnnualCost(costPerTonne, tonnesToOffset);
  const [ modalOpen, setModalOpen ] = useState(false);

  return (
    <VStack
      alignItems="stretch"
      w="100%"
      flex={1}
    >

      {currentPortfolio && (
        <Text
          fontSize="small"
          color={theme.colors.purple}
        >Your current plan
        </Text>
      )}

      <PortfolioCardWrapper>

        <PortfolioInfo>
          <TitleWrapper>
            {/* flex 9999 means assume maximum space when another flex:1 sibling exists, so both assume full-width when wrapped onto new lines */}

            <Box flex="9999 0 15ch">
              <HStack alignItems="baseline">
                <Text
                  fontSize="lg"
                  fontWeight="medium"
                  textTransform="capitalize"
                  lineHeight="1.1"
                  letterSpacing="-0.5px"
                >
                  {portfolio.name}
                </Text>

                {portfolio.description && (
                  <QuestionCircleSvg
                    width="1rem"
                    fill={theme.colors.black}
                    cursor="pointer"
                    style={{ flex: "0 0 auto" }}
                    onClick={e => {
                      // prevents click event capturing below and ticking the item
                      e.preventDefault();
                      setModalOpen(true);
                    }}
                  />
                )}
              </HStack>

              <Text
                fontSize="xx-small"
                alignSelf="center"
              >
                Click on the question marks for more information on this portfolio
              </Text>

              <Text
                fontSize="xs"
                mt={2}
              >
                {formatCurrency(costPerTonne)}

                {" "}per tonne

                {" "}

                ({formatCurrency(costPerTonne / 100)}

                {" "}per 0.01 tonnes)
              </Text>
            </Box>

            <PortfolioPrice>
              <Text
                fontSize={{
                  base: theme.fontSizes.base,
                  xl: theme.fontSizes.lg
                }}
                fontWeight="bold"
                color={theme.colors.purple}
                mr="1"
              >
                {formatCurrency(planType === UserOffsetPortfolioInterval.MONTHLY ? monthlyCost : annualCost)}
              </Text>

              <Text fontSize="xs">
                {planType === UserOffsetPortfolioInterval.MONTHLY ? "per month" : "per year"}
              </Text>
            </PortfolioPrice>
          </TitleWrapper>

          <Text
            fontSize="xs"
            fontWeight="medium"
            mt={theme.spacing[ 2 ]}
          >
            {portfolio.whyToChoose}
          </Text>

          <UnorderedList
            fontSize="xs"
            color={theme.colors.gray[ 500 ]}
            mt={theme.spacing[ 2 ]}
          >
            {portfolio.offsetProviders.map(provider => (
              <ListItem key={provider.name}>
                <b>
                  {provider.portfolioOffsetProvider.portfolioPercentage}
                  %

                </b>

                {" "}

                {provider.name}
              </ListItem>
            ))}
          </UnorderedList>
        </PortfolioInfo>

        <Modal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          size="2xl"
        >
          <ModalOverlay />

          <ModalContent>
            <ModalCloseButton />

            <ModalBody
              px={theme.spacing[ 32 ]}
              py={theme.spacing[ 12 ]}
            >
              <VStack
                alignItems="stretch"
                spacing={6}
              >
                <Box align="center">
                  <PortfolioPieChart
                    portfolio={portfolio}
                    size={120}
                  />
                </Box>

                <TitleWrapper>
                  <div>
                    <Text
                      fontSize="lg"
                      fontWeight="medium"
                    >
                      {portfolio.name}
                    </Text>

                    <Text
                      fontSize="xs"
                      fontWeight="medium"
                    >
                      {formatCurrency(costPerTonne)}

                      {" "}
                      per tonne
                    </Text>
                  </div>

                  <PortfolioPrice>
                    <Text
                      fontSize="md"
                      fontWeight="bold"
                      color={theme.colors.purple}
                    >
                      {formatCurrency(monthlyCost)}
                    </Text>

                    <Text fontSize="xs">
                      a month
                    </Text>
                  </PortfolioPrice>
                </TitleWrapper>

                <Divider my={theme.spacing[ 4 ]} />

                <Text fontSize="sm">
                  <ul>
                    {portfolio.description.split("|").map((line, index) => (

                      <li key={index.toString()}>{line}</li>

                    ))}
                  </ul>
                </Text>
              </VStack>
            </ModalBody>
          </ModalContent>
        </Modal>
      </PortfolioCardWrapper>
    </VStack>
  );
};

const PortfolioCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing[ 6 ]};
  padding-bottom: ${theme.spacing[ 8 ]};
  flex: 1;
  border-bottom: 1px solid #ccc;
`;

const PortfolioInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: .5rem;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: .5em;
`;

const PortfolioPrice = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: baseline;
  line-height: 1.1;
  flex-grow: 1;

  @media screen and (max-width: 420px) {
    flex-direction: row;
    flex-grow: 9999;
    align-items: baseline;
  }
`;