import { VStack } from "@chakra-ui/react";

export const UpperThird: typeof VStack = props => (
  <VStack
    flex={1}
    minH="300px"
    h="66vh"
    maxH="1000px"
    width="100%"
    {...props}
  />
);