const formatter = new Intl.NumberFormat("en-GB");

export const formatNumber = formatter.format;

const currencyFormatter = new Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
  minimumIntegerDigits: 1,
  minimumFractionDigits: 0
});

const trimOrPadZero = (numberStr: string): string => numberStr.replace(/\.\d$/, substr => substr.endsWith("0") ? substr.slice(0, substr.length - 3) : substr + 0);

export const formatCurrency = (n: number): string => trimOrPadZero(currencyFormatter.format(n));

export const roundCO2 = (n: number | null | undefined): string => n ? n.toFixed(1) : "-";

export const roundCostOrSaving = (n: number | null | undefined): string => n ? n.toFixed(2) : "-";