import type { AxiosError } from "axios";

import { isNodeEnv } from "../../helpers";

export const handleDevPostcodeLookup = (error: AxiosError, cb: (json: unknown) => void): true | undefined => {
  const matchUrl = "/properties/search?postcode=bs16 6rr";

  if (isNodeEnv("development") && error.config?.url === matchUrl) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    cb({ data: require("./test_postcode_response.json") });
    // just making it clear in devtools that this is a mock response
    // eslint-disable-next-line no-console
    console.log("%c[\"useAxios/utils\"]: patched response object", "color: gray");

    return true;
  }
};