import { useSpring } from "framer-motion";
import {
  useState,
  useEffect
} from "react";

const MULTIPLIER = 2;
const NAV_HEIGHT = () => document.querySelector("nav")?.clientHeight ?? 0;
const viewportPercentage = (n: number) => (n - NAV_HEIGHT()) / window.innerHeight;

export const useAnimatedScrollState = (): number => {
  const scrollValue = useSpring(1 - viewportPercentage(window.scrollY), {
    mass: 0.5,
    bounce: 0,
    damping: 10
  });

  const [ animatedStateValue, setAnimatedStateValue ] = useState<number>(scrollValue.get());

  useEffect(() => {
    // registers a listener and updates state
    const unlisten = scrollValue.onChange(setAnimatedStateValue);

    return () => unlisten();
  }, [ scrollValue ]);

  useEffect(() => {
    const handleScroll = () => scrollValue.set(Math.max(0, 1 - (viewportPercentage(window.scrollY) * MULTIPLIER)));

    window.addEventListener("scroll", handleScroll, {
      passive: true,
      capture: false
    });
  }, [ scrollValue ]);

  return animatedStateValue;
};