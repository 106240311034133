export const colors = {
  black: "#000000",
  white: "#ffffff",
  white70: "#ffffff70",
  gray: {
    100: "#F4F4F1",
    150: "#f5f0f0",
    200: "#D2D2D2",
    300: "#CBC5C5",
    400: "#AAAAAA",
    500: "#909298",
    600: "#767676",
    700: "#625F5F",
    800: "#62656e"
  },
  green: {
    50: "#B8FDEB",
    100: "#0de291",
    200: "#17C784",
    300: "#4BD886",
    400: "#065336"
  },
  greenYellow: "#B9D544",
  yellow: "#E3D320",
  yellowOrange: "#DFBD24",
  orange: "#D9962A",
  red: {
    300: "#EF7C7C",
    700: "#B70909",
    900: "#BA170C"
  },
  pink: "#C1165E",
  blue: {
    500: "#1e58eb",
    700: "#1952e3",
    800: "#1246c9"
  },
  brown: "#333332",
  purple: "#767BFF"
};