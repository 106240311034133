import React from "react";
import {
  HStack,
  Spacer,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  BoxProps,
  Image,
  ImageProps,
  Flex
} from "@chakra-ui/react";

import { Wrapper } from "./Wrapper";
import { Text } from "../text";
import theme from "../theme";

const LeftColumn: typeof Box = props => (
  <Box
    wordBreak="break-word"
    overflowWrap="break-word"
    maxWidth="100%"
    hyphens="auto"
    flexBasis={{
      base: "100%",
      sm: "100%",
      md: "30%",
      lg: "40%"
    }}
    mb="1em"
    pr={{
      base: "2em",
      lg: "3em"
    }}
    _empty={{
      m: "0 !important",
      p: "0 !important"
    }}
    {...props}
  />
);

const RightColumn: typeof Box = props => (
  <Box
    flex={1}
    {...props}
  />
);

const firefoxBaselineFix: BoxProps["sx"] = {
  /* Fixing baseline bug in Firefox */
  "@-moz-document url-prefix()": { alignItems: "flex-start" }
};

const joinAsParagraphs = (strings: Array<string|ContentType>) => typeof strings[ 0 ] === "string" ? strings.map((str, i) => (<p key={i.toString()}>{str}</p>)) : strings;

const CredentialsLayout = ({
  name, content, image, ...restProps
}) => {
  return (

    <Box
      {...restProps}
      width="100%"
      sx={{ scrollMargin: "10vmin" }}

    >
      <Wrapper
        flex="0"
        width="100%"
      >
        <Flex
          sx={firefoxBaselineFix}
          alignItems="center"
          flexWrap="wrap"
          spacing="0"
          lineHeight="1.75"
        >
          <LeftColumn>
            <Text
              type="heading"
              fontWeight="medium"
            >
              {name}
            </Text>

            <img
              src={image}
              alt={name}
              width='250px'
              height='250px'
            />
          </LeftColumn>

          <RightColumn
            type="body"
            color={theme.colors.gray[ 700 ]}
            sx={{
              "[aria-role]": { color: theme.colors.gray[ 400 ] },
              "a[href]": { color: theme.colors.blue[ 500 ] }
            }}
          >
            <Text>{content}</Text>
          </RightColumn>
        </Flex>
      </Wrapper>
    </Box>

  );
};

export default CredentialsLayout;

{ /* <div {...restProps}>
      <p>{name}</p>

      <img
        src={image}
        alt={name}
      />

      <p>{content}</p>
    </div> */ }