import { useRef } from "react";
import {
  BoxProps,
  HStack,
  ResponsiveValue,
  StackProps,
  useBreakpointValue as useChakraBreakpointValue,
  forwardRef,
  IconButton
} from "@chakra-ui/react";
import {
  ChevronLeftIcon,
  ChevronRightIcon
} from "@chakra-ui/icons";
import styled from "styled-components";

import { isTouchDevice } from "../../helpers/is-touch-device";
import {
  cssCalcWrappingGutterMap,
  getWrapperForBreakpoint
} from "../../helpers/wrapper";
import { useBreakpointKeyValue } from "../../hooks/useBreakpointKeyValue";
import { desktopBreakpointKey } from "../../hooks/useIsDesktop";
import theme from "../theme";
// Need this for scroll behavior to work on Safari
import "scroll-behavior-polyfill";

export const Carousel = forwardRef<StackProps, "div">(({ children, ...restProps }, ref) => {
  const carouselRef = useRef<null | HTMLDivElement>(null);
  const amountToScrollHorizontally = window.innerWidth * 0.5;

  const maxCarouselMarginX = useChakraBreakpointValue({
    base: "40px",
    [ desktopBreakpointKey ]: "150px"
  });

  const [ _, breakpointValue ] = useBreakpointKeyValue();
  // css size as a string (i.e. may contain `calc()`)
  // no smaller than maxCarouselMarginX (to avoid teeny fade)
  // else wrapper-gutter size (see getWrapperForBreakpoint)
  const scrollSidePaddingCssStr = `max(${maxCarouselMarginX}, calc((100% - ${(breakpointValue ? getWrapperForBreakpoint("layout", breakpointValue) : 0)}) / 2))`;

  const scrollHorizontally = (scrollOffset: number) => {
    if (carouselRef.current) {
      carouselRef.current.scrollLeft += scrollOffset;
    }
  };

  return (
    <HStack ref={ref}>
      <ArrowNavigationCard
        style={{
          left: 0,
          borderRadius: "0px 5px 5px 0px"
        }}
        aria-label='Previous reduce topic'
        onClick={() => scrollHorizontally(-amountToScrollHorizontally)}
        icon={(
          <ChevronLeftIcon boxSize={8} />
        )}
      />

      <HStack
        ref={carouselRef}
        style={{ scrollBehavior: "smooth" }}
        {...carouselStyleProps(scrollSidePaddingCssStr)}
        {...restProps}
      >
        {children}
      </HStack>

      <ArrowNavigationCard
        onClick={() => scrollHorizontally(amountToScrollHorizontally)}

        style={{
          right: 0,
          borderRadius: "5px 0px 0px 5px"
        }}
        aria-label='Next reduce topic'
        icon={(
          <ChevronRightIcon boxSize={8} />
        )}
      />

    </HStack>
  );
});

const carouselStyleProps = (scrollSidePaddingCssStr: string): StackProps => ({
  overflowX: "scroll",
  spacing: {
    "base": theme.spacing[ 6 ],
    "sm": theme.spacing[ 8 ],
    [ desktopBreakpointKey ]: theme.spacing[ 12 ],
    "2xl": theme.spacing[ 20 ]
  },
  alignItems: "stretch",
  sx: {
    "scrollSnapType": "x",
    "&::-webkit-scrollbar": { display: isTouchDevice() ? "none" : "initial" },
    // the fade on the right side of the carousel
    // fading starts to happen at the same point as the end margin of the
    // last item
    "-webkit-mask-image": `
    linear-gradient(to right, 
      black calc(100% - ${scrollSidePaddingCssStr}),
    rgba(0,0,0,0.1)
    )`,
    "& > *": carouselItemStyleProps(scrollSidePaddingCssStr)
  }
});

// the `sx` prop was not being applied to the nested children, so forcing props
// to accept scrollSnapAlign & scrollMarginLeft, which neither are supported yet
// by chakra
type ScrollChildProps = {
  scrollSnapAlign: ResponsiveValue<string>,
  scrollMarginLeft: ResponsiveValue<string>,
};

const carouselItemStyleProps = (scrollSidePaddingCssStr: string): BoxProps & ScrollChildProps => ({
  whiteSpace: "unset",
  flexShrink: 0,
  scrollSnapAlign: "center",
  // gives an off-center offset to first visible item alignment snap pos
  scrollMarginLeft: { [ desktopBreakpointKey ]: "-25vw" },
  _first: { ml: cssCalcWrappingGutterMap("layout") },
  // fixes flexbox end padding/margin bug
  // see https://stackoverflow.com/a/38994114
  // must be applied to last-child
  _last: {
    "mr": scrollSidePaddingCssStr,
    "position": "relative",
    "&::after": {
      content: "''",
      width: scrollSidePaddingCssStr,
      height: "1px",
      position: "absolute",
      left: "100%",
      top: "0",
      pointerEvents: "none",
      visibility: "hidden"
    }
  }
});

const ArrowNavigationCard = styled(IconButton)`
  position: absolute;
  z-index: 1;
  height: 25%;
  width: 5%;
  color: ${theme.colors.gray[ 400 ]};
  background-color: ${theme.colors.black};
  opacity: 0.90;
  &:hover {
    background-color: ${theme.colors.black};
    color: ${theme.colors.green[ 200 ]};
  };
`;