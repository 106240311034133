import {
  Center,
  VStack
} from "@chakra-ui/react";

const UnstyledCenterContent: typeof Center = ({ children, ...props }) => (
  <VStack
    w="100%"
    h="100%"
    flex={1}
    justifyContent="center"
    alignItems="center"
    {...props}
  >
    {children}
  </VStack>
);

export const CenterContent = UnstyledCenterContent;