import {
  Box,
  VStack,
  HStack,
  Divider,
  Image
} from "@chakra-ui/react";
import { Text } from "src/components/text";
import theme from "src/components/theme";

const STEP_COUNT = 4;

const HowItWorksSectionStep: React.FC<{image: string, stepNumber: number}> = ({
  image, children, stepNumber
}) => {
  return (
    <Box
      flexBasis={{
        base: "100%",
        md: "45%",
        lg: `${90 / STEP_COUNT}%`
      }}
      py={{ md: theme.spacing[ 10 ] }}
      pb={{
        base: theme.spacing[ 10 ],
        md: 0
      }}
      _last={{ pb: 0 }}
    >
      <VStack
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <Image
          src={image}
          width="100%"
          height={200}
          objectFit="cover"
        />

        <HStack
          w="100%"
        >
          <Text
            fontSize="xl"
            color={theme.colors.gray[ 300 ]}
            w="30px"
            textAlign="center"
            fontWeight="500"
          >
            {stepNumber}
          </Text>

          <Divider
            borderWidth={1}
            opacity={1}
            borderColor={theme.colors.gray[ 400 ]}
          />
        </HStack>

        <Text
          pl="30px"
          fontWeight="500"
          fontSize={theme.fontSizes.md}
        >{children}
        </Text>
      </VStack>
    </Box>
  );
};

export default HowItWorksSectionStep;