import { useAxios } from "../../hooks/useAxios";
import { JSONAPIResponse } from "../json-api-response";

type V2ConfigEndpointConfig = {
    CALC: EndpointConfig<
    any, JSONAPIResponse<{
      canAddHP: boolean;
      propertyId: number;
      asIs: {
        "heatingUsageEnergy": number;
        "heatingUsagePounds": number;
        "co2ePerYear": number;
        "sapGrade": string;
        "sapNumber": number;
      },
      withORHP: {
        "heatingUsageEnergy": number,
        "heatingUsagePounds": number,
        "co2ePerYear": number,
        "sapGrade": string,
        "sapNumber": number
      },
      withORHPAndUF: {
        "heatingUsageEnergy": number,
        "heatingUsagePounds": number,
        "co2ePerYear": number,
        "sapGrade": string,
        "sapNumber": number
      }
    }>
    >;
};

const requestInitialisers: RequestConfig<V2ConfigEndpointConfig> = {
  CALC: (
    (data: any) => ({
      url: "/polypipe",
      method: "POST",
      data,
      timeout: 100000
    })
  )
};

export const usePolypipeAPI: UseApiRequest<V2ConfigEndpointConfig> = name => useAxios(requestInitialisers[ name ]);
