import {
  Table,
  Tr,
  Text,
  TableContainer,
  Button,
  AccordionItem,
  Box,
  Input,
  Tbody,
  Grid,
  AccordionPanel,
  Accordion,
  GridItem,
  AccordionButton,
  AccordionIcon
} from "@chakra-ui/react";
import { Recommendation } from "src/api/users/footprint";
import theme from "src/components/theme";
import { formatCurrency } from "src/helpers/number";
import {
  MdOutlineSignalCellularAlt1Bar,
  MdOutlineSignalCellularAlt2Bar,
  MdOutlineSignalCellularAlt
} from "react-icons/md";
import Switch from "react-switch";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";

interface Props {
  selectedSlugs: string[]
  toggleSlug: (r: Recommendation) => void
  recommendations: Recommendation[]
  lowPriority?: true;
  isAuthenticated?: false;
}

export const RecommendationsTable: React.FC<Props> = ({
  recommendations, selectedSlugs, toggleSlug, lowPriority, isAuthenticated
}) => {
  if (lowPriority) {
    return (
      <Box>
        {recommendations.map(r => (
          <Text
            mb={2}
            key={r.recommendation.slug}
          >{r.recommendation.name}
          </Text>
        ))}
      </Box>
    );
  }

  return (
    <TableContainer
      border={`1px solid ${theme.colors.gray[ 100 ]}`}
      rounded="2xl"
      width="100%"
      shadow="sm"
    >
      <Table>

        <Tbody>
          {recommendations.map(r => {
            const checked = selectedSlugs.find(s => s === r.recommendationSlug) ? true : false;

            { /* if it is a low priority table, we don't render anything but the name */ }

            return (
              <Tr key={r.recommendationSlug}>
                <Accordion
                  border={theme.colors.white}
                  allowMultiple
                >

                  <AccordionItem
                    borderBottom={`1px solid ${theme.colors.gray[ 100 ]}`}
                    overflow="hidden"
                  >
                    <AccordionButton>

                      <AccordionIcon />

                      <Grid
                        templateColumns={{
                          base: "repeat(5, 1fr)",
                          sm: "repeat(6, 1fr)",
                          md: `repeat(${isAuthenticated ? 8 : 7}, 1fr)`
                        }}
                        gap={8}
                        width="100%"
                        padding={theme.spacing[ 2 ]}

                      >
                        <GridItem
                          alignSelf="center"
                          textAlign="left"
                          w='100%'
                          colSpan={{
                            base: 4,
                            lg: 3
                          }}
                        >
                          <Box width="100%">
                            <Text
                              fontSize={{ base: theme.fontSizes.base }}
                              fontWeight="medium"
                              opacity="0.9"
                            >{r.recommendation.name}

                            </Text>
                          </Box>

                        </GridItem>

                        <GridItem
                          w='100%'
                          display={{
                            base: "none",
                            lg: "block"
                          }}
                        >
                          <Text
                            color={`${theme.colors.gray[ 800 ]}`}
                            fontSize={12}
                          >EPC Points
                          </Text>

                          <Text
                            fontSize="lg"
                            fontWeight="bold"
                            color="gray.500"
                          >{typeof r.epcChange === "number" ? r.epcChange > 0 ? <span style={{ color: "#0de291" }}>+{`${r.epcChange.toFixed(0)}`}</span> : r.epcChange.toFixed(0) : "N/A"}
                          </Text>
                        </GridItem>

                        <GridItem
                          display={{
                            base: "none",
                            md: "block"
                          }}
                          w='100%'
                          textAlign="right"
                        >
                          <Text
                            fontSize={12}
                            color={`${theme.colors.gray[ 800 ]}`}

                          >Save
                          </Text>

                          <Text><span style={{ }}>{typeof r.annualCO2Saving === "number" ? r.annualCO2Saving.toFixed(1) : "N/A"}</span> CO<span>&#8322;</span></Text>

                          <Text>{typeof r.annualCostSaving === "number" ? <span style={{ }}>{formatCurrency(Number(r.annualCostSaving.toFixed(0)))}</span> : "N/A"}/yr</Text>

                        </GridItem>

                        <GridItem
                          w='100%'
                          display={{
                            base: "none",
                            md: "block"
                          }}
                          textAlign="right"
                        >
                          <Text
                            fontSize={12}
                            color={`${theme.colors.gray[ 800 ]}`}

                          >One-off Cost
                          </Text>

                          <Text>{typeof r.totalCost === "number" ? formatCurrency(Number(r.totalCost.toFixed(0))) : "N/A"}</Text>
                        </GridItem>

                        <GridItem

                          display={{
                            base: "none",
                            sm: "block"
                          }}
                          textAlign="right"
                        >
                          <Text
                            fontSize={12}
                            color={`${theme.colors.gray[ 800 ]}`}

                          >Returns
                          </Text>

                          <Text>{typeof r.paybackYears === "string" && typeof r.annualPercentageReturn === "number" && r.annualPercentageReturn >= 0 ? `${(r.annualPercentageReturn * 100).toFixed(0)}% y/y` : "N/A"}</Text>

                          <Text>{typeof r.paybackYears === "string" && typeof r.annualPercentageReturn === "number" && r.annualPercentageReturn >= 0 ? `${r.paybackYears > 50 ? "> 50 years" : `${r.paybackYears} years`}` : "N/A"}</Text>

                        </GridItem>

                        {isAuthenticated && (
                          <GridItem

                            display={{
                              base: "none",
                              md: "block"
                            }}
                          >
                            <Text
                              fontSize={12}
                              color={`${theme.colors.gray[ 800 ]}`}
                            >Interested
                            </Text>

                            <Switch
                              onColor={theme.colors.green[ 100 ]}
                              offColor={theme.colors.gray[ 300 ]}
                              checked={checked}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              onChange={() => {
                                toggleSlug(r);
                              }}
                            />

                          </GridItem>
                        )}

                      </Grid>

                    </AccordionButton>

                    <AccordionPanel
                      style={{
                        whiteSpace: "normal",
                        wordWrap: "break-word"
                      }}
                    >
                      <Grid
                        padding={theme.spacing[ 2 ]}
                        templateColumns='repeat(8, 1fr)'
                        gap={4}
                        overflowWrap="break-word"
                        textOverflow="ellipsis"
                      >

                        <GridItem
                          colSpan={12}
                          style={{ textWrap: "wrap" }}
                          display={{
                            base: "block",
                            lg: "none"
                          }}
                        >

                          <Text
                            color={theme.colors.gray[ 600 ]}
                            fontSize={12}
                          >Save
                          </Text>

                          <Box
                            overflowWrap="break-word"
                            textOverflow="ellipsis"
                            display="flex"
                          >
                            <Box>
                              <Text> EPC change {typeof r.epcChange === "number" ? r.epcChange > 0 ? (
                                <span
                                  style={{
                                    fontWeight: 800,
                                    color: "#0de291"
                                  }}
                                >+{`${r.epcChange.toFixed(0)}`}
                                </span>
                              ) : r.epcChange.toFixed(0) : "N/A"}, {r.annualCO2Saving?.toFixed(1)} CO<span>&#8322;</span> reduction, and £{r.annualCostSaving?.toFixed(2)} in costs
                              </Text>
                            </Box>

                          </Box>

                        </GridItem>

                        <GridItem
                          colSpan={{
                            base: 12,
                            lg: 7
                          }}
                          style={{ textWrap: "wrap" }}
                        >

                          <Text
                            color={theme.colors.gray[ 600 ]}
                            fontSize={12}
                          >About
                          </Text>

                          <Box
                            display="flex"
                            overflowWrap="break-word"
                            textOverflow="ellipsis"
                          >
                            {r.recommendation.details}
                          </Box>

                        </GridItem>

                        <GridItem

                          justifyContent="center"
                        >
                          <Box>
                            <Text
                              whiteSpace="nowrap"
                              fontSize={12}
                              color={theme.colors.gray[ 600 ]}
                            >Level of Disruption
                            </Text>

                            <Text
                              fontSize="xl"
                              marginRight={1}
                              textColor={r.recommendation.disruption === 1 ? "blue.700" : r.recommendation.disruption === 2 ? "yellow" : "red"}
                            >{r.recommendation.disruption === 1 ? <MdOutlineSignalCellularAlt1Bar /> : r.recommendation.disruption === 2 ? <MdOutlineSignalCellularAlt2Bar /> : <MdOutlineSignalCellularAlt />}
                            </Text>

                          </Box>
                        </GridItem>

                        {r.scoreReasons && r.scoreReasons.filter(s => s.split(":")[ 1 ].includes("-") === false).length > 0 && (
                          <GridItem
                            colSpan={{
                              base: 12,
                              lg: 7
                            }}
                          >
                            <Box>
                              <Text
                                whiteSpace="nowrap"
                                fontSize={12}
                                color={theme.colors.gray[ 600 ]}
                              >
                                Other Positive Factors
                              </Text>

                              <Box display="flex">
                                {r.scoreReasons.filter(s => s.split(":")[ 1 ].includes("-") === false).map(text => (
                                  <Box
                                    key={text}
                                    borderColor="blue.100"
                                    borderWidth={1}
                                    textAlign="center"
                                    color="gray.800"
                                    padding={2}
                                    whiteSpace="nowrap"
                                    borderRadius="md"
                                    mr={2}
                                  >
                                    <Text
                                      width="full"
                                      marginRight={1}
                                    >
                                      {text.split(":")[ 0 ]}
                                    </Text>
                                  </Box>
                                ))}
                              </Box>

                            </Box>
                          </GridItem>
                        )}

                        {r.scoreReasons && r.scoreReasons.filter(s => s.split(":")[ 1 ].includes("-")).length > 0 && (
                          <GridItem
                            colSpan={{
                              base: 12,
                              lg: 7
                            }}
                          >
                            <Box>
                              <Text
                                whiteSpace="nowrap"
                                fontSize={12}
                                color={theme.colors.gray[ 600 ]}
                              >
                                Minor Negative Factors
                              </Text>

                              <Box display="flex">
                                {r.scoreReasons.filter(s => s.split(":")[ 1 ].includes("-")).map(text => (
                                  <Box
                                    key={text}
                                    borderWidth={1}
                                    borderColor="#edc598"
                                    textAlign="center"
                                    color="gray.800"
                                    padding={2}
                                    whiteSpace="nowrap"
                                    borderRadius="md"
                                    mr={2}
                                  >
                                    <Text
                                      width="full"
                                      marginRight={1}
                                    >
                                      {text.split(":")[ 0 ]}
                                    </Text>
                                  </Box>
                                ))}
                              </Box>

                            </Box>
                          </GridItem>
                        )}

                      </Grid>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Tr>
            );
          })}
        </Tbody>

      </Table>
    </TableContainer>

  );
};

const calculateROI = (cost: number, returnPerYear: number) => {
  const years = Math.ceil(cost / returnPerYear);
  const newData = [];
  let remainingCost = cost;

  for (let year = 1; year <= years; year++) {
    remainingCost -= returnPerYear;

    newData.push({
      year,
      return: year * returnPerYear,
      remainingCost: Math.max(0, remainingCost)
    });
  }

  return (newData);
};