import {
  Radio as ChakraRadio,
  VStack,
  RadioGroup,
  HStack,
  RadioProps
} from "@chakra-ui/react";
import {
  Control,
  Controller
} from "react-hook-form";
import { Text } from "src/components/text";
import styled from "styled-components";

import {
  accentColor,
  rowStyleProps,
  VStackStyleProps
} from ".";
import {
  Question,
  QuestionOption
} from "../../api/questions";
import theme from "../theme";
import { FormField } from "./FormField";
import { BoltSvg } from "./BoltSvg";

type UnstyledRadioProps = {
  question: Question;
  control: Control;
  setValue: (name: string, value: unknown, config?: Record<string, unknown>) => void;
};

const heatingQuestion = "What type of home heating system do you have?";

enum HeatingCategory {
  HeatingOil = "Heating oil",
  MainsGas = "Mains gas or LPG",
  HeatPump = "Heat pump",
  Other = "Other"
}

/**
 * Function for rendering radio options for the heating type question
 * @param question
 * @returns radio options for radio group
 */
const renderHeatingQuestionOptions = (question: Question) => {
  const heatingOil: QuestionOption[] = [];
  const mainsGas: QuestionOption[] = [];
  const heatPump: QuestionOption[] = [];
  const other: QuestionOption[] = [];

  question.questionOptions.map(option => {
    if (option.value.includes(HeatingCategory.HeatingOil)) {
      heatingOil.unshift(option);
    } else if (option.value.includes(HeatingCategory.MainsGas)) {
      mainsGas.unshift(option);
    } else if (option.value.includes(HeatingCategory.HeatPump)) {
      heatPump.push(option); // the order was reversed
    } else {
      other.unshift(option);
    }
  });

  const suppliedAnswer = question.answers?.[ 0 ];
  const isEPCSuggested = (option: QuestionOption) => [ option.label, option.value ].includes(suppliedAnswer?.value.value);

  return (
    <>
      <HeatingCategoryTitle>{HeatingCategory.MainsGas}</HeatingCategoryTitle>

      {mainsGas.map(option => (
        renderRadioOption(option, isEPCSuggested(option))
      ))}

      <HeatingCategoryTitle>{HeatingCategory.HeatingOil}</HeatingCategoryTitle>

      {heatingOil.map(option => (
        renderRadioOption(option, isEPCSuggested(option))
      ))}

      <HeatingCategoryTitle>{HeatingCategory.HeatPump}</HeatingCategoryTitle>

      {heatPump.map(option => (
        renderRadioOption(option, isEPCSuggested(option))
      ))}

      <HeatingCategoryTitle>{HeatingCategory.Other}</HeatingCategoryTitle>

      {other.map(option => (
        renderRadioOption(option, isEPCSuggested(option))
      ))}
    </>
  );
};

const renderRadioOption = (option: QuestionOption, isSuggested?: boolean) => (
  <HStack
    key={option.id}
    {...rowStyleProps}
    sx={{
      "--chakra-shadows-outline": `0 0 0 2px ${accentColor}`,
      "label": {
        "width": "100%",
        "cursor": "pointer",
        "fontSize": theme.fontSizes.md,
        "& + label": { margin: "0" },
        "input[type='radio'] + *": { mr: 2 },
        "&>span:last-child": { flex: 1 }
      }
    }}
  >
    <ChakraRadio
      value={option.label || option.value}
      {...radioStyleProps}
    >
      {option.value}

    </ChakraRadio>

    {isSuggested && (
      <BoltSvg />
    )}
  </HStack>
);

const UnstyledRadio: React.FC<UnstyledRadioProps> = ({
  question, control, setValue
}) => {
  const suppliedAnswer = question.answers?.[ 0 ];
  const isEPCSuggested = (option: QuestionOption) => [ option.label, option.value ].includes(suppliedAnswer?.value.value);

  return (
    <FormField question={question}>
      <Controller
        name={`${question.id}.${question.slug}`}
        defaultValue={suppliedAnswer?.value.value}
        control={control}
        render={({ field }) => (
          <RadioGroup
            {...field}
            defaultValue={suppliedAnswer?.value.value}
            onChange={nextValue => setValue(`${question.id.toString()}.${question.slug}`, nextValue, { shouldTouch: true })}
          >
            <VStack {...VStackStyleProps}>
              {question.question === heatingQuestion ? (
                renderHeatingQuestionOptions(question)
              ) : (
                question.questionOptions.map(option => (
                  renderRadioOption(option, isEPCSuggested(option))
                ))
              )}
            </VStack>
          </RadioGroup>
        )}
      />
    </FormField>
  );
};

const radioStyleProps: RadioProps = {
  bg: "transparent",
  _checked: {
    backgroundColor: "white",
    _after: {
      content: "\"\"",
      bg: accentColor,
      width: "50%",
      height: "50%",
      borderRadius: "50%"
    },
    borderColor: accentColor,
    borderWidth: "2px"
  }
};

const HeatingCategoryTitle = styled(Text)`
  color: ${theme.colors.gray[ 400 ]};
  padding-top: ${theme.spacing[ 4 ]};
  padding-bottom: ${theme.spacing[ 1 ]};
  text-transform: uppercase;
  font-weight: 600;
`;

export const Radio = UnstyledRadio;