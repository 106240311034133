import { useState } from "react";
import {
  Box,
  HStack,
  VStack
} from "@chakra-ui/react";
import {
  NavLink,
  Link,
  useHistory,
  useLocation
} from "react-router-dom";
import {
  ChevronUpIcon,
  ChevronDownIcon
} from "@chakra-ui/icons";

import { Text } from "../text";
import { theme } from "../theme";

interface MenuLinkProps {
  title: string;
  to: string;
  exact?: boolean;
  onClick?: () => void;
  onCloseMenu?: () => void;
}

interface MenuParentLinkProps {
  parentProps: MenuLinkProps;
  subItems: MenuLinkProps[];
}

/**
 * MenuNavLink: NavLink component for menu
 * with activeStyle
 */
export const MenuNavLink: React.FC<MenuLinkProps> = ({
  title,
  to,
  onClick,
  exact,
  onCloseMenu
}) => (
  <Text
    to={to}
    as={NavLink}
    exact={exact || true}
    color={theme.colors.gray[ 600 ]}
    _hover={{ color: theme.colors.black }}
    onClick={() => {
      onClick && onClick();
      onCloseMenu && onCloseMenu();
    }}
    activeStyle={{
      color: theme.colors.black,
      fontWeight: 600
    }}
  >
    {title}
  </Text>
);

/**
 * MenuLink: Link Component for menu
 */
export const MenuLink: React.FC<MenuLinkProps> = ({
  title,
  to,
  onClick,
  onCloseMenu
}) => (
  <Text
    to={to}
    as={Link}
    color={theme.colors.gray[ 600 ]}
    _hover={{ color: theme.colors.black }}
    onClick={() => {
      onClick && onClick();
      onCloseMenu && onCloseMenu();
    }}
  >
    {title}
  </Text>
);

/**
 * MenuParentNavLink: NavLink component for parent menu items with sub-items
 * Sub-items are MenuNavLink components
 */
export const MenuParentNavLink: React.FC<MenuParentLinkProps> = ({ parentProps, subItems }) => {
  const [ hover, setHover ] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const currentLocation = location.pathname.split("/")[ 1 ];
  const parentLocation = parentProps.to.split("/")[ 1 ];
  // A sub item will have the same location as their parent
  const [ showSubItems, setShowSubItems ] = useState(currentLocation === parentLocation);

  /**
   * Function for handling double clicking on parent menu links
   * On first click => show any children
   * On second click => navigate to the parent link route and close menu
   */
  const onClickHandler = () => {
    if (showSubItems) {
      history.push(parentProps.to);
      parentProps.onCloseMenu && parentProps.onCloseMenu();
    } else {
      setShowSubItems(true);
    }
  };

  return (
    <>
      <VStack align="left">
        <HStack
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          cursor="pointer"
        >
          <Box
            color={hover || (location.pathname === parentProps.to) ? theme.colors.black : theme.colors.gray[ 600 ]}
            onClick={onClickHandler}
          >
            {parentProps.title}
          </Box>

          {showSubItems ? (
            <ChevronUpIcon
              onClick={() => setShowSubItems(!showSubItems)}
              color={hover || (location.pathname === parentProps.to) ? theme.colors.black : theme.colors.gray[ 600 ]}
            />
          ) : (
            <ChevronDownIcon
              onClick={() => setShowSubItems(!showSubItems)}
              color={hover || (location.pathname === parentProps.to) ? theme.colors.black : theme.colors.gray[ 600 ]}
            />
          )}
        </HStack>

        {showSubItems && (
          <VStack
            align="left"
            paddingLeft={theme.spacing[ 4 ]}
            paddingBottom={theme.spacing[ 2 ]}
          >
            {subItems.map(subItem => (
              <MenuNavLink
                key={subItem.title}
                to={subItem.to}
                title={subItem.title}
                onCloseMenu={subItem.onCloseMenu}
              />
            ))}
          </VStack>
        )}

      </VStack>
    </>
  );
};
