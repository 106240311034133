import React, { useState } from "react";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import GenousLogo from "../../assets/images/Genous-logo.png";
import GenousLogoBlack from "../../assets/images/GenousLogoBlack.png";
import FooterLogos from "../../assets/images/Footer-logos.png";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

// Converts an image URL to a base64 data URI.
const convertImageToBase64 = url => {
  return new Promise((resolve, reject) => {
    const image = new Image();

    image.crossOrigin = "Anonymous"; // Use this if the image is served from a different domain

    image.onload = () => {
      const canvas = document.createElement("canvas");

      canvas.width = image.naturalWidth;
      canvas.height = image.naturalHeight;
      const ctx = canvas.getContext("2d");

      ctx.drawImage(image, 0, 0);

      try {
        const dataURI = canvas.toDataURL("image/png");

        resolve(dataURI);
      } catch (error) {
        reject(error);
      }
    };

    image.onerror = () => {
      reject(new Error("Could not load image at " + url));
    };
    image.src = url;
  });
};

// Component that handles PDF generation
const DrivewayDoc = () => {
  const [ loading, setLoading ] = useState(false);

  const generatePDF = async () => {
    setLoading(true);

    try {
      const headerImage = await convertImageToBase64(GenousLogo);
      const footerImage = await convertImageToBase64(FooterLogos);

      const docDefinition = {
        pageSize: "A4",
        pageMargins: [
          40,
          100,
          40,
          60
        ],

        header: [
          {
            text: "Decarbonise materially, effectively, easily.",
            alignment: "center",
            fontSize: 11,
            margin: [
              0,
              10,
              0,
              10
            ]
          },
          {
            image: headerImage,
            alignment: "center"
          }
        ],

        footer: [
          {
            image: footerImage,
            alignment: "center"
          }
        ],
        content: [
          {
            text: "DRIVEWAY",
            style: "mainHeading",
            alignment: "center"
          },
          {
            text: "A collaborative, joined up approach towards installation",
            alignment: "center",
            style: {
              italics: true,
              color: "#ADADAD"
            }
          },
          " ",
          {
            text: "Site Details",
            margin: [
              0,
              10,
              0,
              10
            ],
            style: {
              fontSize: 11,
              decoration: "underline"
            }

          },

          {
            table: {
              body: [
                [ "Address Line 1   ", "Flat 17, Copper Close, Blue Road, London" ],
                [ "Address Line 2   ", "Example" ],
                [ "Address Line 3   ", "Example" ],
                [ "Address Line 4   ", "Example" ],
                [ "Postcode   ", "KT5 8TB" ]

              ]
            }
          },

          {
            text: "The customer/ who's involved",
            margin: [
              0,
              10,
              0,
              10
            ],
            style: {
              fontSize: 11,
              decoration: "underline"
            }

          },

          {
            table: {
              body: [
                [
                  "Contact Name",
                  "Role",
                  "Contact Number",
                  "Email"
                ],
                [
                  "Rob Field",
                  "Owner",
                  "07680 545454",
                  "rob@genous.earth"
                ]

              ]
            }
          },

          {
            text: "Other notes on customer",
            margin: [
              0,
              10,
              0,
              10
            ],
            style: {
              fontSize: 11,
              decoration: "underline"
            }
          },

          {
            style: "tableExample",
            table: {
              widths: [ 500 ],
              heights: [ 120 ],
              body: [ [ "[EXAMPLE] Customer is extremely houseproud, beware of cream lounge carpet, shoe coverings to be worn at all times and please lay dustsheets / “Roll & Stroll” if operating indoors. \n\n [Example] Customer is a retired M & E engineer.\n\n [Example] Customer is a retired M & E engineer.\n\n [Example] Customer has an un-friendly Alsatian dog, prone to biting strangers. \n\n [Example] Blind elderly relative lives with homeowner." ] ]
            }
          },
          {
            text: "",
            pageBreak: "after"
          }, // Page break
          {
            text: "Project Details",
            margin: [
              0,
              10,
              0,
              10
            ],
            style: {
              fontSize: 11,
              decoration: "underline"
            }
          },
          {
            text: "Existing Equipment",
            margin: [
              0,
              10,
              0,
              10
            ],
            style: {
              fontSize: 11,
              decoration: "underline"
            }
          },

          {
            style: "tableExample",
            table: {
              body: [
                [
                  "Combi Boiler",
                  "Oil",
                  "Utility",
                  "1"
                ],
                [
                  "Controls",
                  "Standard\n RF Room\n Stat",
                  "Lounge",
                  "1"
                ],
                [
                  "Spare",
                  "Spare",
                  "Spare",
                  "Spare"
                ],
                [
                  " ",
                  " ",
                  " ",
                  " "
                ],
                [
                  "Mains Fuse (Size)",
                  "100A",
                  "Utility Room",
                  ""
                ],
                [
                  "Mains Power",
                  "Consumer Unit",
                  "Utility Room",
                  ""
                ],
                [
                  "Mains Power",
                  "MCB Mfctr",
                  "MK",
                  ""
                ],
                [
                  "Mains Power",
                  "",
                  "",
                  "1"
                ],
                [
                  "Water",
                  "Main Stop Cock",
                  "Under main electrical cupboard in utility",
                  ""
                ],
                [
                  " ",
                  " ",
                  " ",
                  " "
                ],

                [
                  "Is the Loft accessible (Y/N)",
                  "N/A",
                  "Is loft lit? (Y/N)",
                  "N/A"
                ],
                [
                  "Is the Loft boarded and ready to hold equipment being installed? (Y/N)",
                  "N/A",
                  "Spare",
                  "Spare"
                ]

              ]
            }
          },

          {
            text: "Equipment being installed",
            margin: [
              0,
              10,
              0,
              10
            ],
            style: {
              fontSize: 11,
              decoration: "underline"
            }
          },

          {
            style: "tableExample",
            table: {
              body: [
                [
                  "ASHP",
                  "Silver",
                  "Samsung HTQ R32 12kw",
                  "1"
                ],
                [
                  "Cylinder",
                  " ",
                  "Telford Tempest 300L",
                  "1"
                ],
                [
                  "SMART Controls",
                  " ",
                  "EPH Stat connected via Samsung MIM",
                  "1"
                ],
                [
                  "Spare",
                  " ",
                  "Spare",
                  "Spare"
                ],
                [
                  "Spare",
                  " ",
                  "Spare",
                  "Spare"
                ],
                [
                  " ",
                  " ",
                  " ",
                  " "
                ],
                [
                  " ",
                  " ",
                  " ",
                  " "
                ],
                [
                  " ",
                  " ",
                  "Powerflush",
                  "1"
                ],
                [
                  " ",
                  " ",
                  "Test and Commission",
                  "1"
                ],
                [
                  " ",
                  " ",
                  " ",
                  " "
                ],
                [
                  "ASHP",
                  "Silver",
                  "Samsung HTQ R32 12kw",
                  "1"
                ],
                [
                  "ASHP",
                  " ",
                  "Patio Outside Utility",
                  " "
                ],
                [
                  "Cylinder",
                  " ",
                  "In place of boiler coming out",
                  " "
                ]

              ]
            }
          },

          {
            text: "",
            pageBreak: "after"
          }, // Page break

          {
            text: "Major Equipment (Location) - Alternative Options",
            margin: [
              0,
              10,
              0,
              10
            ],
            style: {
              fontSize: 11,
              decoration: "underline"
            }
          },

          {
            style: "tableExample",
            table: {
              body: [
                [
                  "ASHP",
                  " ",
                  "N/A",
                  " "
                ],
                [
                  "ASHP Base Required? (Y/N)",
                  "No",
                  "Patio is Indian Stone Slab, level and Solid.",
                  ""
                ],
                [
                  " ",
                  " ",
                  " ",
                  " "
                ],
                [
                  "Unvented Cylinder",
                  " ",
                  "N/A",
                  " "
                ],
                [
                  "Cylinder Discharge",
                  " ",
                  "Outside Wall, No issue",
                  "Spare"
                ],
                [
                  "ASHP Drain",
                  " ",
                  "Into downpipe drain outside utility",
                  " "
                ]

              ]
            }
          },

          {
            text: "Other related technologies being installed",
            margin: [
              0,
              10,
              0,
              10
            ],
            style: {
              fontSize: 11,
              decoration: "underline"
            }
          },

          {
            style: "tableExample",
            table: {
              margin: [
                0,
                10,
                0,
                10
              ],
              widths: [
                100,
                100,
                100,
                100
              ],
              body: [
                [
                  " ",
                  " ",
                  " ",
                  " "
                ],
                [
                  " ",
                  " ",
                  " ",
                  " "
                ],
                [
                  " ",
                  " ",
                  " ",
                  " "
                ],
                [
                  " ",
                  " ",
                  " ",
                  " "
                ]

              ]
            }
          },
          "\n\n",

          {
            style: "tableExample",
            table: {
              widths: [
                "*",
                50,
                150,
                "*"
              ],
              body: [
                [
                  "Equipment disposal",
                  " ",
                  "Dispose off old boiler rads and Misc. waste",
                  "1"
                ],
                [
                  "Spare",
                  " ",
                  "Spare",
                  "Spare"
                ],
                [
                  "Spare",
                  " ",
                  "Spare",
                  "Spare"
                ]

              ]
            }
          },

          {
            text: "System Design Specification",
            margin: [
              0,
              10,
              0,
              10
            ],
            style: {
              fontSize: 11,
              decoration: "underline"
            }
          },

          {
            style: "tableExample",
            table: {
              margin: [
                0,
                10,
                0,
                10
              ],
              body: [
                [ "System Design Flow Temperature (°C)", "55 degrees" ],
                [ "At ambient design condition/temperature (°C)", "-5" ],
                [ "With room temperature", "21" ],
                [ "SCOP of unit at design temperature", "3.61" ]

              ]
            }
          },

          {
            text: "",
            pageBreak: "after"
          }, // Page break

          {
            text: "Radiator Schedule",
            margin: [
              0,
              10,
              0,
              10
            ],
            style: {
              fontSize: 11,
              decoration: "underline"
            }
          },

          {
            style: "tableExample",
            table: {
              margin: [
                0,
                10,
                0,
                10
              ],
              body: [
                [
                  {
                    text: "Are radiator changes required? (Y/n)",
                    colSpan: 3,
                    alignment: "center"
                  },
                  {},
                  {},
                  { text: "No" },
                  { text: "Are extra radiators required? (Y/n)" },
                  { text: "No" }

                ],
                [
                  " ",
                  " ",
                  " ",
                  " ",
                  " ",
                  " "
                ],
                [
                  " ",
                  " ",
                  " ",
                  " ",
                  " ",
                  " "
                ],
                [
                  "Room # 1",
                  "Current",
                  " ",
                  " ",
                  "Spare",
                  "Spare"
                ],
                [
                  "Room # 1",
                  "Current",
                  " ",
                  " ",
                  "Spare",
                  "Spare"
                ],
                [
                  "Room # 2",
                  "Current",
                  " ",
                  " ",
                  "Spare",
                  "Spare"
                ],
                [
                  "Room # 3",
                  "Current",
                  " ",
                  " ",
                  "Spare",
                  "Spare"
                ],
                [
                  "Room # 4",
                  "Current",
                  " ",
                  " ",
                  "Spare",
                  "Spare"
                ],
                [
                  "Room # 5",
                  "Current",
                  " ",
                  " ",
                  "Spare",
                  "Spare"
                ],
                [
                  "Room # 6",
                  "Current",
                  " ",
                  " ",
                  "Spare",
                  "Spare"
                ],
                [
                  "Room # 7",
                  "Current",
                  " ",
                  " ",
                  "Spare",
                  "Spare"
                ],
                [
                  "Room # 8",
                  "Current",
                  " ",
                  " ",
                  "Spare",
                  "Spare"
                ],
                [
                  "Room # 9",
                  "Current",
                  " ",
                  " ",
                  "Spare",
                  "Spare"
                ],
                [
                  "Room # 10",
                  "Current",
                  " ",
                  " ",
                  "Spare",
                  "Spare"
                ]

              ]
            }
          },

          {
            text: "Project Information (Other)",
            margin: [
              0,
              10,
              0,
              10
            ],
            style: {
              fontSize: 11,
              decoration: "underline"
            }
          },

          {
            style: "tableExample",
            table: {
              widths: [ 500 ],
              heights: [ 120 ],
              body: [ [ "Customer didn’t want any radiator upgrades and was running the oil boiler at a 55 degree flow temp and was happy with ambient.  Certain rooms are south facing and despite many being undersized as per the HLC, it was decided that no emitter changes were to be done at this stage.  Also, customer is installing a 12-20 panel solar array and battery later in 2024." ] ]
            }
          },

          {
            text: "",
            pageBreak: "after"
          }, // Page break
          {
            text: "Floor Plan",
            margin: [
              0,
              10,
              0,
              10
            ],
            style: {
              fontSize: 11,
              decoration: "underline"
            }
          },
          {
            text: "",
            pageBreak: "after"
          }, // Page break
          {
            text: "Pictures",
            margin: [
              0,
              10,
              0,
              10
            ],
            style: {
              fontSize: 11,
              decoration: "underline"
            }
          }

        ],
        styles: {
          h5: { fontSize: 11 },
          mainHeading: {
            fontSize: 22,
            bold: true,
            decoration: "underline",
            // marginTop: 10,
            marginBottom: 10
          },

          anotherStyle: {
            italics: true,
            alignment: "right"
          }
        }
      };

      pdfMake.createPdf(docDefinition).download("genous-driveway-doc.pdf");
    } catch (error) {
      console.error("Error creating PDF:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <button
      onClick={generatePDF}
      disabled={loading}
    >
      {loading ? "Loading..." : "Download PDF"}
    </button>
  );
};

export default DrivewayDoc;
