import {
  BoxProps,
  CircularProgressProps,
  useMergeRefs,
  CircularProgress,
  Box
} from "@chakra-ui/react";
import {
  useSpring,
  MotionValue
} from "framer-motion";
import {
  useRef,
  useState,
  useEffect
} from "react";
import { useInView } from "react-intersection-observer";

import theme from "../theme";

interface ProgressCircleProps extends BoxProps {
  thickness?: CircularProgressProps["thickness"];
  value: NonNullable<CircularProgressProps["value"]>;
  max: NonNullable<CircularProgressProps["max"]>;
  children?: Element | React.ReactNode;
  innerValue?: number;
}

const ProgressCircle: React.FC<Omit<BoxProps, keyof ProgressCircleProps> & ProgressCircleProps> = ({
  children, thickness = "10px", max, value, innerValue, ...props
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [ hasAnimated, setHasAnimated ] = useState(false);
  const animatedValue = useSpring(0, { duration: hasAnimated ? 0 : 500 }) as MotionValue<number>;
  const animatedInnerValue = useSpring(0, { duration: hasAnimated ? 0 : 500 }) as MotionValue<number>;
  const [ animatedState, set ] = useState(animatedValue.get());
  const [ animatedInnerState, setInner ] = useState(animatedValue.get());

  useEffect(() => {
    if (!hasAnimated && animatedValue.get() === value) {
      // hurry animations along if editing values after initial animations
      setHasAnimated(true);
    }
  }, [
    animatedState,
    animatedValue,
    hasAnimated,
    value
  ]);

  const { ref: inViewRef, entry } = useInView({
    threshold: [
      0.25,
      0.5,
      0.75
    ]
  });

  const { intersectionRatio } = entry ?? { intersectionRatio: 0 };
  const containerRef = useMergeRefs(ref, inViewRef);

  useEffect(() => {
    if (value && intersectionRatio > 0.75) {
      const unlisten = animatedValue.onChange(set);

      animatedValue.set(value);

      return () => unlisten();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ value, intersectionRatio ]);

  useEffect(() => {
    if (innerValue && intersectionRatio > 0.75) {
      const unlisten = animatedInnerValue.onChange(setInner);

      animatedInnerValue.set(innerValue);

      return () => unlisten();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ innerValue, intersectionRatio ]);

  const sharedCircleProps: CircularProgressProps = {
    trackColor: "none",
    width: "100%",
    size: "100%"
  };

  const BaseCircle = (
    <CircularProgress
      {...sharedCircleProps}
      value={1}
      max={1}
      color="black"
      thickness={thickness}
      position="absolute"
      transform="scale(0.925)"
      aria-hidden
    />
  );

  const ValueOuterCircle = (
    <CircularProgress
      {...sharedCircleProps}
      value={animatedState}
      max={max}
      thickness="8px"
      color={theme.colors.green[ "300" ]}
      opacity={0.8}
    />
  );

  const ValueInnerCircle = (
    <CircularProgress
      {...sharedCircleProps}
      value={animatedInnerState}
      max={max}
      thickness="8px"
      color={theme.colors.purple}
      opacity={0.8}
      transform="scale(0.8) rotateY(180deg)"
      position="absolute"
      left={0}
      top={0}
      bottom={0}
      right={0}
    />
  );

  return (
    <Box
      width="100%"
      display="grid"
      placeItems="center"
      position="relative"
      ref={containerRef}
      {...props}
    >
      {BaseCircle}

      {ValueOuterCircle}

      {!!innerValue && ValueInnerCircle}

      <Box
        // some vary rough approximations here but the actual thickness is more
        // than the value passed as props, so 3x * 2 (both sides of circle)
        maxW={`calc(${ref?.current?.firstElementChild?.getBoundingClientRect().height ?? 200}px - (6 * ${thickness}))`}
        position="absolute"
        top="50%"
        left="50%"
        transform="translateX(-50%) translateY(-50%)"
        textAlign="center"
      >
        {children}
      </Box>
    </Box>
  );
};

export default 	ProgressCircle;