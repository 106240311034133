import {
  Box,
  BoxProps,
  Image
} from "@chakra-ui/react";
import {
  desktopBreakpointKey,
  desktopBreakpointValue
} from "src/hooks/useIsDesktop";
import VersusAverage from "src/pages/footprint/sections/SavingsSection/VersusAverage";
import EmissionsComparison from "src/pages/footprint/sections/SavingsSection/EmissionsComparison";
import SavingsData from "src/pages/footprint/sections/SavingsSection/SavingsData";
import { FootprintScores } from "src/api/users/footprint";
import {
  motion,
  useViewportScroll,
  useTransform
} from "framer-motion";

import theme from "../../../../components/theme";
import { Wrapper } from "../../../../components/shared/Wrapper";

export type SavingsSectionProps = Partial<FootprintScores> & {
  isLoading?: boolean;
};

const AnimatedBox = motion<Omit<BoxProps, "transition">>(Box);
const NAV_HEIGHT = () => document.querySelector("nav")?.clientHeight ?? 0;

export const SavingsSection: React.FC<SavingsSectionProps> = props => {
  const { scrollY } = useViewportScroll();
  const initialViewportScrollY = useTransform(scrollY, [ NAV_HEIGHT(), window.innerHeight ], [ 10, -40 ]);

  return (
    <AnimatedBox
      background="white"
      borderRadius={20}
      position="relative"
      marginTop={-10}
      pb={{
        base: theme.spacing[ 8 ],
        [ desktopBreakpointKey ]: theme.spacing[ 12 ]
      }}
      overflow="hidden"
      style={{ translateY: window.innerWidth >= parseInt(desktopBreakpointValue, 10) ? initialViewportScrollY : undefined }}
    >
      <Wrapper
        position="relative"
        zIndex={1}
        variant="narrow"
        w={{ [ desktopBreakpointKey ]: "100%" }}
        px={{
          base: "1em",
          [ desktopBreakpointKey ]: theme.spacing[ 12 ]
        }}
        pt={`calc(${theme.spacing[ 4 ]} + ${arrowSize})`}
        color={theme.colors.black}
        textAlign="center"
        display="grid"
        gridTemplateRows={{ base: "repeat(auto, 1fr)" }}
        gridTemplateColumns={{ [ desktopBreakpointKey ]: "repeat(auto-fill, minmax(300px, 1fr))" }}
        gridGap={theme.spacing[ 4 ]}
        {...bottomArrow}
      >

        <SavingsData
          footprint={props.footprint}
          potentialSavings={props.potentialSavings}
          potentialMoneySavings={props.potentialMoneySavings}
          footprintComparisons={props.footprintComparisons}
          savingsComparisons={props.savingsComparisons}
          sapGrade={props.sapGrade}
          potentialSapGrade={props.potentialSapGrade}
          isLoading={props.isLoading}
        />

        <EmissionsComparison {...props.savingsComparisons} />

        <VersusAverage
          footprint={props.footprint}
          potentialSavings={props.potentialSavings}
          footprintComparisons={props.footprintComparisons}
        />
      </Wrapper>

      <Image
        src="https://genous-public-assets.s3.eu-west-2.amazonaws.com/public/background/background-gradient.png"
        aria-hidden
        position="absolute"
        inset="0"
        width='100%'
        height='100%'
        objectFit="cover"
        opacity={0.5}
      />
    </AnimatedBox>
  );
};

const arrowSize = "20px";

const bottomArrow: BoxProps = {
  position: "relative",
  _after: {
    content: "\"\"",
    pos: "absolute",
    width: "0px",
    height: "0px",
    top: "0px",
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: 1,
    borderWidth: arrowSize, // size of triangle
    borderStyle: "solid",
    borderTopColor: "black", // color of triangle
    borderLeftColor: "transparent",
    borderRightColor: "transparent",
    borderBottomColor: "transparent"
  }
  /* make space after the arrow */
  // sx: { "& + * > :first-child": { pt: `calc(${theme.spacing[ 10 ]} + ${arrowSize})` } }
};