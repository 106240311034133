import {
  Tag,
  Wrap
} from "@chakra-ui/react";
import {
  StoryblokComponent,
  SbBlokData,
  useStoryblok
} from "@storyblok/react";
import { useEffect } from "react";
import { isStage } from "src/helpers/stage";

import { Heading } from "../text";
import theme from "../theme";

export const Page: React.FC<{showTitle?: boolean, showTags?: boolean}> = ({ showTitle, showTags }) => {
  const path = window.location.pathname;
  const slug = path === "/" ? "home" : path;
  const story = useStoryblok(slug, { version: isStage("production") ? "published" : "draft" });
  const title = story?.content?.title;

  useEffect(() => {
    document.title = `${title ? `${title} - ` : ""}Genous`;
  }, [ title ]);

  if (!story?.content?.content) {
    return <></>;
  }

  return (
    <>
      {showTitle && <Heading mt={theme.spacing[ 10 ]}>{title}</Heading>}

      {showTags && story.tag_list && (
        <Wrap
          py={theme.spacing[ 3 ]}
        >
          {story.tag_list.map((tag, index) => (
            <Tag
              key={`tag_${index.toString()}`}
              textColor={theme.colors.gray[ 400 ]}
              fontWeight="normal"
              fontSize="xxs"
              size="sm"
            >
              {tag}
            </Tag>
          ))}
        </Wrap>
      )}

      {(story.content.content as SbBlokData[]).map(blok => (
        <StoryblokComponent
          blok={blok}
          key={blok._uid}
        />
      ))}
    </>
  );
};