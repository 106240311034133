import {
  Button,
  HStack
} from "@chakra-ui/react";
import {
  FieldValues,
  UseFormGetValues,
  UseFormRegister
} from "react-hook-form";
import { Question } from "src/api/questions";
import styled from "styled-components";

import { Text } from "../text";
import theme from "../theme";

interface UnstyledNumberInputProps {
  question: Question;
  register: UseFormRegister<FieldValues>;
  setValue: (name: string, value: unknown, config?: Record<string, unknown> | undefined) => void;
  getValues: UseFormGetValues<FieldValues>;
}

const NumberInputWrapper = styled.div`
  width: 100%;
  text-align: left;
`;

const NumberInputStepper = styled(HStack)`
  margin-top: 1rem;
  text-align: left;
  background-color: white;
  padding: ${theme.spacing[ 3 ]};
  border-radius: ${theme.radius.rounded_full};
  max-width: fit-content;
  width: 9rem;

  .stepper {
    border: 2px solid ${theme.colors.gray[ 600 ]};
    border-radius: ${theme.radius.rounded_full};
    text-align: center;
    width: 1rem;
  }
`;

const NumberInputElement = styled.input`
  display: block;
  margin-top: ${theme.spacing[ 4 ]};
  width: 2rem;
  text-align: center;
`;

export const NumberInput: React.FC<UnstyledNumberInputProps> = ({
  question, register, setValue, getValues
}) => {
  // the "how many people live in your home" question should default to 1 (one)
  const getIsOccupantsQuestion = (questionSlug: string | undefined): boolean => Boolean(questionSlug && questionSlug.includes("house_hold_size"));
  const minValue = getIsOccupantsQuestion(question.slug) ? 1 : 0;

  const decrementValue = () => {
    const currentValue = getValues(`${question.id}`);

    if (typeof currentValue === "undefined" || Number.parseInt(currentValue) <= minValue) {
      setValue(`${question.id}`, minValue);
    } else {
      setValue(`${question.id}`, Number.parseInt(currentValue) - 1);
    }
  };

  const incrementValue = () => {
    const currentValue = getValues(`${question.id}`);

    if (typeof currentValue === "undefined" || Number.parseInt(currentValue) < minValue) {
      setValue(`${question.id}`, minValue);
    } else {
      setValue(`${question.id}`, Number.parseInt(currentValue) + 1);
    }
  };

  const value = question.answers.length ? Number.parseInt(question.answers[ 0 ].value.value) : undefined;

  return (
    <NumberInputWrapper>
      <label>
        <Text fontWeight="medium">
          {question.question}
        </Text>

        <NumberInputStepper>
          <Button
            variant="outline"
            className="stepper"
            onClick={decrementValue}
            size="sm"
            data-testid="number-input-stepper_decrement"
          >
            -
          </Button>

          <NumberInputElement
            type="number"
            {...register(`${question.id}`)}
            defaultValue={getIsOccupantsQuestion(question.slug) ? value ?? 1 : 0}
          />

          <Button
            variant="outline"
            className="stepper"
            onClick={incrementValue}
            size="sm"
            data-testid="number-input-stepper_increment"
          >
            +
          </Button>
        </NumberInputStepper>
      </label>
    </NumberInputWrapper>
  );
};
