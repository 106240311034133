import React, { useCallback } from "react";
import {
  HStack,
  Box
} from "@chakra-ui/react";

import { Text } from "../../components/text";
import theme from "../../components/theme";
import { Wrapper } from "../../components/shared/Wrapper";

type QuestionSectionProgress = {
  stepNumber: number;
  totalStepsInSection: number;
};

type SectionProgressProps = { progress: QuestionSectionProgress };

export const SectionProgressFrame: React.FC<SectionProgressProps> = props => {
  const mapProgress = useCallback(() => new Array(props.progress.totalStepsInSection).fill(null).map((_, index) => renderStep(index < props.progress.stepNumber, index)), [ props.progress.stepNumber, props.progress.totalStepsInSection ]);

  return (
    <>
      <Box
        bg="black"
        w="100%"
      >
        <Wrapper
          variant="progress"
          py={theme.spacing[ 5 ]}
        >
          <HStack
            justifyContent="space-between"
            fontSize="smaller"
            textTransform="uppercase"
            fontWeight="bold"
            letterSpacing="1px"
            mb="1rem"
          >
            <Text
              fontSize={theme.fontSizes.xxs}
              color={theme.colors.gray[ 300 ]}
              letterSpacing="2px"
            >
              Home Check
            </Text>

          </HStack>

          <HStack>
            {mapProgress()}
          </HStack>
        </Wrapper>
      </Box>
    </>
  );
};

const renderStep = (active: boolean, index: number) => (
  <Box
    key={index}
    flex={1}
    height="2px"
    background={active ? theme.colors.green[ 100 ] : theme.colors.gray[ 700 ]}
  />
);