import { Spinner } from "@chakra-ui/react";
import {
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from "react";
import { useForm } from "react-hook-form";
import { Question } from "src/components/form";
import { CenterContent } from "src/components/shared/CenterContent";
import { useTypedSelector } from "src/redux/useTypeSelector";
import { QuestionType } from "src/api/questions";
import { useDispatch } from "react-redux";
import { useCurrentUserContext } from "src/hooks/useCurrentUserContext";
import {
  RecommendationGeneratorStatus,
  useQuestionsApi
} from "src/api/questions/questions.api";
import {
  updateSectionGroups,
  updateCurrentSectionGroup,
  updatePropertyId
} from "src/redux/actionCreators";
import { Button } from "src/components/button";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { DevTool } from "@hookform/devtools";
import ReactGA from "react-ga4";
import mixpanel from "mixpanel-browser";
import { snakeCase } from "snake-case";
import { useFootprint } from "src/hooks/useFootprint";
import { useAuthContext } from "src/hooks/useAuthContext";
import { isStage } from "src/helpers/stage";
import { LoadingPage } from "src/components/shared";

import {
  filterBasedOnDepends,
  formatAnswer,
  getAllQuestionNamesFromDepends,
  getAnswerValues,
  getIsValidPostcode
} from "src/helpers";

import { RenderSectionButtons } from "./SectionButtons";
import { SectionGroupDescription } from "./SectionGroupDescription";
import { ExistingAccount } from "./ExistingAccount";

interface Props {
  onComplete: () => void;
}

export const SectionGroupQuestions: React.FC<Props> = ({ onComplete }) => {
  const { authDataState } = useAuthContext();
  // Property ID from redux
  const { propertyId } = useTypedSelector(state => state.property);
  // Existing property ID associated with the user - used to determine if this is a first or re-submission
  const [ { propertyId: existingPropertyId } ] = useCurrentUserContext() ?? [ {} ];
  const { sectionGroups, currentSectionGroupIndex } = useTypedSelector(state => state.sectionGroups);
  const isEPCQuestion = useMemo(() => currentSectionGroupIndex ? sectionGroups[ currentSectionGroupIndex ].name === "EPC Check" : false, [ currentSectionGroupIndex, sectionGroups ]);
  // for validating the postcode on the full address form page
  const isAddressQuestion = useMemo(() => currentSectionGroupIndex ? sectionGroups[ currentSectionGroupIndex ].questions.find(q => q.type === "address_lookup") : false, [ currentSectionGroupIndex, sectionGroups ]);
  const [ submitAnswersResponse, submitAnswersRequest ] = useQuestionsApi("SUBMIT_ANSWER");
  const [ resubmitAnswersResponse, resubmitAnswersRequest ] = useQuestionsApi("UPDATE_ANSWER");
  const [ allAnswersSubmitted, setAllAnswersSubmitted ] = useState(false);
  const [ getRecommendationStatusResponse, getRecommendationStatusRequest ] = useQuestionsApi("GET_RECOMMENDATION_STATUS");
  const [ isNextButtonVisible, setNextButtonVisible ] = useState(true);
  const [ skipButtonVisible, setSkipButtonVisible ] = useState(false);
  const backVisible = useMemo(() => typeof currentSectionGroupIndex === "number" && currentSectionGroupIndex > 0, [ currentSectionGroupIndex ]);
  const dispatch = useDispatch();
  const [ , setFootprint ] = useFootprint();
  const [ trackedEventNames, setTrackedEventNames ] = useState<string[]>([]);

  const {
    register, control, handleSubmit, setValue, getValues, watch, reset, formState: { isDirty }
  } = useForm();

  useEffect(() => {
    reset();
  }, [ currentSectionGroupIndex, reset ]);

  useLayoutEffect(() => {
    window.scrollTo({ top: 0 });
  }, [ currentSectionGroupIndex ]);

  // Update the redux state when there's an answers response
  useEffect(() => {
    if (submitAnswersResponse && submitAnswersResponse.data) {
      // Update the array of section groups
      dispatch(updateSectionGroups(submitAnswersResponse.data.data.sectionGroups));
      // Update the index of the current section group
      dispatch(updateCurrentSectionGroup(submitAnswersResponse.data.data.currentSectionGroup));
      // Update the property ID
      dispatch(updatePropertyId(submitAnswersResponse.data.data.propertyId));

      // If there's also footprint data, put that in redux too, and answers are finished
      if (submitAnswersResponse.data.data.currentSectionGroup === null) {
        setAllAnswersSubmitted(true);
      }

      // Set next button to visible after completing submitting
      setNextButtonVisible(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ submitAnswersResponse ]);

  useEffect(() => {
    const generatorId = submitAnswersResponse.data?.data.generatorId || resubmitAnswersResponse.data?.data.generatorId;

    if (allAnswersSubmitted && generatorId) {
      const interval = setInterval(() => {
        getRecommendationStatusRequest({ id: generatorId });
      }, 2500);

      return () => {
        clearInterval(interval);
      };
    }
  }, [
    allAnswersSubmitted,
    submitAnswersResponse,
    resubmitAnswersResponse,
    getRecommendationStatusRequest
  ]);

  useEffect(() => {
    if (getRecommendationStatusResponse.data?.data.status === RecommendationGeneratorStatus.SUCCESS) {
      setFootprint({ footprintScores: getRecommendationStatusResponse.data?.data.footprintScores });
      onComplete();
    }
  }, [
    getRecommendationStatusResponse.data?.data,
    setFootprint,
    onComplete
  ]);

  // Update the redux state when there's an update answers response
  useEffect(() => {
    if (resubmitAnswersResponse && resubmitAnswersResponse.data) {
      // Update the array of section groups
      dispatch(updateSectionGroups(resubmitAnswersResponse.data.data.sectionGroups));
      // Update the index of the current section group
      dispatch(updateCurrentSectionGroup(resubmitAnswersResponse.data.data.currentSectionGroup));
      // Update the property ID
      dispatch(updatePropertyId(resubmitAnswersResponse.data.data.propertyId));

      // If there's also footprint data, put that in redux too, and answers are finished
      if (resubmitAnswersResponse.data.data.currentSectionGroup === null) {
        setAllAnswersSubmitted(true);
      }

      setNextButtonVisible(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ resubmitAnswersResponse ]);

  // avoids default form submit action and hijacks submit / ENTER keypress in
  // favor of progressing the address lookup step
  const clickFind = () => document.getElementById("EPC_find_button")?.click();

  if (allAnswersSubmitted) {
    return (
      <LoadingPage
        text="Creating your personalised recommendations"
        subtext="We're looking at dozens of options for your home"
      />
    );
  }

  if (!sectionGroups || sectionGroups.length < 1 || typeof currentSectionGroupIndex !== "number") {
    return (
      <>
      </>
    );
  }

  // Determines which questions depend on others
  // And uses that with react-hook-form's watch function
  // To update the form based on these dependencies
  const dependenciesToWatch = () => isAddressQuestion ? [ "address-postcode" ] : getAllQuestionNamesFromDepends(sectionGroups[ currentSectionGroupIndex ]);
  const validPostcode = isAddressQuestion && getIsValidPostcode(getValues()?.[ sectionGroups[ currentSectionGroupIndex ].id ]?.[ "address-postcode" ]);

  // Triggered when the "Next" button is clicked on any section group page
  // Sends the section group answers to the backend to store answers to questions
  // Then navigates to the section group handler which deals with getting the next unanswered section group
  const handleSectionGroupSubmit = (data: Record<string, string | number | Record<string, string>>) => {
    if (typeof currentSectionGroupIndex === "number") {
      // Look through each key of the data object
      const formattedData = Object.keys(data).map(key => {
        // Get the question that matches this key
        const question = sectionGroups[ currentSectionGroupIndex ].questions.find(q => q?.id === Number(key));
        const mappedAnswerValues = getAnswerValues(data[ key ]);

        if (question && question.type === QuestionType.Select) {
          // this question-type requires some fallback/default selection functionality
          const [ value, ...rest ] = mappedAnswerValues;
          // answer supplied by EPC or User
          const suppliedAnswer = question.answers?.[ 0 ];
          const firstOption = question.questionOptions?.[ 0 ];

          // value will sometimes default to `undefined`, so we choose most logical fallbacks
          // If value isn't undefined, then return it
          const output = value ? value :
          // Otherwise, if an answer is supplied from the EPC, use that
            suppliedAnswer ?
              suppliedAnswer.value.value :
              // Otherwise, just pick the first option
              firstOption.value;

          return {
            questionId: Number(key),
            answer: formatAnswer(question.type, [ output, ...rest ])
          };
        }

        return {
          questionId: Number(key),
          answer: formatAnswer(question?.type ?? QuestionType.Text, mappedAnswerValues)
        };
      });

      const priorities = localStorage.getItem("priorities-v1");
      const disruptionAttitude = localStorage.getItem("disruptionAttitude-v1");
      const budget = localStorage.getItem("budget-v1");

      const answersBody = {
        propertyId,
        data: formattedData,
        preferences: priorities ? JSON.parse(priorities) : undefined,
        disruptionAttitude: disruptionAttitude ? JSON.parse(disruptionAttitude).value : undefined,
        budget: budget ? JSON.parse(budget).value : undefined
      };

      // If the current user has a property ID, this is an update
      // Otherwise, this is a first submission
      existingPropertyId || authDataState.authenticated ?
        resubmitAnswersRequest(answersBody) :
        submitAnswersRequest(answersBody);
    }

    const sectionGroupName = sectionGroups[ currentSectionGroupIndex ].name;
    const eventName = `ir_${snakeCase(sectionGroupName)}`;

    if (!trackedEventNames.includes(eventName)) {
      if (isStage("production")) {
        ReactGA.event({
          category: "Impact Review",
          action: sectionGroupName,
          label: `User completed the ${sectionGroupName} section`,
          value: currentSectionGroupIndex
        });
      }

      mixpanel.track(eventName);
      setTrackedEventNames([ ...trackedEventNames, eventName ]);
    }
  };

  const handleBack = () => {
    dispatch(updateCurrentSectionGroup(currentSectionGroupIndex - 1));
  };

  return (
    <>
      {backVisible && (
        <Button
          leftIcon={<ArrowBackIcon />}
          background="none"
          color="black"
          alignSelf="start"
          mb="1rem"
          onClick={handleBack}
        >
          Back
        </Button>
      )}

      <form
        onSubmit={handleSubmit(isNextButtonVisible || isEPCQuestion ? handleSectionGroupSubmit : clickFind)}
        onInput={() => watch(dependenciesToWatch() ?? [])}
        onFocus={dependenciesToWatch() ? () => watch() : undefined}
      >
        <CenterContent
          flex="0"
          justifyContent="flex-start"
        >
          <SectionGroupDescription description={sectionGroups[ currentSectionGroupIndex ].description} />

          {sectionGroups[ currentSectionGroupIndex ].questions
            .filter(question => !question.dependsUpon || filterBasedOnDepends(getValues, sectionGroups[ currentSectionGroupIndex ], question))
            .sort((question1, question2) => question1.order - question2.order).map((question, index) => (
              <Question
                key={question.id}
                question={question}
                handleSubmit={handleSectionGroupSubmit}
                control={control}
                register={register}
                reset={reset}
                setValue={setValue}
                getValues={getValues}
                setNextButtonVisible={setNextButtonVisible}
                setSkipButtonVisible={setSkipButtonVisible}
                index={index}
              />
            ))}
        </CenterContent>

        {!!sectionGroups[ currentSectionGroupIndex ] && (
          <RenderSectionButtons>
            {({ NextButton, SkipButton }) => (
              <>
                {skipButtonVisible && (
                  <SkipButton
                    isDisabled={submitAnswersResponse.pending || resubmitAnswersResponse.pending || (isAddressQuestion && !validPostcode) || (isEPCQuestion && !isDirty)}
                    marginRight={{
                      base: "auto",
                      sm: 0
                    }}
                  >
                    {submitAnswersResponse.pending || resubmitAnswersResponse.pending ? <Spinner size="sm" /> : "Next"}
                  </SkipButton>
                )}

                {isNextButtonVisible && (
                  <NextButton
                    isDisabled={submitAnswersResponse.pending || resubmitAnswersResponse.pending || (isAddressQuestion && !validPostcode) || (isEPCQuestion && !isDirty)}
                    marginRight={{
                      base: "auto",
                      sm: 0
                    }}
                  >
                    {submitAnswersResponse.pending || resubmitAnswersResponse.pending ? <Spinner size="sm" /> : "Next"}
                  </NextButton>
                )}
              </>
            )}
          </RenderSectionButtons>
        )}

        <ExistingAccount />
      </form>

      {process.env.REACT_APP_HOOKFORM_DEVTOOLS === "true" && (
        <DevTool control={control} />
      )}
    </>
  );
};