import { Box } from "@chakra-ui/react";
import { RouteComponentProps } from "react-router";
import { AddToCalendarButton } from "add-to-calendar-button-react";

import { FullSpace } from "../components/shared/FullSpace";
import { Wrapper } from "../components/shared/Wrapper";
import theme from "../components/theme";

export const BSAEventRegister: React.FC<RouteComponentProps> = () => {
  return (
    <FullSpace>
      <Wrapper
        variant="text"
        py={{
          base: theme.spacing[ 8 ],
          md: theme.spacing[ 12 ]
        }}
        fontSize={theme.fontSizes.sm}
        sx={{
          "h1,h2,h3,h4,h5,h6": {
            my: "1em",
            fontWeight: "600",
            fontSize: "base"
          },
          "p,ol,ul,li": { my: "1em" },
          "ol, ol > li, h1, h2, h3": { mt: "2em" },
          "ol > ul": { pl: "2em" }
        }}
      >
        <Box
          display="flex"
          justifyContent="flex-end"
          mx={6}
          mb={2}
        >
          <AddToCalendarButton
            name="Genous: Improving Home Retrofits For Borrowers and Lenders"
            startDate="2024-09-13"
            startTime="09:15"
            endTime="10:45"
            options={[
              "Apple",
              "Google",
              "Microsoft365",
              "iCal",
              "Outlook.com"
            ]}
            timeZone="Europe/London"
            location="https://meet.google.com/hmr-irqg-dwe"
            description="URL: [url]https://meet.google.com/hmr-irqg-dwe[/url]"
          />
        </Box>

        <Box>
          <iframe
            width="100%"
            height="950px"
            src="https://docs.google.com/forms/d/e/1FAIpQLSfkm0Pdg6e6nNa__uUWyNQSU2I_5vuiPP2u7l_4icj77xK3eA/viewform?embedded=true"
          />
        </Box>

      </Wrapper>
    </FullSpace>
  );
};
