import { Text } from "src/components/text/Text";
import { theme } from "src/components/theme";

import {
  AvailabilityStatus,
  Recommendation,
  RecommendationStatus
} from "../api/users/footprint";
import { roundCostOrSaving } from "./number";

export const isCompleted = (subject: Recommendation): boolean => subject.status === "already_done";

export const isProblematic = (subject: Recommendation): boolean => subject.status === "might_not_be_possible";

export const isImpossible = (subject: Recommendation): boolean => subject.status === "impossible";

export const isActionable = (subject: Recommendation): boolean => subject.status === "possible" || subject.status === "might_not_be_possible";

export const isAvailableForInterest = (subject: Recommendation): boolean => [ RecommendationStatus.Possible, RecommendationStatus.MightNotBePossible ].includes(subject.status) && subject.recommendation.status !== AvailabilityStatus.OnHold;

export const getPotentialAnnualCostSavings = (recommendation: Recommendation): JSX.Element => (
  recommendation.annualCostSaving ? (

    <Text
      as="span"
      fontWeight="bold"
    >
      {`£${roundCostOrSaving(recommendation.annualCostSaving)} `}
    </Text>
  ) :
    (
      <Text as="span">
        -
      </Text>
    )

);

const recommendationNameOverrides: Record<string, string> = {
  "Install loft/room-in-roof insulation": "Install loft insulation",
  "Install heat pump (air-source)": "Install heat pump",
  "Generate your own renewable energy": "Install solar panels",
  "Move to 100% renewable electricity": "Switch to renewable energy"
};

export const formatRecommendationName = (recommendation: Recommendation): string => {
  const name = recommendation.altRecommendationName ?? recommendation.recommendation.name;

  return recommendationNameOverrides[ name ] ?? name;
};