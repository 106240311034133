import React, {
  useEffect,
  useState
} from "react";
import {
  RouteComponentProps,
  useHistory
} from "react-router";
import { VStack } from "@chakra-ui/react";
import {
  FieldValues,
  SubmitHandler,
  useForm
} from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import Auth from "@aws-amplify/auth";
import { flushSync } from "react-dom";
import { generateRandomPassword } from "src/helpers/password";

import { routes } from "src/constants";

import { useAuthContext } from "../../hooks/useAuthContext";
import { Button } from "../../components/button";
import { CenterContent } from "../../components/shared/CenterContent";
import { Text } from "../../components/text";
import {
  FieldError,
  StyledChakraTextInput as Input
} from "../../components/form";
import { FullSpace } from "../../components/shared/FullSpace";
import { LoadingPage } from "../../components/shared/LoadingPage";
import { Wrapper } from "../../components/shared/Wrapper";
import theme from "../../components/theme";

export type ConfirmNewPasswordPageProps = RouteComponentProps<
  undefined,
  Record<string, unknown>,
  { from: string, email: string }
>;

const schema = Joi.object({ code: Joi.string().required() });

export const RetrieveCodePage: React.FC<ConfirmNewPasswordPageProps> = ({ location }) => {
  const { authDataState, setAuthDataState } = useAuthContext();
  const history = useHistory();
  const [ _email, setEmail ] = useState<string | null>(null);
  const [ confirmPasswordResetError, setConfirmPasswordResetError ] = useState<string | null>(null);

  const {
    register, handleSubmit, formState: { errors, isDirty }
  } = useForm({ resolver: joiResolver(schema) });

  useEffect(() => {
    if (location.state.email) {
      setEmail(location.state.email);
    }
  }, [ location.state.email ]);

  const handleVerify: SubmitHandler<FieldValues> = async (data: Record<string, string>) => {
    try {
      if (location.state.mode === "reset") {
        history.push(routes.resetPassword, {
          email: location.state.email,
          code: data.code
        });
      } else {
        const password = generateRandomPassword();

        await Auth.forgotPasswordSubmit(location.state.email, data.code, password);
        const response = await Auth.signIn(location.state.email, password);

        flushSync(() => setAuthDataState({
          authenticated: true,
          error: null,
          authenticating: false,
          idToken: response.signInUserSession?.idToken?.jwtToken || "",
          isAdmin: false
        }));
        history.push(routes.footprint);
      }
    } catch (e) {
      setConfirmPasswordResetError("Invalid code or code has expired");
    }
  };

  return (
    <FullSpace
      bg={theme.colors.gray[ 100 ]}
      py={theme.spacing[ 8 ]}
    >
      <Wrapper variant="form">
        <CenterContent justifyContent="flex-start">
          {authDataState.authenticating ? (
            <LoadingPage />
          ) : (
            <VStack
              w="100%"
              spacing={theme.spacing[ 6 ]}
            >
              <Text
                textAlign="center"
                fontSize={theme.fontSizes.lg}
                fontWeight="semibold"
              >
                Please check your email
              </Text>

              <Text
                textAlign="center"
                fontSize="sm"
              >
                {` We have emailed you  ${location.state.mode === "reset" ? "a verification code" : "an access code"}. Please enter the code below to ${location.state.mode === "reset" ? "reset your password" : "retrieve your Home Check"}.`}
              </Text>

              <form
                onSubmit={handleSubmit(handleVerify)}
                style={{ width: "100%" }}
              >
                <VStack
                  spacing={theme.spacing[ 6 ]}
                  w="100%"
                >
                  {confirmPasswordResetError && (
                    <Text color="red">
                      {confirmPasswordResetError}
                    </Text>
                  )}

                  <Input
                    type="text"
                    placeholder="Code"
                    isInvalid={errors.code}
                    w="100%"
                    textAlign="center"
                    {...register("code")}
                  />

                  <FieldError
                    error={errors.code}
                    fieldName="Code"
                  />

                  <Button
                    type="submit"
                    disabled={!isDirty}
                  >
                    Submit
                  </Button>
                </VStack>
              </form>
            </VStack>
          )}
        </CenterContent>
      </Wrapper>
    </FullSpace>
  );
};

export default RetrieveCodePage;