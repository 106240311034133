import {
  HStack,
  Box,
  Button,
  Image
} from "@chakra-ui/react";
import React from "react";
import { desktopBreakpointKey } from "src/hooks/useIsDesktop";
import { Wrapper } from "src/components/shared";
import { Text } from "src/components/text";
import theme from "src/components/theme";
import screenshots from "src/assets/images/third-party/demo.png";

export const BestInClassSection: React.FC = () => (
  <Wrapper
    py={{ [ desktopBreakpointKey ]: theme.spacing[ 24 ] }}
  >
    <HStack

      alignItems="flex-start"
      spacing={{
        base: 0,
        [ desktopBreakpointKey ]: theme.spacing[ 10 ]
      }}
      color="black"
      width="85vw"
      flexWrap={{
        base: "wrap",
        [ desktopBreakpointKey ]: "nowrap"
      }}
    >
      <Box
        width={{
          base: "100%",
          xl: "50%"
        }}
      >
        <Text
          fontSize="xl"
          flexBasis={{
            base: "100%",
            [ desktopBreakpointKey ]: "40%"
          }}
          fontWeight="medium"
          lineHeight="1.2"
          letterSpacing="-0.01em"
          pt={{
            base: "10%",
            [ desktopBreakpointKey ]: "5%"
          }}
          pr="10%"
          mb={{
            base: theme.spacing[ 8 ],
            [ desktopBreakpointKey ]: 0
          }}
        >
          Cut Carbon, Cut Costs
        </Text>

        <Text
          fontSize="md"
          flexBasis={{
            base: "100%",
            [ desktopBreakpointKey ]: "60%"
          }}
          pt={{
            base: 0,
            [ desktopBreakpointKey ]: "5%"
          }}
          pb={{
            base: "10%",
            [ desktopBreakpointKey ]: "5%"
          }}
          color={theme.colors.gray[ 800 ]}
          lineHeight="1.5"
          letterSpacing="-0.01em"
        >
          Use Genous to discover ways to reduce your carbon emissions by up to a third, save money and help future generations.<br />

          <br />
          Our decarbonisation concierge service will provide tailormade recommendations specifically for you and your lifestyle. And we have a network of trusted partners who can do the installation work for you.

        </Text>
      </Box>

      <Box
        display={{
          base: "none",
          xl: "block"
        }}
        position="relative"
        right={-50}
        width="90%"
        height="100%"
      >
        <Image
          position="relative"
          width="95%"
          src={screenshots}
        />

      </Box>
    </HStack>
  </Wrapper>
);