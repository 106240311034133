import React from "react";
import {
  Text,
  Box,
  HStack,
  VStack,
  Button
} from "@chakra-ui/react";
import theme from "src/components/theme";
import { Recommendation } from "src/api/users/footprint";

interface Props {
    selected: Recommendation;
    required: Recommendation;
    onSelect(required: Recommendation): void;
    onCancel(): void;
}

const RequiredCombination: React.FC<Props> = ({
  selected,
  required,
  onSelect,
  onCancel
}) => (
  <VStack
    spacing={6}
    alignItems="flex-start"
  >
    <Box>
      <Text fontSize="lg">Required Combination</Text>

      <Text>{`${selected.recommendation.name} requires ${required.recommendation.name}`} to work.</Text>
    </Box>

    <HStack
      justifyContent="space-between"
      alignItems="flex-start"
      width="full"
    >

      <HStack
        width="full"
        justifyContent='flex-end'
        spacing={4}
      >

        <HStack>
          <Button
            minWidth={200}
            color={theme.colors.blue[ 500 ]}
            size="sm"
            onClick={() => onCancel()}
          >Cancel
          </Button>

          <Button
            minWidth={200}
            color={theme.colors.blue[ 500 ]}
            size="sm"
            onClick={() => onSelect(required)}
          >Add {required.recommendation.name}
          </Button>
        </HStack>
      </HStack>
    </HStack>
  </VStack>
);

export default RequiredCombination;