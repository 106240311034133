import { UserOffsetPortfolioAttributes } from "../offsets";

export enum RecommendationGeneratorStatus {
  CALCULATING = "CALCULATING",
  ERROR = "ERROR",
  SUCCESS = "SUCCESS"
}

export interface PropertyFootprint {
  recommendationsStatus: {
    id: number;
    status: RecommendationGeneratorStatus;
  },
  recommendationsImpossible: number;
  calculations: Calculation[];
  recommendations: Recommendation[];
  comingSoon: Recommendation[];
  footprintScores: FootprintScores;
  offsetSubscription: UserOffsetPortfolioAttributes | null;
}

export interface FootprintScores {
  footprint: number;
  potentialSavings: number;
  sapGrade: string;
  potentialSapGrade: string;
  potentialMoneySavings: number;
  savingsComparisons?: {
      parisFlights: number;
      newYorkFlights: number;
      hamburgers: number;
      coffeeCups: number;
      plasticBags: number;
      percentUKEmissionsSaved: number;
  },
  footprintComparisons?: {
      percentAverageUKFootprint: number;
      percentAverageGlobalFootprint: number;
  }
}

export enum AvailabilityStatus {
  Available = "available",
  ComingSoon = "coming_soon",
  OnHold = "on_hold"
}
export interface Calculation {
  id: number;
  calculationSlug: string;
  value: number;
}

export interface Recommendation {
  id: number;
  // `(string & {})` allows for autocomplete to work on RecommendationSlug, but
  // also allows any other string!
  // eslint-disable-next-line @typescript-eslint/ban-types
  recommendationSlug: RecommendationSlug | (string & {});
  annualCO2Saving: number | null;
  annualCostSaving: number | null;
  epcChange: number | null;
  impossibleCombinations: string[];
  requiredCombinations: string[];
  annualPercentageReturn: number | null;
  conditionalNote: string | null;
  totalCost: number;
  score: number;
  scoreReasons: string[];
  interested: boolean;
  status: RecommendationStatus;
  statusReason?: string;
  recommendation: RecommendationDetails;
  paybackYears: number | null;
  altRecommendationName?: string | null;
  priority: "HIGH" | "MED" | "LOW";
}

interface RecommendationDetails {
  id: number;
  name: string;
  category: string;
  details: string;
  slug: RecommendationSlug;
  disruption: number;
  status: AvailabilityStatus;
}

export enum RecommendationStatus {
  Possible = "possible",
  Impossible = "impossible",
  MightNotBePossible = "might_not_be_possible",
  AlreadyDone = "already_done",
  CommunicationPending = "communication_pending"
}

export enum RecommendationSlugs {
  CAVITY_INSULATION = "cavity_insulation_recommendation",
  CHANGE_ENERGY_SUPPLIER = "change_energy_supplier_recommendation",
  FLOOR_INSULATION = "floor_insulation_recommendation",
  LOFT_INSULATION = "loft_insulation_recommendation",
  USE_ELECTRIC_VEHICLE = "use_electric_vehicle_recommendation",
  USE_RENEWABLE_HEATING = "use_renewable_heating_recommendation",
  INSTALL_MICROGENERATION = "install_microgeneration_recommendation",
  FOOD_AND_DRINK = "food_and_drink_recommendation",
  FLIGHTS_AND_HOLIDAYS = "flights_and_holidays_recommendation",
  RETAIL = "retail_recommendation",
  FINANCIAL_SERVICES = "financial_services_recommendation",
  COMMUNITY_ENGAGEMENT = "community_engagement_recommendation",
  WHOLE_HOUSE_SURVEY = "whole_house_survey_recommendation"
}

export type RecommendationSlug =
  "cavity_insulation_recommendation" |
  "loft_insulation_recommendation" |
  "floor_insulation_recommendation" |
  "change_energy_supplier_recommendation" |
  "use_electric_vehicle_recommendation" |
  "use_renewable_heating_recommendation" |
  "install_microgeneration_recommendation" |
  "food_and_drink_recommendation" |
  "flights_and_holidays_recommendation" |
  "retail_recommendation" |
  "financial_services_recommendation" |
  "community_engagement_recommendation" |
  "whole_house_survey_recommendation";
