import {
  Box,
  Center
} from "@chakra-ui/react";

import { Heading } from "../text";

type UnstyledHeaderBlockProps = {
  height?: string;
  textSize?: string;
  centre?: boolean;
};

const UnstyledHeaderBlock: React.FC<UnstyledHeaderBlockProps> = ({
  height,
  textSize,
  centre,
  children
}) => (
  centre ? (
    <Center
      w="100%"
      h={height || "3rem"}
      bg="gray.200"
      alignItems="center"
      padding="1rem"
    >
      <Heading
        size={textSize || "md"}
      >
        {children}
      </Heading>
    </Center>
  ) : (
    <Box
      w="100%"
      h={height || "3rem"}
      bg="gray.200"
      alignItems="center"
      padding="1rem"
    >
      <Heading
        size={textSize || "md"}
      >
        {children}
      </Heading>
    </Box>
  )
);

export const HeaderBlock = UnstyledHeaderBlock;