import {
  VStack,
  Stack,
  Box,
  Progress,
  ProgressProps,
  BoxProps
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { Wrapper } from "src/components/shared";
import { Text } from "src/components/text";
import theme from "src/components/theme";
import { breakpoints } from "src/components/theme/breakpoints";
import { roundCostOrSaving } from "src/helpers/number";
import { desktopBreakpointKey } from "src/hooks/useIsDesktop";
import { SavingsSectionProps } from "src/pages/footprint/sections/SavingsSection";

const VersusAverage = ({
  footprint = 0,
  potentialSavings = 0,
  footprintComparisons = {
    percentAverageGlobalFootprint: 0,
    percentAverageUKFootprint: 0
  }
}: Pick<SavingsSectionProps, "footprint" | "potentialSavings" | "footprintComparisons">): JSX.Element => {
  return (
    <VStack
      bg="white"
      borderRadius={theme.radius.lg}
      padding={[ "2rem " ]}
      gridColumn="1 / -1"
    >
      <Wrapper
        as={Stack}
        flexDirection={{
          base: "column",
          [ desktopBreakpointKey ]: "row"
        }}
        alignItems="center"
        justifyContent="space-between"
        maxW={{ [ desktopBreakpointKey ]: breakpoints.md }}
        spacing={{
          base: theme.spacing[ 10 ],
          [ desktopBreakpointKey ]: 0
        }}
      >
        <VStack>
          <Stack
            flexDirection={{ [ desktopBreakpointKey ]: "row" }}
            spacing={0}
            alignItems="center"
          >
            <Text
              fontSize={{
                base: theme.fontSizes.xs,
                sm: theme.fontSizes.sm
              }}
              w={[ "10ch", "15ch" ]}
              textAlign="right"
              marginInlineEnd="2ch"
            >
              Household individuals
            </Text>

            <ProgressBar
              color={!footprint ? "transparent" : "black"}
              isIndeterminate={!footprint}
              value={footprint}
              max={footprint}
              savings={!footprint ? undefined : potentialSavings}
            />
          </Stack>

          <Stack
            flexDirection={{ [ desktopBreakpointKey ]: "row" }}
            spacing={0}
            alignItems="center"
          >
            <Text
              fontSize={{
                base: theme.fontSizes.xs,
                sm: theme.fontSizes.sm
              }}
              w={[ "10ch", "15ch" ]}
              textAlign="right"
              marginInlineEnd="2ch"
            >
              UK average
            </Text>

            <ProgressBar
              color={!footprint ? "transparent" : theme.colors.gray[ 400 ]}
              isIndeterminate={!footprint}
              value={(footprint / footprintComparisons.percentAverageUKFootprint) * 100}
              max={footprint}
            />
          </Stack>

          <Stack
            flexDirection={{ [ desktopBreakpointKey ]: "row" }}
            spacing={0}
            alignItems="center"
          >
            <Text
              fontSize={{
                base: theme.fontSizes.xs,
                sm: theme.fontSizes.sm
              }}
              w={[ "10ch", "15ch" ]}
              textAlign="right"
              marginInlineEnd="2ch"
            >
              Global average
            </Text>

            <ProgressBar
              color={!footprint ? "transparent" : theme.colors.gray[ "200" ]}
              isIndeterminate={!footprint}
              value={(footprint / footprintComparisons.percentAverageGlobalFootprint) * 100}
              max={footprint}
            />
          </Stack>
        </VStack>

        <Text
          fontSize="sm"
          textAlign={{ [ desktopBreakpointKey ]: "left" }}
          maxWidth={{ [ desktopBreakpointKey ]: "38ch" }}
        >
          Today, each member of your household has a carbon impact <b>{roundCostOrSaving(footprintComparisons.percentAverageUKFootprint / 100)}</b> times the UK average and

          {" "}<b>{roundCostOrSaving(footprintComparisons.percentAverageGlobalFootprint / 100)}</b>{" "}
          times the global average – before accounting for lifestyle factors like food and travel.
        </Text>
      </Wrapper>
    </VStack>
  );
};

const AnimatedBox = motion<Omit<BoxProps, "transition">>(Box);

const ProgressBar = (p: ProgressProps & {savings?: number}) => (
  <AnimatedBox
    pos='relative'
    transformOrigin="left"
    initial={{ scaleX: 0 }}
    whileInView={{ scaleX: 1 }}
    transition={{
      type: "spring",
      mass: 0.5,
      stiffness: 60
    }}
  >
    <Progress
      {...p}
      size="lg"
      height="2em"
      minW={[ "15ch", "20ch" ]}
      bgColor="transparent"
      colorScheme="blackAlpha"
      sx={{ "[role=\"progressbar\"]": { backgroundColor: p.color } }}
    />

    {p.savings && (
      <Progress
        {...p}
        value={p.savings}
        size="lg"
        height="2em"
        minW={[ "15ch", "20ch" ]}
        bgColor="transparent"
        sx={{ "[role=\"progressbar\"]": { backgroundColor: theme.colors.green[ 300 ] } }}
        pos="absolute"
        right="0"
        bottom="0"
        transform="translateY(8px) rotateY(180deg) scaleY(0.8)"
        opacity={0.8}
      />
    )}
  </AnimatedBox>
);

export default VersusAverage;