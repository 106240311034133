import { QuestionAnswer } from "./answer";

export interface Section {
  id: number;
  name: string;
  questionSectionGroups?: QuestionSectionGroup[];
}

export interface QuestionSectionGroupResponse {
  sectionGroups: QuestionSectionGroup[];
  currentSectionGroup: number | null;
  generatorId: number | null;
}

export interface QuestionSectionGroup {
  id: number;
  name: string;
  description: string;
  order: number;
  questions: Question[];
  type: QuestionType;
}

export interface Question {
  id: number;
  slug: string;
  question: string;
  helpText: string;
  defaultValue: string | number;
  order: number;
  type: QuestionType;
  questionOptions: QuestionOption[];
  answers: QuestionAnswer[],
  dependsUpon: number | null,
  dependsUponValue: {values: string[]} | null,
  required?: true;
}

export enum QuestionType {
  Text = "text",
  Password = "password",
  Number = "number",
  Select = "select",
  Checkbox = "checkbox",
  Radio = "radio",
  AddressLookup = "address_lookup",
  EPCLookup = "epc_lookup",
  PINLocation = "map_pin",
  FormGroup= "FormGroup",
  PropertySize = "property_size"
}

export interface QuestionOption {
  id: number;
  value: string;
  label: string;
  helpText: string | null;
}