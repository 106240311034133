import React from "react";
import {
  Flex,
  Heading,
  Text,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer
} from "@chakra-ui/react";

import "./pv-performance.css";
import PostRetrofitSelfUseGenous from "./charts/PostRetrofitSelfUseGenous";
import MonthlyGenDemandGenous from "./charts/MonthlyGenDemandGenous";

const PVPerformanceDocs = () => {
  return (
    <Flex
      direction="column"
      gap={10}
      justifyContent="center"
      width='90%'
      margin='30px auto'
    >
      <Heading
        textAlign="center"
        color='teal'
      >PV Performance estimate - MCS and Genous Methodologies
      </Heading>

      {/* Installation Data */}
      <Heading
        className='table-header'
        as='h4'
        size='sm'
        padding={4}
        bgColor='teal'
      >A. Installation Data
      </Heading>

      <TableContainer>
        <Table
          variant='striped'
          colorScheme='teal'
        >

          <Thead>
            <Tr>
              <Th />

              <Th>Array 1</Th>

              <Th>Array 2</Th>

              <Th>Array 3</Th>

              <Th>Total</Th>

              <Th>Units</Th>

              <Th>Notes</Th>
            </Tr>
          </Thead>

          <Tbody>
            <Tr>
              <Td>Number of panels</Td>

              <Td>Value Here</Td>

              <Td>Value Here</Td>

              <Td>Value Here</Td>

              <Td>Value Here</Td>

              <Td>Value Here</Td>

              <Td>Value Here</Td>

            </Tr>

            <Tr>
              <Td>Installed capacity of system</Td>

              <Td>Value Here</Td>

              <Td>Value Here</Td>

              <Td>Value Here</Td>

              <Td>Value Here</Td>

              <Td>Value Here</Td>

              <Td>Value Here</Td>

            </Tr>

            <Tr>
              <Td>Orientation of the system - degrees from South</Td>

              <Td>Value Here</Td>

              <Td>Value Here</Td>

              <Td>Value Here</Td>

              <Td>Value Here</Td>

              <Td>Degrees</Td>

              <Td>Negative = East, Positive = West</Td>

            </Tr>

            <Tr>
              <Td>Inclination of the system - degrees from horizontal</Td>

              <Td>Value Here</Td>

              <Td>Value Here</Td>

              <Td>Value Here</Td>

              <Td>Value Here</Td>

              <Td>Value Here</Td>

              <Td>Value Here</Td>

            </Tr>

            <Tr>
              <Td>Postcode region</Td>

              <Td colSpan={6}>Value Here</Td>

            </Tr>

          </Tbody>

        </Table>
      </TableContainer>

      {/* performance calcs */}

      <Heading
        className='table-header'
        as='h4'
        size='sm'
        padding={4}
        bgColor='teal'
      >B. Performance Calculations
      </Heading>

      <TableContainer>
        <Table
          variant='striped'
          colorScheme='teal'
        >

          <Thead>
            <Tr>
              <Th />

              <Th>Array 1</Th>

              <Th>Array 2</Th>

              <Th>Array 3</Th>

              <Th>Total</Th>

              <Th>Units</Th>

              <Th>Notes</Th>
            </Tr>
          </Thead>

          <Tbody>
            <Tr>
              <Td>kWh/kWp per year</Td>

              <Td>Value Here</Td>

              <Td>Value Here</Td>

              <Td>Value Here</Td>

              <Td>N/A</Td>

              <Td>kWh/kWp</Td>

              <Td>From MCS table</Td>

            </Tr>

            <Tr>
              <Td>Shade Factor (SF)</Td>

              <Td>Value Here</Td>

              <Td>Value Here</Td>

              <Td>Value Here</Td>

              <Td>N/A</Td>

              <Td />

              <Td> 1 = no shading; 0 = completely shaded</Td>

            </Tr>

            <Tr>
              <Td fontWeight="bold">Estimated Annual Output</Td>

              <Td fontWeight="bold">Value Here</Td>

              <Td fontWeight="bold">Value Here</Td>

              <Td fontWeight="bold">Value Here</Td>

              <Td fontWeight="bold">Value Here</Td>

              <Td />

              <Td />

            </Tr>

          </Tbody>

        </Table>
      </TableContainer>

      {/* Self consumption w/o battery */}
      <Heading
        className='table-header'
        as='h4'
        size='sm'
        bgColor='teal'
        padding={4}

      >C. Estimated self consumption - without battery storage
      </Heading>

      <TableContainer>
        <Table
          variant='striped'
          colorScheme='teal'
        >

          <Thead>
            <Tr>
              <Th />

              <Th
                colSpan={2}
                textAlign="center"
              >Today
              </Th>

              <Th
                colSpan={2}
                textAlign="center"
              >Post-retrofit
              </Th>

              <Th>Units</Th>

              <Th>Notes</Th>
            </Tr>

            <Tr>
              <Th />

              <Th>MCS</Th>

              <Th>Genous</Th>

              <Th>MCS</Th>

              <Th>Genous</Th>

              <Th />

              <Th />
            </Tr>
          </Thead>

          <Tbody>
            <Tr>
              <Td>Assumed Occupancy Archetype</Td>

              <Td>Value Here</Td>

              <Td>Value Here</Td>

              <Td>Value Here</Td>

              <Td>Value Here</Td>

              <Td />

              <Td>Genous score between 0 (out all day) and 1 (in all day)</Td>

            </Tr>

            <Tr>
              <Td>
                <Td>Annual Electricity Consumption</Td>

                <Td>
                  <Tr>Heating</Tr>

                  <Tr>Electric Vehicle</Tr>

                  <Tr>Hot water power diverter</Tr>

                  <Tr>Other</Tr>

                  <Tr fontWeight="bold">Total</Tr>
                </Td>
              </Td>

              <Td>
                <Tr>N/A</Tr>

                <Tr>N/A</Tr>

                <Tr>N/A</Tr>

                <Tr>N/A</Tr>

                <Tr fontWeight="bold">N/A</Tr>
              </Td>

              <Td>
                <Tr>N/A</Tr>

                <Tr>N/A</Tr>

                <Tr>N/A</Tr>

                <Tr>N/A</Tr>

                <Tr fontWeight="bold">N/A</Tr>
              </Td>

              <Td>
                <Tr>N/A</Tr>

                <Tr>N/A</Tr>

                <Tr>N/A</Tr>

                <Tr>N/A</Tr>

                <Tr fontWeight="bold">N/A</Tr>
              </Td>

              <Td>
                <Tr>N/A</Tr>

                <Tr>N/A</Tr>

                <Tr>N/A</Tr>

                <Tr>N/A</Tr>

                <Tr fontWeight="bold">N/A</Tr>
              </Td>

              <Td>KwH</Td>

              <Td />

            </Tr>

            <Tr>

              <Td>MCS Valid</Td>

              <Td
                colSpan={2}
                textAlign='center'
              >Value Here
              </Td>

              <Td
                colSpan={2}
                textAlign='center'
              >Value Here
              </Td>

              <Td />

              <Td>MCS valid for &lt; 6,000kWh usage and generation, &lt; 15kWh storage</Td>

            </Tr>

            <Tr>

              <Td>Assumed annual elecricity generation from Solar PV System</Td>

              <Td>Value Here
              </Td>

              <Td>Value Here
              </Td>

              <Td>Value Here
              </Td>

              <Td>Value Here
              </Td>

              <Td>kWh</Td>

              <Td />

            </Tr>

            <Tr>
              <Td>
                Expected Solar PV Self-consumption (PV Only)

              </Td>

              <Td>Value Here
              </Td>

              <Td>Value Here
              </Td>

              <Td>Value Here
              </Td>

              <Td>Value Here
              </Td>

              <Td>kWh</Td>

              <Td />

            </Tr>

            <Tr>
              <Td>
                Expected Solar PV export (PV Only)

              </Td>

              <Td>Value Here
              </Td>

              <Td>Value Here
              </Td>

              <Td>Value Here
              </Td>

              <Td>Value Here
              </Td>

              <Td>%</Td>

              <Td />

            </Tr>

            <Tr>
              <Td>
                Grid electricity independence / self-sufficiency (PV Only)

              </Td>

              <Td>Value Here
              </Td>

              <Td>Value Here
              </Td>

              <Td>Value Here
              </Td>

              <Td>Value Here
              </Td>

              <Td>%</Td>

              <Td />

            </Tr>

          </Tbody>

        </Table>
      </TableContainer>

      {/* Self consumption with battery */}
      <Heading
        className='table-header'
        as='h4'
        size='sm'
        padding={4}
        bgColor='teal'

      >D. Estimated self consumption - with battery storage
      </Heading>

      <TableContainer>
        <Table
          variant='striped'
          colorScheme='teal'
        >

          <Thead>
            <Tr>
              <Th />

              <Th
                colSpan={2}
                textAlign="center"
              >Today
              </Th>

              <Th
                colSpan={2}
                textAlign="center"
              >Post-retrofit
              </Th>

              <Th>Units</Th>

              <Th>Notes</Th>
            </Tr>

            <Tr>
              <Th />

              <Th>MCS</Th>

              <Th>Genous</Th>

              <Th>MCS</Th>

              <Th>Genous</Th>

              <Th />

              <Th />
            </Tr>
          </Thead>

          <Tbody>
            <Tr>
              <Td>Electrical Energy Storage System (EESS - battery) size</Td>

              <Td
                colSpan={4}
                textAlign="center"
              >4
              </Td>

              <Td>kWh</Td>

              <Td>Usable Capacity</Td>

            </Tr>

            <Tr>
              <Td>
                Expected solar PV self-consumption (with battery)

              </Td>

              <Td>
                Value Here

              </Td>

              <Td>
                Value Here

              </Td>

              <Td>
                Value Here

              </Td>

              <Td>
                Value Here

              </Td>

              <Td>KwH</Td>

              <Td />

            </Tr>

            <Tr>

              <Td>Expected solar PV export (with battery)
              </Td>

              <Td>Value Here
              </Td>

              <Td>Value Here
              </Td>

              <Td>Value Here
              </Td>

              <Td>Value Here
              </Td>

              <Td>kWh
              </Td>

              <Td />

              <Td />

            </Tr>

            <Tr>

              <Td>Grid electricity independence/self-sufficiency (with battery)</Td>

              <Td>Value Here
              </Td>

              <Td>Value Here
              </Td>

              <Td>Value Here
              </Td>

              <Td>Value Here
              </Td>

              <Td>%</Td>

              <Td />

            </Tr>

          </Tbody>

        </Table>
      </TableContainer>

      {/* Annual Benefits */}
      <Heading
        className='table-header'
        as='h4'
        size='sm'
        padding={4}
        bgColor='teal'

      >E. Annual Benefits
      </Heading>

      <TableContainer>
        <Table
          variant='striped'
          colorScheme='teal'
        >

          <Thead>
            <Tr>
              <Th />

              <Th
                colSpan={2}
                textAlign="center"
              >Today
              </Th>

              <Th
                colSpan={2}
                textAlign="center"
              >Post-retrofit
              </Th>

              <Th>Units</Th>

              <Th>Notes</Th>
            </Tr>

            <Tr>
              <Th />

              <Th>MCS</Th>

              <Th>Genous</Th>

              <Th>MCS</Th>

              <Th>Genous</Th>

              <Th />

              <Th />
            </Tr>
          </Thead>

          <Tbody>
            <Tr>
              <Td>Assumed marginal cost of electricity</Td>

              <Td>Value Here
              </Td>

              <Td>Value Here
              </Td>

              <Td>Value Here
              </Td>

              <Td>Value Here
              </Td>

              <Td>£ per kWh</Td>

              <Td />

            </Tr>

            <Tr>
              <Td>Assumed export (Small export guarantee or other) rate</Td>

              <Td>Value Here
              </Td>

              <Td>Value Here
              </Td>

              <Td>Value Here
              </Td>

              <Td>Value Here
              </Td>

              <Td>£ per kWh</Td>

              <Td />

            </Tr>

            <Tr>
              <Td>Reduction of bill from self-use</Td>

              <Td>Value Here
              </Td>

              <Td>Value Here
              </Td>

              <Td>Value Here
              </Td>

              <Td>Value Here
              </Td>

              <Td>£ per kWh</Td>

              <Td />

            </Tr>

            <Tr>
              <Td>Income from export</Td>

              <Td>Value Here
              </Td>

              <Td>Value Here
              </Td>

              <Td>Value Here
              </Td>

              <Td>Value Here
              </Td>

              <Td>£</Td>

              <Td />

            </Tr>

            <Tr>
              <Td>Total Benefit from PV System</Td>

              <Td>Value Here
              </Td>

              <Td>Value Here
              </Td>

              <Td>Value Here
              </Td>

              <Td>Value Here
              </Td>

              <Td>£</Td>

              <Td />

            </Tr>

            <Tr>
              <Td>Cost of Installation</Td>

              <Td
                colSpan={4}
                textAlign="center"
              >£5500
              </Td>

              <Td>£</Td>

              <Td />

            </Tr>

            <Tr>
              <Td>Implied annual rate of return</Td>

              <Td>Value Here
              </Td>

              <Td>Value Here
              </Td>

              <Td>Value Here
              </Td>

              <Td>Value Here
              </Td>

              <Td>£</Td>

              <Td />

            </Tr>

            <Tr>
              <Td>CO <sub>2</sub>e reduction</Td>

              <Td
                colSpan={4}
                textAlign="center"
              >6
              </Td>

              <Td>tonnes p.a.</Td>

              <Td />

            </Tr>

          </Tbody>

        </Table>
      </TableContainer>

      {/*Cumulative Benefits */}
      <Heading
        className='table-header'
        as='h4'
        size='sm'
        padding={4}
        bgColor='teal'

      >F. Cumulative Benefits
      </Heading>

      <TableContainer>
        <Table
          variant='striped'
          colorScheme='teal'
        >

          <Thead>
            <Tr>
              <Th />

              <Th
                colSpan={2}
                textAlign="center"
              >Today
              </Th>

              <Th
                colSpan={2}
                textAlign="center"
              >Post-retrofit
              </Th>

              <Th>Units</Th>

              <Th>Notes</Th>
            </Tr>

            <Tr>
              <Th />

              <Th>MCS</Th>

              <Th>Genous</Th>

              <Th>MCS</Th>

              <Th>Genous</Th>

              <Th />

              <Th />
            </Tr>
          </Thead>

          <Tbody>
            <Tr>
              <Td>Time Period to evaluate</Td>

              <Td
                colSpan={4}
                textAlign="center"
              >25
              </Td>

              <Td>Years</Td>

              <Td />

            </Tr>

            <Tr>
              <Td>Annual electricity price rise assumed</Td>

              <Td
                colSpan ={4}
                textAlign="center"
              >1
              </Td>

              <Td>% pa</Td>

              <Td />

            </Tr>

            <Tr>
              <Td>Annual export price rise assumed</Td>

              <Td
                colSpan={4}
                textAlign="center"
              >1
              </Td>

              <Td>% .p.a.</Td>

              <Td />

            </Tr>

            <Tr>
              <Td>Total bill savings + export income </Td>

              <Td>Value Here
              </Td>

              <Td>Value Here
              </Td>

              <Td>Value Here
              </Td>

              <Td>Value Here
              </Td>

              <Td>£</Td>

              <Td />

            </Tr>

            <Tr>
              <Td>Implied payback period </Td>

              <Td>Value Here
              </Td>

              <Td>Value Here
              </Td>

              <Td>Value Here
              </Td>

              <Td>Value Here
              </Td>

              <Td>Years</Td>

              <Td />

            </Tr>

            <Tr>
              <Td>Total returns / expenditure </Td>

              <Td>Value Here
              </Td>

              <Td>Value Here
              </Td>

              <Td>Value Here
              </Td>

              <Td>Value Here
              </Td>

              <Td>%</Td>

              <Td />

            </Tr>

            <Tr>
              <Td>Total CO <sub>2</sub>e avoided </Td>

              <Td
                colSpan={4}
                textAlign="center"
              >150
              </Td>

              <Td>tonnes .p.a.</Td>

              <Td />

            </Tr>

          </Tbody>

        </Table>
      </TableContainer>

      {/*Monthly Generation and demand */}
      <Heading
        className='table-header'
        as='h4'
        size='sm'
        padding={4}
        bgColor='teal'

      >
        G. Expected Generation and demand by month - Genous Methodology Only (Post-retrofit)
      </Heading>

      <MonthlyGenDemandGenous />

      {/*Monthly Generation and demand */}
      <Heading
        className='table-header'
        as='h4'
        size='sm'
        padding={4}
        bgColor='teal'

      >
        H. Expected self-use/export - Genous Methodology Only (Post-retrofit)
      </Heading>

      <PostRetrofitSelfUseGenous />

    </Flex>
  );
};

export default PVPerformanceDocs;