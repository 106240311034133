import {
  useState,
  useEffect
} from "react";

export type Wait = number | boolean;

type WaitState = {waiting: Wait, setWaiting: React.Dispatch<React.SetStateAction<Wait>>};

export const useWait = (): WaitState => {
  const [ waiting, setWaiting ] = useState<Wait>(false);

  useEffect(() => {
    if (waiting) {
      if (typeof waiting === "number") {
        const t = setTimeout(() => setWaiting(false), waiting);

        return () => clearTimeout(t);
      } else setWaiting(waiting);
    }
  }, [ waiting ]);

  return {
    waiting,
    setWaiting
  };
};