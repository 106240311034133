import { useLocalStorage } from "@gravitywelluk/react-hooks";
import { useEffect } from "react";

interface UtmParameters {
  source?: string;
  medium?: string;
  campaign?: string;
  term?: string;
  content?: string;
}

const initialState: UtmParameters = {};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useUtmParameters = () => {
  const storage = useLocalStorage<UtmParameters>("utm", initialState);
  const [ utm, setUtm ] = storage;

  // TODO get from URL, if there store in local storage, then return from local storage
  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);

    update("source", utm, params);
    update("medium", utm, params);
    update("campaign", utm, params);
    update("source", utm, params);
    update("term", utm, params);
    update("content", utm, params);
    setUtm(utm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return storage;
};

const update = (name: keyof UtmParameters, utm: UtmParameters, params: URLSearchParams) => {
  const value = params.get(`utm_${name}`);

  if (value) {
    utm[ name ] = value;
  }
};