import {
  HStack,
  List,
  ListItem,
  Box,
  ListIcon,
  VStack,
  Spacer,
  Button
} from "@chakra-ui/react";
import { AnimatePresence } from "framer-motion";
import type { Recommendation } from "src/api/users/footprint";
import { Wrapper } from "src/components/shared";
import { Text } from "src/components/text";
import { BiCheck } from "react-icons/bi";
import theme from "src/components/theme";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowRightSvg } from "src/assets/icons/arrow-right.svg";

import { routes } from "src/constants";

const ActionsListTile = ({ list }: {list: Recommendation[]}): JSX.Element => {
  return (
    <AnimatePresence>
      {!!list.length && (
        <Wrapper
          variant="narrow"
          my={[ 12, 20 ]}
          w="100%"
        >
          <VStack
            bg="white"
            borderTop="2px solid black"
            p={[
              4,
              8,
              12
            ]}
            w="100%"
            alignItems="start"
          >
            <Text
              fontSize={{
                base: theme.fontSizes.md,
                lg: theme.fontSizes.lg
              }}
              fontWeight="semibold"
            >
              My interests
            </Text>

            <Text
              fontSize={{
                base: theme.fontSizes.xs,
                lg: theme.fontSizes.sm,
                xl: theme.fontSizes.base
              }}
              fontWeight="light"
              color={theme.colors.gray[ 500 ]}
            >
              You have selected the following:
            </Text>

            <Spacer />

            <List
              spacing={1}
              w="100%"
            >
              {list.map(recommendation => (
                <HStack
                  as={ListItem}
                  key={recommendation.id}
                  bg={theme.colors.gray[ 100 ]}
                  borderRadius={theme.radius.sm}
                  p=".5em 1em"
                  alignItems="end"
                  w="100%"
                >
                  <ListIcon
                    as={BiCheck}
                    color={theme.colors.green[ 100 ]}
                    fontSize="1.75em"
                  />

                  <Text>
                    {recommendation.altRecommendationName ?? recommendation.recommendation.name}
                  </Text>
                </HStack>
              ))}
            </List>

            <Spacer
              p={3}
            />

            <Button
              as={Link}
              to={{
                pathname: routes.takeAction,
                state: { recommendations: list }
              }}
              bg="black"
              color={theme.colors.green[ 100 ]}
              borderRadius={0}
              fontSize="16"
              px={[ 6, 8 ]}
              py={[ 4, 6 ]}
              _hover={{ bg: theme.colors.gray[ 700 ] }}
              alignSelf="center"
            >
              <Text>Tell me more</Text>

              <Box
                w="1em"
                ml=".75em"
              >
                <ArrowRightSvg fill="currentColor" />
              </Box>
            </Button>
          </VStack>
        </Wrapper>
      )}
    </AnimatePresence>
  );
};

export default ActionsListTile;