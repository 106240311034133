import {
  Box,
  Spinner,
  BackgroundProps,
  Text
} from "@chakra-ui/react";

import { UpperThird } from "./UpperThird";

interface LoadingPageProps {bg?: BackgroundProps["bg"]; text?: string; subtext?: string}

const UnstyledLoadingPage: React.FC<LoadingPageProps> = ({
  bg, text, subtext
}) => (
  <Box
    w="100%"
    h="100%"
    display="flex"
    justifyContent="center"
    alignItems="flex-start"
    flex="1"
    bg={bg}
  >
    <UpperThird
      w="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      padding="1rem"
    >
      <Text
        textAlign="center"
        fontSize="md"
      >{text}
      </Text>

      {subtext && <Text textColor="gray.600">{subtext}</Text>}

      <Box marginTop={5}>
        <Spinner
          marginTop="6"
          size="md"
        />
      </Box>

    </UpperThird>

  </Box>
);

export const LoadingPage = UnstyledLoadingPage;