import {
  useHistory,
  useParams
} from "react-router";
import AppExtensionsSDK from "@pipedrive/app-extensions-sdk";
import { usePropertiesApi } from "src/api/properties/property.api";
import { usePipedriveApi } from "src/api/pipedrive";
import {
  useCallback,
  useEffect
} from "react";
import {
  GoogleMap,
  Marker,
  useJsApiLoader
} from "@react-google-maps/api";

const containerStyle = {
  borderRadius: 10,
  height: "500px",
  width: "100%"
};

const PipedrivePage = () => {
  const sdk = new AppExtensionsSDK().initialize();
  const selectedIds = new URLSearchParams(window.location.search).get("selectedIds");
  const userId = new URLSearchParams(window.location.search).get("userId");
  const token = new URLSearchParams(window.location.search).get("token");
  const companyId = new URLSearchParams(window.location.search).get("companyId");
  const history = useHistory();

  const [ addressLookupResponse, addressLookupRequest ] = usePipedriveApi(
    "GET_PROPERTY"
  );

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ""
  });

  useEffect(() => {
    addressLookupRequest({
      selectedIds,
      token,
      userId,
      companyId
    });
  }, [
    addressLookupRequest,
    selectedIds,
    token,
    userId,
    companyId
  ]);

  const onLoad = useCallback(
    map => {
      if (addressLookupResponse.data && addressLookupResponse.data.data && addressLookupResponse.data.data.lat) {
        //@ts-ignore
        map.fitBounds(new window.google.maps.LatLngBounds({
          lat: addressLookupResponse.data.data.lat,
          lng: addressLookupResponse.data.data.lng
        }));
        map.setZoom(20);
      }
    },
    [ addressLookupResponse.data ]
  );

  useEffect(() => {
    // Redirect to error page for all errors other than EPC not found
    if (addressLookupResponse.error) {
      history.push("/error", { error: addressLookupResponse.error });
    }
  }, [ addressLookupResponse.error, history ]);

  if (addressLookupResponse.data && addressLookupResponse.data.data && addressLookupResponse.data.data.lat) {
    return (
      isLoaded ? (
        <GoogleMap
          mapContainerClassName="shadow-xl"
          mapContainerStyle={containerStyle}
          options={{
            fullscreenControl: false,
            disableDefaultUI: false,
            streetViewControl: false,
            mapTypeControl: false,
            draggableCursor: "crosshair",
            maxZoom: 19,
            minZoom: 18,
            mapTypeId: "hybrid"
          }}
          onLoad={onLoad}
          center={{
            lat: addressLookupResponse.data.data.lat,
            lng: addressLookupResponse.data.data.lng
          }}
          tilt={0}
        >
          <Marker
            draggable
            position={{
              lat: addressLookupResponse.data.data.lat,
              lng: addressLookupResponse.data.data.lng
            }}
          />
        </GoogleMap>
      ) : <p>Loading</p>
    );
  } else {
    return (<p>Customer did not enter location.</p>);
  }
};

export default PipedrivePage;