import React, {
  useEffect,
  useMemo,
  useState
} from "react";
import {
  Box,
  Link as ChakraLink,
  Button,
  Checkbox,
  Input,
  Text
} from "@chakra-ui/react";
import {
  ChevronLeftIcon,
  ChevronRightIcon
} from "@chakra-ui/icons";
import { useSurveyAPI } from "src/api/survey/survey.api";
import { Link as ReactRouterLink } from "react-router-dom";

import {
  LoadingPage,
  Wrapper
} from "../shared";
import theme from "../theme";

const SurveyPurchaseForm = () => {
  const [ getSurveyProductResponse, getSurveyProductRequest ] = useSurveyAPI("GET_SURVEY_PRODUCT");

  const [ selectedAddons, setSelectedAddons ] = useState({
    solar: false,
    heating: false,
    insulation: false,
    initital: true
  });

  const toggleAddon = (key: keyof typeof selectedAddons) => {
    setSelectedAddons({
      ...selectedAddons,
      [ key ]: !selectedAddons[ key ]
    });
  };

  // Initial fetch
  useEffect(() => {
    getSurveyProductRequest({});
  }, [ getSurveyProductRequest ]);

  useEffect(() => {
    if (getSurveyProductResponse.data?.data && selectedAddons.initital === true) {
      setSelectedAddons({
        solar: getSurveyProductResponse.data.data.selectedAddons.solar,
        heating: getSurveyProductResponse.data.data.selectedAddons.heating,
        insulation: getSurveyProductResponse.data.data.selectedAddons.insulation,
        initital: false
      });
    }
  }, [ getSurveyProductResponse, selectedAddons ]);

  useEffect(() => {
    getSurveyProductRequest({
      selectedAddons: {
        solar: selectedAddons.solar,
        insulation: selectedAddons.insulation,
        heating: selectedAddons.heating
      }
    });
  }, [ selectedAddons, getSurveyProductRequest ]);

  const excludedText = useMemo(() => {
    const titles: string[] = [];

    if (getSurveyProductResponse.data?.data.availableAddons.heating === false) {
      titles.push("Heating");
    }

    if (getSurveyProductResponse.data?.data.availableAddons.insulation === false) {
      titles.push("Insulation");
    }

    if (getSurveyProductResponse.data?.data.availableAddons.solar === false) {
      titles.push("Solar");
    }

    if (titles.length === 0) {
      return null;
    }

    return `${titles.join(", ")} addon(s) are excluded since you were not interested in them`;
  }, [ getSurveyProductResponse ]);

  const goToPayment = () => {
    if (getSurveyProductResponse.data) {
      window.location.replace(getSurveyProductResponse.data.data.checkoutLink);
    }
  };

  if (getSurveyProductResponse.pending) {
    return <LoadingPage />;
  }

  if (getSurveyProductResponse.error) {
    return (
      <Wrapper
        my={theme.spacing[ 10 ]}
        variant="text"
        textAlign="center"
      >
        <Text>There was an Error</Text>

        <Button
          variant="ghost"
          onClick={() => {
            getSurveyProductRequest({
              selectedAddons: {
                solar: selectedAddons.solar,
                insulation: selectedAddons.insulation,
                heating: selectedAddons.heating
              }
            });
          }}
        >Retry
        </Button>
      </Wrapper>
    );
  }

  return (
    <Wrapper
      my={theme.spacing[ 16 ]}
      variant="form"
    >
      <ChakraLink
        as={ReactRouterLink}
        to="/next-steps"

        marginBottom={5}
        color='#C4C7C5'
        display="inline-block"
      >
        <ChevronLeftIcon />
        Back
      </ChakraLink>

      <Text fontSize="lg">Purchase A Survey</Text>

      <Text
        fontSize="sm"
        color="gray.600"
      >Let us know what addons you would like and when you want your Survey done
      </Text>

      <Box my={theme.spacing[ 4 ]}>
        <Text fontSize="md">1) Add-ons</Text>

        <Text
          fontSize="sm"
          color="gray.600"
        >We've pre-selected your survey based on the interests you highlighted on the last page but you can adjust here to tailor your survey so it's just right
        </Text>

        {getSurveyProductResponse.data?.data.availableAddons.solar && (
          <Option
            checked={selectedAddons.solar}
            text="+ Solar"
            onClick={() => toggleAddon("solar")}
          />
        )}

        {getSurveyProductResponse.data?.data.availableAddons.heating && (
          <Option
            checked={selectedAddons.heating}
            text="+ Heating"
            onClick={() => toggleAddon("heating")}
          />
        )}

        {getSurveyProductResponse.data?.data.availableAddons.insulation && (
          <Option
            checked={selectedAddons.insulation}
            text="+ Insulation"
            onClick={() => toggleAddon("insulation")}
          />
        )}

        <Text
          fontSize="sm"
          textColor="gray.600"
        >{excludedText}
        </Text>

        <Box
          display="flex"
          justifyContent="flex-end"
          my={theme.spacing[ 4 ]}
        >
          <Text fontWeight="semibold">Total £{getSurveyProductResponse.data?.data.price}</Text>
        </Box>

      </Box>

      <Text fontSize="md">2) Date and Time</Text>

      <Text
        fontSize="sm"
        textColor="gray.600"
      >Please let us know 2-3 morning or afternoon slots that you could make from (from one week from now onwards) and we'll get your survey scheduled.
        <br />

        <br />
        We'll also need a mobile phone number to get confirmation to you.

        <br />

        <br />

        Our survey partner, Vibrant Energy Matters will then be in touch directly to confirm.

      </Text>

      <br />

      <Input
        background="gray.100"
        variant="filled"
        placeholder="Tell us when you want your survey done"
      />

      {/* add date picker */}

      <br />

      <Text fontSize="md">3) Checkout</Text>

      <Text
        fontSize="sm"
        textColor="gray.600"
      >Our secure payment provider Stripe will handle the checkout.
      </Text>

      <Box
        justifyContent="flex-end"
        display="flex"
      >
        <Button
          variant="ghost"
          color="blue.500"
          onClick={goToPayment}
          fontSize="sm"
          rightIcon={<ChevronRightIcon />}
        >Pay £{getSurveyProductResponse.data?.data.price} Securely With Stripe
        </Button>
      </Box>

    </Wrapper>
  );
};

interface OptionProps {
  text: string;
  checked: boolean;
  onClick: () => void
}

const Option = ({
  text, checked, onClick
}: OptionProps) => (
  <Box
    display="flex"
    justifyContent="space-between"
    py={theme.spacing[ 4 ]}
    border="1px solid gray.400"
    onClick={onClick}
  >
    <Text>{text}</Text>

    <Checkbox isChecked={checked} />

  </Box>
);

export default SurveyPurchaseForm;