import { Link } from "react-router-dom";
import theme from "src/components/theme";

import { routes } from "src/constants";

import { CtaSection } from "./CtaSection";

export const LetsGetGoingSection: React.FC = () => (
  <CtaSection
    heading="Let's get going"
    content={[
      <span key={1}>Take our 5-minute <b>Home Check</b> to find out what changes will work for your home.

        However, if you'd like to speak to one of our home decarbonisation experts, {" "}

        <span>
          <a
            style={{ color: theme.colors.blue[ 500 ] }}
            href="/contact"
          >click here
          </a>{" "}
        </span>
        to get in touch and we'll call you back.
      </span>
    ]}
    cta="Start your Home Check"
    route={routes.welcome}
  />
);