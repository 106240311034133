import { useAxios } from "../../hooks/useAxios";
import { JSONAPIResponse } from "../json-api-response";

type PipedriveEndpointConfig = {
  GET_PROPERTY: EndpointConfig<
    any,
    JSONAPIResponse<{lat: number, lng: number}>
  >;
};

const requestInitialisers: RequestConfig<PipedriveEndpointConfig> = {
  GET_PROPERTY: (
    ({
      selectedIds, token, userId, companyId
    }: any) => ({
      url: `/pipedrive/ge?selectedIds=${selectedIds}&token=${token}&userId=${userId}&companyId=${companyId}`,
      method: "GET",
      unauthenticated: true
    })
  ) as any
};

export const usePipedriveApi: UseApiRequest<PipedriveEndpointConfig> = name => useAxios(requestInitialisers[ name ]);
