import { Text } from "../text";

interface FieldErrorProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
  fieldName: string;
}

export const FieldError: React.FC<FieldErrorProps> = ({
  error,
  fieldName
}) => {
  if (error) {
    return (
      <Text
        as="small"
        color="red"
      >
        {error.type === "required" ? `${fieldName} is required` : error.message}
      </Text>
    );
  } else return (
    <></>
  );
};