import {
  Action,
  ActionTypes
} from "../actionTypes";
import {
  initialState,
  State
} from "../state";

export const propertyReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case ActionTypes.UPDATE_PROPERTY_ID: {
      const propertyId = action.payload;

      return {
        ...state,
        propertyId
      };
    }
    default: return state;
  }
};