export const isNodeEnv = (arg: typeof process.env.NODE_ENV): boolean => process.env.NODE_ENV === arg;

/**
 * Console log only when node env is "development"
 * @param logGroupName console.group($arg)
 * @param error the `console.log`'d error
 */
export const devLog = (logGroupName: string, error: unknown): void => {
  if (isNodeEnv("development")) {
    { /* eslint-disable no-console */ }

    console.groupCollapsed(logGroupName);
    console.log(error);
    console.groupEnd();

    { /* eslint-enable no-console */ }
  }
};