import {
  Box,
  ImageProps,
  Image,
  BoxProps,
  forwardRef
} from "@chakra-ui/react";

type BackgroundImageProps = BoxProps & {
  imageProps: ImageProps & {children: React.ReactNode};
  ref?: React.Ref<HTMLDivElement>;
};

/**
 * Render inner sources like so:
 * ```jsx
 * <BackgroundImage
 *   imageProps={{
 *     children: (<>...</>)
 *   }}
 * 	...
 * >
 * ```
 *
 * NOTE must use native `<img>` tag as the last/fallback img tag inside
 * <picture>, using a Chakra `<Image>` will result in that image always being downloaded
 */
export const BackgroundImage = forwardRef<BackgroundImageProps, "div">(({
  children, imageProps, ...restProps
}, ref): JSX.Element => {
  const fillArea: ImageProps = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "center center",
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    zIndex: 0
  };

  const sharedImgProps: ImageProps = {
    ...fillArea,
    "loading": "lazy",
    "pointerEvents": "none",
    "aria-hidden": true
  };

  return (
    <Box
      ref={ref}
      position="relative"
      sx={{
        "& > *:not(picture)": {
          "zIndex": 1,
          "position": "relative",
          "*": {
            zIndex: 1,
            position: "relative"
          }
        }
      }}
      {...restProps}
    >
      <Image
        as="picture"
        decoding="async"
        {...sharedImgProps}
        sx={{ "& > img:last-child": { ...fillArea } }}
        style={{
          opacity: "0",
          transition: "opacity 1s ease-out"
        }}
        onLoadCapture={e => {
          if (imgsLoadedOnce.has((e.target as HTMLImageElement).src)) {
            e.currentTarget.style.opacity = "1";
            e.currentTarget.style.transition = "unset";
          }
        }}
        onLoad={e => {
          e.currentTarget.style.opacity = "1";
          imgsLoadedOnce.add((e.target as HTMLImageElement).src);
        }}
        {...imageProps}
      />

      {/* Inner content */}

      {children}
    </Box>
  );
});

// avoid fading the image _every_ time by keeping track of its first load
const imgsLoadedOnce = new Set();