import { EnergyRating } from "../../api/properties";
import "./EPCGraph.css";

interface EPCScore {
  value: number;
  band: EnergyRating;
}

interface EPCGraphProps {
  currentScore: EPCScore;
  potentialScore: EPCScore;
}

export const EPCGraph: React.FC<EPCGraphProps> = ({ currentScore, potentialScore }) => {
  const getBandPositionY = (band: string) => {
    switch (band) {
      case "A": return "25";
      case "B": return "75";
      case "C": return "125";
      case "D": return "175";
      case "E": return "225";
      case "F": return "275";
      case "G": return "325";
      default: return "gray";
    }
  };

  return (
    <svg
      width="615"
      viewBox="0 0 615 376"
      xmlns="http://www.w3.org/2000/svg"
      style={{ maxWidth: "100%" }}
      aria-labelledby="svg-title"
      aria-describedby="svg-desc"
      role="img"
      className="epc-energy-rating-graph"
    >
      <title id="svg-title">
        Energy efficiency chart
      </title>

      <desc id="svg-desc">
        This property’s current energy rating is
        {" "}

        {currentScore.band}

        {" "}

        with a score of
        {" "}

        {currentScore.value}

        . It has a potential energy rating of
        {" "}

        {potentialScore.band}

        {" "}
        with a score of

        {" "}

        {potentialScore.value}
        .
      </desc>

      <line
        x1="72"
        y1="0"
        x2="72"
        y2="376"
        style={{
          stroke: "#b1b4b6",
          strokeWidth: 1
        }}
      />

      <line
        x1="410"
        y1="0"
        x2="410"
        y2="376"
        style={{
          stroke: "#b1b4b6",
          strokeWidth: 1
        }}
      />

      <line
        x1="510"
        y1="0"
        x2="510"
        y2="376"
        style={{
          stroke: "#b1b4b6",
          strokeWidth: 1
        }}
      />

      <line
        x1="0"
        y1="25"
        x2="615"
        y2="25"
        style={{
          stroke: "#b1b4b6",
          strokeWidth: 1
        }}
      />

      <line
        x1="0"
        y1="0"
        x2="615"
        y2="0"
        style={{
          stroke: "#b1b4b6",
          strokeWidth: 1
        }}
      />

      <line
        x1="0"
        y1="0"
        x2="615"
        y2="0"
        style={{
          stroke: "#b1b4b6",
          strokeWidth: 1
        }}
      />

      <line
        x1="0"
        y1="0"
        x2="0"
        y2="376"
        style={{
          stroke: "#b1b4b6",
          strokeWidth: 1
        }}
      />

      <line
        x1="615"
        y1="376"
        x2="615"
        y2="0"
        style={{
          stroke: "#b1b4b6",
          strokeWidth: 1
        }}
      />

      <line
        x1="615"
        y1="376"
        x2="0"
        y2="376"
        style={{
          stroke: "#b1b4b6",
          strokeWidth: 1
        }}
      />

      <rect
        width="78"
        height="50"
        x="72"
        y="25"
        className="band-a"
      />

      <rect
        width="118"
        height="50"
        x="72"
        y="75"
        className="band-b"
      />

      <rect
        width="158"
        height="50"
        x="72"
        y="125"
        className="band-c"
      />

      <rect
        width="198"
        height="50"
        x="72"
        y="175"
        className="band-d"
      />

      <rect
        width="238"
        height="50"
        x="72"
        y="225"
        className="band-e"
      />

      <rect
        width="278"
        height="50"
        x="72"
        y="275"
        className="band-f"
      />

      <rect
        width="318"
        height="50"
        x="72"
        y="325"
        className="band-g"
      />

      <rect
        width="72"
        height="50"
        x="0"
        y="25"
        className="band-a-score"
      />

      <rect
        width="72"
        height="50"
        x="0"
        y="75"
        className="band-b-score"
      />

      <rect
        width="72"
        height="50"
        x="0"
        y="125"
        className="band-c-score"
      />

      <rect
        width="72"
        height="50"
        x="0"
        y="175"
        className="band-d-score"
      />

      <rect
        width="72"
        height="50"
        x="0"
        y="225"
        className="band-e-score"
      />

      <rect
        width="72"
        height="50"
        x="0"
        y="275"
        className="band-f-score"
      />

      <rect
        width="72"
        height="50"
        x="0"
        y="325"
        className="band-g-score"
      />

      <text
        role="presentation"
        aria-hidden="true"
        x="0"
        y="0"
        className="letter"
      >
        <tspan
          x="107"
          y="64"
        >
          A
        </tspan>

        <tspan
          x="147"
          y="114"
        >
          B
        </tspan>

        <tspan
          x="187"
          y="164"
        >
          C
        </tspan>

        <tspan
          x="227"
          y="214"
        >
          D
        </tspan>

        <tspan
          x="267"
          y="264"
        >
          E
        </tspan>

        <tspan
          x="307"
          y="314"
        >
          F
        </tspan>

        <tspan
          x="347"
          y="364"
        >
          G
        </tspan>
      </text>

      <text
        role="presentation"
        aria-hidden="true"
        x="0"
        y="0"
        className="small"
      >
        <tspan
          x="8"
          y="55"
        >
          92+
        </tspan>

        <tspan
          x="8"
          y="105"
        >
          81-91
        </tspan>

        <tspan
          x="8"
          y="155"
        >
          69-80
        </tspan>

        <tspan
          x="8"
          y="205"
        >
          55-68
        </tspan>

        <tspan
          x="8"
          y="255"
        >
          39-54
        </tspan>

        <tspan
          x="8"
          y="305"
        >
          21-38
        </tspan>

        <tspan
          x="8"
          y="355"
        >
          1-20
        </tspan>
      </text>

      <text
        role="presentation"
        aria-hidden="true"
        x="8"
        y="15"
        className="small"
        dominantBaseline="middle"
      >
        Score
      </text>

      <text
        role="presentation"
        aria-hidden="true"
        x="85"
        y="15"
        className="small"
        dominantBaseline="middle"
      >
        Energy rating
      </text>

      <text
        role="presentation"
        aria-hidden="true"
        x="460"
        y="15"
        className="small"
        textAnchor="middle"
        dominantBaseline="middle"
      >
        Current
      </text>

      <text
        role="presentation"
        aria-hidden="true"
        x="565"
        y="15"
        className="small"
        textAnchor="middle"
        dominantBaseline="middle"
      >
        Potential
      </text>

      <svg
        role="presentation"
        aria-hidden="true"
        x="415"
        y={getBandPositionY(currentScore.band)}
        width="90"
        height="50"
      >
        <rect
          width="75"
          height="50"
          className={`band-${currentScore.band.toLowerCase()}`}
          x="25"
        />

        <polygon
          points="0,25 25,50 25,0 0,25"
          className={`band-${currentScore.band.toLowerCase()}`}
        />

        <text
          x="18"
          y="32"
          className="current-potential-number"
        >
          {currentScore.value}

          {" "}
          |
        </text>

        <text
          x="67"
          y="32"
          className="small-letter"
        >
          {currentScore.band}
        </text>
      </svg>

      <svg
        role="presentation"
        aria-hidden="true"
        x="515"
        y={getBandPositionY(potentialScore.band)}
        width="90"
        height="50"
      >
        <rect
          width="75"
          height="50"
          className={`band-${potentialScore.band.toLowerCase()}`}
          x="25"
        />

        <polygon
          points="0,25 25,50 25,0 0,25"
          className={`band-${potentialScore.band.toLowerCase()}`}
        />

        <text
          x="18"
          y="32"
          className="current-potential-number"
        >
          {potentialScore.value}

          {" "}
          |
        </text>

        <text
          x="67"
          y="32"
          className="small-letter"
        >
          {potentialScore.band}
        </text>
      </svg>
    </svg>
  );
};