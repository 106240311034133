import {
  HStack,
  VStack,
  Link as ChakraLink
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Wrapper } from "src/components/shared";
import { Text } from "src/components/text";
import theme from "src/components/theme";
import { desktopBreakpointKey } from "src/hooks/useIsDesktop";

import { routes } from "src/constants";

import HowItWorksSectionSteps from "./HowItWorksSectionSteps";

const HowItWorksSection = (): JSX.Element => {
  return (
    <Wrapper
      py={{ [ desktopBreakpointKey ]: theme.spacing[ 16 ] }}
      color={theme.colors.black}
    >
      <VStack
        alignItems="flex-start"
        py={[ `clamp(${theme.spacing[ 12 ]}, 10vh, ${theme.spacing[ 40 ]})`, `clamp(${theme.spacing[ 16 ]}, 12vh, ${theme.spacing[ 48 ]})` ]}
      >
        <HStack
          w="100%"
          alignItems="baseline"
          justifyContent="space-between"
        >
          <Text
            type="homepageHeading"
            fontWeight="medium"
            lineHeight="1.2"
            letterSpacing="-0.01em"
            pr="5%"
            mb={{
              base: theme.spacing[ 8 ],
              [ desktopBreakpointKey ]: 0
            }}
          >How it works
          </Text>

          <ChakraLink
            as={Link}
            to={routes.howItWorks}
            color={theme.colors.gray[ 600 ]}
          >
            Read more
          </ChakraLink>
        </HStack>

        <br />

        <HStack
          alignItems="flex-start"
          flexWrap={{ base: "wrap" }}
          justifyContent="space-between"
          spacing={{
            base: 0,
            lg: theme.spacing[ 6 ]
          }}
        >
          <HowItWorksSectionSteps />
        </HStack>
      </VStack>
    </Wrapper>
  );
};

export default HowItWorksSection;