import { Box } from "@chakra-ui/react";

import theme from "../theme";
import { desktopBreakpointKey } from "../../hooks/useIsDesktop";

export const GrayGradientContainer: React.FC = props => (
  <Box
    bg="white"
    position="relative"
  >
    <Box
      backgroundImage={"url(\"https://genous-public-assets.s3.eu-west-2.amazonaws.com/public/background/bg_gray.svg\")"}
      bgRepeat="no-repeat"
      bgSize="cover"
      py={{
        "base": `clamp(${theme.spacing[ 12 ]}, 10vh, ${theme.spacing[ 40 ]})`,
        [ desktopBreakpointKey ]: theme.spacing[ 24 ],
        "2xl": theme.spacing[ 40 ]
      }}
      position="relative"
      sx={{
        "& > *": {
          position: "relative",
          zIndex: 1
        }
      }}
    >
      {props.children}
    </Box>

    <Box
      position="absolute"
      top="0"
      left="0"
      right="0"
      bottom="0"
      bgGradient={{
        base: `linear-gradient(-30deg, ${theme.colors.gray[ 200 ]}, rgba(255,255,255,0.2) 33% 95%, white), linear-gradient(to top, white, rgba(255,255,255,0.2))`,
        [ desktopBreakpointKey ]: "linear-gradient(-30deg, white, transparent 25% 75%, white), linear-gradient(to top, white, transparent)"
      }}
      bgRepeat="no-repeat"
      bgSize="cover"
      pointerEvents="none"
    />
  </Box>
);