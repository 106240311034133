import { topicPreviewContent } from "src/components/reduce/topics";
import { StackProps } from "@chakra-ui/react";
import { useRef } from "react";
import theme from "src/components/theme";
import { desktopBreakpointKey } from "src/hooks/useIsDesktop";
import { ReduceCarbonTopicPreview } from "src/components/reduce/ReduceCarbonTopPreview";
import { Carousel } from "src/components/carousel";
import useIntersectionObserver from "src/hooks/useIntersectionObserver";

export const ActionsCarousel: React.FC = () => {
  const observedRef = useRef<HTMLDivElement>(null);
  const observed = useIntersectionObserver(observedRef, { freezeOnceVisible: true });

  return (
    <Carousel
      ref={observedRef}
      {...sectionStyleProps}
    >
      {Object.entries(topicPreviewContent).map(([
        title,
        {
          imgAlt, imgSrc, text
        }
      ], index) => (
        <ReduceCarbonTopicPreview
          key={index.toString()}
          title={title}
          text={text}
          imgSrc={observed?.isIntersecting ?? !window.IntersectionObserver ? imgSrc : ""}
          imgAlt={imgAlt}
        />
      ))}
    </Carousel>
  );
};

const sectionStyleProps: StackProps = {
  mt: {
    base: theme.spacing[ 12 ],
    [ desktopBreakpointKey ]: theme.spacing[ 24 ]
  },
  py: theme.spacing[ 2 ]
};