import {
  useCallback,
  useMemo
} from "react";
import { breakpoints } from "src/components/theme/breakpoints";

import {
  BreakpointKey,
  BreakpointValue,
  useBreakpointKeyValue
} from "./useBreakpointKeyValue";

/**
  * okay, so before, we couldn't define variants (form, layout etc) as
  responsive values, like how Chakra allows (in object form; {sm: x, lg: x},
  so this fn basically finds the most appropriate breakpoint from the variant supplied (checking if less/equal to curr breakpoint) and applies it
  */
const useResponsiveBreakpointValue = <T>(responsiveValues: Partial<Record<keyof typeof breakpoints, T>>): T | undefined => {
  const [ currentBreakpointKey, currentBreakpointValue ] = useBreakpointKeyValue();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const getValueFromBreakpointCallback = useCallback(getValueFromBreakpoint, [
    currentBreakpointKey,
    currentBreakpointValue,
    responsiveValues
  ]);

  if (!Object.keys(responsiveValues).length) {
    throw new Error("No responsive values provided to `useResponsiveBreakpointValue`");
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return useMemo(() => getValueFromBreakpointCallback(responsiveValues, currentBreakpointKey, currentBreakpointValue!), [
    currentBreakpointKey,
    currentBreakpointValue,
    getValueFromBreakpointCallback,
    responsiveValues
  ]);
};

export const getValueFromBreakpoint = <T>(breakpointValues: Partial<Record<keyof typeof breakpoints, T>>, currentBreakpointKey: BreakpointKey, currentBreakpointValue: BreakpointValue): T | undefined => {
  const match = Object.keys(breakpointValues).reduce((acc, variantBreakpoint) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (variantBreakpoint === currentBreakpointKey || parseInt(breakpoints[ variantBreakpoint as keyof typeof breakpoints ]) <= parseInt(currentBreakpointValue!)) {
      return variantBreakpoint;
    }

    return acc;
  }, "base");

  return breakpointValues[ match as keyof typeof breakpointValues ];
};

export default useResponsiveBreakpointValue;
