import { motion } from "framer-motion";
import styled from "styled-components";

export const ScrollablePageSection = styled(motion.div)<{width?: string, top?: number}>`
  height: 100%;
  min-width: ${props => props.width || "50%"};
  align-items: left;
  overflow-y: auto;
  z-index: 5;
  position: relative;
  top: ${props => `${props.top}px` || 0};
`;