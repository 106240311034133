import "./polyfills";

import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.css";
import {
  ChakraProvider,
  ColorModeScript
} from "@chakra-ui/react";
import Amplify from "@aws-amplify/core";
import { BrowserRouter } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import {
  storyblokInit,
  apiPlugin
} from "@storyblok/react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import { chakraTheme } from "./components/theme";
import { AuthProvider } from "./contexts/AuthContext";
import { CurrentUserProvider } from "./contexts/CurrentUserContext";
import { store } from "./redux/store";
import App from "./pages";
import { Page } from "./components/storyblok/Page";
import { SectionBlok } from "./components/storyblok/SectionBlok";
import { CtaBlok } from "./components/storyblok/CtaBlok";
import { ImageBlok } from "./components/storyblok/ImageBlok";
import { ImageCarouselBlok } from "./components/storyblok/ImageCarouselBlok";
import { HeroBlok } from "./components/storyblok/HeroBlok";
import { TeamBiosBlok } from "./components/storyblok/TeamBiosBlok";
import { CaseStudiesBlok } from "./components/storyblok/CaseStudyBlok";
import { NewsHubBlok } from "./components/storyblok/NewsHubBlok";
import { BlogBlok } from "./components/storyblok/BlogBlok";
import { CredentialsBlok } from "./components/storyblok/CredentialsBlok";
import { TestimonialsBlok } from "./components/storyblok/TestimonialsBlok";

window.process = process;
// @ts-ignore
window.Buffer = Buffer;
window.Buffer = Buffer;

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [ new BrowserTracing() ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0
});

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_COGNITO_REGION,
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT,
    authenticationFlowType: "USER_PASSWORD_AUTH",
    oauth: {
      domain: process.env.REACT_APP_COGNITO_DOMAIN,
      scope: [
        "email",
        "openid",
        "aws.cognito.signin.user.admin"
      ],
      redirectSignIn: process.env.REACT_APP_COGNITO_CALLBACK_URL,
      redirectSignOut: process.env.REACT_APP_COGNITO_LOGOUT_URL,
      clientId: process.env.REACT_APP_COGNITO_APP_CLIENT,
      responseType: "code"
    }
  }
});

storyblokInit({
  accessToken: process.env.REACT_APP_STORYBLOK_ACCESS_TOKEN,
  use: [ apiPlugin ],
  components: {
    page: Page,
    hero: HeroBlok,
    section: SectionBlok,
    cta: CtaBlok,
    image: ImageBlok,
    imageCarousel: ImageCarouselBlok,
    teamBios: TeamBiosBlok,
    caseStudy: CaseStudiesBlok,
    newsHub: NewsHubBlok,
    blog: BlogBlok,
    credentials: CredentialsBlok,
    testimonials: TestimonialsBlok
  }
});

// wrap app with context providers
const ReactRoot: React.ReactElement = (
  <React.StrictMode>
    <ReduxProvider store={store}>
      <ChakraProvider
        theme={chakraTheme}
        resetCSS
      >
        <ColorModeScript initialColorMode="light" />

        <AuthProvider>
          <BrowserRouter>
            <CurrentUserProvider>
              <App />
            </CurrentUserProvider>
          </BrowserRouter>
        </AuthProvider>
      </ChakraProvider>
    </ReduxProvider>
  </React.StrictMode>
);

// attach React app to DOM
ReactDOM.render(
  ReactRoot,
  document.getElementById("root")
);
