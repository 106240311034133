import { PieChart } from "react-minimal-pie-chart";
import { Data } from "react-minimal-pie-chart/types/commonTypes";
import { OffsetPortfolio } from "src/api/offsets";

const PortfolioPieChart: React.FC<{portfolio: OffsetPortfolio, size?: number}> = ({ portfolio, size = 50 }) => {
  const pieData: Data = portfolio.offsetProviders.map((offsetProvider, i) => {
    return {
      key: i,
      value: offsetProvider.portfolioOffsetProvider.portfolioPercentage,
      // progressively lighten the segments for each segment index
      color: `hsl(0, 0%, 0%, ${0.8 - (0.6 / portfolio.offsetProviders.length) * i})`
    } as Data[number];
  }).concat({
    id: "leftover",
    value: 100 - portfolio.offsetProviders.reduce((prev, current) => prev + current.portfolioOffsetProvider.portfolioPercentage, 0),
    // leftover percentage should be very light
    color: "hsl(0, 0%, 0%, 0.1)"
  } as Data[number]);

  return (
    <PieChart
      startAngle={-90}
      data={pieData}
      style={{ width: size }}
      totalValue={100}
    />
  );
};

export default PortfolioPieChart;