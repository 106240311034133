import "./EPCGraph.css";

export const EPCPlaceholder: React.FC = () => {
  return (
    <svg
      width="615"
      viewBox="0 0 615 376"
      xmlns="http://www.w3.org/2000/svg"
      style={{ maxWidth: "100%" }}
      aria-labelledby="svg-title"
      aria-describedby="svg-desc"
      role="img"
      className="epc-energy-rating-graph"
    >
      <title id="svg-title">
        Placeholder energy efficiency chart
      </title>

      <line
        x1="72"
        y1="0"
        x2="72"
        y2="376"
        style={{
          stroke: "#b1b4b6",
          strokeWidth: 1
        }}
      />

      <line
        x1="410"
        y1="0"
        x2="410"
        y2="376"
        style={{
          stroke: "#b1b4b6",
          strokeWidth: 1
        }}
      />

      <line
        x1="510"
        y1="0"
        x2="510"
        y2="376"
        style={{
          stroke: "#b1b4b6",
          strokeWidth: 1
        }}
      />

      <line
        x1="0"
        y1="25"
        x2="615"
        y2="25"
        style={{
          stroke: "#b1b4b6",
          strokeWidth: 1
        }}
      />

      <line
        x1="0"
        y1="0"
        x2="615"
        y2="0"
        style={{
          stroke: "#b1b4b6",
          strokeWidth: 1
        }}
      />

      <line
        x1="0"
        y1="0"
        x2="615"
        y2="0"
        style={{
          stroke: "#b1b4b6",
          strokeWidth: 1
        }}
      />

      <line
        x1="0"
        y1="0"
        x2="0"
        y2="376"
        style={{
          stroke: "#b1b4b6",
          strokeWidth: 1
        }}
      />

      <line
        x1="615"
        y1="376"
        x2="615"
        y2="0"
        style={{
          stroke: "#b1b4b6",
          strokeWidth: 1
        }}
      />

      <line
        x1="615"
        y1="376"
        x2="0"
        y2="376"
        style={{
          stroke: "#b1b4b6",
          strokeWidth: 1
        }}
      />

      <rect
        width="78"
        height="50"
        x="72"
        y="25"
        className="band-placeholder"
      />

      <rect
        width="118"
        height="50"
        x="72"
        y="75"
        className="band-placeholder"
      />

      <rect
        width="158"
        height="50"
        x="72"
        y="125"
        className="band-placeholder"
      />

      <rect
        width="198"
        height="50"
        x="72"
        y="175"
        className="band-placeholder"
      />

      <rect
        width="238"
        height="50"
        x="72"
        y="225"
        className="band-placeholder"
      />

      <rect
        width="278"
        height="50"
        x="72"
        y="275"
        className="band-placeholder"
      />

      <rect
        width="318"
        height="50"
        x="72"
        y="325"
        className="band-placeholder"
      />

      <rect
        width="72"
        height="50"
        x="0"
        y="25"
        className="band-placeholder"
      />

      <rect
        width="72"
        height="50"
        x="0"
        y="75"
        className="band-placeholder"
      />

      <rect
        width="72"
        height="50"
        x="0"
        y="125"
        className="band-placeholder"
      />

      <rect
        width="72"
        height="50"
        x="0"
        y="175"
        className="band-placeholder"
      />

      <rect
        width="72"
        height="50"
        x="0"
        y="225"
        className="band-placeholder"
      />

      <rect
        width="72"
        height="50"
        x="0"
        y="275"
        className="band-placeholder"
      />

      <rect
        width="72"
        height="50"
        x="0"
        y="325"
        className="band-placeholder"
      />

      <svg
        role="presentation"
        aria-hidden="true"
        x="415"
        y="125"
        width="90"
        height="50"
      >
        <rect
          width="75"
          height="50"
          className="band-placeholder"
          x="25"
        />

        <polygon
          points="0,25 25,50 25,0 0,25"
          className="band-placeholder"
        />
      </svg>

      <svg
        role="presentation"
        aria-hidden="true"
        x="515"
        y="25"
        width="90"
        height="50"
      >
        <rect
          width="75"
          height="50"
          className="band-placeholder"
          x="25"
        />

        <polygon
          points="0,25 25,50 25,0 0,25"
          className="band-placeholder"
        />
      </svg>
    </svg>
  );
};