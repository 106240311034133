import {
  storyblokEditable,
  SbBlokData
} from "@storyblok/react";
import { render } from "storyblok-rich-text-react-renderer";

import { HeroSection } from "../landing/sections/HeroSection";

type HeroBlockProps = { blok: SbBlokData & {heading?: string, content?: string, image: Array<{ filename: string}>, compact: boolean, hidden: boolean } };

export const HeroBlok: React.FC<HeroBlockProps> = ({ blok }) =>
  !blok.hidden ? (
    <HeroSection
      heading={blok.heading ? blok.heading : undefined}
      content={blok.content ? render(blok.content) : undefined}
      imgUrl={blok.image?.length ? blok.image[ 0 ].filename : undefined}
      compact={blok.compact}
      {...storyblokEditable(blok)}
    />
  ) :
    <></>;
