/**
 * based on TailwindCSS spacing scale (8px system) (https://v1.tailwindcss.com/docs/customizing-spacing#default-spacing-scale)
 */

export const spacing = {
  px: "1px",
  1: "4px",
  2: "8px",
  3: "12px",
  4: "16px",
  5: "20px",
  6: "24px",
  8: "32px",
  10: "40px",
  12: "48px",
  16: "64px",
  20: "80px",
  24: "96px",
  32: "128px",
  40: "160px",
  48: "192px",
  56: "224px",
  64: "256px"
} as const;