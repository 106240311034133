import {
  VStack,
  Link
} from "@chakra-ui/react";
import { Link as RouteLink } from "react-router-dom";
import theme from "src/components/theme";

import { routes } from "src/constants";

import { Text } from "../../components/text/Text";

export const ExistingAccount: React.FC = () => (
  <VStack mt={theme.spacing[ 4 ]}>
    <Text
      mt={theme.spacing[ 4 ]}
      fontSize={theme.fontSizes.sm}
      color={theme.colors.gray[ 700 ]}
    >
      Already completed the Home Check?
    </Text>

    <Link
      as={RouteLink}
      to={routes.retrieve}
      fontSize={theme.fontSizes.sm}
      color={theme.colors.green[ 200 ]}
    >
      Retrieve your results
    </Link>
  </VStack>
);