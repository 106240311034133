import {
  Control,
  Controller,
  FieldValues,
  UseFormReset,
  useWatch,
  useForm,
  UseFormGetValues
} from "react-hook-form";
import {
  InputGroup,
  VStack,
  Box,
  Flex,
  HStack
} from "@chakra-ui/react";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useState
} from "react";
import {
  ArrowBackIcon,
  CheckIcon
} from "@chakra-ui/icons";

import {
  StyledChakraTextInput as Input,
  Select
} from ".";
import { Button } from "../button";
import { Question } from "../../api/questions";
import { FormField } from "./FormField";
import {
  AddressData,
  PostcodeLookupResponse
} from "../../postcode-lookup/postcode-lookup";
import {
  addressLinesToUse,
  formatAddress
} from "../../postcode-lookup/format-address";
import { getFieldName } from "../../postcode-lookup/get-field-name";
import { autoCompleteInput } from "../../helpers/auto-complete-input";
import { RenderSectionButtons } from "../../pages/section/SectionButtons";
import { usePropertiesApi } from "../../api/properties/property.api";
import theme from "../theme";
import { Text } from "../text";
import { getIsValidPostcode } from "../../helpers";

type UnstyledAddressLookupProps = {
  question: Question;
  getValues: UseFormGetValues<FieldValues>;
  setPostcode: (postcode: string) => void;
  control: Control;
  reset: UseFormReset<FieldValues>;
  setNextButtonVisible?: React.Dispatch<React.SetStateAction<boolean>>;
};

const MIN_POSTCODE_VALIDATION_CHECK_LENGTH = 4;

const UnstyledAddressLookup: React.FC<UnstyledAddressLookupProps> = ({
  question,
  control,
  setNextButtonVisible,
  getValues,
  setPostcode: setPostcodeOutside,
  reset
}) => {
  const [ postcode, setPostcode ] = useState<string | null>(
    localStorage.getItem("postcode")
  );

  const [ addressList, setAddressList ] = useState<PostcodeLookupResponse | null>(
    null
  );

  const [ selectedAddress, setSelectedAddress ] = useState<AddressData | null>(
    null
  );

  const [ enterAddressManually, setEnterAddressManually ] = useState(false);

  const [ addressLookupResponse, addressLookupRequest ] = usePropertiesApi(
    "GET_POSTCODE_LOOKUP_RESULTS"
  );

  useEffect(() => {
    return () => {
      getValues()[ question.id ] && localStorage.setItem("postcode", getValues()[ question.id ][ "address-postcode" ]);
    };
  }, [ getValues, question.id ]);

  const emptyAddress = {
    line_1: "",
    line_2: "",
    line_3: "",
    line_4: "",
    district: "",
    town_or_city: "",
    county: "",
    country: ""
  };

  useEffect(() => {
    if (postcode) {
      addressLookupRequest({ postcode });
      setPostcodeOutside(postcode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // as this is a multi-part section, we want to hide the form submission btn
  // until the last part
  useEffect(() => {
    if (setNextButtonVisible) setNextButtonVisible(Boolean(selectedAddress));
  }, [
    selectedAddress,
    setNextButtonVisible,
    enterAddressManually
  ]);

  useEffect(() => {
    if (addressLookupResponse.complete && addressLookupResponse.data) {
      setAddressList(addressLookupResponse.data.data);
    }
  }, [ addressLookupResponse ]);

  const findAddresses = async () => {
    if (postcode) {
      addressLookupRequest({ postcode });
    }
  };

  const handlePostcodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPostcode(event.target.value);
    setPostcodeOutside(event.target.value);
    localStorage.setItem("postcode", event.target.value || "");
  };

  const removePostcodeSelection = () => {
    // Reset manual address view
    setEnterAddressManually(false);
    // Remove any already selected address
    setSelectedAddress(null);
    // Reset form
    reset();
    // Reset address list
    setAddressList(null);
  };

  const handleAddressSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    // Reset manual address view
    setEnterAddressManually(false);
    // Set selected address
    setSelectedAddress(JSON.parse(event.target.value));
  };

  const setEmptyAddress = () => {
    setSelectedAddress(emptyAddress);
    setEnterAddressManually(true);
  };

  // performing postcode validation on the first step only requires reading from
  // our local state value
  const [ isFindAttempted, setFindAttempted ] = useState(false);

  const showPostcodeLookupError = Boolean(
    postcode &&
      isFindAttempted &&
      postcode.length >= MIN_POSTCODE_VALIDATION_CHECK_LENGTH &&
      !getIsValidPostcode(postcode)
  );

  // performing postcode validation on the following address step requires
  // reading from the hook-form state
  const finalAddressPostcode = useWatch({
    name: `${question.id.toString()}.address-postcode`,
    control
  });

  const showFinalAddressPostcodeError = Boolean(
    finalAddressPostcode &&
      finalAddressPostcode.length >= MIN_POSTCODE_VALIDATION_CHECK_LENGTH &&
      !getIsValidPostcode(finalAddressPostcode)
  );

  {
    /** NEXT button will only be visible when selectedAddress is set */
  }

  if (selectedAddress)
    return (
      <VStack
        width="100%"
        align="left"
      >
        <Button
          onClick={removePostcodeSelection}
          leftIcon={<ArrowBackIcon />}
          background="none"
          color="black"
          alignSelf="start"
          mb="1rem"
        >
          Back to postcode
        </Button>

        {/** Hiding the selected address when viewing the manual address form */}

        <div style={{ display: enterAddressManually ? "none" : "unset" }}>
          {selectedAddress && (
            <HStack alignItems="flex-start">
              <Box
                bg={theme.colors.white}
                p={theme.spacing[ 4 ]}
                flexGrow={1}
              >
                {formatAddress(selectedAddress)}
              </Box>

              <Flex
                justifyContent="center"
                alignItems="center"
                alignSelf="stretch"
                px={theme.spacing[ 1 ]}
                py={theme.spacing[ 1 ]}
                w={theme.spacing[ 12 ]}
                flexShrink={0}
              >
                <Flex
                  bg={theme.colors.green[ 100 ]}
                  w="2ch"
                  h="2ch"
                  justifyContent="center"
                  alignItems="center"
                  p={3}
                  borderRadius={theme.radius.rounded_full}
                >
                  <CheckIcon color="white" />
                </Flex>
              </Flex>
            </HStack>
          )}
        </div>

        {/** Only showing the manual address form when enterAddressManually is true */}

        <div style={{ display: enterAddressManually ? "unset" : "none" }}>
          {Object.keys(selectedAddress).map(line => {
            if (addressLinesToUse.includes(line)) {
              return (
                <FormField
                  question={question}
                  questionId={line}
                  label={getFieldName(line)}
                  key={line}
                >
                  <Controller
                    name={`${question.id.toString()}.address-${line}`}
                    control={control}
                    defaultValue={selectedAddress[ line as keyof typeof selectedAddress ]}
                    render={({ field }) => {
                      return (
                        <Input
                          type="text"
                          mb="1rem"
                          {...autoCompleteInput()}
                          {...field}
                        />
                      );
                    }}
                  />
                </FormField>
              );
            }
          })}

          <FormField
            question={question}
            questionId="postcode"
            label={getFieldName("postcode")}
          >
            <Controller
              name={`${question.id.toString()}.address-postcode`}
              control={control}
              defaultValue={postcode}
              render={({ field }) => {
                return (
                  <>
                    <Input
                      type="text"
                      isInvalid={showFinalAddressPostcodeError}
                      maxLength={8}
                      minLength={MIN_POSTCODE_VALIDATION_CHECK_LENGTH}
                      _invalid={{
                        border: "none",
                        boxShadow: "0 0 0 2px " + theme.colors.orange
                      }}
                      {...autoCompleteInput()}
                      {...field}
                    />

                    {showFinalAddressPostcodeError && renderPostcodeFormatError}
                  </>
                );
              }}
            />
          </FormField>
        </div>
      </VStack>
    );

  return (
    <VStack
      width="100%"
      align="left"
    >
      {/**
       * If there are no results yet - show postcode field with:
       * FIND and ENTER ADDRESS MANUALLY buttons
       * */}

      {!addressList ? (
        <FormField
          key={question.id}
          question={question}
          label="What is your postcode?"
        >
          <InputGroup
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Input
              type={question.type || "text"}
              onChange={handlePostcodeChange}
              defaultValue={postcode || undefined}
              isInvalid={showPostcodeLookupError}
              maxLength={8}
              minLength={MIN_POSTCODE_VALIDATION_CHECK_LENGTH}
              _invalid={{
                border: "none",
                boxShadow: "0 0 0 2px " + theme.colors.orange
              }}
              {...autoCompleteInput()}
            />

            {showPostcodeLookupError && renderPostcodeFormatError}

            <RenderSectionButtons>
              {({ NextButton }) => (
                <VStack spacing={theme.spacing[ 10 ]}>
                  <NextButton
                    type="button"
                    id="EPC_find_button"
                    isDisabled={!postcode || !getIsValidPostcode(postcode)}
                    onPointerOutCapture={() => setFindAttempted(true)}
                    onClick={findAddresses}
                    debounce={2000}
                    isLoading={addressLookupResponse.pending}
                    marginRight={0}
                  >
                    Find
                  </NextButton>

                  <NextButton
                    type="button"
                    onClick={setEmptyAddress}
                    debounce={2000}
                    disabled={addressLookupResponse.pending}
                    inverted
                  >
                    Enter Address Manually
                  </NextButton>
                </VStack>
              )}
            </RenderSectionButtons>
          </InputGroup>
        </FormField>
      ) : (
        /** Show a dropdown with all available addresses */
        <>
          <Button
            onClick={removePostcodeSelection}
            leftIcon={<ArrowBackIcon />}
            background="none"
            color="black"
            alignSelf="start"
            mb="1rem"
          >
            Change my postcode
          </Button>

          <Select
            question={question}
            onSelect={handleAddressSelect}
            placeholder="Please select your address"
          >
            {addressList.addresses.map(address => {
              return (
                <option
                  value={JSON.stringify(address)}
                  key={address.line_1}
                >
                  {formatAddress(address)}
                </option>
              );
            })}
          </Select>

          <VStack pt={theme.spacing[ 5 ]}>
            <Button
              type="button"
              onClick={setEmptyAddress}
              debounce={2000}
              disabled={addressLookupResponse.pending}
              inverted
            >
              Enter Address Manually
            </Button>
          </VStack>
        </>
      )}
    </VStack>
  );
};

const renderPostcodeFormatError = (
  <Text
    fontSize={theme.fontSizes.sm}
    color={theme.colors.orange}
    m="1em 0 calc(-1*(2em + 1ch))"
    textAlign="center"
  >
    Please enter a valid UK postcode
  </Text>
);

export const AddressLookup = UnstyledAddressLookup;