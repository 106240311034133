import {
  storyblokEditable,
  SbBlokData
} from "@storyblok/react";
import { render } from "storyblok-rich-text-react-renderer";
import React from "react";

import BioLayout from "../shared/BioLayout";
import { theme } from "../theme";

  type TeamBiosBlokProps = { blok: SbBlokData & { expert_name: string, description: React.ReactNode[], hidden: boolean, expert_picture: { filename: string} } };

export const TeamBiosBlok: React.FC<TeamBiosBlokProps> = ({ blok }) =>
  !blok.hidden ? (
    <BioLayout
      name ={blok.expert_name}
      content={render(blok.description ?? "") ?? ""}
      image={blok.expert_picture ? blok.expert_picture.filename : undefined}
      bg={blok.grey ? theme.colors.gray[ 100 ] : undefined}
      py="60px"
      className="section-blok"
      {...storyblokEditable(blok)}
    />
  ) : <></>;
