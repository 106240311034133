import { HStack } from "@chakra-ui/react";
import React from "react";

import { Wrapper } from "../../shared/Wrapper";
import { Text } from "../../text";
import theme from "../../theme";
import { desktopBreakpointKey } from "../../../hooks/useIsDesktop";

export const ShowCommitmentSection: React.FC = () => (
  <Wrapper
    py={{ [ desktopBreakpointKey ]: theme.spacing[ 16 ] }}
  >
    <HStack
      alignItems="flex-start"
      spacing={{
        base: 0,
        [ desktopBreakpointKey ]: theme.spacing[ 10 ]
      }}
      color="black"
      flexWrap={{
        base: "wrap",
        [ desktopBreakpointKey ]: "nowrap"
      }}
    >
      <Text
        type="homepageHeading"
        flexBasis={{
          base: "100%",
          [ desktopBreakpointKey ]: "40%"
        }}
        fontWeight="medium"
        lineHeight="1.2"
        letterSpacing="-0.01em"
        pr="5%"
        mb={{
          base: theme.spacing[ 8 ],
          [ desktopBreakpointKey ]: 0
        }}
      >
        Start your journey to a low carbon future
      </Text>

      <Text
        type="homepageBody"
        flexBasis={{
          base: "100%",
          [ desktopBreakpointKey ]: "60%"
        }}
        lineHeight="1.5"
        letterSpacing="-0.01em"
      >
        There are small steps, and then there are giant leaps. We all need to make major changes for the planet but not all these require future technologies or radical changes to our lifestyles - we help you with impactful changes that work for you and are deliverable today.
      </Text>

    </HStack>
  </Wrapper>
);