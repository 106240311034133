import {
  Badge,
  Box,
  useDisclosure,
  VStack,
  Button,
  HStack,
  Spacer,
  Image,
  ImageProps,
  Stack,
  ButtonProps,
  Collapse,
  StackProps
} from "@chakra-ui/react";
import {
  AvailabilityStatus,
  Recommendation,
  RecommendationStatus
} from "src/api/users/footprint";
import { Text } from "src/components/text";
import { HiOutlineCheck } from "react-icons/hi";
import { AiOutlinePlus } from "react-icons/ai";
import {
  RiCheckboxCircleFill,
  RiPauseCircleFill
} from "react-icons/ri";
import { FaEnvelope } from "react-icons/fa";
import { MdOutlineDoNotDisturb } from "react-icons/md";
import theme from "src/components/theme";
import { sentenceCase } from "src/helpers/string";
import { ReactComponent as ChevronDown } from "src/assets/icons/chevron-down.svg";
import { ReactComponent as ChevronUp } from "src/assets/icons/chevron-up.svg";
import { motion } from "framer-motion";
import { useHistory } from "react-router";
import { useState } from "react";
import { useUsersApi } from "src/api/users/users.api";

import {
  formatRecommendationName,
  isAvailableForInterest
} from "src/helpers";

import {
  getGradientByIndex,
  gradientBackgrounds
} from "./gradients";

const AnimatedVStack = motion<Omit<StackProps, "transition">>(VStack);

const WholeHomeSurveyTile = ({ index, recommendations }: {index: number; recommendations: Recommendation[]}): JSX.Element => {
  const history = useHistory();
  const [ isToggled, setIsToggled ] = useState(false);
  const { onToggle, isOpen } = useDisclosure();
  const description = "If you are thinking about a major home improvement or retrofit project then some options not covered separately below might be possible for you. We can discuss options and help create a holistic set of measures in this case. ";
  const available = true;
  const IconComponent = isToggled ? HiOutlineCheck : AiOutlinePlus;
  const [ , callbackRequest ] = useUsersApi("REQUEST_CALL");

  return (
    <AnimatedVStack
      pos="relative"
      flex={1}
      w="100%"
      bgColor={theme.colors.gray[ 100 ]}
      alignItems="stretch"
      spacing={0}
      {...animatedProps(index)}
    >
      {/* Gradient background  */}

      <Image
        src={index != null ? getGradientByIndex(index) ?? undefined : gradientBackgrounds[ gradientBackgrounds.length - 1 ]}
        aria-hidden
        loading="lazy"
        {...bgGradientStyles}
      />

      {/* Inner Content */}

      <Stack
        zIndex={2}
        flexDirection={{
          base: "column",
          lg: "row-reverse"
        }}
      >
        {/* Optional image for Whole House Survey */}

        <Box
          padding={{ lg: "2rem" }}
          flexBasis={{ lg: "100%" }}
        >
          <Image
            src="https://genous-public-assets.s3.eu-west-2.amazonaws.com/public/whole-home.jpg"
            w="100%"
            h="100%"
            objectFit="cover"
          />
        </Box>

        {/* Contents */}

        <VStack
          alignItems="start"
          w="100%"
          p={{
            base: "1.5rem",
            md: "2rem"
          }}
          pos="relative"
          spacing={4}
        >

          <Text
            fontSize={theme.fontSizes.lg}
            fontWeight={500}
          >
            Whole home improvement
          </Text>

          <Text>We'll need a bit more information about your home and lifestyle to suggest the best options for you. Get in touch with a Genous Agent and we'll be able to help.</Text>

          {/**
               * DESCRIPTION
              */}

          <>
            <HStack
              onClick={onToggle}
              cursor="pointer"
            >
              <Text
                color={theme.colors.gray[ 500 ]}
              >More about this recommendation
              </Text>

              {isOpen ? (
                <ChevronUp
                  width="1rem"
                  fill={theme.colors.gray[ 500 ]}
                />
              ) : (
                <ChevronDown
                  width="1rem"
                  fill={theme.colors.gray[ 500 ]}
                />
              )}
            </HStack>

            <Collapse
              in={isOpen}
              animateOpacity
            >
              <Text color={theme.colors.gray[ 500 ]}>
                {description}
              </Text>
            </Collapse>
          </>

          {/* just a nudge of space */}

          <Spacer />

          {/* DONE BADGE */}

          {/* ON HOLD BADGE */}

          {available && (
            <Button
              onClick={() => {
                callbackRequest({
                  useEmail: true,
                  usePhone: false,
                  actions: recommendations.filter(r => r.interested).map(r => r.recommendation.name)
                });

                setTimeout(() => {
                  history.push("/next-steps");
                }, 50);
              }}
              {...ctaButtonStyles}
              leftIcon={(
                <HStack
                  color={isToggled ? "black" : theme.colors.green[ 100 ]}
                  background={isToggled ? theme.colors.green[ 100 ] : "black"}
                  height="3em"
                  width="3em"
                  justifyContent="center"
                  alignItems="center"
                  boxShadow="inset -2px 0 0 black"
                >
                  <IconComponent
                    fontSize="1.33em"
                  />
                </HStack>
              )}
            >
              <Text
                px="1em"
                flex="1"
                fontWeight={isToggled ? "medium" : undefined}
              >
                Advise Me / Get a Survey
              </Text>
            </Button>
          )}

        </VStack>

      </Stack>
    </AnimatedVStack>
  );
};

const bgGradientStyles: ImageProps = {
  objectFit: "cover",
  position: "absolute",
  left: "0",
  right: "0",
  top: "0",
  bottom: "0",
  width: "100%",
  height: "100%",
  zIndex: 1,
  bgColor: theme.colors.gray[ 100 ],
  opacity: 0.5
};

const ctaButtonStyles: ButtonProps = {
  variant: "outline",
  fontWeight: "inherit",
  borderRadius: "0",
  padding: "0",
  alignItems: "center",
  overflow: "hidden",
  iconSpacing: "0",
  w: "100%",
  maxW: {
    base: "100%",
    sm: "400px"
  },
  justifyContent: "flex-start",
  border: "2px solid",
  borderColor: "black",
  height: "3em"
};

const animatedProps: (index?: number) => Parameters<typeof AnimatedVStack>[0] = (index?: number) => ({
  initial: {
    opacity: 0,
    y: 20
  },
  whileInView: {
    opacity: 1,
    y: 0
  },
  viewport: {
    amount: .2,
    once: true
  },
  transition: {
    type: "spring",
    mass: 0.5,
    stiffness: 60,
    delay: index ? index * 0.05 : 0
  }
});

export default WholeHomeSurveyTile;