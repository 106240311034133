import {
  VStack,
  HStack,
  Spinner,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Box
} from "@chakra-ui/react";
import { Text } from "src/components/text";
import theme from "src/components/theme";
import { SavingsSectionProps } from "src/pages/footprint/sections/SavingsSection";
import { ReactComponent as QuestionSvg } from "src/assets/icons/question-mark-circle.svg";
import { useInView } from "react-intersection-observer";
import CountUp from "react-countup";
import ProgressCircle from "src/components/progress-circle/ProgressCircle";
import { formatCurrency } from "src/helpers/number";

const SavingsData = ({
  sapGrade = "N/A",
  potentialSapGrade = "N/A",
  potentialSavings = 0,
  potentialMoneySavings = 0,
  footprint = 0,
  isLoading
}: SavingsSectionProps): JSX.Element => {
  const { ref: inViewRef, inView } = useInView({ triggerOnce: true });

  return (
    <VStack
      bg="white"
      borderRadius={theme.radius.lg}
      padding={[ "2rem 1rem" ]}
    >
      <HStack
        justifyContent="center"
        mb={theme.spacing[ 3 ]}
      >
        <Text
          fontWeight="bold"
          size="xl"
        >
          Your household could annually save up to
        </Text>
      </HStack>

      {/* Large number */}

      <HStack
        display="inline-flex"
        justifyContent="center"
        position="relative"
      >

        <Text
          lineHeight="1"
          fontWeight="bold"
          color={theme.colors.green[ 100 ]}
          data-testid="savings-data-large-number"
        >
          {isLoading ? (
            <Spinner
              height="43px"
              width="43px"
              m="9px"
              thickness="3px"
              color={theme.colors.gray[ 400 ]}
              as="span"
            />
          ) : formatCurrency(Number(potentialMoneySavings.toFixed(0)))
          // increase font-size for numbers before decimal point if present
            .split("")
            .map((char, i, arr) => {
              const decimalPoint = arr.indexOf(".");
              const hasDecimal = decimalPoint !== -1;
              const isBeforeDecimal = hasDecimal && i < decimalPoint;

              return (
                <Text
                  key={i.toString()}
                  as="span"
                  fontSize={!hasDecimal || isBeforeDecimal ? theme.fontSizes.xxxl : theme.fontSizes.xxl}
                >
                  {char}
                </Text>
              );
            })}
        </Text>

      </HStack>

      <HStack>

        <Text
          lineHeight="1"
          fontWeight="bold"
        >
          and
        </Text>

      </HStack>

      <HStack
        display="inline-flex"
        justifyContent="center"
        position="relative"
      >
        <Text
          lineHeight="1"
          fontWeight="bold"
          color={theme.colors.green[ 100 ]}
          data-testid="savings-data-large-number"
        >
          {isLoading ? (
            <Spinner
              height="43px"
              width="43px"
              m="9px"
              thickness="3px"
              color={theme.colors.gray[ 400 ]}
              as="span"
            />
          ) : potentialSavings
            .toFixed(1)
            .replace(/\.?0$/g, "") // remove ending '.0' if present
          // increase font-size for numbers before decimal point if present
            .split("")
            .map((char, i, arr) => {
              const decimalPoint = arr.indexOf(".");
              const hasDecimal = decimalPoint !== -1;
              const isBeforeDecimal = hasDecimal && i < decimalPoint;

              return (
                <Text
                  key={i.toString()}
                  as="span"
                  fontSize={!hasDecimal || isBeforeDecimal ? theme.fontSizes.xxxl : theme.fontSizes.xxl}
                >
                  {char}
                </Text>
              );
            })}
        </Text>

        <Popover
          placement="right"
        >
          <PopoverTrigger>
            <QuestionSvg
              width="1em"
              height="1em"
              fill={theme.colors.gray[ 400 ]}
              cursor="pointer"
              style={{
                position: "absolute",
                top: "0",
                right: "-2ch"
              }}
            />
          </PopoverTrigger>

          <PopoverContent
            maxH="90vh"
            padding="2"
            overflowY="auto"
            overflowX="hidden"
          >
            <PopoverBody>
              We have calculated the savings that should be achievable from implementing particular measures (as shown below) against your household's emissions in carbon dioxide equivalent tonnes (includes other greenhouse gases on a like-for-like basis). Our FAQs provide more detail on our methodology.
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </HStack>

      <Text color={theme.colors.green[ 100 ]}>
        tonnes CO
        <sub>
          2
        </sub>
        e
      </Text>

      <ProgressCircle
        value={potentialSavings}
        max={footprint}
        maxW="350px"
      >
        <Box
        // for some reason an extra Box wrapper was needed for inView detection
        // to work
          ref={inViewRef}
        >
          <Text
            color={theme.colors.gray[ 500 ]}
            fontSize={theme.fontSizes.xs}
            textAlign="center"
          >
            <span>
              Estimated household
              <br />
              carbon output
            </span>

            <br />

            <Text
              color="black"
              fontSize="xl"
              fontWeight="bold"
              as="span"
              display='block'
              mt="3"
              lineHeight="0.9"
            >
              {isLoading ? "--" : (
                <CountUp
                  end={inView ? footprint : 0}
                  decimals={1}
                  separator=","
                  duration={1}
                />
              )}
            </Text>

            <Text
              as="span"
              color="black"
              fontWeight="medium"
            >
              tonnes CO
              <sub>
                2
              </sub>
              e
            </Text>
          </Text>
        </Box>
      </ProgressCircle>

      <HStack flexDirection="column">
        <Text
          fontWeight="semibold"
          textColor={theme.colors.gray[ 800 ]}
        >Your Current EPC
        </Text>

        <Box>
          <Text
            fontWeight="bold"
            fontSize='xl'
            textColor={theme.colors.green[ 100 ]}
          >{sapGrade}
          </Text>
        </Box>
      </HStack>

      <HStack flexDirection="column">
        <Text
          fontWeight="semibold"
          textColor={theme.colors.gray[ 800 ]}
        >Your potential EPC rating
        </Text>

        <Box>
          <Text
            fontWeight="bold"
            fontSize='xl'
            textColor={theme.colors.green[ 100 ]}
          >{potentialSapGrade}
          </Text>
        </Box>
      </HStack>
    </VStack>
  );
};

export default SavingsData;