import {
  AddIcon,
  MinusIcon
} from "@chakra-ui/icons";
import {
  HStack,
  Spacer,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  BoxProps,
  Image,
  ImageProps
} from "@chakra-ui/react";

import { Wrapper } from "./Wrapper";
import { Text } from "../text";
import theme from "../theme";
import { kebabCase } from "../../helpers/string";

type ContentType = string | React.ReactNode;
export type ContentBody = string | ContentType[] | Record<string, ContentType | ContentType[]>;

type ColumnLayoutProps = { title: string, content: ContentBody, isLinkable?: boolean };

export const ColumnLayout = ({
  title, content, isLinkable, ...restProps
}: ColumnLayoutProps & BoxProps): JSX.Element => {
  return (
    <Box
      {...restProps}
      width="100%"
      sx={{ scrollMargin: "10vmin" }}
      {...(isLinkable ? { id: kebabCase(title) } : {})}
    >
      <Wrapper
        flex="0"
        width="100%"
      >
        <HStack
          sx={firefoxBaselineFix}
          alignItems={Array.isArray(content) && typeof content[ 0 ] !== "string" ? "flex-start" : "baseline"}
          flexWrap="wrap"
          spacing="0"
          lineHeight="1.75"
        >
          <LeftColumn>
            <Text
              type="heading"
              fontWeight="medium"
            >
              {title}
            </Text>
          </LeftColumn>

          <RightColumn
            type="body"
            color={theme.colors.gray[ 700 ]}
            sx={{
              "[aria-role]": { color: theme.colors.gray[ 400 ] },
              "a[href]": { color: theme.colors.blue[ 500 ] }
            }}
          >
            {Array.isArray(content) ? <>{joinAsParagraphs(content)}</> : typeof content === "object" ? (
              <Accordion allowToggle>
                {Object.entries(content).map(([ key, value ], index) => (
                  <AccordionItem key={index.toString()}>
                    {({ isExpanded }) => (
                      <>
                        <AccordionButton
                          textAlign="left"
                          fontSize={`max(${theme.fontSizes.sm}, 0.8em)`}
                        >
                          {key}

                          <Spacer />

                          {isExpanded ? (
                            <MinusIcon
                              fontSize='12px'
                              color={theme.colors.green[ 200 ]}
                            />
                          ) : (
                            <AddIcon
                              fontSize='12px'
                              color={theme.colors.green[ 200 ]}
                            />
                          )}
                        </AccordionButton>

                        <AccordionPanel fontSize={`max(${theme.fontSizes.sm}, 0.8em)`}>
                          {Array.isArray(value) ? joinAsParagraphs(value) : value}
                        </AccordionPanel>
                      </>
                    )}
                  </AccordionItem>
                ))}
              </Accordion>
            ) : (
              <Text>{content}</Text>
            )}
          </RightColumn>
        </HStack>
      </Wrapper>
    </Box>
  );
};

const LeftColumn: typeof Box = props => (
  <Box
    wordBreak="break-word"
    overflowWrap="break-word"
    maxWidth="100%"
    hyphens="auto"
    flexBasis={{
      base: "100%",
      sm: "100%",
      md: "30%",
      lg: "40%"
    }}
    mb="1em"
    pr={{
      base: "2em",
      lg: "3em"
    }}
    _empty={{
      m: "0 !important",
      p: "0 !important"
    }}
    {...props}
  />
);

const RightColumn: typeof Box = props => (
  <Box
    flex={1}
    {...props}
  />
);

const joinAsParagraphs = (strings: Array<string|ContentType>) => typeof strings[ 0 ] === "string" ? strings.map((str, i) => (<p key={i.toString()}>{str}</p>)) : strings;

export const renderSubtitle = (str: string): JSX.Element => (
  <span
    aria-role="heading"
    key={kebabCase(str)}
  >
    {str}
  </span>
);

export const renderImage = (image: string, alt: string, props?: ImageProps): JSX.Element => (
  <Image
    src={image}
    key={image}
    loading="lazy"
    alt={alt}
    width="100%"
    maxHeight="min(488px, 50vh)"
    objectFit="cover"
    objectPosition="center center"
    my={{
      base: "1em 0 0.5em",
      md: "2em 0 1em"
    }}
    {...props}
  />
);

export const columnSpacing = {
  base: theme.spacing[ 10 ],
  sm: theme.spacing[ 12 ],
  md: theme.spacing[ 20 ],
  lg: theme.spacing[ 24 ],
  xl: theme.spacing[ 32 ]
};

ColumnLayout.Spacer = () => (
  <Spacer
    pt={columnSpacing}
    flex="0"
  />
);

// just adds some breathing-room before footer
export const extraPaddingLast: BoxProps = {
  _last: {
    "::after": {
      content: "''",
      display: "block",
      width: "1px",
      height: columnSpacing
    }
  }
};

const firefoxBaselineFix: BoxProps["sx"] = {
  /* Fixing baseline bug in Firefox */
  "@-moz-document url-prefix()": { alignItems: "flex-start" }
};