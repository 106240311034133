import {
  Action,
  ActionTypes
} from "../actionTypes";
import {
  initialState,
  State
} from "../state";

export const sectionGroupReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case ActionTypes.UPDATE_SECTION_GROUPS: {
      const sectionGroups = action.payload;

      return {
        ...state,
        sectionGroups
      };
    }
    case ActionTypes.UPDATE_CURRENT_SECTION_GROUP: {
      const newSectionGroupIndex = action.payload;

      return {
        ...state,
        currentSectionGroupIndex: newSectionGroupIndex
      };
    }
    default: return state;
  }
};