import { Box } from "@chakra-ui/react";
import { FullSpace } from "src/components/shared/FullSpace";
import theme from "src/components/theme";
import { desktopBreakpointKey } from "src/hooks/useIsDesktop";
import { CaseStudyCard } from "src/components/case-studies";
import styled from "styled-components";
import {
  Stories,
  useStoryblokApi
} from "@storyblok/react";
import {
  useEffect,
  useState
} from "react";
import { LoadingPage } from "src/components/shared";
import { isStage } from "src/helpers/stage";

  type CaseStudyProps = { blok: SbBlokData & { title: string, description: React.ReactNode[], hidden: boolean, featured_image: { filename: string} } };

const Grid = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: center;
  padding: ${theme.spacing[ 8 ]} ${theme.spacing[ 16 ]};
`;

export const CaseStudiesBlok: React.FC<CaseStudyProps> = ({ blok }) => {
  const fallbackImage = "https://genous-public-assets.s3.eu-west-2.amazonaws.com/public/case-studies/electric-vehicle.jpg";
  const sbApi = useStoryblokApi();
  const [ caseStudies, setCaseStudies ] = useState<Stories>();

  useEffect(() => {
    async function fetchData() {
      const fetchedCaseStudies = await sbApi.getStories({
        version: isStage("production") ? "published" : "draft",
        starts_with: "case-studies/",
        per_page: 100
      });

      setCaseStudies(fetchedCaseStudies);
    }

    fetchData();
  }, []);

  if (!caseStudies) {
    return (
      <LoadingPage />
    );
  }

  return !blok.hidden ? (
    <FullSpace>

      <Grid
        p={{
          base: theme.spacing[ 10 ],
          [ desktopBreakpointKey ]: theme.spacing[ 16 ]
        }}
      >
        {caseStudies.data.stories.map((caseStudy, index) => {
          const previewImage = caseStudy.content.previewImage?.length ? caseStudy.content.previewImage[ 0 ] as { filename: string, alt: string } : undefined;

          return (
            <CaseStudyCard
              key={index.toString()}
              title={caseStudy.content.title}
              text={caseStudy.content.description}
              imgSrc={previewImage?.filename || fallbackImage}
              imgAlt={previewImage?.alt || "Image related to the case study"}
              to={`/${caseStudy.full_slug}`}
              tags={caseStudy.tag_list}
            />
          );
        })}
      </Grid>

    </FullSpace>
  ) : <></>;
};
