import { TextProps } from "@chakra-ui/react";

import { Text } from "../text";

/**
 * Sometimes with text-wrapping its desirable for a string of words appear
 * on the same line, or to avoid a 'one word on a line' scenario
 * @example
 * // OH NO
 * big
 * apple
 * // WINNING
 * big apple
 */

export const NoWrap: React.FC<TextProps> = ({ children, ...props }) => (
  <Text
    whiteSpace='nowrap'
    as="span"
    {...props}
  >{children}
  </Text>
);