import {
  createContext,
  useCallback,
  useEffect,
  useState
} from "react";
import { useHistory } from "react-router";
import { useUtmParameters } from "src/hooks/useUtmParameters";

import { useUsersApi } from "../api/users/users.api";
import { useAuthContext } from "../hooks/useAuthContext";

export interface CurrentUserState {
  user: User | null;
  loading: boolean;
  propertyId?: number;
}

export interface CurrentUserActions {
  refetchUser: () => void;
}

const initialUserContext: CurrentUserState = {
  user: null,
  loading: false,
  propertyId: undefined
};

const initialUserActions: CurrentUserActions = { refetchUser: () => null };

export type CurrentUserContextHook = [CurrentUserState, CurrentUserActions];

export const CurrentUserContext = createContext<CurrentUserContextHook>([ initialUserContext, initialUserActions ]);

export const CurrentUserProvider: React.FC = ({ children }) => {
  const { authDataState } = useAuthContext();
  const [ getCurrentUserResponse, getCurrentUserRequest ] = useUsersApi("GET_ME");
  const [ userState, setUserState ] = useState(initialUserContext);
  const history = useHistory();
  const [ utm ] = useUtmParameters();

  const refetchUser = useCallback(() => {
    getCurrentUserRequest({});
  }, [ getCurrentUserRequest ]);

  useEffect(() => {
    if (authDataState.authenticated) {
      // fetch current user info on login
      getCurrentUserRequest(utm);
    } else {
      // clear current user on logout
      setUserState(initialUserContext);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    authDataState.authenticated,
    authDataState.authenticating,
    getCurrentUserRequest,
    history
  ]);

  // update current user on successful response
  useEffect(() => {
    if (getCurrentUserResponse.data && getCurrentUserResponse.data.data) {
      setUserState({
        loading: getCurrentUserResponse.pending,
        user: getCurrentUserResponse.data.data.currentUser,
        propertyId: getCurrentUserResponse.data.data.propertyId
      });
    }
  }, [ getCurrentUserResponse ]);

  return (
    <CurrentUserContext.Provider
      value={[ userState, { refetchUser } ]}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};
