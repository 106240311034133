import { Box } from "@chakra-ui/react";
import { FullSpace } from "src/components/shared/FullSpace";
import theme from "src/components/theme";
import { desktopBreakpointKey } from "src/hooks/useIsDesktop";
import styled from "styled-components";
import {
  Stories,
  useStoryblokApi,
  storyblokEditable
} from "@storyblok/react";
import {
  useEffect,
  useState
} from "react";
import { LoadingPage } from "src/components/shared";
import { isStage } from "src/helpers/stage";
import { render } from "storyblok-rich-text-react-renderer";

import CredentialsLayout from "../shared/CredentialsLayout";

  type CredentialsProps = { blok: SbBlokData & { title: string, description: React.ReactNode[], hidden: boolean, featured_image: { filename: string} } };

const Grid = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: center;
  padding: ${theme.spacing[ 8 ]} ${theme.spacing[ 16 ]};
`;

export const CredentialsBlok: React.FC<CredentialsProps> = ({ blok }) => {
  const fallbackImage = "https://genous-public-assets.s3.eu-west-2.amazonaws.com/public/case-studies/electric-vehicle.jpg";
  const sbApi = useStoryblokApi();
  const [ credentials, setCredentials ] = useState<Stories>();

  useEffect(() => {
    async function fetchData() {
      const fetchedCredentials = await sbApi.getStories({
        version: isStage("production") ? "published" : "draft",
        starts_with: "credentials",
        per_page: 100
      });

      setCredentials(fetchedCredentials);
    }

    fetchData();
  }, []);

  if (!credentials) {
    return (
      <LoadingPage />
    );
  }

  return !blok.hidden ? (
    <FullSpace>

      <Grid
        p={{
          base: theme.spacing[ 10 ],
          [ desktopBreakpointKey ]: theme.spacing[ 16 ]
        }}
      >
        {credentials.data.stories.map((caseStudy, index) => {
          const previewImage = caseStudy.content.previewImage?.length ? caseStudy.content.previewImage[ 0 ] as { filename: string, alt: string } : undefined;

          return (
            <CredentialsLayout
              key={index.toString()}
              name ={caseStudy.content.title}
              content={caseStudy.content.description}
              image={previewImage?.filename || fallbackImage}
              bg={blok.grey ? theme.colors.gray[ 100 ] : undefined}
              py="60px"
              className="section-blok"
              {...storyblokEditable(blok)}
            />
          );
        })}
      </Grid>

    </FullSpace>
  ) : <></>;
};

// export const CredentialsBlok: React.FC<CredentialsProps> = ({ blok }) =>
//   !blok.hidden ? (
//     <BioLayout
//       name ={blok.title}
//       content={render(blok.description ?? "") ?? ""}
//       image={blok.previewImage ? blok.previewImage.filename : undefined}
//       py={theme.spacing[ 20 ]}
//       className="section-blok"
//       {...storyblokEditable(blok)}
//     />
//   ) : <></>;
