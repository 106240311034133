import {
  Box,
  Link,
  VStack,
  Stack,
  Spacer,
  Divider,
  StackProps,
  HStack,
  Image,
  Flex
} from "@chakra-ui/react";
import { Link as RouteLink } from "react-router-dom";
import {
  FaFacebook,
  FaLinkedin
} from "react-icons/fa";

import { Text } from "../text";
import { Logo } from "../shared/Logo";
import { Wrapper } from "../shared/Wrapper";
import theme from "../theme";
import { routes } from "../../constants";
import { useBreakpointKeyValue } from "../../hooks/useBreakpointKeyValue";
import { breakpoints } from "../theme/breakpoints";
import RECC from "../../assets/images/third-party/recc-tsi.png";
import MCS from "../../assets/images/third-party/mcs.png";
import TM from "../../assets/images/third-party/tm.jpg";
import IWA from "../../assets/images/third-party/IWA_APPROVED.png";
import IAA from "../../assets/images/third-party/iaa-color-strap.jpg";

const bodyTextColor = theme.colors.gray[ "500" ];
const wideBreakpoint = "md";

export const Footer: React.FC = () => {
  const [ _, value = "0" ] = useBreakpointKeyValue();
  const isWide = parseInt(value) >= parseInt(breakpoints[ wideBreakpoint ]);

  return (
    <Box
      as="footer"
      {...footerStyleProps}
    >
      <Wrapper>

        <Stack {...FooterStackStyleProps}>

          <VStack alignItems="flex-start">

            <Link href="/">
              <Logo white />
            </Link>

            <HStack>
              <Link
                href="https://www.facebook.com/people/Genous/100083233837622"
                target="_blank"
              >
                <FaFacebook size={20} />
              </Link>

              <Link
                href="https://linkedin.com/company/genous"
                target="_blank"
              >
                <FaLinkedin size={20} />
              </Link>
            </HStack>

          </VStack>

          <VStack
            alignItems="flex-start"
            w="100%"
          >

            <Link
              as={RouteLink}
              to={routes.aboutGenous}
            >
              About Us
            </Link>

            <Link
              as={RouteLink}
              to={routes.insightsNewsHub}
            >
              Insights
            </Link>

            {/* <Link
              as={RouteLink}
              to={routes.usefulInformation}
            >
              Useful Information
            </Link> */}

            <Link
              as={RouteLink}
              to={routes.faqs}
            >
              FAQs
            </Link>

            <Link
              as={RouteLink}
              to={routes.contact}
            >
              Contact us
            </Link>

            <Link
              as={RouteLink}
              to={routes.privacyPolicy}
            >
              Privacy policy
            </Link>

            <Link
              as={RouteLink}
              to={routes.termsAndConditions}
            >
              Terms &amp; conditions
            </Link>
          </VStack>

          <VStack
            alignItems="flex-start"
            w="100%"
            display={{
              base: "none",
              md: "block"
            }}
          >
            <Image
              src={MCS}
              alt='MCS'
              width={100}
              height={100}
              display={{
                base: "none",
                md: "block"
              }}
            />

            <Image
              src={TM}
              alt='TrustMark'
              width={100}
              height={100}
            />

          </VStack>

          <Image
            src={RECC}
            alt='RECC & TSI'
            display={{
              base: "none",
              md: "block"
            }}
          />

          <VStack
            display={{
              base: "none",
              md: "block"
            }}
          >
            <Image
              src={IWA}
              alt='IWA'

            />

            <Image
              src={IAA}
              alt='IAA'

            />
          </VStack>

          <Flex
            flexDir="column"
            gap={4}
            display={{
              base: "flex",
              md: "none"
            }}
          >
            <img
              src={RECC}
              alt='RECC & TSI'
            />

            <Flex
              flexWrap="wrap"
              gap={4}
              justifyContent="center"
              alignItems="center"

            >
              <img
                src={MCS}
                alt='MCS'
                width={100}
                height={100}
              />

              <img
                src={TM}
                alt='TrustMark'
                width={100}
                height={100}
              />

              <img
                src={IWA}
                alt='IWA'
                width={100}
                height={100}
              />

              <img
                src={IAA}
                alt='IAA'
                width={250}
                height={250}
              />
            </Flex>
          </Flex>

          <Flex />

          {isWide && <Spacer />}

        </Stack>

        <VStack spacing={theme.spacing[ 4 ]}>
          <Divider
            borderColor={bodyTextColor}
            opacity="0.2"
          />

          <Text
            as="small"
            fontSize="xs"
          >
            &copy; {new Date().getFullYear()}

            {" "}

            <Link
              as={RouteLink}
              to={routes.about}
            >Genous Limited
            </Link>

          </Text>
        </VStack>

      </Wrapper>
    </Box>
  );
};

const footerStyleProps: StackProps = {
  flex: 0,
  bg: theme.colors.brown,
  py: theme.spacing[ 4 ],
  _empty: { display: "none" },
  color: bodyTextColor,
  fontSize: {
    base: theme.fontSizes.sm,
    md: theme.fontSizes.base
  }
};

const FooterStackStyleProps: StackProps = {
  py: {
    "base": theme.spacing[ 8 ],
    "md": theme.spacing[ 12 ],
    "2xl": theme.spacing[ 16 ]
  },
  direction: {
    base: "column",
    [ wideBreakpoint ]: "row"
  },
  justifyContent: "flex-start",
  alignItems: "flex-start",
  spacing: {
    base: theme.spacing[ 8 ],
    md: theme.spacing[ 16 ]
  }
};