import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js/auto";
import { Bar } from "react-chartjs-2";
import { incrementalNumber } from "@ngneat/falso";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: { legend: { position: "bottom" as const } }
};

const labels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export const data = {
  labels,
  datasets: [
    {
      label: "Self-consumption",

      data: [
        80,
        63,
        46,
        37,
        33,
        31,
        30,
        34,
        40,
        49,
        64,
        86
      ],
      backgroundColor: "#156082"
    },
    {
      label: "Grid independence",
      data: [
        15,
        21,
        45,
        52,
        53,
        53,
        53,
        53,
        53,
        40,
        35,
        34
      ],
      backgroundColor: "#E97132"
    }
  ]
};

const PostRetrofitSelfUseGenous = () => {
  return (
    <Bar
      options={options}
      data={data}
    />
  );
};

export default PostRetrofitSelfUseGenous;