const carRearImg = "https://genous-public-assets.s3.eu-west-2.amazonaws.com/public/reduce-your-carbon/car_rear.jpg";
const sofaCoupleImg = "https://genous-public-assets.s3.eu-west-2.amazonaws.com/public/reduce-your-carbon/sofa_couple.jpg";
const solarRoofImg = "https://genous-public-assets.s3.eu-west-2.amazonaws.com/public/solar-panel-min.jpeg";
const windFarmImg = "https://genous-public-assets.s3.eu-west-2.amazonaws.com/public/reduce-your-carbon/wind_farm.jpg";
const treeBranchesImg = "https://genous-public-assets.s3.eu-west-2.amazonaws.com/public/reduce-your-carbon/tree_branches.jpg";

export enum TopicTitles {
	HomeEfficiency = "Home Efficiency",
  RenewableHeating = "Renewable Heating",
  HomeInsulation = "Home Insulation",
  HeatingControls = "Heating Controls",
	SolarPanels = "Solar Panels",
  ElectricVehicles = "Electric Vehicles",
	GreenEnergy = "Green Energy",
	Offsets = "Carbon Offsets",
  OtherHomeChanges = "Other Home Improvements"
}

export const topicPreviewContent = {
  [ TopicTitles.HomeInsulation ]: {
    text: "Warmer in the winter, cooler in the summer: get lower costs and more comfort by insulating walls, lofts and floors.",
    imgSrc: "https://genous-public-assets.s3.eu-west-2.amazonaws.com/public/insulation-min.jpeg",
    imgAlt: ""
  },

  [ TopicTitles.SolarPanels ]: {
    text: "Generate your own free, completely renewable electricity.",
    imgSrc: solarRoofImg,
    imgAlt: "House roof on sunny day, featuring some solar panels"
  },

  [ TopicTitles.RenewableHeating ]: {
    text: "Go renewable or make your home renewable-ready – the biggest carbon saving most people can make.",
    imgSrc: "https://genous-public-assets.s3.eu-west-2.amazonaws.com/public/heat-pump-min.jpeg",
    imgAlt: "House roof on sunny day, featuring some solar panels"
  },

  [ TopicTitles.HeatingControls ]: {
    text: "The latest smart heating control systems can reduce bills and increase flexibility, so you only heat when you need to. ",
    imgSrc: "https://genous-public-assets.s3.eu-west-2.amazonaws.com/public/heating-controls-min.jpg",
    imgAlt: ""
  },

  [ TopicTitles.OtherHomeChanges ]: {
    text: "Explore other changes you can make.",
    imgSrc: sofaCoupleImg,
    imgAlt: ""
  },

  [ TopicTitles.ElectricVehicles ]: {
    text: "Electric vehicles are now actually both cheaper and better for many people.",
    imgSrc: carRearImg,
    imgAlt: "View of a white, modern car from its rear light"
  },

  [ TopicTitles.GreenEnergy ]: {
    text: "Not all green tariffs are 100% renewable. Switch to one that is.",
    imgSrc: windFarmImg,
    imgAlt: "A wind farm featuring many wind turbines, on an overcast day"
  },

  [ TopicTitles.Offsets ]: {
    text: "High-quality offsets which address the carbon impact that you can’t reduce in other ways.",
    imgSrc: treeBranchesImg,
    imgAlt: "Thin ends of a tree branch, displaying autumn colors"
  }
};