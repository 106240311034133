import {
  storyblokEditable,
  SbBlokData
} from "@storyblok/react";
import { render } from "storyblok-rich-text-react-renderer";

import {
  CtaSection,
  CtaSectionProps
} from "../landing/sections/CtaSection";

export const CtaBlok: React.FC<{blok: SbBlokData & CtaSectionProps & { hidden: boolean }}> = ({ blok }) =>
  !blok.hidden ? (
    <CtaSection
      heading={blok.heading}
      content={render(blok.content ?? "") ?? ""}
      cta={blok.cta}
      route={blok.route}
      className="section-blok"
      {...storyblokEditable(blok)}
    />
  ) : <></>;
