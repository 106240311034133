import {
  Box,
  Text,
  Input,
  HStack,
  VStack,
  Select
} from "@chakra-ui/react";
import {
  useEffect,
  useState
} from "react";

const Options = [
  {
    label: "Small (less than 60 sqm/ 600 sqft - likely studio/small 1-bed)",
    value: "Small"
  },
  {
    label: "Medium (60-120 sqm/ 600-1200 sqft - likely 1-3 beds)",
    value: "Medium"
  },
  {
    label: "Large (120-200 sqm/ 1200-2000 sq ft - likely 4-5 beds)",
    value: "Large"
  },
  {
    label: "Very large (over 200 sqm/ 2000 sq ft - likely 5 beds+)",
    value: "Very large"
  }
];

interface Props {
    questionId: string;
    setValue: any;
}

enum UnitOfMeasure {
    "METER",
    "FEET"
}

export const PropertySize: React.FC<Props> = ({ questionId, setValue }) => {
  const [ estimatedSize, setEstimatedSize ] = useState(Options[ 0 ].value);
  const [ unitOfMeasurement, setUnitOfMeasurement ] = useState(UnitOfMeasure.METER);

  useEffect(() => {
    setValue(questionId, estimatedSize);
  }, [
    estimatedSize,
    questionId,
    setValue
  ]);

  const onChangePropertySize = (val: string) => {
    if (!val) {
      setValue(questionId, estimatedSize);
    }

    const valNum = Number(val);

    if (valNum <= 10) {
      setValue(questionId, estimatedSize);
    } else if (unitOfMeasurement === UnitOfMeasure.FEET) {
      const convertedToMeter = valNum / 10.7639;

      setValue(questionId, convertedToMeter);
    } else {
      setValue(questionId, valNum);
    }
  };

  return (
    <VStack spacing={10}>

      <HStack
        width="full"
        alignContent='flex-start'
      >

        <Input
          placeholder="Example: 40"
          type="number"
          onChange={e => onChangePropertySize(e.target.value)}
        />

        <Select
          background="white"
          height={50}
          onChange={e => {
            setUnitOfMeasurement(Number(e.target.value));
          }}
        >
          <option value={UnitOfMeasure.METER}>Square Meter</option>

          <option value={UnitOfMeasure.FEET}>Square Feet</option>
        </Select>
      </HStack>

      <VStack>
        <Text>If you don't know its area, leave the above blank and pick one of the options below:</Text>

        <Select
          height={50}
          background="white"
          onChange={e => {
            setEstimatedSize(e.target.value);
          }}
        >
          <option />

          {Options.map(option => {
            return (
              <option
                value={option.value}
                key={option.value}
              >
                {option.label}
              </option>
            );
          })}
        </Select>
      </VStack>
    </VStack>
  );
};