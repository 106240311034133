import {
  Box,
  BoxProps,
  ComponentWithAs,
  HStack,
  Image,
  Wrap,
  Tag
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import theme from "src/components/theme";
import { desktopBreakpointKey } from "src/hooks/useIsDesktop";
import { Text } from "src/components/text";

type CaseStudyCardProps = {
  title: string;
  text: string;
  imgSrc: string;
  imgAlt: string;
  to: string;
  tags?: string[];
};

export const CaseStudyCard: ComponentWithAs<"div", BoxProps & CaseStudyCardProps> = ({
  title, text, imgSrc, imgAlt, to, tags, ...restProps
}) => {
  if (typeof tags === "string") {
    tags = tags.split(",");
  }

  return (
    <Box
      as={Link}
      to={to ? to : "#"}
      m={[
        theme.spacing[ 2 ],
        theme.spacing[ 3 ],
        theme.spacing[ 5 ]
      ]}
      {...caseCardStyle()}
      {...restProps}
    >
      {/* image section */}

      <Box
        sx={{ aspectRatio: { base: "1.4" } }}
        bg={theme.colors.green[ 200 ]}
        mb={{ base: theme.spacing[ 4 ] }}
        pos="relative"
      >
        <Image
          src={imgSrc}
          alt={imgAlt}
          loading="lazy"
          width="100%"
          height="100%"
          objectFit="cover"
          transition="opacity 1s ease-out"
        />
      </Box>

      {/* Tags */}

      {tags && (
        <Wrap pb={theme.spacing[ 3 ]}>
          {tags.map((tag, index) => (
            <Tag
              key={`tag_${index.toString()}`}
              textColor={theme.colors.gray[ 400 ]}
              fontWeight="normal"
              fontSize="xxs"
              size="sm"
            >
              {tag}
            </Tag>
          ))}
        </Wrap>
      )}

      {/* bottom section */}

      <HStack
        spacing={theme.spacing[ 6 ]}
      >
        <Box
          px={{ base: theme.spacing[ 1 ] }}
        >
          <Text
            color={theme.colors.gray[ 800 ]}
            fontSize={{
              base: theme.fontSizes.sm,
              md: theme.fontSizes.base,
              [ desktopBreakpointKey ]: theme.fontSizes.md
            }}
            fontWeight="medium"
            opacity="0.9"
            mb="0.33em"
          >
            {title}
          </Text>

          <Text
            color={theme.colors.gray[ 800 ]}
            fontSize={{
              "base": theme.fontSizes.xs,
              [ desktopBreakpointKey ]: theme.fontSizes.sm,
              "2xl": theme.fontSizes.base
            }}
            noOfLines={to && 4}
          >
            {text}
          </Text>

          {to && (
            <Text
              color={theme.colors.gray[ 400 ]}
              fontSize={{
                base: theme.fontSizes.xs,
                [ desktopBreakpointKey ]: theme.fontSizes.sm
              }}
              mt={theme.spacing[ 3 ]}
            >
              Read more
            </Text>
          )}

        </Box>
      </HStack>
    </Box>
  );
};

const caseCardStyle = (): BoxProps => ({
  bg: "white",
  border: `2px solid ${theme.colors.gray[ 200 ]}`,
  _hover: { borderColor: theme.colors.green[ 200 ] },
  p: {
    base: theme.spacing[ 3 ],
    md: theme.spacing[ 5 ]
  },
  width: { base: "min(400px, 75vmin)" },
  minH: { base: "min(400px, 75vmin)" }
});
