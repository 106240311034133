import {
  Icon,
  Button
} from "@chakra-ui/react";

import { ReactComponent as MenuSvg } from "../../assets/icons/menu.svg";

interface NavMenuButtonProps {
  onClick: () => void;
}

export const NavMenuButton: React.FC<NavMenuButtonProps> = ({ onClick }) => (
  <Button
    onClick={onClick}
    background="none"
    color="black"
    borderRadius="4px"
    padding={2}
    w="40px"
    _hover={{ bg: "#eee" }}
    _active={{ bg: "#aaa" }}
  >
    <Icon
      as={MenuSvg}
      width={{
        "base": "18px",
        "2xl": "20px"
      }}
      height={{
        "base": "18px",
        "2xl": "20px"
      }}
    />
  </Button>
);