import { StackProps } from "@chakra-ui/react";
import { useRef } from "react";
import theme from "src/components/theme";
import { desktopBreakpointKey } from "src/hooks/useIsDesktop";
import { Carousel } from "src/components/carousel";
import useIntersectionObserver from "src/hooks/useIntersectionObserver";

import { ImageCarouselItem } from "./ImageCarouselItem";

export type ImageCarouselProps = {
    items: Array<{
        title?: string,
        text?: string,
        url?: string
        imgSrc: string,
        imgAlt: string
    }>
};

export const ImageCarousel: React.FC<ImageCarouselProps> = ({ items }) => {
  const observedRef = useRef<HTMLDivElement>(null);
  const observed = useIntersectionObserver(observedRef, { freezeOnceVisible: true });

  return (
    <Carousel
      ref={observedRef}
      {...sectionStyleProps}
    >
      {items.map((item, index) => (
        <ImageCarouselItem
          key={index.toString()}
          title={item.title}
          text={item.text}
          url={item.url}
          imgSrc={observed?.isIntersecting ?? !window.IntersectionObserver ? item.imgSrc : ""}
          imgAlt={item.imgAlt}
        />
      ))}
    </Carousel>
  );
};

const sectionStyleProps: StackProps = {
  mt: {
    base: theme.spacing[ 12 ],
    [ desktopBreakpointKey ]: theme.spacing[ 24 ]
  },
  py: theme.spacing[ 2 ]
};