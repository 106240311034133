import Auth, { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import React, { useState } from "react";
import {
  VStack,
  Box,
  Image
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

import { routes } from "src/constants";

import { StyledChakraTextInput as Input } from "../../components/form";
import { useAuthContext } from "../../hooks/useAuthContext";
import { LoginPageProps } from ".";
import { Button } from "../../components/button";
import { CenterContent } from "../../components/shared/CenterContent";
import { Text } from "../../components/text";
import { LoadingPage } from "../../components/shared/LoadingPage";
import { Wrapper } from "../../components/shared/Wrapper";
import { FullSpace } from "../../components/shared/FullSpace";
import theme from "../../components/theme";
import googleSigninButtonLight2x from "../../assets/images/third-party/btn_google_signin_light_normal_web@2x.png";
import { Separator } from "../../components/shared/Separator";

/**
 *
 * @param param0
 * @returns
 */
export const LoginPage: React.FC<LoginPageProps> = ({ location, history }) => {
  const { authDataState, setAuthDataState } = useAuthContext();
  const [ email, setEmail ] = useState("");
  const [ password, setPassword ] = useState("");
  const [ error, setError ] = useState<string | null>(null);

  React.useEffect(() => {
    if (authDataState.authenticated) {
      // redirect to previous page, otherwise footprint
      history.push(location?.state?.from || routes.footprint);
    }
  }, [
    history,
    authDataState,
    location
  ]);

  const handleLogin = async () => {
    setAuthDataState({
      authenticated: false,
      error: null,
      authenticating: true,
      idToken: "",
      isAdmin: false
    });

    try {
      const response = await Auth.signIn(email, password);

      setAuthDataState({
        authenticated: true,
        error: null,
        authenticating: false,
        idToken: response.signInUserSession?.idToken?.jwtToken || "",
        isAdmin: false
      });
    } catch (e) {
      console.error(e);
      setError(String(e));

      setAuthDataState({
        authenticated: false,
        error: String(e),
        authenticating: false,
        idToken: "",
        isAdmin: false
      });
    }
  };

  const handleGoogleLogin = async () => {
    setAuthDataState({
      authenticated: false,
      error: null,
      authenticating: true,
      idToken: "",
      isAdmin: false
    });
    await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
    history.push("/oauth", { from: location?.state?.from || "/" });
  };

  return (
    <FullSpace
      bg={theme.colors.gray[ 100 ]}
      py={theme.spacing[ 8 ]}
    >
      <Wrapper variant="form">
        <CenterContent justifyContent="flex-start">
          {authDataState.authenticating ? (
            <LoadingPage />
          ) : (
            <Box
              as="form"
              w="100%"
            >
              <VStack
                w="100%"
              >
                <Text
                  mb={theme.spacing[ 6 ]}
                  fontSize="lg"
                  fontWeight="medium"
                >
                  <Text
                    as="b"
                    fontWeight="semibold"
                  >
                    Sign in
                  </Text>

                  {" "}
                  to your account
                </Text>

                {error && (
                  <Text color="red">
                    {error}
                  </Text>
                )}

                <Input
                  placeholder="Email address"
                  value={email}
                  type="email"
                  onChange={e => setEmail(e.target.value)}
                  isInvalid={error !== null}
                  isRequired
                />

                <Input
                  placeholder="Password"
                  value={password}
                  type="password"
                  onChange={e => setPassword(e.target.value)}
                  marginTop="1rem"
                  isInvalid={error !== null}
                  isRequired
                />

                <Text
                  as={Link}
                  to={{
                    pathname: "/login/forgot-password",
                    state: {
                      from: location?.state?.from || "/",
                      email
                    }
                  }}
                  my={`${theme.spacing[ 3 ]} !important`}
                >
                  <Text
                    as="small"
                    color={theme.colors.gray[ 500 ]}
                  >
                    Forgot password?
                  </Text>
                </Text>

                <Button
                  type="submit"
                  onClick={handleLogin}
                  disabled={!email || !password}
                  marginTop="2rem"
                >
                  Sign in
                </Button>

                {process.env.REACT_APP_SSO_DISABLED !== "true" && (
                  <>

                    <Separator
                      color={theme.colors.gray[ 300 ]}
                    >
                      <Text
                        fontSize={theme.fontSizes.xs}
                        fontWeight="semibold"
                        letterSpacing="2px"
                      >
                        OR
                      </Text>
                    </Separator>

                    <button
                      onClick={handleGoogleLogin}
                      style={{
                        display: "block",
                        textAlign: "center",
                        margin: "0 auto"
                      }}
                      aria-label="Sign in with Google"
                    >
                      <Image
                        src={googleSigninButtonLight2x}
                        width={200}
                        aria-hidden
                      />
                    </button>
                  </>
                )}

              </VStack>
            </Box>
          )}
        </CenterContent>
      </Wrapper>
    </FullSpace>
  );
};