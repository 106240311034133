import { QuestionSectionGroup } from "src/api/questions";

export interface State {
  sectionGroups: QuestionSectionGroup[];
  currentSectionGroupIndex: number | null;
  propertyId?: number;
}

export const initialState: State = {
  sectionGroups: [],
  currentSectionGroupIndex: 0
};