import {
  GoogleMap,
  useJsApiLoader,
  Marker
} from "@react-google-maps/api";
import {
  useCallback,
  useEffect,
  useState
} from "react";
import {
  FieldValues,
  UseFormRegister
} from "react-hook-form";
import { isMobile } from "react-device-detect";

import { Text } from "../text";
import { Question } from "../../api/questions";
import { LoadingPage } from "../shared";

const containerStyle = {
  borderRadius: 10,
  height: "500px",
  width: "100%",
  marginBottom: "5px",
  marginTop: "5px"
};

type UnstyledInputProps = {
  question: Question;
  key: number;
  setValue: any;
  handleSubmit: any;
  register: UseFormRegister<FieldValues>;
  setNextButtonVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  setSkipButtonVisible: any;
  getValues: () => any;
};

const Map = ({
  question, setValue, setNextButtonVisible, setSkipButtonVisible, handleSubmit
}: UnstyledInputProps) => {
  const [ coords, setCoords ] = useState(() => {
    return {
      loading: true,
      lat: null as number | null,
      lng: null as number | null
    };
  });

  useEffect(() => {
    return () => setSkipButtonVisible(false);
  }, [ setSkipButtonVisible ]);

  const skipQuestion = useCallback(() => {
    handleSubmit({ [ `${question.id}` ]: {} });
  }, [ question.id, handleSubmit ]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ""
  });

  const lookupAddress = useCallback((postcode: string) => {
    return fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${postcode}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
    )
      .then((res: any) => res.json())
      .then((data: any) => {
        if (!data.results.length) {
          alert("Nothing found with search query");
        }
        const lat = parseFloat(data.results[ 0 ].geometry.location.lat);
        const lng = parseFloat(data.results[ 0 ].geometry.location.lng);

        setCoords({
          lat,
          loading: false,
          lng
        });
      })
      .catch(skipQuestion);
  }, [ skipQuestion ]);

  useEffect(() => {
    if (coords.lat) {
      setValue(`${question.id}`, [ coords.lng, coords.lat ]);
      localStorage.setItem("latlng", JSON.stringify(coords));
    }
  }, [
    coords,
    setValue,
    question
  ]);

  useEffect(() => {
    if (setNextButtonVisible) setNextButtonVisible(true);
    // Skip if no postcode
    const postcode = localStorage.getItem("postcode");

    if (!postcode) {
      // skipQuestion();
    } else if (!coords.lat) {
      lookupAddress(postcode);
    }
  }, [
    lookupAddress,
    setNextButtonVisible,
    coords.lat,
    skipQuestion
  ]);

  const onLoad = useCallback(
    map => {
      if (coords.lat) {
        //@ts-ignore
        map.fitBounds(new window.google.maps.LatLngBounds(coords));
        map.setZoom(20);
      }
    },
    [ coords ]
  );

  return isLoaded && !coords.loading ? (
    <div>
      <GoogleMap
        mapContainerClassName="shadow"
        mapContainerStyle={containerStyle}
        options={{
          fullscreenControl: false,
          disableDefaultUI: false,
          streetViewControl: false,
          mapTypeControl: false,
          draggableCursor: "crosshair",
          maxZoom: 19,
          minZoom: 18,
          mapTypeId: "hybrid"
        }}
        center={coords}
        tilt={0}
        onLoad={onLoad}
        onClick={e => {
          setCoords({
            ...coords,
            lat: e.latLng.lat(),
            lng: e.latLng.lng()
          });
        }}
      >
        <div
          style={{
            position: "absolute",
            padding: 10,
            display: "flex",
            justifyContent: "center",
            width: "100%"
          }}
        >
          <Text
            onClick={skipQuestion}
            style={{
              background: "white",
              padding: 20,
              borderRadius: 10,
              fontSize: 11
            }}
          >
            Drop a pin on your home
          </Text>
        </div>

        <Marker
          draggable
          position={coords}
        />
      </GoogleMap>

      <div className="mt-2">
        <p>Tips:</p>

        <ul>
          <li>Use + and – to zoom in and out. Try to zoom in until the + gets greyed out.</li>

          <li>Drag - or click and drag - to move the map until your home is in view and then tap to drop the pin.</li>
        </ul>
      </div>
    </div>
  ) : (
    <LoadingPage />
  );
};

export default Map;
