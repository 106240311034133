import {
  HStack,
  VStack,
  useRadioGroup
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { useEffect } from "react";
import {
  Control,
  Controller,
  FieldValues,
  UseFormGetValues,
  UseFormSetValue
} from "react-hook-form";
import { useHistory } from "react-router";
import { useTypedSelector } from "src/redux/useTypeSelector";
import styled from "styled-components";

import {
  Text,
  Heading
} from "../text";
import { usePropertiesApi } from "../../api/properties/property.api";
import { Question } from "../../api/questions";
import { CenterContent } from "../shared/CenterContent";
import { LoadingPage } from "../shared/LoadingPage";
import { EPCGraph } from "../epc";
import theme from "../theme";
import { NoWrap } from "../shared/NoWrap";
import { RenderSectionButtons } from "../../pages/section/SectionButtons";
import { EPCPlaceholder } from "../epc/EPCPlaceholder";
import EPCRadioCard from "./EPCRadioCard";

type UnstyledEPCLookupProps = {
  question: {id: number; slug: string};
  control: Control;
  postcode?: string;
  handleSubmit: any;
  addrLine1?: string;
  setValue: UseFormSetValue<any>;
  setNextButtonVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  onData?: (vals: any) => void;
};

const UnstyledEPCLookup: React.FC<UnstyledEPCLookupProps> = ({
  question, control, postcode, addrLine1, handleSubmit, setValue, setNextButtonVisible, onData
}) => {
  const history = useHistory();
  const [ getEPCForPropertyResponse, getEPCForPropertyRequest ] = usePropertiesApi("GET_EPC_BY_POSTCODE");

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "epc",
    defaultValue: undefined
  });

  const group = getRootProps();

  useEffect(() => {
    if (postcode) {
      getEPCForPropertyRequest({
        postcode,
        addrLine1
      });
    }
  }, [
    getEPCForPropertyRequest,
    addrLine1,
    postcode
  ]);

  useEffect(() => {
    // Redirect to error page for all errors other than EPC not found
    if (getEPCForPropertyResponse.error) {
      history.push("/error", { error: getEPCForPropertyResponse.error });
    }
  }, [ getEPCForPropertyResponse.error, history ]);

  useEffect(() => {
    if (getEPCForPropertyResponse.data && getEPCForPropertyResponse.data.data.length === 0) {
      onData && onData(null);
      setNextButtonVisible?.(false);
    }
  }, [ getEPCForPropertyResponse.data, setNextButtonVisible ]);

  useEffect(() => {
    setNextButtonVisible && setNextButtonVisible(true);
  }, [ setNextButtonVisible ]);

  if (getEPCForPropertyResponse.pending) return (
    <LoadingPage />
  );

  // If data is empty array then no EPCs found
  if (getEPCForPropertyResponse.data && getEPCForPropertyResponse.data.data.length === 0) {
    // Set the question value to null so the backend knows that no EPC has been found for the property
    setValue(`${question.id.toString()}.${question.slug}`, null);

    return (
      <CenterContent
        justifyContent="flex-start"
        justifyItems="center"
        textAlign={{
          base: "left",
          sm: "center"
        }}
        maxW={{ sm: "29rem" }}
      >
        <Heading my={theme.spacing[ 10 ]}>
          We couldn't find an EPC for
          {" "}

          <NoWrap
            whiteSpace={{
              base: "initial",
              md: "nowrap"
            }}
          >
            your property
          </NoWrap>
        </Heading>

        <Text>
          If you think you do have an EPC and your address can be written in more than one way (e.g. ‘39A’ or ‘Garden Flat, 39’) then you can go back and try again. If you don’t have an EPC, or still can’t find it, just fill in the following questions to give us some more information about your property and we’ll do our own calculations.
        </Text>

        <div>
          <RenderSectionButtons>
            {({ NextButton }) => (
              <NextButton>
                Continue
              </NextButton>
            )}
          </RenderSectionButtons>
        </div>

      </CenterContent>
    );
  }

  if (getEPCForPropertyResponse.data) return (
    <CenterContent>
      <Text marginBottom={theme.spacing[ 4 ]}>
        Thanks – we’ve found at least one EPC that looks like it’s for your property.
        {" "}

        <b>
          Please select the most up-to-date EPC for your address.
        </b>
      </Text>

      <Controller
        name={`${question.id}.${question.slug}`}
        control={control}
        render={({ field }) => (
          <EPCGrid
            {...group}
            {...field}
          >
            {getEPCForPropertyResponse.data && getEPCForPropertyResponse.data.data.map(epc => (
              <EPCRadioCard
                key={epc[ "lmk-key" ]}
                {...getRadioProps({ value: epc[ "lmk-key" ] })}
                // This is a ridiculous hack to fix the next button bug
                // Without this, you have to click the submit button twice to submit the form
                // That's because touched is set to false on radios, even when their values change
                // So here we just re-set the value to the same thing but set touched to true so it submits first time!!!
                onChange={() => {
                  setValue(`${question.id.toString()}.${question.slug}`, field.value, { shouldTouch: true });
                  setTimeout(() => handleSubmit && handleSubmit({ [ `${question.id}` ]: field.value }), 50);
                  onData && onData(epc);
                }}
                render={() => {
                  return (
                    <>
                      <EPCGraph
                        currentScore={{
                          band: epc[ "current-energy-rating" ],
                          value: Number(epc[ "current-energy-efficiency" ])
                        }}
                        potentialScore={{
                          band: epc[ "potential-energy-rating" ],
                          value: Number(epc[ "potential-energy-efficiency" ])
                        }}
                      />

                      <HStack
                        fontSize="smaller"
                        fontWeight="600"
                        mt="10px"
                      >
                        <CheckCircleIcon
                          color={field.value === epc[ "lmk-key" ] ? theme.colors.green[ 100 ] : theme.colors.gray[ 200 ]}
                          boxSize="4"
                          aria-hidden
                        />

                        <VStack
                          alignItems="start"
                          flex="1"
                        >
                          <Text
                            color="gray.400"
                          >
                            {epc[ "inspection-date" ]}
                          </Text>

                          <Text
                            color="gray.700"
                            mt="3px !important"
                          >
                            {epc[ "address" ]}
                          </Text>

                          <Text
                            color="gray.700"
                            mt="0 !important"
                          >
                            {epc[ "postcode" ]}
                          </Text>
                        </VStack>
                      </HStack>
                    </>
                  );
                }}
              />
            ))}

            <EPCRadioCard
              {...getRadioProps({ value: "none" })}
              onChange={() => {
                onData(null);
                setValue(`${question.id.toString()}.${question.slug}`, field.value, { shouldTouch: true });
              }}
              render={() => {
                return (
                  <>
                    <EPCPlaceholder />

                    <HStack
                      fontSize="smaller"
                      fontWeight="600"
                      mt="10px"
                    >
                      <CheckCircleIcon
                        color={field.value === "none" ? theme.colors.green[ 100 ] : theme.colors.gray[ 200 ]}
                        boxSize="4"
                        aria-hidden
                      />

                      <Text
                        as="b"
                        mt="30"
                      >
                        {getEPCForPropertyResponse.data && getEPCForPropertyResponse.data.data.length > 1 ? "I don't see my EPC here" : "This is not my EPC"}
                      </Text>
                    </HStack>
                  </>
                );
              }}
            />
          </EPCGrid>
        )}
      />

    </CenterContent>
  );

  return (
    <LoadingPage />
  );
};

export const EPCLookup = UnstyledEPCLookup;

const EPCGrid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${theme.spacing[ 4 ]};
  align-items: stretch;
  align: center;
`;