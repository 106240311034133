import styled from "styled-components";
import { Link } from "react-router-dom";
import { theme } from "src/components/theme";
import {
  Box,
  Button,
  Fade
} from "@chakra-ui/react";

import { routes } from "src/constants";

import { Text } from "../text";

const gradient = `${theme.colors.green [ 50 ]}, ${theme.colors.blue[ 800 ]}`;

export const ImpactReviewButton: React.FC<{delay?: number}> = ({ delay }) => {
  return (
    <Fade
      in
      delay={delay}
      style={{ transitionDuration: "2s" }}
    >
      <Wrapper>
        <Button
          as={Link}
          to={routes.welcome}
          data-testid="start-impact-review-button"
          position="relative"
          bg="white"
          color="white"
          fontWeight="semibold"
          fontSize={theme.fontSizes.base}
          borderRadius={theme.radius.sm}
          h="56px"
          _hover={{ bg: `${theme.colors.gray [ 100 ]}` }}
          _active={{ bg: `${theme.colors.white}` }}
          p={0}
          filter="drop-shadow(0px 14px 14px rgba(0, 60, 225, 0.1))"
          display={{
            base: "none",
            md: "inherit"
          }}
        >
          <Text
            bgGradient={`linear(175deg, ${gradient})`}
            bgClip='text'
            as="span"
            p={`${theme.spacing[ 1 ]} ${theme.spacing[ 20 ]}`}
          >Start your Home Check
          </Text>

          <Box
            position="absolute"
            top="5px"
            left="5px"
            right="5px"
            bottom="5px"
            border="1px solid white"
            sx={{ borderImage: `linear-gradient(30deg, ${gradient}) 1` }}
          />
        </Button>
      </Wrapper>
    </Fade>
  );
};

const Wrapper = styled.div`
  position: fixed;
  bottom: 5%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  padding: ${theme.spacing[ 5 ]};
  z-index: 100;
`;
