import {
  Input as ChakraInput,
  InputProps,
  NumberInputProps,
  NumberInput
} from "@chakra-ui/react";
import {
  FieldValues,
  UseFormRegister
} from "react-hook-form";

import { Question } from "../../api/questions";
import { autoCompleteInput } from "../../helpers/auto-complete-input";
import theme from "../theme";
import { FormField } from "./FormField";

type UnstyledInputProps = {
  question: Question;
  register: UseFormRegister<FieldValues>;
};

const UnstyledInput: React.FC<UnstyledInputProps> = ({ question, register }) => {
  return (
    <FormField question={question}>
      <ChakraInput
        placeholder={`Enter your ${question.slug.replace("_", " ")}`}
        type={question.type || "text"}
        {...register(`${question.id}`)}
      />
    </FormField>
  );
};

export const textInputStyleProps: InputProps = {
  bg: "white",
  borderRadius: "0",
  border: "none",
  h: "50px",
  display: "block",
  fontSize: {
    base: theme.fontSizes.sm,
    sm: theme.fontSizes.base
  },
  _placeholder: { color: theme.colors.gray[ 400 ] }
};

const numberInputStyleProps: NumberInputProps = {
  borderColor: "transparent",
  sx: { "& > input": { height: "100%" } }
};

const StyledChakraTextInput = ChakraInput;

StyledChakraTextInput.defaultProps = {
  ...textInputStyleProps as InputProps,
  ...autoCompleteInput()
};

const StyledChakraNumberInput = NumberInput;

StyledChakraTextInput.defaultProps = {
  ...textInputStyleProps as InputProps,
  ...numberInputStyleProps as NumberInputProps,
  ...autoCompleteInput()
};

export const Input = UnstyledInput;
export {
  StyledChakraTextInput,
  StyledChakraNumberInput
};
