import { QuestionIcon } from "@chakra-ui/icons";
import {
  UseFormRegister,
  FieldValues
} from "react-hook-form";
import {
  CheckboxGroup as ChakraCheckboxGroup,
  Checkbox as ChakraCheckbox,
  VStack,
  HStack,
  CheckboxProps,
  StackProps,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger
} from "@chakra-ui/react";
import { sentenceCase } from "src/helpers/string";

import {
  Question,
  QuestionOption
} from "../../api/questions";
import { FormField } from "./FormField";
import theme from "../theme";
import { BoltSvg } from "./BoltSvg";

type UnstyledCheckboxGroupProps = {
  question: Question;
  register: UseFormRegister<FieldValues>;
};

const UnstyledCheckboxGroup: React.FC<UnstyledCheckboxGroupProps> = ({ question, register }) => {
  const suppliedAnswer = question.answers?.[ 0 ];
  const isEPCSuggested = (option: QuestionOption) => suppliedAnswer?.value.value.includes(option.label) ?? false;

  return (
    <FormField question={question}>
      <ChakraCheckboxGroup>
        <VStack {...VStackStyleProps}>
          {question.questionOptions.map(option => {
            return (
              <HStack
                key={option.id}
                {...rowStyleProps}
                {...checkboxStyleProps}
              >
                <ChakraCheckbox
                  {...register(`${question.id}.${option.label}`)}
                  defaultChecked={isEPCSuggested(option)}
                  {...checkboxStyleProps}
                >
                  {option.value}
                </ChakraCheckbox>

                {isEPCSuggested(option) && (
                  <BoltSvg />
                )}

                {!!option.helpText && (
                  <Popover
                    placement="auto"
                  >
                    <PopoverTrigger>
                      <span
                        role="button"
                        title={sentenceCase(`Info about ${option.value}`)}
                        aria-label="More about this question"
                      >
                        <QuestionIcon color={theme.colors.gray[ "300" ]} />
                      </span>
                    </PopoverTrigger>

                    <PopoverContent
                      maxH="90vh"
                      padding="2"
                      overflowY="auto"
                      overflowX="hidden"
                    >
                      {option.helpText?.length < 700 && <PopoverArrow />}

                      <PopoverBody>
                        {option.helpText}
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                )}

              </HStack>
            );
          })}
        </VStack>
      </ChakraCheckboxGroup>
    </FormField>
  );
};

const UnstyledCheckbox: React.FC<UnstyledCheckboxGroupProps> = ({ question, register }) => (
  <ChakraCheckbox
    {...register(question.slug)}
    {...checkboxStyleProps}
  >
    {question.question}
  </ChakraCheckbox>
);

export const accentColor = theme.colors.green[ 100 ];

export const checkboxStyleProps: CheckboxProps = {
  flex: 1,
  // finnicky overwriting default chakra checkbox style
  sx: {
    "input[type='checkbox'] + span": { "--chakra-shadows-outline": `0 0 0 2px ${accentColor}` },
    "input[type='checkbox'] + span[data-checked]": {
      background: "none",
      padding: "2px",
      color: accentColor,
      borderColor: accentColor,
      svg: {
        stroke: "currentColor !important",
        width: "100%",
        height: "100%"
      }
    },
    // unchecked box style
    "input[type='checkbox'] + span:not([data-checked])": { borderColor: theme.colors.gray[ 200 ] },
    // remove default label + label margin
    "&~*&": {
      margin: "0",
      borderTop: "2px solid " + theme.colors.gray[ 100 ]
    }
  }
};

export const VStackStyleProps: StackProps = {
  align: "left",
  spacing: "1px"
};

export const rowStyleProps: StackProps = {
  w: "100%",
  bg: "white",
  padding: `${theme.spacing[ 4 ]} ${theme.spacing[ 4 ]}`,
  lineHeight: 1.2,
  _first: { borderRadius: `${theme.radius.sm} ${theme.radius.sm} 0 0` },
  _last: { borderRadius: `0 0 ${theme.radius.sm} ${theme.radius.sm}` }
};

export const Checkbox = UnstyledCheckbox;
export const CheckboxGroup = UnstyledCheckboxGroup;