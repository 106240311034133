import React, { useState } from "react";
import {
  RouteComponentProps,
  useHistory
} from "react-router";
import { VStack } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import Auth from "@aws-amplify/auth";

import { routes } from "src/constants";

import { useAuthContext } from "../../hooks/useAuthContext";
import { Button } from "../../components/button";
import { CenterContent } from "../../components/shared/CenterContent";
import { Text } from "../../components/text";
import {
  FieldError,
  StyledChakraTextInput as Input
} from "../../components/form";
import { LoadingPage } from "../../components/shared/LoadingPage";
import { Wrapper } from "../../components/shared/Wrapper";
import { FullSpace } from "../../components/shared/FullSpace";
import theme from "../../components/theme";

export type ForgotPasswordPageProps = RouteComponentProps<
  undefined,
  Record<string, unknown>,
  { from: string }
>;

const schema = Joi.object({ email: Joi.string().email({ tlds: { allow: false } }).required() });

export const ForgotPasswordPage: React.FC<ForgotPasswordPageProps> = ({ location }) => {
  const { authDataState } = useAuthContext();
  const history = useHistory();
  const [ confirmPasswordResetError, setConfirmPasswordResetError ] = useState<string | null>(null);

  const {
    register, handleSubmit, formState: { errors, isDirty }
  } = useForm({ resolver: joiResolver(schema) });

  // const handleVerify = async (data: Record<string, string>) => {
  //   // TODO: Handle undefined location.state
  //   // Send the password reset email
  //   try {
  //     await Auth.forgotPassword(data.email);

  //     // Move user to a page where they can enter their verification code
  //     history.push("/login/forgot-password/reset", {
  //       from: location.state.from || "/",
  //       email: data.email
  //     });
  //   } catch (e) {
  //     setConfirmPasswordResetError(String(e));
  //   }
  // };

  const handleResetPassword = async (data: Record<string, string>) => {
    try {
      await Auth.forgotPassword(data.email);

      history.push(routes.retrieveCode, {
        email: data.email,
        mode: "reset"
      });
    } catch (e) {
      if (e.code === "UserNotFoundException") {
        setConfirmPasswordResetError("Email not registered!");
      } else {
        setConfirmPasswordResetError("Error verifying email. Please try again");
      }

      console.log(e);
    }
  };

  return (
    <FullSpace
      bg={theme.colors.gray[ 100 ]}
      py={theme.spacing[ 8 ]}
    >
      <Wrapper variant="form">
        <CenterContent justifyContent="flex-start">
          {authDataState.authenticating ? (
            <LoadingPage />
          ) : (
            <VStack
              // maxW="300px"
              w="100%"
              spacing={theme.spacing[ 6 ]}
            >
              <Text
                textAlign="center"
                fontSize={theme.fontSizes.lg}
                fontWeight="semibold"
              >
                Forget your password?
              </Text>

              <Text
                textAlign="center"
                fontSize="sm"
              >
                Enter your email address below to reset your password
              </Text>

              <form
                onSubmit={handleSubmit(handleResetPassword)}
                style={{ width: "100%" }}
              >
                <VStack
                  spacing={theme.spacing[ 8 ]}
                  w="100%"
                >
                  {confirmPasswordResetError && (
                    <Text color="red">
                      {confirmPasswordResetError}
                    </Text>
                  )}

                  <Input
                    type="text"
                    placeholder="Enter your email address"
                    w="100%"
                    isInvalid={errors.email}
                    {...register("email")}
                  />

                  <FieldError
                    error={errors.email}
                    fieldName="Email address"
                  />

                  <Button
                    type="submit"
                    disabled={!isDirty}
                  >
                    Submit
                  </Button>
                </VStack>
              </form>
            </VStack>
          )}
        </CenterContent>
      </Wrapper>
    </FullSpace>
  );
};

export default ForgotPasswordPage;