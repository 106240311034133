export * from "./routes";
export * from "./recommendationCopy";

export const budgetOptions = [
  {
    label: "<£1,000",
    value: "Less than 1000"
  },
  {
    label: "£1,000 - £5,000",
    value: "1000-5000"
  },
  {
    label: "£5,000 - £10,000",
    value: "5000-10000"
  },
  {
    label: "I'm open if it can go on the mortgage",
    value: "I'm open if it can go on the mortgage"
  },
  {
    label: "Show me everything",
    value: "Show me everything"
  }
];

export const disruptionOptions = [
  {
    label: "I'm considering major work anyway",
    value: "I'm considering major work anyway"
  },
  {
    label: "I don't mind",
    value: "I don't mind building work"
  },
  {
    label: "Yes, if it's not too disruptive",
    value: "Yes if it's not too disruptive"
  },
  {
    label: "Not really, please",
    value: "Not really"
  }
];