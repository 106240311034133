import {
  Select as ChakraSelect,
  SelectProps,
  HStack,
  Box
} from "@chakra-ui/react";
import {
  ChangeEvent,
  useState
} from "react";
import {
  Control,
  Controller
} from "react-hook-form";

import { Question } from "../../api/questions";
import theme from "../theme";
import { FormField } from "./FormField";
import { ReactComponent as ChevronSvg } from "../../assets/icons/chevron-down.svg";
import { BoltSvg } from "./BoltSvg";

type UnstyledSelectProps = {
  question: Question;
  placeholder?: string;
  control?: Control;
  onSelect?: (event: ChangeEvent<HTMLSelectElement>) => void;
};

const height = 50;
const iconsContainerWidth = "70px";
const backgroundColor = "white";

const UnstyledSelect: React.FC<UnstyledSelectProps> = ({
  question,
  placeholder,
  control,
  onSelect,
  children
}) => {
  const [ hasChanged, setChanged ] = useState(false);
  const isEPCAnswered = !hasChanged && !!question.answers?.[ 0 ];

  const selectElementProps: SelectProps = {
    iconSize: iconsContainerWidth,
    bg: backgroundColor,
    borderRadius: "0",
    border: "none",
    h: `${height}px`,
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    w: "100%",
    display: "block",
    icon: renderIcon(isEPCAnswered),
    fontSize: {
      base: theme.fontSizes.sm,
      sm: theme.fontSizes.base
    },
    // The chevron icon placement was messing up so this centers it again
    sx: {
      "& + div": {
        height: `${height}px`,
        bottom: `${-1 * (height / 2)}px`,
        top: "unset"
      }
    }
  };

  const SelectElem: React.FC<SelectProps> = extraProps => (
    <ChakraSelect
      placeholder={placeholder || "* Select an option"}
      isRequired
      {...extraProps}
      {...selectElementProps}
    >
      {children}
    </ChakraSelect>
  );

  return (
    <FormField
      question={question}
    >
      <Box
        bg={backgroundColor}
        position="relative"
        height={height}
        onChange={() => setChanged(true)}
      >
        {control ? (
          <Controller
            name={`${question.id}.${question.slug}`}
            control={control}
            render={({ field }) => (
              <SelectElem {...field}>
                {children}
              </SelectElem>
            )}
          />
        ) : (
          <SelectElem onChange={event => onSelect && onSelect(event)}>
            {children}
          </SelectElem>
        )}
      </Box>
    </FormField>
  );
};

const renderIcon = (isEPCAnswered: boolean) => {
  return (
    <>
      <HStack
        h={height}
        w={iconsContainerWidth}
        bg={backgroundColor}
        spacing="0"
        color={theme.colors.blue[ 500 ]}
        fontSize={theme.fontSizes.sm}
        transform="translateX(-2ch)"
        pl="2ch"
      >
        {isEPCAnswered && (
          <BoltSvg
            position="absolute"
            transform="translateX(-3ch)"
          />
        )}

        <Box
          w="1.2em"
          h="1.2em"
        >
          <ChevronSvg
            width="100%"
            height="100%"
            fill={theme.colors.gray[ 500 ]}
          />
        </Box>
      </HStack>
    </>
  );
};

export const Select = UnstyledSelect;