import {
  Box,
  BoxProps,
  useRadio,
  UseRadioProps
} from "@chakra-ui/react";
import React, {
  HTMLProps,
  PropsWithChildren
} from "react";

interface RenderPropProps {
  render?: (inputProps: Omit<HTMLProps<HTMLInputElement>, never>) => JSX.Element;
  containerProps?: Partial<BoxProps>;
  radioCheckboxProps?: Partial<BoxProps>;
}

/**
 * Custom radio using {@link useRadio} from Chakra.
 * You can customise styling with the `containerProps` and `radioCheckboxProps`
 * props
 */
export const RadioCard = (props: PropsWithChildren<(UseRadioProps | undefined) & RenderPropProps>): JSX.Element => {
  const { getInputProps, getCheckboxProps } = useRadio(props);
  const chakraInputProps = getInputProps();
  const chakraRadioCheckboxProps = getCheckboxProps();

  const renderOutput = (children?: React.ReactNode) => (
    <Box
      type="submit"
      as="label"
      tabIndex={1}
      width="100%" // annoyingly always have to declare this for Box
      {...props.containerProps}
    >
      {/*
        native input visually hidden, but is here for semantics/accessibility
      */}

      <input {...chakraInputProps} />

      <Box
        {...chakraRadioCheckboxProps}
        {...props.radioCheckboxProps}
      >
        {children ?? props.children}
      </Box>
    </Box>
  );

  return props.render ? renderOutput(props.render(chakraInputProps)) : renderOutput() ;
};
