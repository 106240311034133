import { QuestionIcon } from "@chakra-ui/icons";
import {
  FormControl,
  FormLabel,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  HStack,
  Flex
} from "@chakra-ui/react";
import { useContext } from "react";

import { BoltSvg } from "./BoltSvg";
import { Question } from "../../api/questions";
import { Text } from "../text";
import theme from "../theme";
import {
  QuestionEPCAnswerContext,
  QuestionIndexContext
} from ".";

type UnstyledFormFieldProps = {
  question: Question;
  questionId?: string;
  label?: string;
  helpText?: string;
};

/**
 * Optionally provide question id, label and helper-text -- else is inferred
 * from question object
 */
const UnstyledFormField: React.FC<UnstyledFormFieldProps> = ({
  question,
  questionId,
  label,
  helpText,
  children
}) => {
  const isEPCAnswered = useContext(QuestionEPCAnswerContext);
  const index = useContext(QuestionIndexContext);
  const isFirstOnPage = index === 0;
  const popoverText = helpText ?? question.helpText;

  return (
    <FormControl id={questionId ?? question.slug}>
      <Flex
        justifyContent="space-between"
        alignItems="flex-start"
        width="100%"
      >
        <FormLabel fontSize={theme.fontSizes.base}>
          {label ?? question.question}
        </FormLabel>

        {!!popoverText && (
          <Popover
            placement="auto"
          >
            <PopoverTrigger>
              <IconButton
                aria-label="More about this question"
                icon={<QuestionIcon />}
                variant="ghost"
                float="right"
                marginBottom={theme.spacing[ 1 ]}
              />
            </PopoverTrigger>

            <PopoverContent
              maxH="90vh"
              padding="2"
              overflowY="auto"
              overflowX="hidden"
            >
              {/* Arrow placement gets weird with really long content */}

              {popoverText?.length < 700 && <PopoverArrow />}

              <PopoverCloseButton />

              <PopoverBody>
                {popoverText}
              </PopoverBody>
            </PopoverContent>
          </Popover>
        )}
      </Flex>

      {!!(isFirstOnPage && isEPCAnswered) && (
        <HStack
          color={theme.colors.blue[ 500 ]}
          mb={`${theme.spacing[ 3 ]}`}
          fontSize={theme.fontSizes.sm}
        >
          <BoltSvg />

          <Text>
            Pre-selected based on your EPC
          </Text>
        </HStack>
      )}

      {children}
    </FormControl>
  );
};

export const FormField = UnstyledFormField;