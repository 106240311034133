import {
  Box,
  VStack,
  Modal,
  List,
  ListItem,
  useToast,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  Button,
  ListIcon,
  keyframes,
  usePrefersReducedMotion,
  Image,
  ModalOverlay,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  StackProps,
  Tooltip
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useState } from "react";
import { Recommendation } from "src/api/users/footprint";
import { useV2ConfigAPI } from "src/api/v2/config";
import ImpossibleCombination from "src/components/recommendation/ImpossibleCombination";
import RequiredCombination from "src/components/recommendation/RequiredCombination";
import { Text } from "src/components/text";
import theme from "src/components/theme";
import { Wrapper } from "src/components/shared";
import WholeHomeSurveyTile from "src/components/recommendation/WholeHomeSurvey";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { MdCheckCircle } from "react-icons/md";
import {
  FaQuestion,
  FaQuestionCircle
} from "react-icons/fa";

import { routes } from "src/constants";

import MCS from "../../../assets/images/third-party/mcs.png";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { RecommendationFooter } from "./RecommendationFooter";
import { RecommendationsTable } from "./RecommendationsTable";

interface Props {
    recommendations: Recommendation[]
    impossibleCount: number;
    isAnonUser: boolean;
}

const AnimatedVStack = motion<Omit<StackProps, "transition">>(VStack);

interface ImpossibleCombination {
  selected: Recommendation;
  impossible: Recommendation;
}

interface RequiredCombination {
  selected: Recommendation;
  required: Recommendation;
}

export const RecommendationsTabularSection: React.FC<Props> = ({
  recommendations, impossibleCount, isAnonUser
}) => {
  const history = useHistory();
  const { authDataState } = useAuthContext();
  const isAuthenticated = authDataState.authenticated;
  const wholeHouseSurvey = recommendations?.find(({ recommendationSlug }) => recommendationSlug === "whole_house_survey_recommendation");
  const hp = recommendations.filter(r => r.priority === "HIGH" && r.recommendationSlug !== "whole_house_survey_recommendation");
  const mp = recommendations.filter(r => r.priority === "MED" && r.recommendationSlug !== "whole_house_survey_recommendation");
  // const lp = recommendations.filter(r => r.priority === "LOW");
  const [ selectedSlugs, setSelectedSlugs ] = useState<string[]>(recommendations.filter(r => r.interested).map(r => r.recommendationSlug)) ;
  const [ impossibleCombination, setImpossibleCombination ] = useState<ImpossibleCombination | null>(null);
  const [ requiredCombination, setRequiredCombination ] = useState<RequiredCombination | null>(null);
  const toast = useToast();
  const [ , updateInterest ] = useV2ConfigAPI("UPDATE_INTEREST");
  const prefersReducedMotion = usePrefersReducedMotion();

  const animation = prefersReducedMotion ?
    undefined :
    `${fadeOut} 0.1s ease-in-out forwards`;

  const toggleRecommendation = (recommendation: Recommendation) => {
    const slugIndex = selectedSlugs.indexOf(recommendation.recommendationSlug);

    // Already selected, remove it
    if (slugIndex !== -1) {
      return toggleSlug(recommendation);
    }

    // Does not exist, add it
    const impossibleSlug = recommendation.impossibleCombinations.find(slug => selectedSlugs.find(selectedSlug => slug === selectedSlug));

    if (impossibleSlug) {
      const impossible = recommendations.find(r => r.recommendationSlug === impossibleSlug);

      if (impossible) {
        return setImpossibleCombination({
          selected: recommendation,
          impossible
        });
      }
    }

    if (recommendation.requiredCombinations.length > 0) {
      // If a required combination is not selected, lets select it
      for (const slug of recommendation.requiredCombinations) {
        const selectedRequiredCombination = selectedSlugs.find(selected => selected === slug);

        // If we have not selected a required combinaiton
        if (!selectedRequiredCombination) {
          const requiredCombination = recommendations.find(recommendation => recommendation.recommendation.slug === slug);

          // If the required combination is not in the listed recommendations, we have a major error
          if (!requiredCombination) {
            return alert(`${recommendation.recommendation.name} requires ${slug}, but it is not a recommendation, so this recommendation cannot be selected`);
          }

          // Show user required combination
          return setRequiredCombination({
            selected: recommendation,
            required: requiredCombination
          });
        } else {
          return toggleSlug(recommendation);
        }
      }
    } else {
      return toggleSlug(recommendation);
    }
  };

  const toggleSlug = (recommendation: Recommendation) => {
    const slugIndex = selectedSlugs.indexOf(recommendation.recommendation.slug);
    // Copy the array to avoid mutating the original
    const newSelectedSlugs: string[] = [ ...selectedSlugs ];

    if (slugIndex === -1) {
      // Slug not found, add it
      newSelectedSlugs.push(recommendation.recommendation.slug);

      updateInterest({
        recommendationId: recommendation.id,
        interested: true
      });
      setSelectedSlugs(newSelectedSlugs);
    } else {
      newSelectedSlugs.splice(slugIndex, 1);

      updateInterest({
        recommendationId: recommendation.id,
        interested: false
      });
      setSelectedSlugs(newSelectedSlugs);
    }
  };

  return (
    <AnimatedVStack
      display="flex"
      pos="relative"
      overflow="hidden"
      flex={1}
      flexDir="column"
      alignItems="center"
      spacing={theme.spacing[ 8 ]}
      {...animatedProps()}
    >

      <Wrapper
        variant={{ base: "narrow" }}
        px={{
          base: "2rem",
          md: "0"
        }}
      >

        <VStack
          overflow="visible"
          spacing={8}
          minHeight={500}
        >

          {hp.length && (
            <VStack
              width="100%"
              spacing={4}
            >
              <Box width="full">

                <Text
                  as="h4"
                  letterSpacing="-0.9px"
                  fontSize={{
                    base: theme.fontSizes.lg,
                    md: theme.fontSizes.lg
                  }}
                >{isAnonUser ? "Recommendations" : "High Potential Options"}
                </Text>

                <Text
                  display="inline"
                  color="gray.600"
                >
                  Based on what we know, the following are likely to show potential for your property and have been pre-selected. {" "}

                </Text>

                <Tooltip
                  cursor="pointer"
                  label="If you definitely don’t want to evaluate something then you can de-select it but, don’t worry, we can adjust and configure and remove items later on, though if you’ve not selected here you won’t hear more about them"
                >
                  <Box
                    display="inline"
                    color="blue.500"
                    cursor="pointer"
                  >
                    More
                  </Box>
                </Tooltip>

              </Box>

              <RecommendationsTable
                selectedSlugs={selectedSlugs}
                toggleSlug={toggleRecommendation}
                recommendations={hp}
                isAuthenticated={isAuthenticated}
              />
            </VStack>
          )}

          {mp.length && !isAnonUser && (

            <VStack
              width="100%"
              spacing={4}
            >
              <Box width="full">

                <Text
                  fontSize={{
                    base: theme.fontSizes.lg,
                    md: theme.fontSizes.lg
                  }}
                >Medium Potential Options
                </Text>

                <Text
                  display="inline"
                  color="gray.600"
                >
                  Some of these options may be incompatible with ones we’ve selected above. {" "}

                </Text>

                <Tooltip
                  cursor="pointer"
                  label="Or they may be slow to payback or otherwise ‘not obvious’. But if you want to include any of these in your evaluation then select them here"
                >
                  <Box
                    display="inline"
                    color="blue.500"
                    cursor="pointer"
                  >
                    More
                  </Box>
                </Tooltip>

              </Box>

              <RecommendationsTable
                selectedSlugs={selectedSlugs}
                toggleSlug={toggleRecommendation}
                recommendations={mp}
                isAuthenticated={isAuthenticated}
              />
            </VStack>
          )}

          {isAnonUser && (
            <>
              <Box
                minHeight={1000}
                top={0}
                position="absolute"
                width="100%"
                height="100%"
                zIndex={2}
                opacity={0.7}
                background="white"
              />

              <Box
                padding={10}
                top="5%"
                position="absolute"
                background="white"
                zIndex={3}
                shadow="lg"
                rounded="md"
                border="1px solid gray.400"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                width={{
                  md: "45%",
                  sm: "100%"
                }}
              >
                <Box
                  fontWeight="semibold"
                  display="block"
                >To see all your recommendations, please register (it's free)
                </Box>

                <br />

                <List
                  textAlign="left"
                  fontSize="sm"
                >
                  <ListItem>    <ListIcon
                    as={MdCheckCircle}
                    color='black'
                                />
                    See all your recommendations in detail
                  </ListItem>

                  <ListItem><ListIcon
                    as={MdCheckCircle}
                    color='black'
                            />Mix & match recommendations to see combined effects
                  </ListItem>

                  <ListItem><ListIcon
                    as={MdCheckCircle}
                    color='black'
                            />Book in-person home survey or talk to an expert
                  </ListItem>

                  <ListItem mb={theme.spacing[ 8 ]}><ListIcon
                    as={MdCheckCircle}
                    color='black'
                                                    />We care about your privacy. We only use your data to help advise you on your home. See our

                    <Link
                      to={routes.privacyPolicy}
                      color="blue.800"
                    >
                      <span style={{ color: "blue" }}>
                        {" "}privacy policy
                      </span>
                    </Link>
                  </ListItem>
                </List>

                <Button
                  as={Link}
                  to={{
                    pathname: routes.register,
                    state: { from: history.location.pathname }
                  }}
                  fontSize={[ "xs", "sm" ]}
                  minW={{
                    base: "unset",
                    md: "10ch"
                  }}
                  borderWidth={1}
                  borderColor={theme.colors.black}
                  borderRadius={3}
                  width={200}
                  height={50}
                  bg="linear-gradient(135deg, #9B72F2, #88D69E);"
                  textColor={theme.colors.white}
                  _hover={{ bg: "linear-gradient(135deg, #813E97, #9CD894);" }}
                >
                  Signup Now
                </Button>

                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                >
                  <p
                    style={{
                      fontSize: 12,
                      marginTop: 10
                    }}
                  >Join over 2000 people cutting their costs and carbon
                  </p>

                  <Image
                    mt={4}
                    src={MCS}
                    alt='MCS'
                    width={75}
                    height={75}
                    display={{
                      base: "none",
                      md: "block"
                    }}
                  />
                </Box>
              </Box>
            </>
          )}

        </VStack>

      </Wrapper>

      <Wrapper
        variant={{ base: "text" }}
        textAlign="center"
        fontSize="sm"
        color={theme.colors.gray[ 700 ]}
      >
        <Text>We also exclude from the above <b>{impossibleCount}</b> interventions that are not applicable or where we need more information to evaluate for this property</Text>
      </Wrapper>

      {!isAnonUser && (
        <Wrapper
          display={{
            base: "none",
            md: "block"
          }}
          variant={{ base: "narrow" }}
          px={{
            base: "2rem",
            md: "0"
          }}
        >
          <WholeHomeSurveyTile
            recommendations={recommendations}
            index={-1}
          />

        </Wrapper>
      )}

      {/*lp.length && (
        <VStack
          display="block"
          alignItems="flex-start"
          width="75%"
          spacing={theme.spacing[ 4 ]}
        >
          <Box>

            <Text
              color={theme.colors.gray[ 800 ]}
              fontSize={{
                base: theme.fontSizes.lg,
                md: theme.fontSizes.lg
              }}
              fontWeight="medium"
              opacity="0.9"
            >Low Potential Options
            </Text>

            <Text color={theme.colors.gray[ 600 ]}>We’ve excluded a number of other options because they don’t fit what we know so far. We may be able to add these but you’ll need to contact us for us to do that for you</Text>

          </Box>

          <RecommendationsTable
            selectedSlugs={selectedSlugs}
            toggleSlug={toggleRecommendation}
            recommendations={lp}
            lowPriority
          />
        </VStack>
            )*/}

      <Modal
        isOpen={requiredCombination !== null}
        onClose={() => setRequiredCombination(null)}
        size="2xl"
        isCentered
      >
        <ModalOverlay />

        <ModalContent>
          <ModalCloseButton />

          <ModalBody padding={theme.spacing[ 8 ]}>
            {requiredCombination && (
              <RequiredCombination
                onCancel={() => {
                  setRequiredCombination(null);
                }}
                onSelect={() => {
                  setSelectedSlugs([
                    ...selectedSlugs,
                    requiredCombination.required.recommendationSlug,
                    requiredCombination.selected.recommendationSlug
                  ]);

                  updateInterest({
                    recommendationId: requiredCombination.required.id,
                    interested: true
                  });

                  updateInterest({
                    recommendationId: requiredCombination.selected.id,
                    interested: true
                  });
                  setRequiredCombination(null);

                  toast({
                    description: `Selected ${requiredCombination.selected.recommendation.name} and ${requiredCombination.required.recommendation.name}`,
                    status: "info"
                  });
                }}
                selected={requiredCombination.selected}
                required={requiredCombination.required}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={impossibleCombination !== null}
        onClose={() => setImpossibleCombination(null)}
        size="2xl"
        isCentered
      >
        <ModalOverlay />

        <ModalContent>
          <ModalCloseButton />

          <ModalBody padding={theme.spacing[ 8 ]}>
            {impossibleCombination && (
              <ImpossibleCombination
                onSelect={(selected, notSelected) => {
                  const newSelectedSlugs: string[] = [ ...selectedSlugs ];
                  const selectedIndex = newSelectedSlugs.indexOf(selected.recommendationSlug);
                  const notSelectedIndex = newSelectedSlugs.indexOf(notSelected.recommendationSlug);

                  if (notSelectedIndex !== -1) {
                    newSelectedSlugs.splice(notSelectedIndex, 1);
                  }

                  if (selectedIndex === -1) {
                    newSelectedSlugs.push(selected.recommendationSlug);
                  }

                  setSelectedSlugs(newSelectedSlugs);

                  updateInterest({
                    recommendationId: selected.id,
                    interested: true
                  });

                  updateInterest({
                    recommendationId: notSelected.id,
                    interested: false
                  });
                  setImpossibleCombination(null);

                  toast({
                    description: `Selected ${selected.recommendation.name}`,
                    status: "info"
                  });
                }}
                selected={impossibleCombination.selected}
                impossible={impossibleCombination.impossible}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>

      {isAuthenticated && (
        <RecommendationFooter
          recommendations={recommendations}
          selectedSlugs={selectedSlugs}
        />
      )}
    </AnimatedVStack>
  );
};

const animatedProps: () => Parameters<typeof AnimatedVStack>[0] = () => ({
  initial: {
    opacity: 0,
    y: 20
  },
  whileInView: {
    opacity: 1,
    y: 0
  },
  viewport: {
    amount: .125,
    once: true
  },
  transition: {
    type: "spring",
    mass: 0.5,
    stiffness: 60,
    delay: 0
  }
});

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0.2;
  }
`;