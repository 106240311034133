import {
  VStack,
  Spacer,
  HStack,
  Box
} from "@chakra-ui/react";
import { Text } from "src/components/text";
import theme from "src/components/theme";
import { ReactComponent as PlaneIcon } from "src/assets/icons/plane.svg";
import { ReactComponent as BurgerIcon } from "src/assets/icons/burger.svg";
import { ReactComponent as BagsIcon } from "src/assets/icons/bags.svg";
import { ReactComponent as CupIcon } from "src/assets/icons/cup.svg";
import {
  Wrapper,
  NoWrap
} from "src/components/shared";
import { FootprintScores } from "src/api/users/footprint";
import {
  formatNumber,
  roundCostOrSaving
} from "src/helpers/number";

const EmissionsComparison: React.FC<Partial<FootprintScores["savingsComparisons"]>> = props => {
  return (
    <VStack
      bg="white"
      borderRadius={theme.radius.lg}
      justifyContent="center"
      padding={[
        "2rem .5rem",
        "2rem 1rem",
        "2rem"
      ]}
    >
      <Wrapper
        variant="text"
        as={VStack}
      >
        <Text mb={theme.spacing[ 3 ]}>
          If every UK household did this, we'd save

          <Text
            as="b"
            color={theme.colors.green[ "100" ]}
          > {roundCostOrSaving(props.percentUKEmissionsSaved)}%
          </Text>{" "}

          of our <NoWrap>national emissions.</NoWrap>
        </Text>

        <Text fontSize="sm">
          To save the equivalent each year, you'd have to:
        </Text>

        <Comparison icon={<PlaneIcon />}>
          Take <b>{props.parisFlights ? formatNumber(props.parisFlights) : "--"}</b> fewer return flights to Paris.
        </Comparison>

        <Comparison icon={<BurgerIcon />}>
          Eat <b>{props.hamburgers ? formatNumber(props.hamburgers) : "--"}</b> fewer hamburgers.
        </Comparison>

        <Comparison icon={<BagsIcon />}>
          Use <b>{props.plasticBags ? formatNumber(props.plasticBags) : "--"}</b> fewer plastic bags.
        </Comparison>

        <Comparison icon={<CupIcon />}>
          Use <b>{props.coffeeCups ? formatNumber(props.coffeeCups) : "--"}</b> fewer disposable coffee cups.
        </Comparison>

        <Spacer />
      </Wrapper>
    </VStack>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Comparison = ({ icon, children }: {icon: JSX.Element, children: any}) => {
  return (
    <HStack
      bg={theme.colors.gray[ 100 ]}
      p="1ch 1.5ch"
      borderRadius={theme.radius.md}
      w="100%"
      spacing={theme.spacing[ 6 ]}
      alignItems="center"
      minH="3.5em"
      // roughly larger than the longest sibling
      maxW="40ch"
    >
      <Box width="1.5em">
        {icon}
      </Box>

      <Text textAlign="left">
        {children}
      </Text>
    </HStack>
  );
};

export default EmissionsComparison;