import {
  AnswersDetails,
  QuestionSectionGroupResponse,
  Section
} from ".";
import { useAxios } from "../../hooks/useAxios";
import { JSONAPIResponse } from "../json-api-response";

// request + response types

interface SectionPathParams {
  id: string;
}

interface NextUnansweredQuestionGroupParams {
  id: number;
  propertyId?: number;
}

interface CreateAnswerParams {
  propertyId?: number;
  data: Array<{
    questionId: number;
    answer: string | number | string[]
  }>
}

interface GetRecommendationParams {
  id: number;
}

interface GetRecommendationStatusPending {
  status: RecommendationGeneratorStatus.CALCULATING;
}

export enum RecommendationGeneratorStatus {
  CALCULATING = "CALCULATING",
  ERROR = "ERROR",
  SUCCESS = "SUCCESS"
}

interface GetRecommendationStatusComplete {
  status: RecommendationGeneratorStatus.SUCCESS
  footprintScores: {
    footprint: number;
    sapGrade: string;
    potentialSapGrade: string;
    potentialMoneySavings: number;
    potentialSavings: number;
  };
}

type QuestionsEndpointConfig = {
  GET_SECTION: EndpointConfig<
    SectionPathParams,
    JSONAPIResponse<Section>
  >;
  GET_NEXT_UNANSWERED_IN_SECTION: EndpointConfig<
    NextUnansweredQuestionGroupParams,
    JSONAPIResponse<QuestionSectionGroupResponse>
  >;
  SUBMIT_ANSWER: EndpointConfig<
    CreateAnswerParams,
    JSONAPIResponse<AnswersDetails & QuestionSectionGroupResponse>
  >;
  UPDATE_ANSWER: EndpointConfig<
    CreateAnswerParams,
    JSONAPIResponse<AnswersDetails & QuestionSectionGroupResponse>
  >;
  GET_RECOMMENDATION_STATUS: EndpointConfig<
  GetRecommendationParams,
  JSONAPIResponse<GetRecommendationStatusPending | GetRecommendationStatusComplete>
  >
};

// users api specific logic

const requestInitialisers: RequestConfig<QuestionsEndpointConfig> = {
  GET_SECTION: (
    ({ id }) => ({
      url: `/sections/${id}`,
      method: "GET",
      unauthenticated: true
    })
  ),
  GET_NEXT_UNANSWERED_IN_SECTION: (
    ({ id, propertyId }) => ({
      url: `/sections/${id}/next`,
      method: "GET",
      params: { propertyId },
      unauthenticated: true
    })
  ),
  SUBMIT_ANSWER: (
    params => ({
      url: "/answers",
      method: "POST",
      data: params,
      unauthenticated: true,
      timeout: 30000
    })
  ),
  UPDATE_ANSWER: (
    params => {
      return ({
        url: "/answers",
        method: "PUT",
        data: params,
        timeout: 30000
      });
    }
  ),
  GET_RECOMMENDATION_STATUS: (
    params => {
      return ({
        url: "/recommendation-status",
        method: "GET",
        params: { id: params.id },
        timeout: 30000,
        unauthenticated: true
      });
    }
  )
};

export const useQuestionsApi: UseApiRequest<QuestionsEndpointConfig> = name => useAxios(requestInitialisers[ name ]);
