import Auth, {
  CognitoHostedUIIdentityProvider,
  CognitoUser
} from "@aws-amplify/auth";
import React, { useState } from "react";
import { Hub } from "@aws-amplify/core";
import mixpanel from "mixpanel-browser";
import { useTypedSelector } from "src/redux/useTypeSelector";

interface AuthDataState {
  authenticated: boolean;
  error: string | null;
  authenticating: boolean;
  idToken: string;
  isAdmin: boolean;
}

// authenticating: true on initial load until auth check complete
const initialAuthContext: AuthDataState = {
  authenticated: false,
  error: null,
  authenticating: true,
  idToken: "",
  isAdmin: false
};

export interface AuthContextAttributes {
  authDataState: AuthDataState;
  setAuthDataState: (newState: AuthDataState) => void;
}

export const AuthContext = React.createContext<AuthContextAttributes>({
  authDataState: initialAuthContext,
  setAuthDataState: () => null
});

const getToken = (user: CognitoUser) => user.getSignInUserSession()?.getIdToken().getJwtToken() || "";
const getGroups = (user: CognitoUser): string[] => user.getSignInUserSession()?.getAccessToken().payload[ "cognito:groups" ] || [];

export const AuthProvider: React.FC = ({ children }) => {
  const [ authDataState, setAuthDataState ] = useState<AuthDataState>(initialAuthContext);
  const { propertyId } = useTypedSelector(state => state.property);

  // Check if the user is already authenticated on initial app load
  React.useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        // Extract the Cognito user groups that the user is part of
        const groups = getGroups(user);

        setAuthDataState({
          authenticated: true,
          error: null,
          authenticating: false,
          idToken: user.signInUserSession.getIdToken().getJwtToken(),
          isAdmin: groups.includes("admin")
        });
      })
      .catch(err => setAuthDataState({
        authenticated: false,
        error: err,
        authenticating: false,
        idToken: "",
        isAdmin: false
      }));
  }, []);

  React.useEffect(() => {
    // listen to Amplify Auth state

    const removeListener = Hub.listen("auth", ({
      payload: {
        event,
        data
      }
    }) => {
      // ***** Workaround for federated user signup bug in Cognito *****
      // when a user signs in with Google, a federated user is created
      // this federated user is then linked to a standard cognito user in pre-sign-up trigger
      // this means we get a standard Cognito user regardless of identity provider :tada:
      // unfortunately, the account linking step triggers the sign up flow for the federated user again
      // this throws an error as the user already exists, which prevents the oath flow from completing
      // here we check for this error and re-run the oath flow to authenticate using the linked Cognito user
      if (event === "signIn_failure" && (data.message as string).includes("Already+found+an+entry+for+username")) {
        // re-run oath flow on duplicate user error
        // uses stored session so authenticates immediately without user intervention
        // cognito + federated user are both already created so the sign up flow is not triggered
        // instead, session created for linked cognito user and signIn event triggered
        Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
      // set auth data  on successful sign in
      // also connect up the user to the correct property
      } else if (event === "signUp") {
        mixpanel.alias(data.userSub, mixpanel.get_distinct_id());
        mixpanel.track("sign_up");
      } else if (event === "signIn") {
        const groups = getGroups(data);

        setAuthDataState({
          authenticated: true,
          error: null,
          authenticating: false,
          idToken: getToken(data),
          isAdmin: groups.includes("admin")
        });
        mixpanel.identify(data.attributes[ "sub" ]);

        mixpanel.people.set({
          $email: data.attributes[ "email" ],
          $first_name: data.attributes[ "custom:firstName" ],
          $last_name: data.attributes[ "custom:lastName" ],
          $property_id: data.attributes[ "custom:propertyId" ]
        });
        mixpanel.track("sign_in");

      // clear auth data on sign out
      } else if (event === "signOut") {
        setAuthDataState({
          authenticated: false,
          error: null,
          authenticating: false,
          idToken: "",
          isAdmin: false
        });
        mixpanel.track("sign_out");
      }
    });

    return removeListener;
  }, [ propertyId ]);

  const contextValue: AuthContextAttributes = React.useMemo(() => ({
    authDataState,
    setAuthDataState
  }), [ authDataState ]);

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};