import { Box } from "@chakra-ui/react";

import theme from "../theme";

export const JoinBox: typeof Box = props => (
  <Box
    width="100%"
    maxWidth="500px"
    height="260px"
    minHeight={{
      "md": "300px",
      "2xl": "400px"
    }}
    boxShadow="0 0 0 6px black, inset 0 0 0 2px white, 0 30px 90px -30px rgba(0,0,0,0.5)"
    background={theme.colors.black}
    p={{
      "base": theme.spacing[ 8 ],
      "2xl": theme.spacing[ 10 ]
    }}
    display="flex"
    flexDirection="column"
    justifyContent="flex-end"
    color={theme.colors.white}
    _hover={{
      boxShadow: "inset 0 0 0 6px black, inset 0 0 0 8px white, 0 20px 30px -20px rgba(0,0,0,0.66)",
      borderRadius: 0,
      color: theme.colors.black,
      background: `linear-gradient(-45deg, ${theme.colors.gray[ 200 ]}, ${theme.colors.gray[ 100 ]} 33%, ${theme.colors.gray[ 100 ]} 66%, ${theme.colors.gray[ 200 ]})`
    }}
    _active={{
      boxShadow: "inset 0 0 0 6px black, inset 0 0 0 8px white, 0 20px 30px -20px rgba(0,0,0,0.66)",
      transform: "scale(0.99999999)",
      transition: "all 150ms ease-out"
    }}
    {...props}
  />
);