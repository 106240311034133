import {
  Box,
  Button,
  Text
} from "@chakra-ui/react";
import theme from "src/components/theme";
import * as xlsx from "xlsx";
import { DownloadIcon } from "@chakra-ui/icons";
import { flatten } from "flat";

interface Props {
    loading: boolean;
    report: any;
    mapping: any;
    dwelling: any;
    msg?: string;
}

export const CalculationReport: React.FC<Props> = ({
  loading,
  dwelling,
  report,
  mapping,
  msg
}) => {
  return (
    <Box>
      <Box
        rounded="sm"
        width="full"
        textAlign= "left"
        mx={10}
        display="flex"
        py={4}
        flexDir="row"
        color={loading ? theme.colors.gray[ 300 ] : "black"}
      >
        <Box width="50%">
          <Text
            mb={2}
            fontWeight="bold"
            color={theme.colors.gray[ 800 ]}
          >Calculations
          </Text>

          {Object.keys(report).map(key => (
            <Box
              key={report[ key ].label}
              mb={2}
            >
              <Text color={theme.colors.gray[ 700 ]}>{report[ key ].label}</Text>

              <Text>{report[ key ].value}</Text>
            </Box>
          ))}
        </Box>

        <Box width="50%">
          <Text
            mb={2}
            fontWeight="bold"
            color={theme.colors.gray[ 800 ]}
          >Dwelling
          </Text>

          {msg && <Text fontSize="xs">{msg}</Text>}

          {Object.keys(mapping).map(key => (
            <Box
              key={key}
              mb={5}
            >
              <Text color={theme.colors.gray[ 700 ]}>{key}</Text>

              <Text>{mapping [ key ] === null ? "Unknown" : mapping [ key ]}</Text>
            </Box>
          ))}

        </Box>
      </Box>
    </Box>
  );
};