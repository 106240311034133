import {
  HStack,
  Spacer,
  StackProps
} from "@chakra-ui/react";
import { useTypedSelector } from "src/redux/useTypeSelector";
import { useMemo } from "react";
import { Button } from "src/components/button";
import theme from "src/components/theme";

type SectionButtonRenderProps = {
	NextButton: typeof Button,
  SkipButton: typeof Button,
	CancelButton: typeof Button,
	ButtonSpacer: typeof Spacer,
};

export const RenderSectionButtons: React.FC<StackProps & {children: (p: SectionButtonRenderProps) => React.ReactNode}> = props => {
  const { children, ...restProps } = props;
  const { sectionGroups, currentSectionGroupIndex } = useTypedSelector(state => state.sectionGroups);

  // For google analytics
  const sectionGroupId = useMemo(() => {
    if (typeof currentSectionGroupIndex === "number" && sectionGroups[ currentSectionGroupIndex ]) {
      return sectionGroups[ currentSectionGroupIndex ].id;
    }
  }, [ currentSectionGroupIndex, sectionGroups ]);

  const NextButton: typeof Button = props => (
    <Button
      data-testid="impact-review-next-button"
      className={sectionGroupId ? `nextBtn_${sectionGroupId}` : "nextBtn"}
      children="Next"
      type="submit"
      {...props}
    />
  );

  const SkipButton: typeof Button = props => (
    <Button
      data-testid="impact-review-next-button"
      className={sectionGroupId ? `nextBtn_${sectionGroupId}` : "nextBtn"}
      type="submit"
      background="white"
      color="black"
      {...props}
    >
      Skip
    </Button>
  );

  const CancelButton: typeof Button = props => (
    <Button
      inverted
      onClick={props.onCancelClick}
      children="Cancel"
      {...props}
    />
  );

  const ButtonSpacer: typeof Spacer = props => (
    <Spacer
      flex={{
        base: 1,
        md: 0
      }}
      mx={{
        base: 0,
        md: `${theme.spacing[ 10 ]} !important`
      }}
      {...props}
    />
  );

  return (
    <HStack
      justifyContent="center"
      width="100%"
      mt={{
        base: theme.spacing[ 16 ],
        md: theme.spacing[ 20 ]
      }}
      px={{
        base: 0,
        sm: theme.spacing[ 10 ]
      }}
      {...restProps}
    >
      {children({
        NextButton,
        CancelButton,
        SkipButton,
        ButtonSpacer
      })}
    </HStack>
  );
};