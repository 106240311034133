import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon
} from "@chakra-ui/icons";
import {
  HStack,
  IconButton,
  NumberInput,
  NumberInputField
} from "@chakra-ui/react";

interface PaginationProps {loading: boolean, page: number, setPage: (n: number) => void, totalPages: number | null}

export const Pagination: React.FC<PaginationProps> = ({
  page, setPage, totalPages, loading
}) => {
  return (
    <HStack
      spacing={5}
      py={6}
      pointerEvents={loading ? "none" : "auto"}
    >
      <IconButton
        aria-label="Go to first page"
        isDisabled={!(page > 1)}
        isLoading={loading}
        icon={(
          <ArrowLeftIcon boxSize={2.5} />
        )}
        size="sm"
        onClick={() => setPage(1)}
      />

      <IconButton
        aria-label="Go to previous page"
        isDisabled={!(page > 1)}
        isLoading={loading}
        icon={<ChevronLeftIcon boxSize={5} />}
        onClick={() => setPage(page - 1)}
      />

      <HStack>
        <NumberInput
          defaultValue={page}
          min={1}
          max={totalPages ?? 99999}
          step={1}
          precision={0}
          keepWithinRange
          borderRadius="md"
          isDisabled={false}
          isLoading={loading}
          onKeyDown={e => e.key === "Enter" && setPage(parseInt((e.target as HTMLInputElement).value))}
          variant="outline"
        >
          <NumberInputField
            borderColor="gray.100"
            borderWidth="2px"
            paddingInlineEnd={0}
            paddingInline={4}
            min-width="fit-content"
            width="5ch"
            textAlign="center"
          />
        </NumberInput>
      </HStack>

      <IconButton
        aria-label="Go to next page"
        isDisabled={page === totalPages}
        isLoading={loading}
        icon={<ChevronRightIcon boxSize={5} />}
        onClick={() => setPage(page + 1)}
      />

      <IconButton
        aria-label="Go to last page"
        isDisabled={page === totalPages}
        isLoading={loading}
        icon={(
          <ArrowRightIcon boxSize={2.5} />
        )}
        size="sm"
        onClick={() => setPage(totalPages ?? 1)}
      />
    </HStack>
  );
};