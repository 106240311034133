import {
  Text,
  Box,
  Select,
  Button,
  Grid,
  GridItem,
  Checkbox,
  Input
} from "@chakra-ui/react";
import {
  useCallback,
  useEffect,
  useState
} from "react";
import {
  useForm,
  Controller
} from "react-hook-form";
import { EPCLookup } from "src/components/form";
import {
  LoadingPage,
  Wrapper
} from "src/components/shared";
import theme from "src/components/theme";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi, { string } from "joi";
import { usePolypipeAPI } from "src/api/polypipe";
import {
  FaArrowCircleRight,
  FaArrowCircleLeft
} from "react-icons/fa";
import { isStage } from "src/helpers/stage";
import { useV2ConfigAPI } from "src/api/v2/config";

import * as EPCMappers from "./mappers";
import {
  AgeBand,
  Form,
  PropertyType,
  WallsShared,
  RoofInsulationThickness,
  WallConstruction,
  GlazingType,
  HeatingSystem,
  FloorDescription
} from "./types";

enum Steps {
    Postcode,
    EPC,
    PropertyType,
    ConfirmDetails,
    PostBuildWorks,
    PropertySize,
    HeatingSystem,
    Results
}

export const Polypipe = () => {
  const [ epc, setEPC ] = useState(null);
  const [ currentStep, setCurrentStep ] = useState(Steps.Postcode);
  const [ , v2WarmupReq ] = useV2ConfigAPI("WARMUP");

  useEffect(() => {
    v2WarmupReq({});
  }, [ v2WarmupReq ]);

  const {
    getValues, reset, setValue
  } = useForm<Form>();

  const incrementPage = () => {
    setCurrentStep(currentStep + 1);
  };

  return (
    <Wrapper
      variant="full"
      bg='gray.50'
    >
      <Box
        mb={8}
        bg="gray.100"
        p={6}
        textAlign="center"
        fontSize='xs'
        fontWeight="bold"
        color="gray.500"
      >
        Heat Pump Viability
      </Box>

      <Wrapper
        py={4}
        minHeight="80vh"
        variant="text"
      >
        <Box p={2}>
          {currentStep === Steps.Postcode && (
            <Postcode
              initialPostcode={getValues().postcode}
              onSubmit={val => {
                setEPC(null);
                setValue("postcode", val.postcode);
                setValue("addrLine1", val.addrLine1);
                incrementPage();
              }}
            />
          )}

          {currentStep === Steps.EPC && (
            <EPC
              onSubmit={val => {
                setEPC(true);
                setValue("secondHeatDescription", val.secondHeatDescription);
                setValue("epcId", val.epcId);
                setValue("floorDescription", val.floorDescription);
                setValue("heatLossCorridor", val.heatLossCorridor);
                setValue("unheatedCorridorLength", val.unheatedCorridorLength);
                setValue("hotWaterDescription", val.hotWaterDescription);
                setValue("wallsDescription", val.wallsDescription);
                setValue("windowDescription", val.windowDescription);
                setValue("windowsEnergyEff", val.windowsEnergyEff);
                setValue("wallsEnergyEff", val.wallsEnergyEff);
                setValue("lowEnergyPercent", val.lowEnergyPercent);
                setValue("roofDescription", val.roofDescription);
                setValue("floorEnergyEff", val.floorEnergyEff);
                setValue("roofEnergyEff", val.roofEnergyEff);
                setValue("mainHeatingDescription", val.mainHeatingDescription);
                setValue("mainHeatingDescriptionCont", val.mainHeatingDescriptionCont);
                setValue("extensionCount", val.extensionCount);
                setValue("numberHabitableRoms", val.numberHabitableRooms);
                setValue("numberHeatedRooms", val.numberHeatedRooms);
                setValue("fixedLightingCount", val.fixedLightingCount);
                setValue("numberOpenFireplaces", val.numberOpenFireplaces);
                setValue("floorHeight", val.floorHeight);
                setValue("mainFuel", val.mainFuel);
                setValue("propertyType", val.propertyType);
                setValue("wallsShared", val.builtForm);
                setValue("ageBand", val.ageBand);
                setValue("propertySize", val.floorArea);
                setValue("wallConstruction", val.wallConstruction);
                setValue("solarPVPanels", val.photoSupply);
                setValue("roofInsulationThickness", val.roofInsulationThickness);
                setCurrentStep(Steps.ConfirmDetails);
              }}
              onNoEPC={() => {
                setCurrentStep(Steps.PropertyType);
              }}
              addrLine1={getValues().addrLine1 || ""}
              postcode={getValues().postcode}
              onBack={() => setCurrentStep(Steps.Postcode)}
            />
          )}

          {currentStep === Steps.PropertyType && (
            <PropertyDetails
              onSubmit={vals => {
                setValue("propertyType", vals.propertyType);
                setValue("wallsShared", vals.wallsShared);
                setValue("ageBand", vals.ageBand);
                setCurrentStep(Steps.PostBuildWorks);
              }}
              onBack={() => setCurrentStep(Steps.Postcode)}
              initialValues={{
                wallsShared: getValues().wallsShared,
                propertyType: getValues().propertyType,
                ageBand: getValues().ageBand
              }}
            />
          )}

          {currentStep === Steps.ConfirmDetails && (
            <ConfirmDetails
              onSubmit={vals => {
                setValue("wallsDescription", vals.wallsDescription);
                setValue("propertyType", vals.propertyType);
                setValue("wallsShared", vals.wallsShared);
                setValue("ageBand", vals.ageBand);
                setValue("windowDescription", vals.windowDescription);
                setValue("roofInsulationThickness", vals.roofInsulationThickness);
                setValue("solarPVPanels", vals.solarPVPanels);
                setValue("floorDescription", vals.floorDescription);
                setValue("surveyFloorDescription", vals.surveyFloorDescription);
                setValue("surveyWallInsulation", vals.surveyWallInsulation);
                setValue("wallConstruction", vals.wallConstruction);
                setCurrentStep(Steps.HeatingSystem);
              }}
              initialValues={{
                wallsShared: getValues().wallsShared,
                propertyType: getValues().propertyType,
                floorDescription: getValues().floorDescription,
                ageBand: getValues().ageBand,
                wallsDescription: getValues().wallsDescription,
                surveyWallInsulation: getValues().surveyWallInsulation,
                windowDescription: getValues().windowDescription,
                surveyFloorDescription: getValues().surveyFloorDescription,
                roofInsulationThickness: getValues().roofInsulationThickness,
                wallConstruction: getValues().wallConstruction,
                solarPVPanels: getValues().solarPVPanels

              }}
              onBack={() => setCurrentStep(Steps.Postcode)}
            />
          )}

          {currentStep === Steps.PostBuildWorks && (
            <PostBuildWorks
              submitForm={vals => {
                setValue("loftInsInstalled", vals.loftInsInstalled);
                setValue("cavityWallInstalled", vals.cavityWallInstalled);
                setValue("floorInsInstalled", vals.floorInsInstalled);
                setValue("heatingControlsInstalled", vals.heatingControlsInstalled);
                setValue("solarPanelsInstalled", vals.solarPanelsInstalled);
                setCurrentStep(Steps.PropertySize);
              }}
              initialValues={{
                loftInsInstalled: getValues().loftInsInstalled,
                cavityWallInstalled: getValues().cavityWallInstalled,
                floorInsInstalled: getValues().floorInsInstalled,
                heatingControlsInstalled: getValues().heatingControlsInstalled,
                solarPanelsInstalled: getValues().solarPanelsInstalled
              }}
              onBack={() => setCurrentStep(Steps.PropertyType)}
            />
          )}

          {currentStep === Steps.PropertySize && (
            <PropertySize
              onSubmit={vals => {
                setValue("propertySize", Number(vals.propertySize));
                setCurrentStep(Steps.HeatingSystem);
              }}
              onBack={() => setCurrentStep(Steps.PostBuildWorks)}
            />
          )}

          {currentStep === Steps.HeatingSystem && (
            <HeatingSystemForm
              submitForm={vals => {
                setValue("heatingSystem", vals.heatingSystem);
                incrementPage();
              }}
              initialValues={{ heatingSystem: getValues().heatingSystem }}
              onBack={() => {
                if (epc) {
                  setCurrentStep(Steps.ConfirmDetails);
                } else {
                  setCurrentStep(Steps.PropertySize);
                }
              }}
            />
          )}

          {currentStep === Steps.Results && (
            <Results form={getValues()} />
          )}

          {currentStep === Steps.Results && (
            <Box mt={4}>
              <Button
                onClick={() => {
                  reset();
                  setCurrentStep(Steps.Postcode);
                }}
                leftIcon={<FaArrowCircleLeft />}
                variant="ghost"
                fontSize="xs"
              >
                Restart
              </Button>
            </Box>
          )}
        </Box>
      </Wrapper>

      <Box
        mt={8}
        bg="black"
        p={6}
        textAlign="center"
        fontSize='xs'
        fontWeight="grey.50"
        color="gray.200"
      >
        Powered by Genous
      </Box>
    </Wrapper>
  );
};

const Navigation = ({
  disableBack, onBack, onForward, disableForward
}: {disableBack?: boolean; onBack?: () => void; disableForward?: boolean; onForward?: () => void;}) => {
  return (
    <Box
      width="full"
      display="flex"
      justifyContent="space-between"
      my={4}
      color="white"
    >
      <Button
        fontSize="sm"
        bg="black"
        fontWeight="bold"
        borderRadius={0}
        width={150}
        onClick={onBack}
        disabled={disableBack}
      >BACK
      </Button>

      <Button
        fontSize="sm"
        bg="black"
        onClick={onForward}
        fontWeight="bold"
        width={150}
        borderRadius={0}
        disabled={disableForward}
      >NEXT
      </Button>
    </Box>
  );
};

const PostcodeValidation = Joi.object<{addrLine1: string; addrLine2: string; city: string; postcode: string}>({
  postcode: Joi.string().required(),
  addrLine1: Joi.string(),
  addrLine2: Joi.string(),
  city: Joi.string()
});

const Postcode = ({ initialPostcode, onSubmit }: {initialPostcode?: string; onSubmit: ({ postcode, addrLine1 }: { addrLine1: string; postcode: string }) => void}) => {
  const {
    control, handleSubmit, watch, formState: { isValid }
  } = useForm<{postcode: string, addrLine2: string; addrLine1: string}>({
    defaultValues: { postcode: initialPostcode || "" },
    resolver: joiResolver(PostcodeValidation)
  });

  watch();

  const onSubmitForm = (val: { addrLine1: string; addrLine2: string; postcode: string }) => {
    onSubmit({
      postcode: val.postcode,
      addrLine1: `${val.addrLine1 || ""} ${val.addrLine2 || ""}`
    });
  };

  return (
    <Box
      style={{ borderImage: "linear-gradient(to left, #9B72F2, #88D69E) 1" }}
      borderColor="linear-gradient(135deg, #9B72F2, #88D69E)"
      borderWidth={2}
      padding={8}
    >
      <Text mb={2}>First, we need your postcode</Text>

      <Controller
        name="postcode"
        control={control}
        render={({ field }) => {
          return (
            <Input
              background={theme.colors.gray[ 100 ]}
              type="text"
              mb="1rem"
              placeholder="Postcode"
              {...field}
            />
          );
        }}
      />

      <Text
        textAlign="center"
        my={4}
      >Optional
      </Text>

      <Controller
        name="addrLine1"
        control={control}
        render={({ field }) => {
          return (
            <Input
              background={theme.colors.gray[ 100 ]}
              type="text"
              mb="1rem"
              placeholder="Address Line 1"
              {...field}
            />
          );
        }}
      />

      <Controller
        name="addrLine2"
        control={control}
        render={({ field }) => {
          return (
            <Input
              background={theme.colors.gray[ 100 ]}
              type="text"
              mb="1rem"
              placeholder="Address Line 2"
              {...field}
            />
          );
        }}
      />

      <Navigation
        onForward={handleSubmit(onSubmitForm)}
        disableBack
        disableForward={!isValid}
      />
    </Box>
  );
};

const EPC = ({
  postcode, addrLine1, onBack, onSubmit, onNoEPC
}: { onBack: () => void; addrLine1: string; postcode: string, onNoEPC: () => void; onSubmit: (props: {wallsEnergyEff: string; hotWaterDescription: string; windowsEnergyEff: string; windowDescription: string;epcId: string; wallsDescription: string; lowEnergyPercent: number; roofDescription: string; floorEnergyEff: string; roofEnergyEff: string; mainHeatingDescription: string; mainHeatingDescriptionCont: string; floorHeight: number; mainFuel: string; fixedLightingCount: number; numberOpenFireplaces: number; numberHeatedRooms: number; numberHabitableRooms: number; extensionCount: number; lowEnergyLightingPercent: number; roofInsulationThickness: string; photoSupply: number; floorInsulation: string; glazingType: string; wallConstruction: string; ageBand: AgeBand; builtForm: WallsShared; floorArea: number; propertyType: PropertyType }) => void }) => {
  const onEPC = useCallback(val => {
    if (!val) {
      onNoEPC();
    } else {
      onSubmit({
        epcId: val[ "lmk-key" ],
        unheatedCorridorLength: Number(val[ "unheated-corridor-length" ]),
        wallsDescription: val[ "walls-description" ],
        windowDescription: val[ "windows-description" ],
        windowsEnergyEff: val[ "windows-energy-eff" ],
        hotWaterDescription: val[ "hot-water-description" ],
        wallsEnergyEff: val[ "walls-energy-eff" ],
        lowEnergyPercent: Number(val[ "low-energy-lighting" ]),
        builtForm: val[ "built-form" ],
        propertyType: val[ "property-type" ],
        secondHeatDescription: val[ "secondheat-description" ],
        ageBand: val[ "construction-age-band" ],
        floorArea: Number(val[ "total-floor-area" ]),
        photoSupply: Number(val[ "photo-supply" ]),
        roofDescription: val[ "roof-description" ],
        floorDescription: val[ "floor-description" ],
        roofInsulationThickness: EPCMappers.roofInsThickness(val[ "roof-description" ]),
        lowEnergyLightingPercent: Number(val[ "low-energy-lighting" ]),
        extensionCount: Number(val[ "extension-count" ]),
        numberHabitableRooms: Number(val[ "number-habitable-rooms" ]),
        numberHeatedRooms: Number(val[ "number-heated-rooms" ]),
        numberOpenFireplaces: Number(val[ "number-open-fireplaces" ]),
        floorEnergyEff: val[ "floor-energy-eff" ],
        country: val[ "country" ],
        roofEnergyEff: val[ "roof-energy-eff" ],
        fixedLightingCount: Number(val[ "fixed-lighting-outlets-count" ]),
        mainFuel: val[ "main-fuel" ],
        mainHeatingDescription: val[ "mainheat-description" ],
        mainHeatingDescriptionCont: val[ "mainheatcont-description" ],
        heatLossCorridor: val[ "heat-loss-corridor" ],
        floorHeight: Number(val[ "number-open-fireplaces" ])
      });
    }
  }, [ onSubmit, onNoEPC ]);

  const { control, setValue } = useForm();

  return (
    <Box>
      <EPCLookup
        control={control}
        postcode={postcode}
        addrLine1={addrLine1}
        onData={onEPC}
        setValue={setValue}
        question={{
          id: 0,
          slug: "xyz"
        }}
      />

      <Navigation
        disableForward
        onBack={onBack}
      />
    </Box>
  );
};

const PropertyDetailsValidation = Joi.object<{propertyType: string; wallsShared: string; ageBand: string}>({
  propertyType: Joi.string().required(),
  wallsShared: Joi.string().required(),
  ageBand: Joi.string().required()
});

const PropertyDetails = ({
  initialValues, onSubmit, onBack
}: { initialValues: {propertyType: PropertyType; wallsShared: WallsShared, ageBand: AgeBand}, onBack: () => void; onSubmit: (vals: {wallsShared: WallsShared; propertyType: PropertyType; ageBand: AgeBand}) => void }) => {
  const {
    control, register, watch, handleSubmit, formState: { isValid }
  } = useForm<Form>({
    resolver: joiResolver(PropertyDetailsValidation),
    defaultValues: {
      wallsShared: initialValues.wallsShared,
      propertyType: initialValues.propertyType,
      ageBand: initialValues.ageBand
    }
  });

  const onSubmitForm = (vals: {wallsShared: WallsShared; propertyType: PropertyType; ageBand: AgeBand}) => {
    onSubmit(vals);
  };

  watch();

  return (
    <Box
      style={{ borderImage: "linear-gradient(to left, #9B72F2, #88D69E) 1" }}
      borderColor="linear-gradient(135deg, #9B72F2, #88D69E)"
      borderWidth={2}
      padding={8}
    >
      <Text
        fontWeight="bold"
        mb={2}
      >Property Details
      </Text>

      <Text
        fontSize="sm"
        color={theme.colors.gray[ 600 ]}
        mb={4}
      >Okay, now we need to confirm some details about your home construction, as this will impact its heat loss and what you can do to reduce it.
      </Text>

      <label>Property Type</label>

      <Controller
        name="propertyType"
        control={control}
        render={({ field }) => {
          return (
            <Select
              background={theme.colors.gray[ 100 ]}
              mb="1rem"
              {...field}
            >
              <option />

              <option value="House">House</option>

              <option value="Bungalow">Bungalow</option>

              <option value="Flat">Flat/Masionette</option>

              <option value="Park home">Park home</option>
            </Select>

          );
        }}
      />

      <label>Walls Shared</label>

      <Controller
        name="wallsShared"
        control={control}
        render={({ field }) => {
          return (
            <Select
              background={theme.colors.gray[ 100 ]}
              mb="1rem"
              {...field}
            >
              <option />

              <option>Detached</option>

              <option>Semi-detached</option>

              <option>Semi-detached/End-terrace</option>

              <option>Mid-terrace</option>

              <option>End-terrace</option>

              <option>Enclosed end-terrace</option>

              <option>Enclosed mid-terrace</option>
            </Select>

          );
        }}
      />

      <label>Age</label>

      <Controller
        name="ageBand"
        control={control}
        render={({ field }) => {
          return (
            <Select
              background={theme.colors.gray[ 100 ]}
              mb="1rem"
              {...field}
            >
              <option />

              <option value="England and Wales: Before 1900">Before 1900</option>

              <option value="England and Wales: 1900-1929">1900-1929</option>

              <option value="England and Wales: 1930-1949">1930-1949</option>

              <option value="England and Wales: 1950-1966">1950-1966</option>

              <option value="England and Wales: 1967-1975">1967-1975</option>

              <option value="England and Wales: 1976-1982">1976-1982</option>

              <option value="England and Wales: 1983-1990">1983-1990</option>

              <option value="England and Wales: 1991-1995">1991-1995</option>

              <option value="England and Wales: 1996-2002">1996-2002</option>

              <option value="England and Wales: 2003-2006">2003-2006</option>

              <option value="England and Wales: 2007-2011">2007-2011</option>

              <option value="England and Wales: 2012 onwards">2012 onwards</option>

            </Select>

          );
        }}
      />

      <Navigation
        onBack={onBack}
        onForward={handleSubmit(onSubmitForm)}
        disableForward={!isValid}
      />

    </Box>
  );
};

interface ConfirmDetailsProps {
  ageBand: AgeBand;
  propertyType: PropertyType;
  wallsShared: WallsShared;
  floorDescription: FloorDescription;
  surveyWallInsulation: string;
  roofInsulationThickness: RoofInsulationThickness;
  surveyFloorDescription: string;
  wallConstruction: WallConstruction;
  windowDescription: GlazingType;
  solarPVPanels: number;
  wallsDescription: string;
}

const ConfirmDetails = ({
  initialValues, onSubmit, onBack
}: { onSubmit: (val: ConfirmDetailsProps) => void; onBack: () => void; initialValues: ConfirmDetailsProps }) => {
  const {
    control, handleSubmit, watch, getValues
  } = useForm<Form>({
    defaultValues: {
      surveyFloorDescription: initialValues.surveyFloorDescription,
      surveyWallInsulation: initialValues.surveyWallInsulation
    }
  });

  const onSubmitForm = (vals: ConfirmDetailsProps) => {
    onSubmit({
      ...initialValues,
      ...Object.keys(vals).filter(key => vals[ key ] ? true : false).reduce((prev, curr) => ({
        ...prev,
        [ curr ]: vals[ curr ]
      }), {})
    });
  };

  watch();

  return (
    <Box
      style={{ borderImage: "linear-gradient(to left, #9B72F2, #88D69E) 1" }}
      borderColor="linear-gradient(135deg, #9B72F2, #88D69E)"
      borderWidth={2}
      padding={8}
    >
      <Text
        mb={2}
        fontWeight="bold"
      >Confirm All Details
      </Text>

      <Text
        fontSize="sm"
        color={theme.colors.gray[ 600 ]}
        mb={4}
      >Please correct or fill in any missing details from your EPC for the main part of the property (extensions may be different)
      </Text>

      <Grid
        mb={2}
        fontWeight="bold"
        templateColumns='repeat(12, 1fr)'
      >
        <GridItem colSpan={4} />

        <GridItem colSpan={4}>EPC</GridItem>

        <GridItem colSpan={4}>Correct if Needed</GridItem>
      </Grid>

      <Controller
        name="propertyType"
        control={control}
        render={({ field }) => {
          return (
            <Grid templateColumns='repeat(12, 1fr)'>
              <GridItem colSpan={4}>
                <Text
                  mr={4}
                  whiteSpace="nowrap"
                >Property Type
                </Text>
              </GridItem>

              <GridItem colSpan={4}>
                <Text mr={4}>{initialValues.propertyType}</Text>
              </GridItem>

              <GridItem colSpan={4}>
                <Select
                  background={theme.colors.gray[ 100 ]}
                  mb="1rem"
                  {...field}
                >
                  <option />

                  <option value="House">House</option>

                  <option value="Bungalow">Bungalow</option>

                  <option value="Flat">Flat/Masionette</option>

                  <option value="Park Home">Park home</option>
                </Select>
              </GridItem>
            </Grid>
          );
        }}
      />

      <Controller
        name="wallsShared"
        control={control}
        render={({ field }) => {
          return (
            <Grid templateColumns='repeat(12, 1fr)'>
              <GridItem colSpan={4}>
                <Text
                  mr={4}
                  whiteSpace="nowrap"
                >Walls Shared
                </Text>
              </GridItem>

              <GridItem colSpan={4}>
                <Text mr={4}>{initialValues.wallsShared}</Text>
              </GridItem>

              <GridItem colSpan={4}>
                <Select
                  background={theme.colors.gray[ 100 ]}
                  mb="1rem"
                  {...field}
                >
                  <option />

                  <option>Detached</option>

                  <option>Semi-detached</option>

                  <option>Semi-detached/End-terrace</option>

                  <option>Mid-terrace</option>

                  <option>End-Terrace</option>

                  <option>Enclosed end-terrace</option>

                  <option>Enclosed mid-terrace</option>
                </Select>
              </GridItem>
            </Grid>
          );
        }}
      />

      <Controller
        name="ageBand"
        control={control}
        render={({ field }) => {
          return (
            <Grid templateColumns='repeat(12, 1fr)'>
              <GridItem colSpan={4}>
                <Text
                  mr={4}
                  whiteSpace="nowrap"
                >Age Band
                </Text>
              </GridItem>

              <GridItem colSpan={4}>
                <Text mr={4}>{initialValues.ageBand}</Text>
              </GridItem>

              <GridItem colSpan={4}>

                <Select
                  background={theme.colors.gray[ 100 ]}
                  mb="1rem"
                  {...field}
                >
                  <option />

                  <option value="England and Wales: Before 1900">Before 1900</option>

                  <option value="England and Wales: 1900-1929">1900-1929</option>

                  <option value="England and Wales: 1930-1949">1930-1949</option>

                  <option value="England and Wales: 1950-1966">1950-1966</option>

                  <option value="England and Wales: 1967-1975">1967-1975</option>

                  <option value="England and Wales: 1976-1982">1976-1982</option>

                  <option value="England and Wales: 1983-1990">1983-1990</option>

                  <option value="England and Wales: 1991-1995">1991-1995</option>

                  <option value="England and Wales: 1996-2002">1996-2002</option>

                  <option value="England and Wales: 2003-2006">2003-2006</option>

                  <option value="England and Wales: 2007-2011">2007-2011</option>

                  <option value="England and Wales: 2012 onwards">2012 onwards</option>

                </Select>
              </GridItem>
            </Grid>

          );
        }}
      />

      <Controller
        name="surveyFloorDescription"
        control={control}
        render={({ field }) => {
          return (
            <Grid templateColumns='repeat(12, 1fr)'>
              <GridItem colSpan={4}>
                <Text
                  mr={4}
                  whiteSpace="nowrap"
                >Floor Description
                </Text>
              </GridItem>

              <GridItem colSpan={4}>
                <Text mr={4}>{initialValues.floorDescription}</Text>
              </GridItem>

              <GridItem colSpan={4}>

                <Select
                  background={theme.colors.gray[ 100 ]}
                  mb="1rem"
                  {...field}
                >
                  <option />

                  <option>Unknown</option>

                  <option>As-built</option>

                  <option>Retro-fitted</option>

                </Select>
              </GridItem>
            </Grid>
          );
        }}
      />

      <Controller
        name="windowDescription"
        control={control}
        render={({ field }) => {
          return (
            <Grid
              mb={2}
              templateColumns='repeat(12, 1fr)'
            >
              <GridItem colSpan={4}>
                <Text
                  mr={4}
                  whiteSpace="nowrap"
                >Glazing Type
                </Text>
              </GridItem>

              <GridItem colSpan={4}>
                <Text mr={4}>{initialValues.windowDescription}</Text>
              </GridItem>

              <GridItem colSpan={4}>

                <Select
                  background={theme.colors.gray[ 100 ]}
                  mb="1rem"
                  {...field}
                >
                  <option />

                  <option value="double glazing installed before 2002">Double glazing installed before 2002</option>

                  <option value="double glazing installed during or after 2002">Double glazing installed during or after 2002</option>

                  <option value="double glazing installed before 2003">Double glazing installed before 2003</option>

                  <option value="double glazing installed during or after 2003">Double glazing installed during or after 2003</option>

                  <option value="double glazing installed before 2006">Double glazing installed before 2006</option>

                  <option value="double glazing installed during or after 2006">Double glazing installed during or after 2006</option>

                  <option value="double glazing, unknown install date">Double glazing, unknown install date</option>

                  <option value="single glazed">Single Glazing</option>

                  <option value="secondary glazing">Secondary Glazing</option>

                  <option value="triple glazing">Triple Glazing</option>

                </Select>
              </GridItem>
            </Grid>

          );
        }}
      />

      <Controller
        name="solarPVPanels"
        control={control}
        render={({ field }) => {
          return (
            <Grid templateColumns='repeat(12, 1fr)'>
              <GridItem colSpan={4}>
                <Text
                  mr={4}
                >Solar (PV) Panels – Percentage Coverage of Roof
                </Text>
              </GridItem>

              <GridItem colSpan={4}>
                <Text
                  mt={4}
                  mr={4}
                >{initialValues.solarPVPanels}
                </Text>
              </GridItem>

              <GridItem colSpan={4}>

                <Box
                  display="flex"
                  alignItems="center"
                >
                  <Input
                    width={100}
                    type="number"
                    background={theme.colors.gray[ 100 ]}
                    mb="1rem"
                    {...field}
                  />

                  <Text
                    fontSize='lg'
                    fontWeight="semibold"
                    pb={2}
                    pl={2}
                  >%
                  </Text>
                </Box>
              </GridItem>
            </Grid>
          );
        }}
      />

      <Box mt={4}>
        <Grid templateColumns='repeat(12, 1fr)'>
          <GridItem colSpan={8} />

          <GridItem
            colSpan={4}
            mb={2}
            fontSize={12}
          >Correct insulation if needed
          </GridItem>

        </Grid>

        <Controller
          name='roofInsulationThickness'
          control={control}
          render={({ field }) => {
            return (
              <Grid templateColumns='repeat(12, 1fr)'>
                <GridItem colSpan={4}>
                  <Text
                    mr={4}
                    whiteSpace="nowrap"
                  >Roof Type
                  </Text>
                </GridItem>

                <GridItem colSpan={4}>
                  <Text mr={4}>{initialValues.roofInsulationThickness}</Text>
                </GridItem>

                <GridItem colSpan={4}>

                  <Select
                    background={theme.colors.gray[ 100 ]}
                    mb="1rem"
                    {...field}
                  >
                    <option />

                    <option>Unknown</option>

                    <option>None</option>

                    <option>12mm</option>

                    <option>25mm</option>

                    <option>50mm</option>

                    <option>75mm</option>

                    <option>100mm</option>

                    <option>150mm</option>

                    <option>200mm</option>

                    <option>250mm</option>

                    <option>270mm</option>

                    <option>300mm</option>

                    <option>350mm</option>

                    <option>400mm or more</option>

                    <option>Not Defined</option>

                  </Select>
                </GridItem>
              </Grid>

            );
          }}
        />

        <Controller
          name="surveyWallInsulation"
          control={control}
          render={({ field }) => {
            return (
              <Grid templateColumns='repeat(12, 1fr)'>
                <GridItem colSpan={4}>
                  <Text
                    mr={4}
                    whiteSpace="nowrap"
                  >
                    Wall Type
                  </Text>
                </GridItem>

                <GridItem colSpan={4}>
                  <Text mr={4}>{initialValues.wallsDescription}</Text>
                </GridItem>

                <GridItem colSpan={4}>

                  <Select
                    background={theme.colors.gray[ 100 ]}
                    mb="1rem"
                    {...field}
                  >
                    <option />

                    <option value="External">External</option>

                    <option value="Filled cavity">Filled cavity</option>

                    <option value="Internal">Internal</option>

                    <option value="As built">As built</option>

                    <option value="Filled cavity + external">Filled cavity + external</option>

                    <option value="Filled cavity + internal">Filled cavity + internal</option>

                    <option value="Unknown">Unknown</option>

                  </Select>
                </GridItem>
              </Grid>

            );
          }}
        />
      </Box>

      <Navigation
        onBack={onBack}
        onForward={handleSubmit(onSubmitForm)}
      />
    </Box>
  );
};

interface PostBuildWorksProps {
  loftInsInstalled: "Yes" | "No";
  cavityWallInstalled: "Yes" | "No";
  floorInsInstalled: "Yes" | "No";
  heatingControlsInstalled: "Yes" | "No";
  solarPanelsInstalled: "Yes" | "No";
}

const PostBuildWorks = ({
  initialValues, onBack, submitForm
}: { onBack: () => void, submitForm: (vals: PostBuildWorksProps) => void, initialValues: Partial<PostBuildWorksProps> }) => {
  const { control, handleSubmit } = useForm<Form>({ defaultValues: initialValues });

  const onSubmitForm = (vals: PostBuildWorksProps) => {
    submitForm(vals);
  };

  return (
    <Box
      padding={8}
      style={{ borderImage: "linear-gradient(to left, #9B72F2, #88D69E) 1" }}
      borderColor="linear-gradient(135deg, #9B72F2, #88D69E)"
      borderWidth={2}
    >
      <Text mb={2}>Have any of these been installed in the property since it was built?</Text>

      <label>Loft Insulation Installed</label>

      <Controller
        name='loftInsInstalled'
        control={control}
        defaultValue="No"
        render={({ field }) => {
          return (
            <Select
              background={theme.colors.gray[ 100 ]}
              mb="1rem"
              {...field}
            >
              <option>No</option>

              <option>Yes</option>

            </Select>

          );
        }}
      />

      <label>Cavity Wall Installed</label>

      <Controller
        name="cavityWallInstalled"
        control={control}
        defaultValue="No"
        render={({ field }) => {
          return (
            <Select
              background={theme.colors.gray[ 100 ]}
              mb="1rem"
              {...field}
            >
              <option>No</option>

              <option>Yes</option>

            </Select>

          );
        }}
      />

      <label>Floor Insulation Installed</label>

      <Controller
        name="floorInsInstalled"
        defaultValue="No"
        control={control}
        render={({ field }) => {
          return (
            <Select
              background={theme.colors.gray[ 100 ]}
              mb="1rem"
              {...field}
            >
              <option>No</option>

              <option>Yes</option>

            </Select>

          );
        }}
      />

      <label>Smart Heating Controls Installed</label>

      <Controller
        name="heatingControlsInstalled"
        defaultValue="No"
        control={control}
        render={({ field }) => {
          return (
            <Select
              background={theme.colors.gray[ 100 ]}
              mb="1rem"
              {...field}
            >
              <option>No</option>

              <option>Yes</option>

            </Select>

          );
        }}
      />

      <label>Solar Panels Installed</label>

      <Controller
        name="solarPanelsInstalled"
        control={control}
        defaultValue="No"
        render={({ field }) => {
          return (
            <Select
              background={theme.colors.gray[ 100 ]}
              mb="1rem"
              {...field}
            >
              <option>No</option>

              <option>Yes</option>

            </Select>

          );
        }}
      />

      <Navigation
        onBack={onBack}
        onForward={handleSubmit(onSubmitForm)}
      />
    </Box>
  );
};

interface PropertySizeProps {
  propertySize: number;
}

const PropertySize = ({ onSubmit, onBack }: {onBack: () => void; onSubmit: ({ propertySize }: PropertySizeProps) => void}) => {
  const {
    control, handleSubmit, getValues, watch, formState
  } = useForm<{estimated: string, actual: number}>({ });

  const onSubmitForm = (valid: {estimated: number, actual: number}) => {
    if (valid.estimated) {
      onSubmit({ propertySize: valid.estimated });
    } else {
      onSubmit({ propertySize: valid.actual });
    }
  };

  watch();

  return (
    <Box
      padding={8}
      style={{ borderImage: "linear-gradient(to left, #9B72F2, #88D69E) 1" }}
      borderColor="linear-gradient(135deg, #9B72F2, #88D69E)"
      borderWidth={2}
    >
      <Text
        mb={2}
        fontWeight="bold"
      >Property Size
      </Text>

      <Text mb={2}>If you know the actual size, enter it. Otherwise, estimate it.</Text>

      <label>Actual</label>

      <Controller
        name="actual"
        control={control}
        render={({ field }) => {
          return (
            <Box
              display="flex"
              alignItems="center"
            >
              <Input
                type="number"
                width={100}
                background={theme.colors.gray[ 100 ]}
                mb="1rem"
                {...field}
              />

              <Text
                fontWeight="semibold"
                ml={2}
                mb={2}
              >m2
              </Text>
            </Box>

          );
        }}
      />

      <label>Estimated</label>

      <Controller
        name="estimated"
        control={control}
        render={({ field }) => {
          return (
            <Select
              background={theme.colors.gray[ 100 ]}
              mb="1rem"
              {...field}
            >
              <option />

              <option value={60}>Small (less than 60 sqm/ 600 sqft - likely studio/small 1-bed)</option>

              <option value={100}>Medium (60-120 sqm/ 600-1200 sqft - likely 1-3 beds)</option>

              <option value={150}>Large (120-200 sqm/ 1200-2000 sq ft - likely 4-5 beds)</option>

              <option value={200}>Very large (over 200 sqm/ 2000 sq ft - likely 5 beds+)</option>

            </Select>

          );
        }}
      />

      <Navigation
        onBack={onBack}
        onForward={handleSubmit(onSubmitForm)}
        disableForward={!getValues().actual && !getValues().estimated}
      />
    </Box>
  );
};

interface HeatingSystemProps {
  heatingSystem: HeatingSystem;
}

const HeatingSystemValidation = Joi.object<{heatingSystem: string;}>({ heatingSystem: Joi.string().required() });

const HeatingSystemForm = ({
  initialValues, onBack, submitForm
}: { onBack: () => void, submitForm: (val: HeatingSystemProps) => void, initialValues: HeatingSystemProps}) => {
  const {
    setValue, watch, getValues, handleSubmit, formState: { isValid }
  } = useForm<HeatingSystemProps>({
    resolver: joiResolver(HeatingSystemValidation),
    defaultValues: { heatingSystem: initialValues.heatingSystem }
  });

  const onSubmitForm = (val: HeatingSystemProps) => {
    submitForm(val);
  };

  watch();

  return (
    <Box
      style={{ borderImage: "linear-gradient(to left, #9B72F2, #88D69E) 1" }}
      borderColor="linear-gradient(135deg, #9B72F2, #88D69E)"
      borderWidth={2}
      padding={8}
    >
      <Text
        fontWeight="bold"
        mb={2}
      >Heating System
      </Text>

      <Text mb={2}>The type of heating you have determines both its efficiency and carbon intensity so it's important we know what you use.</Text>

      <SelectCard
        onClick={() => setValue("heatingSystem", "Mains gas or LPG; boiler under 10 yrs old")}
        isChecked={getValues().heatingSystem === "Mains gas or LPG; boiler under 10 yrs old"}
        text="Mains gas or LPG; boiler under 10 yrs old"
      />

      <SelectCard
        onClick={() => setValue("heatingSystem", "Mains gas or LPG; boiler over 10 yrs old")}
        isChecked={getValues().heatingSystem === "Mains gas or LPG; boiler over 10 yrs old"}
        text="Mains gas or LPG; boiler over 10 yrs old"
      />

      <SelectCard
        onClick={() => setValue("heatingSystem", "Heating oil; boiler under 10 yrs old")}
        isChecked={getValues().heatingSystem === "Heating oil; boiler under 10 yrs old"}
        text="Heating oil; boiler under 10 yrs old"
      />

      <SelectCard
        onClick={() => setValue("heatingSystem", "Heating oil; boiler over 10 yrs old")}
        isChecked={getValues().heatingSystem === "Heating oil; boiler over 10 yrs old"}
        text="Heating oil; boiler over 10 yrs old"
      />

      <SelectCard
        onClick={() => setValue("heatingSystem", "Heat pump (ground-source)")}
        isChecked={getValues().heatingSystem === "Heat pump (ground-source)"}
        text="Heat pump (ground-source)"
      />

      <SelectCard
        onClick={() => setValue("heatingSystem", "Heat pump (air-source)")}
        isChecked={getValues().heatingSystem === "Heat pump (air-source)"}
        text="Heat pump (air-source)"
      />

      <SelectCard
        onClick={() => setValue("heatingSystem", "Electric heating (not heat pump)")}
        isChecked={getValues().heatingSystem === "Electric heating (not heat pump)"}
        text="Electric heating (not heat pump)"
      />

      <SelectCard
        onClick={() => setValue("heatingSystem", HeatingSystem[ "District/ communal heating" ])}
        isChecked={getValues().heatingSystem === "District/ communal heating"}
        text="District/ communal heating"
      />

      <SelectCard
        onClick={() => setValue("heatingSystem", HeatingSystem[ "Biomass boiler" ])}
        isChecked={getValues().heatingSystem === "Biomass boiler"}
        text="Biomass boiler"
      />

      <Navigation
        onBack={onBack}
        onForward={handleSubmit(onSubmitForm)}
        disableForward={!getValues().heatingSystem}
      />

    </Box>
  );
};

const SelectCard = ({
  text, isChecked, onClick
}: {onClick: () => void; isChecked: boolean; text: string}) => (
  <Box
    display="flex"
    bg="gray.100"
    alignItems="center"
    onClick={onClick}
    cursor="pointer"
    mb={4}
    p={4}
  >
    <Checkbox
      width={0.5}
      height={4}
      isChecked={isChecked}
      mr={8}
    />

    <Text>{text}</Text>
  </Box>
);

const Results = ({ form }: {form: Form}) => {
  const [ res, req ] = usePolypipeAPI("CALC");

  useEffect(() => {
    req(form);
  }, [ req, form ]);

  if (res.error) {
    return (
      <Box>
        <Text>There was an error calculating heat pumps, please try again later.</Text>
      </Box>
    );
  }

  if (!res.complete && !res.data) {
    return <LoadingPage text="Working out heat pump viability" />;
  }

  const output = res.data!.data;

  if (!output.canAddHP) {
    return (
      <Box>
        <Text>A heat pump may not be suitable for your property. <a
          style={{ color: "blue" }}
          href="https://genous.earth/contact"
                                                                 >Please message Genous
                                                                 </a> and we’ll give you a personalised response.
        </Text>
      </Box>
    );
  }

  return (
    <Box>
      <YouCanSave
        cost ={output.withORHPAndUF.heatingUsagePounds}
        co2={output.withORHPAndUF.co2ePerYear}
      />

      <Text mb={2}>A heat pump looks like it should be suitable for your home. See the detailed potential savings against your current heating system below:</Text>

      <Box>
        <Grid
          templateColumns='repeat(12, 1fr)'
        >
          <GridItem colSpan={6} />

          <GridItem
            colSpan={6}
            fontSize={12}
            textAlign="right"
          >Air-source heat pump
          </GridItem>
        </Grid>

        <Grid
          mb={2}
          textAlign="right"
          templateColumns='repeat(12, 1fr)'
          fontSize={12}
        >
          <GridItem colSpan={3} />

          <GridItem colSpan={3}>With current heating system</GridItem>

          <GridItem
            colSpan={3}
            mr={2}
          >Heat pump + current radiators savings
          </GridItem>

          <GridItem
            colSpan={3}
            fontWeight="semibold"
          >Heat pump + underfloor savings
          </GridItem>

        </Grid>

        <Grid
          mb={2}
          textAlign="right"
          templateColumns='repeat(12, 1fr)'
        >
          <GridItem
            colSpan={3}
            textAlign="left"
            fontSize={12}
          >Heating and electricity usage
          </GridItem>

          <GridItem colSpan={3}>{Number(output.asIs.heatingUsageEnergy.toFixed(0)).toLocaleString()}kWh <Box> £{Number(output.asIs.heatingUsagePounds.toFixed(0)).toLocaleString()}</Box></GridItem>

          <GridItem colSpan={3}>{Number(output.withORHP.heatingUsageEnergy.toFixed(0)).toLocaleString()}kWh

            <Box
              display={{
                base: "none",
                md: "inline"
              }}
            > ({((output.withORHP.heatingUsageEnergy / output.asIs.heatingUsageEnergy) * 100).toFixed(0)}%)
            </Box>

            <Box>£{Number(output.withORHP.heatingUsagePounds.toFixed(0)).toLocaleString()}

              <Box
                display={{
                  base: "none",
                  md: "inline"
                }}
              > ({(output.withORHP.heatingUsagePounds / output.asIs.heatingUsagePounds * 100).toFixed(0)}%)
              </Box>
            </Box>
          </GridItem>

          <GridItem
            colSpan={3}
            fontWeight="semibold"
          >{Number(output.withORHPAndUF.heatingUsageEnergy.toFixed(0)).toLocaleString()}kWh

            <Box
              display={{
                base: "none",
                md: "inline"
              }}
            >
              ({((output.withORHPAndUF.heatingUsageEnergy / output.asIs.heatingUsageEnergy) * 100).toFixed(0)}%)
            </Box>

            <Box>£{Number(output.withORHPAndUF.heatingUsagePounds.toFixed(0)).toLocaleString()}

              <Box
                display={{
                  base: "none",
                  md: "inline"
                }}
              >
                ({(output.withORHPAndUF.heatingUsagePounds / output.asIs.heatingUsagePounds * 100).toFixed(0)}%)
              </Box>
            </Box>
          </GridItem>

        </Grid>

        <Grid
          mb={2}
          textAlign="right"
          templateColumns='repeat(12, 1fr)'
        >
          <GridItem
            colSpan={3}
            textAlign="left"
            fontSize={12}
          >CO2e per year (tonnes)
          </GridItem>

          <GridItem
            colSpan={3}
          >{output.asIs.co2ePerYear.toFixed(1)}t
          </GridItem>

          <GridItem
            colSpan={3}
          >{output.withORHP.co2ePerYear.toFixed(1)}t <Box
            display={{
              base: "none",
              md: "inline"
            }}
                                                     > ({((1 - (output.withORHP.co2e / output.asIs.co2ePerYear)) * 100).toFixed(0)}%)
                                                     </Box>
          </GridItem>

          <GridItem
            colSpan={3}
            fontWeight="semibold"
          >{output.withORHPAndUF.co2ePerYear.toFixed(1)}t <Box
            display={{
              base: "none",
              md: "inline"
            }}
                                                          >({((1 - (output.withORHPAndUF.co2e / output.asIs.co2ePerYear)) * 100).toFixed(0)}%)
                                                          </Box>
          </GridItem>

        </Grid>

        {/*
        <Grid
          mb={4}
          templateColumns='repeat(12, 1fr)'
          textAlign="right"
        >
          <GridItem
            colSpan={3}
            textAlign="left"
            fontSize={12}
          >EPC band/score &#185;
          </GridItem>

          <GridItem colSpan={3}>{output.asIs.sapGrade}/{(output.asIs.sapNumber.toFixed())}</GridItem>

          <GridItem colSpan={3}>{printSAP(output.withORHP.sapNumberDiff)} ({output.withORHP.sapGrade}/{output.withORHP.sapNumber.toFixed(0)})</GridItem>

          <GridItem
            colSpan={3}
            fontWeight="semibold"
          >{printSAP(output.withORHPAndUF.sapNumberDiff)} ({output.withORHPAndUF.sapGrade}/{output.withORHPAndUF.sapNumber.toFixed(0)})
          </GridItem>

        </Grid>*/}

      </Box>

      <Text mb={4}>If you'd like to get a quote for underfloor heating, or want a quote for heat pumps (and/or other home efficiency technologies like solar panels or insulation) with underfloor heating, click below:</Text>

      <Box
        display="flex"
        flexDir="row"
        justifyContent="space-between"
      >
        <Button
          px={2}
          width="45%"
          fontSize={10}
          variant="ghost"
          color="white"
          _hover={{
            color: "black",
            bg: "gray.100"
          }}
          borderRadius={0}
          background="black"
          rightIcon={<FaArrowCircleRight />}
          onClick={() => window.open("https://www.polypipeufh.com/get-a-quote/")}
        >
          Get a UFH quote only
        </Button>

        <Button
          width="45%"
          background="black"
          _hover={{
            color: "black",
            bg: "gray.100"
          }}
          color="white"
          borderRadius={0}
          px={2}
          fontSize={10}
          rightIcon={<FaArrowCircleRight />}
          variant="ghost"
          onClick={() => window.open(`${isStage("production") ? "https://genous.earth" : "https://staging.genous.earth"}/footprint-anon?pId=` + res.data?.data.propertyId)}
        >Get HP/retrofit quote (incl. UFH)
        </Button>
      </Box>

      <Box mt={4}>
        <Text
          fontSize="xs"
          textColor={theme.colors.gray[ 500 ]}
        >Note: illustrative savings using Genous Gold ASHP at 60 degrees C (current radiators) and 45 degrees C (underfloor); further details are required for an accurate performance estimate. &#185;Based on Genous digital twin model, which may differ from your actual EPC.
        </Text>
      </Box>

    </Box>
  );
};

const YouCanSave = ({ co2, cost }: {co2: number; cost: number}) => (
  <Box
    mb={4}
    borderWidth={5}
    padding={2}
    textAlign="center"
    style={{ borderImage: "linear-gradient(to left, #9B72F2, #88D69E) 1" }}
    borderColor="linear-gradient(135deg, #9B72F2, #88D69E)"
  >
    <Text fontWeight="semibold">With an air source heat pump and underfloor heating you could potentially save <span
      style={{
        color: "#9B72F2",
        fontWeight: "bold"
      }}
                                                                                                               >£{Number(cost.toFixed(0)).toLocaleString()}
                                                                                                               </span> and <span
      style={{
                                                                                                                   color: "#9B72F2",
                                                                                                                   fontWeight: "bold"
                                                                                                                 }}
                >{co2.toFixed(1)}
    </span> tonnes CO2e each year
    </Text>
  </Box>

);

const printSAP = (number: number) => number > 0 ? `+${number.toFixed(0)}` : number.toFixed(0);